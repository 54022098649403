const GRID_COMMON_COLUMN_CONFIG_NAME = 'documents_all_grid';
const GRID_REMOVED_COLUMN_CONFIG_NAME = 'documents_all_removed';
const GRID_REJECTED_COLUMN_CONFIG_NAME = 'documents_all_rejected';
const GRID_SHARING_COLUMN_CONFIG_NAME = 'documents_sharing_context';
const GRID_ERROR_AND_WARNING_COLUMN_CONFIG_NAME = 'error_and_warnings_docs';
const GRID_ARCHIVE_COLUMN_CONFIG_NAME = 'documents_all_archived';

const attrRenderer = (attrName) => (value, metaData, record) => {
	const attributes = record.get('attributes');
	const attrValue = edi.utils.getAttributeByName(attributes, attrName);
	return edi.renderers.valueAsToolTip(attrValue, metaData);
};

edi.columns.addColumns({
	[GRID_COMMON_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			order: 10,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: true,
			flex: 1,
			order: 20,
			dataIndex: 'number'
		},
		correctionNumber: {
			text: 'documents.column.correction.number',
			sortable: false,
			hidden: true,
			flex: 1,
			order: 30,
			dataIndex: 'correctionNumber'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			sortable: true,
			flex: 1.5,
			order: 40,
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs'
		},
		orderCorrectionNumber: {
			text: 'document.order.correction.number',
			sortable: true,
			hidden: true,
			flex: 1,
			order: 50,
			dataIndex: 'orderCorrectionNumber'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			flex: 1.5,
			hidden: true,
			order: 60,
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs'
		},
		orderCorrectionDate: {
			text: 'document.order.correction.date',
			sortable: true,
			hidden: true,
			flex: 1,
			order: 70,
			dataIndex: 'orderCorrectionDate'
		},
		removeDate: {
			text: 'documents.column.removeDate',
			flex: 1.5,
			hidden: true,
			order: 80,
			dataIndex: 'removeDate',
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			flex: 1.5,
			order: 90,
			dataIndex: 'doctime',
			renderer: 'docTime'
		},
		documentSender: {
			text: 'documents.column.document.sender',
			sortable: false,
			flex: 1,
			order: 100,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		documentRecipient: {
			text: 'documents.column.document.recipient',
			sortable: false,
			flex: 1,
			order: 110,
			dataIndex: 'toOrg',
			renderer: 'organization'
		},
		buyerName: {
			text: 'document.buyer',
			sortable: false,
			flex: 1,
			hidden: true,
			order: 120,
			dataIndex: 'buyerName',
			renderer: attrRenderer('buyerName')
		},
		buyerGLN: {
			text: 'column.buyer.iln',
			flex: 1,
			sortable: true,
			hidden: true,
			order: 130,
			dataIndex: 'buyerGLN',
			renderer: attrRenderer('BUYER_ILN')
		},
		buyerCode: {
			text: 'column.buyer.item.code',
			hidden: true,
			order: 140,
			flex: 1,
			dataIndex: 'buyerCode',
			renderer: attrRenderer('buyerCode')
		},
		sellerName: {
			text: 'document.seller',
			sortable: false,
			flex: 1,
			hidden: true,
			order: 150,
			dataIndex: 'sellerName',
			renderer: attrRenderer('sellerName')
		},
		sellerGLN: {
			text: 'column.seller.iln',
			flex: 1,
			sortable: true,
			hidden: true,
			order: 160,
			dataIndex: 'sellerGLN',
			renderer: attrRenderer('SELLER_ILN')
		},
		viewedByReceiver: {
			text: 'documents.column.viewedByReceiver',
			hidden: true,
			flex: 1,
			order: 170,
			dataIndex: 'viewedByReceiver',
			renderer: 'yesNo'
		},
		transporter: {
			text: 'documents.column.document.transporter',
			sortable: false,
			align: 'center',
			flex: 1,
			hidden: true,
			order: 180,
			dataIndex: 'transporter',
			renderer: attrRenderer('transporter')
		},
		ultimateCustomer: {
			text: 'documents.ultimate.customer',
			sortable: false,
			align: 'center',
			flex: 1,
			hidden: true,
			order: 185,
			dataIndex: 'ultimateCustomer',
			renderer: attrRenderer('ultimateCustomerName')
		},
		businessState: {
			text: 'documents.column.status',
			flex: 1.5,
			order: 190,
			htmlEncode: true,
			dataIndex: 'businessState',
			renderer: 'columnStatus'
		},
		type: {
			text: 'documents.column.type',
			flex: 1.5,
			renderer: 'doctype',
			order: 200,
			htmlEncode: true,
			dataIndex: 'type'
		},
		orderNumber: {
			text: 'documents.column.orderNumber',
			sortable: true,
			order: 210,
			flex: 1.5,
			dataIndex: 'orderNumber'
		},
		orderDate: {
			text: 'column.buyer.order.date',
			hidden: true,
			sortable: true,
			flex: 1,
			order: 220,
			dataIndex: 'orderDate',
			renderer: attrRenderer('ORDER_DATE')
		},
		desadvNumber: {
			text: 'documents.column.desadvNumber',
			sortable: true,
			hidden: true,
			order: 230,
			flex: 1.5,
			dataIndex: 'desadvNumber'
		},
		contractNumber: {
			text: 'documents.column.contractNumber',
			sortable: true,
			hidden: true,
			order: 240,
			flex: 1.5,
			dataIndex: 'contractNumber'
		},
		documentFunctionCode: {
			text: 'document.function.code',
			sortable: false,
			hidden: true,
			flex: 1,
			order: 250,
			dataIndex: 'documentFunctionCode',
			renderer: 'documentFunctionCode'
		},
		pointName: {
			text: 'document.delivery.point.name',
			flex: 1,
			sortable: false,
			hidden: true,
			order: 260,
			dataIndex: 'deliveryName',
			renderer: attrRenderer('deliveryName')
		},
		deliveryGLN: {
			text: 'document.delivery.point.gln',
			flex: 1,
			sortable: true,
			hidden: true,
			order: 270,
			dataIndex: 'deliveryGLN',
			renderer: attrRenderer('deliveryGLN')
		},
		pointAddress: {
			text: 'document.delivery.point.address',
			flex: 1.5,
			sortable: false,
			hidden: true,
			order: 280,
			dataIndex: 'deliveryAddress',
			renderer: attrRenderer('deliveryAddress')
		},
		totalSumm: {
			text: 'documents.column.summ.with.NDS',
			sortable: false,
			flex: 1,
			align: 'right',
			order: 290,
			hidden: true,
			dataIndex: 'totalSumm',
			renderer: 'displayCurrencyDecimals'
		},
		totalNetAmount: {
			text: 'documents.column.summ.without.NDS',
			sortable: true,
			flex: 1,
			align: 'right',
			order: 300,
			hidden: true,
			dataIndex: 'totalNetAmount',
			renderer: 'displayCurrencyDecimals'
		},
		totalTaxAmount: {
			text: 'documents.column.summ.NDS',
			sortable: true,
			flex: 1,
			align: 'right',
			order: 310,
			hidden: true,
			dataIndex: 'totalTaxAmount',
			renderer: 'displayCurrencyDecimals'
		},
		versionId: {
			text: 'document.version',
			sortable: false,
			hidden: true,
			flex: 1,
			order: 320,
			dataIndex: 'versionId',
			renderer: function (value) {
				return value && value !== 'null' ? value : '';
			}
		},
		remark: {
			text: 'documents.column.remark',
			flex: 1.5,
			sortable: false,
			hidden: true,
			order: 330,
			dataIndex: 'remark',
			renderer: attrRenderer('remark')
		},
		estimatedDeliveryDate: {
			text: 'delivery.delivery.date',
			hidden: true,
			sortable: false,
			order: 340,
			flex: 1,
			dataIndex: 'estimatedDeliveryDate',
			renderer: attrRenderer('ESTIMATED_DELIVERY_DATE')
		},
		estimatedDeliveryTime: {
			text: 'time.of.delivery',
			hidden: true,
			sortable: false,
			order: 350,
			flex: 1,
			dataIndex: 'estimatedDeliveryTime',
			renderer: attrRenderer('estimatedDeliveryTime')
		},
		transportDocNumber: {
			text: 'documents.column.transportDocNumber',
			hidden: true,
			sortable: false,
			order: 360,
			flex: 1,
			dataIndex: 'transportDocNumber',
			renderer: attrRenderer('transportDocNumber')
		},
		factureNumber: {
			text: 'documents.column.factureNumber',
			hidden: true,
			sortable: false,
			order: 370,
			flex: 1,
			dataIndex: 'factureNumber',
			renderer: attrRenderer('factureNumber')
		},
		factureDate: {
			text: 'documents.column.factureDate',
			hidden: true,
			sortable: false,
			order: 380,
			flex: 1,
			dataIndex: 'factureDate',
			renderer: attrRenderer('factureDate')
		},
		invoiceNumber: {
			text: 'documents.column.invoiceNumber',
			hidden: true,
			sortable: false,
			order: 390,
			flex: 1,
			dataIndex: 'invoiceNumber',
			renderer: attrRenderer('INVOICE_NUMBER')
		},
		recadvNumber: {
			text: 'documents.column.recadvNumber',
			hidden: true,
			sortable: false,
			order: 400,
			flex: 1,
			dataIndex: 'recadvNumber',
			renderer: attrRenderer('RECEIVING_ADVICE_NUMBER')
		}
	}
});

edi.columns.addColumns({
	[GRID_REMOVED_COLUMN_CONFIG_NAME]: {
		parents: GRID_COMMON_COLUMN_CONFIG_NAME,
		config: {
			removeDate: {
				hidden: false
			},
			documentVersion: {
				text: 'document.version',
				sortable: false,
				hidden: true,
				flex: 1,
				dataIndex: 'documentVersion',
				order: 255,
				renderer: function (value) {
					return value && value !== 'null' ? value : '';
				}
			},
			correctionNumber: undefined,
			orderCorrectionNumber: undefined,
			orderCorrectionDate: undefined,
			viewedByReceiver: undefined,
			transportDocNumber: undefined,
			factureNumber: undefined,
			factureDate: undefined,
			invoiceNumber: undefined,
			recadvNumber: undefined
		}
	}
});

edi.columns.addColumns({
	[GRID_REJECTED_COLUMN_CONFIG_NAME]: {
		parents: GRID_COMMON_COLUMN_CONFIG_NAME,
		config: {
			removeDate: {
				hidden: false
			},
			documentVersion: {
				text: 'document.version',
				sortable: false,
				hidden: true,
				flex: 1,
				dataIndex: 'documentVersion',
				order: 255,
				renderer: function (value) {
					return value && value !== 'null' ? value : '';
				}
			},
			correctionNumber: undefined,
			orderCorrectionNumber: undefined,
			orderCorrectionDate: undefined,
			viewedByReceiver: undefined
		}
	}
});

edi.columns.addColumns({
	[GRID_SHARING_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			hidden: true,
			sortable: true
		},
		docId: {
			text: 'documents.column.id',
			dataIndex: 'docId',
			renderer: function (value) {
				return value.hasOwnProperty('id') ? value.id : '';
			},
			order: 1,
			flex: 1,
			sortable: false
		},
		docNumber: {
			text: 'documents.column.number',
			dataIndex: 'docNumber',
			order: 1,
			flex: 1,
			sortable: false
		},
		docType: {
			text: 'documents.column.type',
			dataIndex: 'docType',
			renderer: function (value) {
				return edi.i18n.getMessage('documents.doctype.' + value);
			},
			order: 1,
			flex: 1,
			sortable: false
		},
		fromOrgName: {
			text: 'documents.column.fromOrg',
			dataIndex: 'fromOrgName',
			order: 2,
			flex: 1,
			sortable: false
		},
		toOrgName: {
			text: 'documents.column.toOrg',
			dataIndex: 'toOrgName',
			order: 3,
			flex: 1,
			sortable: false
		},
		onLooker: {
			text: 'column.onLooker',
			dataIndex: 'onLooker',
			order: 4,
			flex: 1,
			sortable: false
		},
		modifyDate: {
			text: 'column.access.grant.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			order: 5,
			flex: 1,
			sortable: true
		}
	}
});

edi.columns.addColumns({
	[GRID_ERROR_AND_WARNING_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'documents.column.id',
			order: 10,
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			order: 20,
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			order: 30,
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			order: 40,
			dataIndex: 'creationDate',
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			order: 50,
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		fromOrg: {
			text: 'documents.column.document.sender',
			order: 60,
			sortable: false,
			hidden: true,
			align: 'center',
			flex: 1,
			dataIndex: 'fromOrg',
			renderer: (...args) => edi.renderers.organization(...args)
		},
		toOrg: {
			text: 'documents.column.document.recipient',
			order: 70,
			sortable: false,
			hidden: true,
			align: 'center',
			flex: 1,
			dataIndex: 'toOrg',
			renderer: (...args) => edi.renderers.organization(...args)
		},
		buyerName: {
			text: 'document.buyer',
			order: 80,
			sortable: false,
			flex: 1,
			dataIndex: 'buyerName',
			renderer: attrRenderer('buyerName')
		},
		buyerGLN: {
			text: 'column.buyer.iln',
			order: 90,
			flex: 1,
			sortable: false,
			hidden: true,
			dataIndex: 'buyerGLN',
			renderer: attrRenderer('BUYER_ILN')
		},
		buyerCode: {
			text: 'column.buyer.item.code',
			order: 100,
			sortable: false,
			hidden: true,
			dataIndex: 'buyerCode',
			renderer: attrRenderer('buyerCode'),
			flex: 1
		},
		sellerName: {
			text: 'document.seller',
			order: 110,
			sortable: false,
			flex: 1,
			dataIndex: 'sellerName',
			renderer: attrRenderer('sellerName')
		},
		sellerGLN: {
			text: 'column.seller.iln',
			flex: 1,
			order: 120,
			sortable: false,
			hidden: true,
			dataIndex: 'sellerGLN',
			renderer: attrRenderer('SELLER_ILN')
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			order: 130,
			hidden: true,
			renderer: (...args) => edi.renderers.columnStatus(...args),
			flex: 1.5,
			htmlEncode: true
		},
		type: {
			text: 'documents.column.type',
			order: 140,
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		orderNumber: {
			text: 'documents.column.orderNumber',
			order: 150,
			dataIndex: 'orderNumber',
			hidden: true,
			sortable: true,
			flex: 1.5
		},
		orderDate: {
			text: 'column.buyer.order.date',
			order: 160,
			hidden: true,
			sortable: false,
			flex: 1,
			dataIndex: 'orderDate',
			renderer: attrRenderer('ORDER_DATE')
		},
		sellerOrderNumber: {
			text: 'column.seller.order.number',
			order: 170,
			dataIndex: 'sellerOrderNumber',
			hidden: true,
			sortable: false,
			renderer: attrRenderer('sellerOrderNumber'),
			flex: 1.5
		},
		sellerOrderDate: {
			text: 'column.seller.order.date',
			order: 180,
			dataIndex: 'sellerOrderDate',
			hidden: true,
			sortable: false,
			renderer: attrRenderer('sellerOrderDate'),
			flex: 1
		},
		desadvNumber: {
			text: 'documents.column.desadvNumber',
			order: 190,
			dataIndex: 'desadvNumber',
			hidden: true,
			flex: 1.5
		},
		desadvDate: {
			text: 'documents.desadv.waybill.date',
			order: 200,
			dataIndex: 'desadvDate',
			sortable: false,
			hidden: true,
			renderer: attrRenderer('desadvDate'),
			flex: 1
		},
		documentFunctionCode: {
			text: 'document.function.code',
			order: 210,
			renderer: (...args) => edi.renderers.documentFunctionCode(...args),
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'documentFunctionCode'
		},
		deliveryName: {
			text: 'document.delivery.point.name',
			order: 220,
			flex: 1,
			sortable: false,
			hidden: true,
			dataIndex: 'deliveryName',
			renderer: attrRenderer('deliveryName')
		},
		deliveryGLN: {
			text: 'document.delivery.point.gln',
			flex: 1,
			order: 230,
			sortable: true,
			hidden: true,
			dataIndex: 'deliveryGLN',
			renderer: attrRenderer('DELIVERY_POINT_ILN')
		},
		deliveryPointCode: {
			text: 'column.delivery.point.code',
			flex: 1,
			order: 240,
			sortable: false,
			hidden: true,
			dataIndex: 'deliveryPointCode',
			renderer: attrRenderer('deliveryPointCode')
		},
		totalSumm: {
			text: 'column.gross.amount',
			order: 250,
			renderer: (...args) => edi.renderers.displayCurrencyDecimals(...args),
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'totalSumm'
		},
		totalNetAmount: {
			text: 'documents.column.summ.without.NDS',
			order: 260,
			renderer: (...args) => edi.renderers.displayCurrencyDecimals(...args),
			dataIndex: 'totalNetAmount',
			hidden: true,
			flex: 1
		},
		warnings: {
			text: 'document.column.warnings',
			order: 270,
			dataIndex: 'warnings',
			flex: 1,
			sortable: false,
			renderer: attrRenderer('warningMessage')
		},
		errors: {
			text: 'document.column.errors',
			order: 280,
			dataIndex: 'errors',
			flex: 1,
			sortable: false,
			renderer: (value, meta, record) => {
				const attributes = record.get('attributes');
				return edi.renderers.errorMessage(attributes);
			}
		},
		integrationChannels: {
			text: 'documents.column.integration.channels',
			dataIndex: 'attributes',
			order: 290,
			sortable: false,
			flex: 1,
			renderer: function (value) {
				const innerOutgoingSystem = edi.utils.getAttributeByName(value, 'innerOutgoingSystem');
				const externalOutgoingSystem = edi.utils.getAttributeByName(value, 'externalOutgoingSystem');
				return [innerOutgoingSystem, externalOutgoingSystem].filter(Boolean).join(', ');
			}
		},
		sendingStatus: {
			text: 'documents.column.sending.status',
			dataIndex: 'attributes',
			order: 300,
			sortable: false,
			flex: 1,
			renderer: function (value) {
				const sendingState = edi.utils.getAttributeByName(value, 'sendingState');
				return sendingState ? edi.i18n.getMessage(`sending.state.${sendingState}`) : '';
			}
		},
		estimatedDeliveryDate: {
			text: 'delivery.delivery.date',
			order: 310,
			dataIndex: 'estimatedDeliveryDate',
			hidden: true,
			sortable: false,
			renderer: attrRenderer('ESTIMATED_DELIVERY_DATE'),
			flex: 1
		},
		estimatedDeliveryTime: {
			text: 'time.of.delivery',
			order: 320,
			dataIndex: 'estimatedDeliveryTime',
			hidden: true,
			sortable: false,
			renderer: attrRenderer('estimatedDeliveryTime'),
			flex: 1
		},
		operatorName: {
			text: 'column.operator',
			order: 330,
			hidden: true,
			sortable: false,
			flex: 1,
			dataIndex: 'operatorName',
			renderer: attrRenderer('operatorName')
		},
		errorProcessingState: {
			text: 'column.error.processing.state',
			order: 340,
			flex: 1.5,
			sortable: false,
			dataIndex: 'errorProcessingState',
			renderer: (value, metaData, record) => {
				const attributes = record.get('attributes');
				const attrValue = edi.utils.getAttributeByName(attributes, 'errorProcessingState');
				const result = attrValue && edi.i18n.getMessage(`document.error.processing.state.${attrValue}`);
				return edi.renderers.valueAsToolTip(result, metaData);
			}
		},
		transportDocNumber: {
			text: 'documents.column.transportDocNumber',
			hidden: true,
			sortable: false,
			order: 350,
			flex: 1,
			dataIndex: 'transportDocNumber',
			renderer: attrRenderer('transportDocNumber')
		},
		factureNumber: {
			text: 'documents.column.factureNumber',
			hidden: true,
			sortable: false,
			order: 360,
			flex: 1,
			dataIndex: 'factureNumber',
			renderer: attrRenderer('factureNumber')
		},
		factureDate: {
			text: 'documents.column.factureDate',
			hidden: true,
			sortable: false,
			order: 370,
			flex: 1,
			dataIndex: 'factureDate',
			renderer: attrRenderer('factureDate')
		},
		invoiceNumber: {
			text: 'documents.column.invoiceNumber',
			hidden: true,
			sortable: false,
			order: 380,
			flex: 1,
			dataIndex: 'invoiceNumber',
			renderer: attrRenderer('INVOICE_NUMBER')
		},
		recadvNumber: {
			text: 'documents.column.recadvNumber',
			hidden: true,
			sortable: false,
			order: 390,
			flex: 1,
			dataIndex: 'recadvNumber',
			renderer: attrRenderer('RECEIVING_ADVICE_NUMBER')
		}
	}
});

edi.columns.addColumns({
	[GRID_ARCHIVE_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'documents.column.id',
			sortable: true,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		number: {
			text: 'documents.column.number',
			sortable: true,
			flex: 1,
			dataIndex: 'number'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1.5,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		removeDate: {
			text: 'documents.column.removeDate',
			dataIndex: 'removeDate',
			flex: 1.5,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		doctime: {
			text: 'documents.column.date',
			dataIndex: 'doctime',
			flex: 1.5,
			renderer: 'docTime'
		},
		documentSender: {
			text: 'documents.column.document.sender',
			sortable: false,
			align: 'center',
			flex: 1,
			dataIndex: 'fromOrg',
			renderer: 'organization'
		},
		documentRecipient: {
			text: 'documents.column.document.recipient',
			sortable: false,
			align: 'center',
			flex: 1,
			dataIndex: 'toOrg',
			renderer: 'organization'
		},
		transporter: {
			text: 'documents.column.document.transporter',
			sortable: false,
			align: 'center',
			flex: 1,
			hidden: true,
			dataIndex: 'toOrg',
			renderer: function (val, meta, record) {
				return edi.utils.getAttributeByName(record.get('attributes'), 'transporter') || '';
			}
		},
		businessState: {
			text: 'documents.column.status',
			dataIndex: 'businessState',
			renderer: 'columnStatus',
			flex: 1.5,
			htmlEncode: true
		},
		type: {
			text: 'documents.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		orderNumber: {
			text: 'documents.column.orderNumber',
			dataIndex: 'orderNumber',
			sortable: true,
			flex: 1.5
		},
		desadvNumber: {
			text: 'documents.column.desadvNumber',
			dataIndex: 'desadvNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		contractNumber: {
			text: 'documents.column.contractNumber',
			dataIndex: 'contractNumber',
			sortable: true,
			hidden: true,
			flex: 1.5
		},
		documentWarnings: {
			text: 'document.warnings',
			sortable: false,
			hidden: true,
			flex: 1,
			dataIndex: 'attributes',
			order: 25,
			htmlEncode: true,
			renderer: function (value, metaData) {
				const errorNomSredIdentTovAndSvedProslezh = edi.utils.getAttributeByName(
					value,
					'errorNomSredIdentTovAndSvedProslezh'
				);
				const warningMessage = edi.utils.getAttributeByName(value, 'warningMessage');
				//если предупреждение прослеживаемости
				if (errorNomSredIdentTovAndSvedProslezh) {
					metaData
						? (metaData.tdAttr =
								'data-qtip="' +
								edi.i18n.getMessage('document.warning.' + errorNomSredIdentTovAndSvedProslezh) +
								'"')
						: null;
					return Ext.String.format(
						'<span style="color:#ffc000;">{0}</span>',
						edi.i18n.getMessage('document.warning.' + errorNomSredIdentTovAndSvedProslezh)
					);
				}
				//если предупреждение бизнес валидации
				else if (warningMessage) {
					return warningMessage;
				} else {
					return '';
				}
			}
		},
		errors: {
			text: 'document.column.errors',
			dataIndex: 'attributes',
			flex: 1,
			hidden: true,
			renderer: edi.renderers.errorMessage
		},
		deliveryGLN: {
			text: 'document.delivery.point.gln',
			flex: 1,
			sortable: true,
			hidden: true,
			dataIndex: 'deliveryGLN',
			renderer: function (value, metaData, record) {
				var deliveryPointGLN = edi.utils.getAttributeByName(record.get('attributes'), 'deliveryGLN');
				return deliveryPointGLN ? edi.renderers.valueAsToolTip(deliveryPointGLN, metaData) : '';
			}
		},
		totalSumm: {
			text: 'documents.column.summ.with.NDS',
			renderer: 'displayCurrencyDecimals',
			sortable: false,
			flex: 1,
			dataIndex: 'totalSumm',
			hidden: true
		},
		totalNetAmount: {
			text: 'documents.column.summ.without.NDS',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalNetAmount',
			sortable: true,
			flex: 1,
			hidden: true
		},
		totalTaxAmount: {
			text: 'documents.column.summ.NDS',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'totalTaxAmount',
			sortable: true,
			flex: 1,
			hidden: true
		},
		remark: {
			text: 'documents.column.remark',
			dataIndex: 'attributes',
			flex: 1.5,
			order: 24,
			renderer: function (value) {
				return edi.utils.getObjectProperty(value, 'remark.value');
			},
			sortable: false,
			hidden: true
		}
	}
});

export {
	GRID_COMMON_COLUMN_CONFIG_NAME,
	GRID_REMOVED_COLUMN_CONFIG_NAME,
	GRID_REJECTED_COLUMN_CONFIG_NAME,
	GRID_SHARING_COLUMN_CONFIG_NAME,
	GRID_ERROR_AND_WARNING_COLUMN_CONFIG_NAME,
	GRID_ARCHIVE_COLUMN_CONFIG_NAME
};
