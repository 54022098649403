const POA_LIST_MODEL = 'POWER_OF_ATTORNEY';
const POA_CODES_MODEL = 'POA_CODES';

edi.models.setModels({
	[POA_LIST_MODEL]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'internalPoaNumber',
				type: 'string'
			},
			{
				name: 'poaNumber',
				type: 'string'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'validFrom',
				type: 'number'
			},
			{
				name: 'validTo',
				type: 'number'
			},
			{
				name: 'principals'
			},
			{
				name: 'confidants'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'orgName',
				type: 'string'
			}
		]
	},
	[POA_CODES_MODEL]: {
		fields: [
			{
				name: 'id',
				type: 'string'
			},
			{
				name: 'submit_value',
				type: 'string'
			},
			{
				name: 'display_value',
				type: 'string'
			},
			{
				name: 'code_display_value',
				type: 'string',
				convert: function (v, record) {
					return record.get('submit_value')
						? record.get('submit_value') + ' - ' + record.get('display_value')
						: v;
				}
			}
		]
	}
});

export { POA_LIST_MODEL, POA_CODES_MODEL };
