/**
 * Methods for data renderers
 * @author Vsevolod Liapin
 */

Object.assign(edi.renderers, {
	SvTD: function (value, metaData) {
		var res = '';
		if (value) {
			for (var i = 0; i < value.length; i++) {
				var line = value[i];
				var lineValues = [line.KodProiskh, line.NomerTD];
				if (res) {
					res += '; ';
				}
				res += lineValues.join(': ');
			}
			metaData ? (metaData.tdAttr = 'data-qtip="' + res + '"') : null;
		}
		return res;
	},
	InfPolStr: function (value, metaData) {
		var res = '';
		if (value) {
			for (var i = 0; i < value.length; i++) {
				var line = value[i];
				var lineValues = [line.Identif, line.Znachen];
				if (res) {
					res += '; ';
				}
				res += lineValues.join(': ');
			}
			metaData ? (metaData.tdAttr = 'data-qtip="' + res + '"') : null;
		}
		return res;
	},
	crpt_status: function (value, meta, record) {
		var data = record && record.getData && record.getData();
		return edi.renderers.utils.convertAttributesToCrptStatus(data.attributes, data.type);
	},
	edem_status: function (value, meta, record) {
		let lineData = record?.getData();
		return edi.renderers.utils.showEdemStatus(lineData.type, lineData.attributes);
	}
});

edi.renderers.utils.convertAttributesToCrptStatus = function (attributes, docType) {
	var attrs = attributes || {};
	var aperakCrptStatus = edi.utils.getAttributeByName(attrs, 'APERAK_CRPT_STATUS');
	var documentCrptErrorSending = edi.utils.getAttributeByName(attrs, 'DOCUMENT_CRPT_ERROR_SENDING');
	var documentCrptSent = edi.utils.getAttributeByName(attrs, 'DOCUMENT_CRPT_SENT');
	var childAnnulError = edi.utils.getAttributeByName(attrs, 'CHILD_ANNUL_CRPT_ERROR');
	var childAnnulSent = edi.utils.getAttributeByName(attrs, 'CHILD_ANNUL_CRPT_SENT');
	var actualCrptState = documentCrptSent || documentCrptErrorSending ? 'sent' : '';
	actualCrptState = parseInt(aperakCrptStatus, 10) === 27 ? 'error' : actualCrptState;
	actualCrptState = parseInt(aperakCrptStatus, 10) === 29 ? 'codes_sent' : actualCrptState;
	actualCrptState = parseInt(aperakCrptStatus, 10) === 23 ? 'error_annul' : actualCrptState;
	if (!!childAnnulSent) {
		if (!childAnnulError) {
			actualCrptState = 'processing_annul';
		} else {
			actualCrptState = childAnnulError === 'true' ? 'error_annul' : 'success_annul';
		}
	}
	var isNeedDocTypeStateArray = ['error', 'sent', 'codes_sent'];
	var additState = isNeedDocTypeStateArray.some((it) => it === actualCrptState)
		? actualCrptState + '.' + docType
		: actualCrptState;
	return actualCrptState
		? edi.renderers.baseStateRenderer(
				actualCrptState.toUpperCase(),
				edi.i18n.getMessage('documents.status.crpt.' + additState)
		  )
		: '';
};

/**
 * The array with methods changes the key suffixes to the status translation
 * Key format for the translation file:
 * "documents.status.{retValue}[.direction|.doctype][.addition]"
 * styleSfx - suffix for css class to set color status
 */
edi.renderers.utils.modifyStateTitleKeys.push(function (getData, direction, doctype, attributes, modifyValues) {
	//UPD/UKD notices
	const isUpdUkdNotice = edi.constants.NOTICE_DOC_TYPES.some((it) => it === doctype);
	if (isUpdUkdNotice) {
		modifyValues.addition = 'UPD_NOTICE';
		modifyValues.styleSfx = 'UPD_NOTICE_';
	}
});

edi.renderers.utils.showEdemStatus = function (docType, attributes, oneLine) {
	const docTypesForEdem = [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD, edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD];
	let reducedDocType = docType.substring(0, edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD.length);
	let result = {
		text: '',
		tooltip: ''
	};

	if (docTypesForEdem.indexOf(reducedDocType) === -1) {
		return result;
	}

	let traceable = attributes?.TRACEABLE?.value;
	if (traceable !== 'true') {
		return result;
	}

	let tkState = attributes?.TK_state?.value;

	if (!tkState) {
		return result;
	}

	const errorTooltips = {
		400: 'document.edem_tooltip.incorrect.request',
		401: 'document.edem_tooltip.no.user.certificate',
		403: 'document.edem_tooltip.authorization.failed',
		429: 'document.edem_tooltip.too.many.requests',
		500: 'document.edem_tooltip.unexpectable.error'
	};

	const colorError = '#e82000';
	const colorSuccess = '#00ac2d';
	const colorProcessing = '#3d7dca';
	let statusColor = tkState === 'TK_UO' ? colorError : tkState === 'TK_KV' ? colorSuccess : colorProcessing;

	let statusMsg =
		tkState === 'TK_UO'
			? 'document.edem_status.error'
			: tkState === 'TK_KV'
			? 'document.edem_status.success'
			: 'document.edem_status.processing';
	statusMsg += '.' + (reducedDocType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ? 'upd' : 'ukd');

	let qtip = errorTooltips[tkState] || '';

	if (oneLine === true) {
		result.text = Ext.String.format(
			'<span style="color:{0};" data-qtip="{2}">{1}</span>',
			statusColor,
			edi.i18n.getMessage(statusMsg),
			edi.i18n.getMessage(qtip)
		);
	} else {
		result.text = Ext.String.format(
			'<span style="color:{0};">{1}</span>',
			statusColor,
			edi.i18n.getMessage(statusMsg)
		);
		result.tooltip = qtip ? edi.i18n.getMessage(qtip) : '';
	}

	return result;
};
