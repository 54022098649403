import { partinRenderers } from './renderer';

const PARTIN_LOCALIZATIONS_COLUMN_CONFIG_NAME = 'partin_localizations';
const PARTIN_HEADQUARTERS_COLUMN_CONFIG_NAME = 'partin_headquarters';
const PARTIN_BRANCHES_COLUMN_CONFIG_NAME = 'partin_branches';

edi.columns.addColumns({
	[PARTIN_LOCALIZATIONS_COLUMN_CONFIG_NAME]: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 0.5,
			sortable: false
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 1.5,
			sortable: true
		},
		taxId: {
			text: 'column.tax.id',
			dataIndex: 'taxId',
			flex: 1
		},
		kpp: {
			text: 'company.kpp.short',
			dataIndex: 'taxRegistrationReasonCode',
			flex: 1
		},
		address: {
			text: 'column.address',
			flex: 1.5,
			renderer: (...args) => partinRenderers.address(...args)
		},
		headquarterName: {
			text: 'catalog.partin.headquarter.name',
			dataIndex: 'headquarter.name',
			flex: 1,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').name
					? record.get('headquarter').name
					: '';
			}
		},
		headquarterIln: {
			text: 'catalog.partin.headquarter.iln',
			dataIndex: 'headquarter.iln',
			flex: 1,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').iln ? record.get('headquarter').iln : '';
			}
		},
		branchName: {
			text: 'catalog.partin.branches.name',
			dataIndex: 'branch.name',
			flex: 1,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('branch') && record.get('branch').name ? record.get('branch').name : '';
			}
		},
		branchIln: {
			text: 'catalog.partin.branches.iln',
			dataIndex: 'branch.iln',
			flex: 1,
			sortable: false,
			hidden: true,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('branch') && record.get('branch').iln ? record.get('branch').iln : '';
			}
		}
	},
	[PARTIN_HEADQUARTERS_COLUMN_CONFIG_NAME]: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 0.5,
			sortable: false
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 1,
			sortable: true
		},
		taxId: {
			text: 'column.org.inn',
			dataIndex: 'taxId',
			flex: 1,
			sortable: false
		},
		taxRegistrationReasonCode: {
			text: 'column.org.kpp',
			dataIndex: 'taxRegistrationReasonCode',
			flex: 1,
			sortable: false
		},
		address: {
			text: 'column.address',
			flex: 1.5,
			hidden: true,
			renderer: (...args) => partinRenderers.address(...args)
		}
	},
	[PARTIN_BRANCHES_COLUMN_CONFIG_NAME]: {
		iln: {
			text: 'column.iln',
			dataIndex: 'iln',
			flex: 0.5,
			sortable: false
		},
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 1,
			sortable: true
		},
		headquarterName: {
			text: 'catalog.partin.headquarter.name',
			dataIndex: 'headquarter.name',
			flex: 1,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').name
					? record.get('headquarter').name
					: '';
			}
		},
		headquarterIln: {
			text: 'catalog.partin.headquarter.iln',
			dataIndex: 'headquarter.iln',
			flex: 1,
			sortable: false,
			renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
				return record.get('headquarter') && record.get('headquarter').iln ? record.get('headquarter').iln : '';
			}
		},
		address: {
			text: 'column.address',
			flex: 1.5,
			renderer: (...args) => partinRenderers.address(...args)
		}
	}
});

let getPartinLocalizationsColumns = () => edi.columns.get(PARTIN_LOCALIZATIONS_COLUMN_CONFIG_NAME);
let getPartinHeadquartersColumns = () => edi.columns.get(PARTIN_HEADQUARTERS_COLUMN_CONFIG_NAME);
let getPartinBranchesColumns = () => edi.columns.get(PARTIN_BRANCHES_COLUMN_CONFIG_NAME);

export {
	getPartinLocalizationsColumns,
	PARTIN_LOCALIZATIONS_COLUMN_CONFIG_NAME,
	getPartinHeadquartersColumns,
	PARTIN_HEADQUARTERS_COLUMN_CONFIG_NAME,
	getPartinBranchesColumns,
	PARTIN_BRANCHES_COLUMN_CONFIG_NAME
};
