/**
 * @author Anatoly Deryshev, Pavel Pirogov
 * Debug functionality
 */
import { createForm, createPanel, createTab, createTabPanel } from '@Components/panels';
import { createContainer, createFormForTranslation, createTwoColumnsLayout } from '@Components/miscComponents';
import {
	createCheckbox,
	createCombo,
	createDisplayField,
	createField,
	createFile,
	createLabel,
	createDateLabel,
	createTextField,
	createTriggerField,
	createFormActionField
} from '@Components/fields';
import { createAddButton, createLink } from '@Components/buttons';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createActionsColumnConfig, createGrid, createToolBar, ROW_COLOR_CLS } from '@Components/grid';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import { createTreePanel } from '@UIkit/components/treePanel';
import { createModalPanel } from '@UIkit/components/modal';
import { MODAL_SIZE } from '@UIkit/components/modal';

if (window.isDevelopment === true) {
	Ext.namespace('edi.debug');

	edi.debug = new (function () {
		var windowFieldsExclude = {
				webkitStorageInfo: true,
				webkitIndexedDB: true,
				TEMPORARY: true,
				PERSISTENT: true,
				addEventListener: true,
				removeEventListener: true,
				dispatchEvent: true,
				cryptoPro: true,
				applicationCache: true,
				params: true,
				UAParser: true
			},
			getWindowScopeFields = function () {
				var res = {},
					field;
				for (field in window) {
					if (
						!windowFieldsExclude.hasOwnProperty(field) &&
						Object.prototype.hasOwnProperty.call(window, field)
					) {
						res[field] = typeof window[field];
					}
				}
				return res;
			},
			initialWindowScopeFields = getWindowScopeFields(),
			getExtraWindowFields = function () {
				var res = {},
					field;
				for (field in window) {
					if (Object.prototype.hasOwnProperty.call(window, field)) {
						if (
							!initialWindowScopeFields.hasOwnProperty(field) &&
							!windowFieldsExclude.hasOwnProperty(field) &&
							!knownExtraFields.hasOwnProperty(field)
						) {
							res[field] = typeof window[field];
						}
					}
				}
				return res;
			},
			knownExtraFields = {},
			modal,
			hideLoading = function () {
				modal.setLoading(false);
			};
		/**
		 * Keep session alive
		 */
		this.keepSessionAlive = function () {
			var timeout;
			var pingPong = function () {
				edi.rest.sendRequest(edi.rest.services.USER.SELF.GET, 'GET', null, null, null, loop);
			};
			var loop = function () {
				timeout ? clearTimeout(timeout) : null;
				timeout = setTimeout(pingPong, edi.constants.SESSION_KEEP_ALIVE_TIMEOUT);
			};
			loop();
			edi.core.logMessage('Initiated session keep alive', 'info');
		};
		/**
		 * Checks for variables sharing in window scope
		 * @param interval
		 */
		this.startAutoScopeSharingCheck = function (interval) {
			setInterval(function () {
				var fields = getExtraWindowFields(),
					field;
				for (field in fields) {
					if (fields.hasOwnProperty(field)) {
						if (!knownExtraFields[field]) {
							knownExtraFields[field] = fields[field];
							edi.core.logMessage(
								"Scope sharing! Variable '" +
									field +
									"' with type '" +
									fields[field] +
									"' added to window scope!",
								'warn'
							);
						}
					}
				}
			}, interval);
			edi.core.logMessage('Initiated variables to window scope sharing check', 'info');
		};
		/**
		 * Displays debug window
		 * @returns {*|Object}
		 */
		this.showDebugWindow = function () {
			var tabPanel = createTabPanel({
				items: [testRequest(), complexRequest(), translationsLoading(), showWindowLeaks()]
			});
			modal = createModalPanel(
				{
					title: edi.i18n.getMessage('debug.window.title'),
					cls: ' edi-test-request-form',
					height: '80%',
					width: MODAL_SIZE.widthLarge,
					resizable: true,
					layout: 'fit',
					items: [tabPanel]
				},
				true
			);
			modal.show();
			return modal;
		};
		/**
		 * Displays debug window
		 * @returns {*|Object}
		 */
		this.getTranslateToKey = function () {
			var formTr = createFormForTranslation();

			document.onclick = function () {
				formTr.toggleVisible(true);
			};
			document.oncontextmenu = function (e) {
				// set position form
				var x = e.clientX,
					y = e.clientY;
				if (x + formTr.panel.getWidth() > window.innerWidth) {
					x = x - formTr.panel.getWidth() - 20;
				}
				if (y + formTr.panel.getHeight() > window.innerHeight) {
					y = y - formTr.panel.getHeight() - 20;
				}
				formTr.panel.setPosition(x + 10, y + 10);

				var el = e.target;
				let str = el.innerText || el.parentElement.innerText;
				if (el.tagName === 'INPUT') {
					str = el.value || el.placeholder;
				}
				if (el.classList[0] === 'x-btn-icon-el') {
					str = el.parentElement.parentElement.parentElement.dataset.qtip;
				}
				/**
				 * Находим последовательность минимум из двух символов, первый из которых всегда буква.
				 * Последовательность может содержать буквы латинского алфавита (a-z, A-Z), цифры (0-9), символ подчеркивания (_) и дефис (-)
				 */
				const regExp = /[A-Za-z][\w.-]+/g;
				let originalKeys = str.match(regExp) ?? [];

				var allStringUpperCase = function (str) {
					var strRep = str.replace(/[^A-Za-z0-9]/gi, '');
					var count = 0,
						i;
					for (i = 0; i < strRep.length; i++) {
						if (strRep[i] === strRep[i].toUpperCase()) {
							count = count + 1;
						}
					}
					return strRep.length === count ? str.toLowerCase() : str;
				};

				/**
				 * Функция для поиска переведенных ключей и их переводов.
				 * @param {string[]} originalKeys - Массив исходных ключей для поиска переводов.
				 * @returns {{ translatedKeys: string[], translatedStrs: string[] }} - Объект с массивами переведенных ключей и их переводов.
				 */
				const findTranslatedKeys = function (originalKeys) {
					return originalKeys.reduce(
						(result, key) => {
							const translatedStr = edi.i18n.getMessage(allStringUpperCase(key), null, false, true);
							const isTranslated = !originalKeys.includes(translatedStr);
							if (isTranslated) {
								result.translatedKeys.push(key);
								result.translatedStrs.push(translatedStr);
							}
							return result;
						},
						{
							translatedKeys: [],
							translatedStrs: []
						}
					);
				};

				const { translatedKeys, translatedStrs } = findTranslatedKeys(originalKeys);
				const translatedKeysStr = translatedKeys.join(' ');
				const translatedStr = translatedStrs.join(' ');
				const isTranslated = translatedKeysStr !== translatedStr;

				if (isTranslated) {
					formTr.setData(translatedKeysStr, translatedStr);
					formTr.toggleVisible(false);
					return false;
				} else {
					formTr.toggleVisible(true);
				}
			};
		};
		/**
		 * Form for display leaked variables to global scope
		 * @returns {*|Object}
		 */
		var showWindowLeaks = function () {
			var panel = createPanel({
				cls: 'edi-test-result-label',
				autoScroll: true
			});
			var setPanelData = function () {
				panel.body.setHtml(edi.utils.stringifyObject(knownExtraFields));
			};
			return createTab({
				title: edi.i18n.getMessage('debug.window.leaks'),
				closable: false,
				layout: 'fit',
				listeners: {
					activate: setPanelData
				},
				items: [
					createForm({
						cls: 'edi-form',
						bodyPadding: 10,
						layout: 'fit',
						items: [panel]
					})
				]
			});
		};
		/**
		 * Form for sending complex requests
		 * @returns {*|Object}
		 */
		var complexRequest = function () {
			var loopCounter,
				loopProgress,
				treeStore,
				idCounter = 0,
				progressClosedFlag = true,
				processData,
				processButton,
				methodsStore = edi.stores.createInlineStore(
					[
						{
							id: 'GET',
							name: 'GET'
						},
						{
							id: 'POST',
							name: 'POST'
						},
						{
							id: 'PUT',
							name: 'PUT'
						},
						{
							id: 'DELETE',
							name: 'DELETE'
						}
					],
					'SIMPLE'
				),
				closeProgress = function () {
					progressClosedFlag = true;
					loopCounter = 0;
					loopProgress.hide();
					loopProgress = null;
				},
				updateProgress = function () {
					var text = edi.utils.formatString(edi.i18n.getMessage('debug.request.loop.progress.count'), {
						count: loopCounter
					});
					if (!loopProgress) {
						progressClosedFlag = false;
						loopProgress = Ext.MessageBox.show({
							closable: false,
							title: edi.i18n.getMessage('debug.request.loop.progress'),
							msg: text,
							fn: closeProgress,
							buttons: Ext.MessageBox.CANCEL,
							wait: true,
							waitConfig: {
								interval: 500,
								text: loopCounter
							}
						});
					} else {
						loopProgress.updateProgress(loopProgress.progressBar.value, loopCounter, text);
					}
				},
				resultData = [],
				resultsButton;
			/**
			 * Collects changes from tree and updates processing data object
			 */
			var updateProcessingData = function () {
				var root = treeStore.getRootNode(),
					requests = [],
					i,
					processChild = function (node) {
						var data = node.getData(),
							objReturn = {},
							i;
						objReturn.url = data.url;
						objReturn.method = data.method;
						if (data.defaults) {
							objReturn.defaults = data.defaults;
						}
						if (data.map) {
							objReturn.map = data.map;
						}
						if (data.exclude) {
							objReturn.exclude = data.exclude;
						}
						if (data.include) {
							objReturn.include = data.include;
						}
						if (data.postData) {
							objReturn.postData = data.postData;
						}
						if (node.childNodes && node.childNodes.length) {
							objReturn.requests = [];
							for (i = 0; i < node.childNodes.length; i++) {
								objReturn.requests.push(processChild(node.childNodes[i]));
							}
						}
						return objReturn;
					};
				for (i = 0; i < root.childNodes.length; i++) {
					requests.push(processChild(root.childNodes[i]));
				}
				if (!processData && requests.length) {
					processData = {
						requests: requests
					};
				} else if (processData) {
					processData.requests = requests;
				}
			};
			/**
			 * Form processor
			 */
			var processForm = function () {
				var formValues = edi.utils.collectFormValues(form);
				modal.setLoading();
				loopCounter = 1;
				resultData = [];
				resultsButton.hide();
				if (processData) {
					if (processData.requests && processData.requests.length) {
						if (formValues.requestLoop) {
							updateProgress();
						}
						processRequests(processData, 0);
					} else {
						edi.core.showError('debug.complex.request.missing.requests', hideLoading);
					}
				} else {
					edi.core.showError('debug.complex.request.no.data.defined', hideLoading);
				}
			};
			/**
			 * Checks if values should be included for processing
			 * @param    {Object}    values
			 * @param    {Object}    rules
			 */
			var isAvailableForProcessing = function (values, rules) {
				var available = true,
					processRules = function (group, type) {
						var i,
							j,
							accepted = true,
							accepted2;
						for (i in group) {
							if (group.hasOwnProperty(i)) {
								if (!Ext.isObject(group[i])) {
									//Equals
									accepted = group[i] == values[i];
								} else {
									accepted2 = true;
									for (j in group[i]) {
										if (group[i].hasOwnProperty(j)) {
											if ('IN' === j) {
												accepted2 =
													Array.isArray(group[i][j]) &&
													group[i][j].some((it) => it === values[i]);
											} else if ('NOTIN' === j) {
												accepted2 = !(
													Array.isArray(group[i][j]) &&
													group[i][j].some((it) => it === values[i])
												);
											} else if ('GREATERTHAN' === j) {
												accepted2 = values[i] > group[i][j];
											} else if ('LESSTHAN' === j) {
												accepted2 = values[i] < group[i][j];
											}
											if (!accepted2) {
												break;
											}
										}
									}
									accepted = accepted2;
								}
								if (('include' === type && !accepted) || ('exclude' === type && accepted)) {
									break;
								}
							}
						}
						return accepted;
					};
				if (rules.exclude || rules.include) {
					if (rules.exclude) {
						available = !processRules(rules.exclude);
					}
					if (available && rules.include) {
						available = processRules(rules.include);
					}
				}
				return available;
			};
			/**
			 * Chained requests processor
			 * @param    {Object}      data     current level initial data
			 * @param    {Number}      level    requests level
			 * @param    {Function}    chain    chain that should be processed next after finising currently prepared chain of request
			 */
			var processRequests = function (data, level, chain) {
				var i,
					j,
					mapValues,
					formValues = edi.utils.collectFormValues(form);
				/**
				 * Fills processing results collection for display in modal results grid
				 * @param reqData
				 * @param isError
				 */
				var setProcessingResult = function (reqData, isError) {
					resultData.push(
						edi.models.createInstance('COMPLEX_REQUEST_RESULT', {
							uri: reqData.uri,
							level: level,
							error: isError,
							method: reqData.method,
							result: reqData.response,
							time: reqData.time
						})
					);
				};
				/**
				 * Success callback
				 * @param    {Object}      reqData
				 * @param    {Function}    callback
				 * @returns {Function}
				 */
				var success = function (reqData, callback) {
					return function (response) {
						reqData.response = response;
						setProcessingResult(reqData, false);
						if (reqData.requests && reqData.requests.length) {
							processRequests(reqData, ++level, callback);
						} else {
							'function' == typeof callback ? callback() : null;
						}
					};
				};
				/**
				 * Failure callback
				 * @param    {Object}      reqData
				 * @param    {Function}    callback
				 * @returns {Function}
				 */
				var failure = function (reqData, callback) {
					return function (response) {
						reqData.response = response;
						setProcessingResult(reqData, true);
						if (!formValues.breakOnError) {
							'function' == typeof callback ? callback() : null;
						} else {
							if (formValues.requestLoop) {
								closeProgress();
							}
							hideLoading();
						}
					};
				};
				/**
				 * Fills url and post data with values from map
				 * @param    {Object}    request
				 * @param    {Object}    data
				 */
				var prepareRequestData = function (request, data) {
					var obj = {};
					Ext.Object.merge(obj, request);
					obj.uri = edi.utils.formatString(obj.url, data, true);
					if (obj.postData) {
						var postData = obj.postData;
						if (Ext.isObject(postData)) {
							postData = Ext.encode(postData);
						}
						obj.request = edi.utils.formatString(postData, data, true);
					}
					return obj;
				};
				/**
				 * Generates request chain method
				 * @param    {Object}      reqData
				 * @param    {Function}    callback
				 * @returns  {Function}
				 */
				var sendRequest = function (reqData, callback) {
					return function () {
						reqData.time = new Date().getTime();
						if (reqData.uri) {
							reqData.uri = reqData.uri.replace(
								'../logic_engine/api/',
								edi.constants.DEFAULT.REST_PREFIX
							);
						}
						edi.rest.sendRequest(
							reqData.uri,
							reqData.method || 'GET',
							reqData.request,
							success(reqData, callback),
							failure(reqData, callback),
							null,
							{
								suppressDefaultError: true
							}
						);
					};
				};
				/**
				 * Method for final finish processing on level 1
				 */
				var finishFn = function () {
					if (!formValues.requestLoop || progressClosedFlag) {
						if (!progressClosedFlag) {
							closeProgress();
						}
						hideLoading();
						showResultsModalGrid();
						resultsButton.show();
					} else {
						loopCounter++;
						updateProgress();
						processRequests(data, 0);
					}
				};

				if (!level) {
					level = 1;
				}
				if (data.response && data.response.items && data.response.items.length) {
					for (j = 0; j < data.response.items.length; j++) {
						mapValues = processResponseMap(data.response.items[j], data.map);
						for (i = 0; i < data.requests.length; i++) {
							data.requests[i].level = level;
							if (data.defaults) {
								Ext.applyIf(data.requests[i], data.defaults);
							}
							if (isAvailableForProcessing(mapValues, data.requests[i])) {
								chain = sendRequest(
									prepareRequestData(data.requests[i], mapValues),
									'function' == typeof chain ? chain : finishFn
								);
							}
						}
					}
				} else {
					if (data.response && data.map) {
						if (!data.defaults) {
							data.defaults = {};
						}
						data.defaults.mapValues = processResponseMap(data.response, data.map);
					}
					for (i = 0; i < data.requests.length; i++) {
						data.requests[i].level = level;
						if (data.defaults) {
							Ext.applyIf(data.requests[i], data.defaults);
						}
						chain = sendRequest(
							prepareRequestData(data.requests[i], mapValues),
							'function' == typeof chain ? chain : finishFn
						);
					}
				}
				if ('function' == typeof chain) {
					chain();
				} else {
					if (formValues.requestLoop) {
						closeProgress();
					}
					edi.core.showError('debug.complex.request.nothing.to.process', hideLoading);
				}
			};
			/**
			 * Prepares data for usage in replacements
			 * @param    {Object}    data    response data object
			 * @param    {Object}    map     objects with properties path map, if defined
			 * @returns  {Object}
			 */
			var processResponseMap = function (data, map) {
				var retObj = {},
					i;
				Ext.Object.merge(retObj, data);
				if (map) {
					for (i in map) {
						if (map.hasOwnProperty(i)) {
							retObj[i] = edi.utils.getObjectProperty(data, map[i]);
						}
					}
				}
				return retObj;
			};
			/**
			 * Creates data objects with all data needed for tree rendering
			 * @param    {Object}    data    data passed from user json
			 */
			var prepareDataForTree = function (data) {
				var retData, i;
				if (data && 'object' == typeof data) {
					retData = edi.utils.clone(data);
					if (retData && retData.requests && retData.requests.length) {
						for (i = 0; i < retData.requests.length; i++) {
							idCounter++;
							retData.requests[i].id = idCounter;
							if (retData.defaults) {
								Ext.applyIf(retData.requests[i], retData.defaults);
							}
							if (!retData.requests[i].method) {
								retData.requests[i].method = 'GET';
							}
							if (retData.requests[i].requests) {
								retData.requests[i] = prepareDataForTree(retData.requests[i]);
								retData.requests[i].leaf = false;
							} else {
								retData.requests[i].leaf = true;
							}
						}
					}
				}
				return retData;
			};
			/**
			 * Displays modal dialog for complex request json entering
			 */
			var showJsonInputModalForm = function (json, callback) {
				json = json ? json : Ext.encode(processData);
				var form = createForm({
					cls: 'edi-form',
					fieldDefaults: {
						labelWidth: 150
					},
					bodyPadding: 10,
					layout: 'card',
					items: [
						createTextField({
							name: 'jsonToProcess',
							cls: 'edi-complex-request-json-input-field',
							validator: function (val) {
								var passed = edi.i18n.getMessage('debug.complex.request.json.empty'),
									decoded;
								if (val) {
									decoded = Ext.decode(val, true);
									passed = !decoded
										? edi.i18n.getMessage('debug.complex.request.json.invalid')
										: true;
								}
								return passed;
							},
							value: json,
							isTextarea: true
						})
					]
				});
				form.isValid();
				var modal = createModalPanel({
					title: edi.i18n.getMessage('debug.complex.request.json.input.title'),
					cls: 'edi-complex-request-json-input-form',
					height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
					width: MODAL_SIZE.widthLarge,
					resizable: true,
					listeners: {
						show: function () {
							form.down('textfield').focus();
						}
					},
					layout: 'fit',
					items: [form],
					buttonsBefore: [
						createButton({
							text: edi.i18n.getMessage('form.btn.save'),
							formBind: true,
							disabled: true,
							bindToForm: form,
							handler: function () {
								if (form.isValid()) {
									var formValues = edi.utils.collectFormValues(form);
									var data = Ext.decode(formValues['jsonToProcess'], true);
									if (data) {
										processData = data;
										treeStore.setProxy({
											type: 'memory',
											data: prepareDataForTree(processData)
										});
										treeStore.load();
										'function' == typeof callback ? callback(processData) : null;
										modal.close();
									}
									processButton.setDisabled(!data);
								}
							}
						})
					]
				});
				modal.show();
			};
			/**
			 * Displays modal dialog for complex request json details
			 */
			var showJsonDetailsModalForm = function () {
				var field,
					copyButton,
					addButton,
					getTextData = function () {
						var selection = window.getSelection(),
							txt;
						selection.removeAllRanges();
						var range = document.createRange();
						range.selectNode(field.inputEl.dom);
						selection.addRange(range);
						txt = selection.toString();
						selection.removeAllRanges();
						return txt;
					},
					openInputModal = function () {
						showJsonInputModalForm(getTextData(), function (data) {
							field.setValue(
								edi.utils.stringifyObject(data, false, {
									useTabs: true
								})
							);
							addButton.setGlyph(data ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD);
							addButton.setText(
								data
									? edi.i18n.getMessage('debug.complex.request.change.data')
									: edi.i18n.getMessage('debug.complex.request.import.data')
							);
							copyButton.setDisabled(!data);
						});
					},
					form = createForm({
						cls: 'edi-form',
						fieldDefaults: {
							labelWidth: 150
						},
						bodyPadding: 10,
						layout: 'fit',
						items: [
							createPanel({
								autoScroll: true,
								items: [
									(field = createDisplayField({
										name: 'jsonToProcess',
										value: processData
											? edi.utils.stringifyObject(processData, false, {
													useTabs: true
											  })
											: ''
									}))
								]
							})
						]
					});
				var modal = createModalPanel({
					title: edi.i18n.getMessage('debug.complex.request.json.details.title'),
					cls: 'edi-complex-request-json-input-form',
					height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
					width: MODAL_SIZE.widthLarge,
					resizable: true,
					layout: 'fit',
					items: [form],
					buttonsBefore: [
						(copyButton = createButton({
							text: edi.i18n.getMessage('debug.complex.request.json.copy'),
							cls: BUTTON_CLS.primary,
							glyph: edi.constants.ICONS.COPY,
							disabled: !processData,
							handler: function () {
								edi.utils.copyToClipboard(field.inputEl.dom);
							}
						})),
						(addButton = createButton({
							text: edi.i18n.getMessage(
								processData ? 'debug.complex.request.change.data' : 'debug.complex.request.import.data'
							),
							cls: BUTTON_CLS.primary,
							glyph: processData ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD,
							formBind: true,
							bindToForm: form,
							disabled: true,
							handler: openInputModal
						}))
					]
				});
				modal.show();
				if (!processData) {
					openInputModal();
				}
			};
			/**
			 * Displays modal grid with requests results
			 */
			var showResultsModalGrid = function () {
				var columns = edi.columns.get('complex_request_result');
				columns.push(
					createActionsColumnConfig({
						items: [
							{
								glyph: edi.constants.ICONS.DETAILS,
								handler: function (grid, rowIndex) {
									var record = grid.getStore().getAt(rowIndex);
									showDetails(record.getData());
								}
							}
						]
					})
				);
				var showDetails = function (data) {
					var form = createForm({
							cls: 'edi-form document-filter',
							fieldDefaults: {
								labelWidth: 150
							},
							region: 'north',
							autoScroll: true,
							bodyPadding: 10,
							collapsible: true,
							collapsed: false,
							collapseMode: 'header',
							title: edi.i18n.getMessage('debug.request.form.title'),
							defaults: {
								anchor: '100%'
							},
							width: '100%',
							border: 0,
							header: {
								cls: 'document-filter-header',
								tools: [
									{
										xtype: 'button',
										glyph: edi.constants.ICONS.FILTER_LIST,
										cls: 'edi-button-filter',
										handler: function () {
											form.toggleCollapse();
										}
									}
								]
							},
							items: [
								createDisplayField({
									name: 'uri',
									fieldLabel: edi.i18n.getMessage('debug.request.url'),
									valueSrc: data
								}),
								createTwoColumnsLayout(
									[
										createDisplayField({
											name: 'method',
											fieldLabel: edi.i18n.getMessage('debug.request.type'),
											valueSrc: data
										}),
										createDisplayField({
											name: 'level',
											fieldLabel: edi.i18n.getMessage('debug.request.level'),
											valueSrc: data
										})
									],
									[
										createDisplayField({
											name: 'time',
											fieldLabel: edi.i18n.getMessage('debug.request.time'),
											value: edi.renderers.dateTimeFromMs(data.time)
										}),
										createDisplayField({
											name: 'error',
											fieldLabel: edi.i18n.getMessage('debug.request.with.error'),
											value: edi.renderers.yesNo(data.error)
										})
									],
									0.5,
									{
										items1Conf: {
											cls: 'two-column-no-padding',
											margin: '0 5 0 0'
										},
										items2Conf: {
											cls: 'two-column-no-padding',
											margin: '0 0 0 5'
										}
									}
								)
							]
						}),
						panel = createPanel({
							cls: 'edi-test-result-label',
							autoScroll: true,
							html: edi.utils.stringifyObject(data.result, false, {
								useTabs: true
							})
						});
					var modal = createModalPanel({
						title: edi.i18n.getMessage('debug.complex.request.result.details.title'),
						cls: 'edi-complex-request-result-details',
						height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
						width: MODAL_SIZE.widthLarge,
						resizable: true,
						layout: 'border',
						items: [form, panel],
						buttons: [
							createButton({
								text: edi.i18n.getMessage('debug.complex.request.json.copy'),
								glyph: edi.constants.ICONS.COPY,
								cls: BUTTON_CLS.primary,
								handler: function () {
									edi.utils.copyToClipboard(panel.body.dom);
								}
							})
						]
					});
					modal.show();
				};
				var grid = createGrid({
					proxyConfig: {
						type: 'pagingmemory',
						data: resultData,
						reader: {
							type: 'array'
						}
					},
					storeConfig: {
						model: edi.models.getModel('COMPLEX_REQUEST_RESULT')
					},
					gridConfig: {
						disablePaging: true,
						columns: columns,
						cls: 'edi-create-field-line-separated',
						border: 0,
						padding: 0,
						listeners: {
							celldblclick: function (view, td, cellIndex, record) {
								showDetails(record.getData());
							}
						}
					},
					viewConfig: {
						getRowClass: function (record) {
							return record.get('error') ? ROW_COLOR_CLS.error : '';
						}
					}
				});
				var modal = createModalPanel(
					{
						title: edi.i18n.getMessage('debug.complex.request.results.title'),
						cls: 'edi-complex-request-results-grid',
						height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
						width: MODAL_SIZE.widthLarge,
						resizable: true,
						layout: 'fit',
						items: [grid]
					},
					true
				);
				modal.show();
			};
			/**
			 * Display modal form for editing request data
			 * @param    {Object}     record    tree store record
			 * @param    {Boolean}    isNew     true if we deal with newly added node - will be deleted on cancel of editing
			 */
			var modalRequestDataForm = function (record, isNew) {
				var data = record.getData(),
					form,
					saveBtn,
					i,
					stores = {
						map: undefined,
						include: undefined,
						exlude: undefined
					},
					postData;
				/**
				 * Collects changeable values from request row edit dialog
				 * @returns {Object}
				 */
				var collectFormData = function () {
					var values = edi.utils.collectFormValues(form.getForm()),
						i,
						createIncludeObj = function (rec) {
							var type = rec.get('type'),
								value = rec.get('value'),
								objRet;
							if (type === 'IN' || type === 'NOTIN') {
								objRet = {};
								objRet[type] = value;
							} else if (type === 'EQUAL') {
								objRet = value[0];
							} else {
								objRet = {};
								objRet[type] = value[0];
							}
							return objRet;
						};
					if (values.method === 'GET') {
						delete values.postData;
					}
					if (stores.map) {
						var mapRecords = stores.map.getRange();
						if (mapRecords.length) {
							values.map = {};
							for (i = 0; i < mapRecords.length; i++) {
								values.map[mapRecords[i].get('field')] = mapRecords[i].get('path');
							}
						}
					}
					if (stores.include) {
						var includeRecords = stores.include.getRange();
						if (includeRecords.length) {
							values.include = {};
							for (i = 0; i < includeRecords.length; i++) {
								values.include[includeRecords[i].get('field')] = createIncludeObj(includeRecords[i]);
							}
						}
					}
					if (stores.exclude) {
						var excludeRecords = stores.exclude.getRange();
						if (excludeRecords.length) {
							values.exclude = {};
							for (i = 0; i < excludeRecords.length; i++) {
								values.exclude[excludeRecords[i].get('field')] = createIncludeObj(excludeRecords[i]);
							}
						}
					}
					return values;
				};
				/**
				 * Checks if any data changed, and we need to activate save button
				 */
				var dataChanged = function () {
					var dataToCompare = {
							url: data.url,
							method: data.method
						},
						changed = false;
					if (data.method === 'POST' || data.method === 'PUT') {
						dataToCompare.postData = data.postData;
					}
					if (form) {
						if (data.map) {
							dataToCompare.map = data.map;
						}
						if (data.include) {
							dataToCompare.include = data.include;
						}
						if (data.exlude) {
							dataToCompare.exlude = data.exlude;
						}
						changed = !edi.utils.compareObjects(dataToCompare, collectFormData());
						saveBtn.setDisabled(!(changed && form.isValid()));
					}
					return changed;
				};
				/**
				 * Creates grid for parents request response properties map for usage in current request
				 * @returns {*|Object}
				 */
				var getMapGrid = function () {
					var mapData = [],
						grid,
						columns = edi.columns.get('complex_request_map');
					var editMap = function (rec) {
						var saveBtn,
							initVals = rec ? rec.getData() : {},
							form = createForm({
								cls: 'edi-form',
								fieldDefaults: {
									labelWidth: 150
								},
								bodyPadding: 10,
								defaults: {
									anchor: '100%'
								},
								autoScroll: true,
								border: 0,
								items: [
									createTextField({
										name: 'field',
										fieldLabel: edi.i18n.getMessage('debug.request.map.field'),
										allowBlank: false,
										value: initVals.field
									}),
									createTextField({
										name: 'path',
										fieldLabel: edi.i18n.getMessage('debug.request.map.field.path'),
										allowBlank: false,
										value: initVals.path
									})
								]
							});
						var modal = createModalPanel({
							title: edi.i18n.getMessage('debug.request.map.row.details.title'),
							cls: 'edi-complex-request-map-details',
							width: MODAL_SIZE.widthSmall,
							resizable: true,
							layout: 'fit',
							items: [form],
							buttonsBefore: [
								(saveBtn = createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('form.btn.save'),
									disabled: !rec,
									handler: function () {
										var values = edi.utils.collectFormValues(form.getForm());
										if (rec) {
											if (initVals.field != values.field || initVals.path != values.path) {
												rec.set('field', values.field);
												rec.set('path', values.path);
											}
										} else {
											stores.map.add(edi.models.createInstance('COMPLEX_REQUEST_MAP', values));
										}
										stores.map.commitChanges();
										grid.getView().refresh(); //Needed cos when we restore initial values on second editing, view do not refresh cell value
										modal.close();
									}
								}))
							]
						});
						form.on('validitychange', function (frm, valid) {
							saveBtn.setDisabled(!valid);
						});
						form.isValid();
						modal.show();
					};
					columns.push(
						createActionsColumnConfig({
							items: [
								{
									glyph: edi.constants.ICONS.EDIT,
									handler: function (grid, rowIndex) {
										var record = grid.getStore().getAt(rowIndex);
										editMap(record);
									}
								},
								{
									glyph: edi.constants.ICONS.DELETE,
									handler: function (grid, rowIndex) {
										edi.core.confirm(
											undefined,
											'debug.request.map.delete.row.question',
											function () {
												grid.getStore().removeAt(rowIndex);
											}
										);
									}
								}
							]
						})
					);
					if (data.map) {
						for (i in data.map) {
							if (data.map.hasOwnProperty(i)) {
								mapData.push({
									field: i,
									path: data.map[i]
								});
							}
						}
					}
					stores.map = edi.stores.createInlineStore(mapData, 'COMPLEX_REQUEST_MAP', undefined, {
						listeners: {
							datachanged: dataChanged,
							update: dataChanged
						}
					});
					grid = createGrid({
						gridConfig: {
							store: stores.map,
							columns: columns,
							cls: 'edi-collapsible-panel edi-create-field-line-separated',
							title: edi.i18n.getMessage('debug.request.map.grid.title'),
							dockedItems: createToolBar({
								items: [createAddButton(() => editMap())]
							}),
							header: {
								cls: 'document-filter-header panel-header-small',
								tools: [
									{
										xtype: 'button',
										glyph: edi.constants.ICONS.FILTER_LIST,
										cls: 'edi-button-collapse',
										handler: function () {
											grid.toggleCollapse();
										}
									}
								]
							},
							border: 0,
							padding: 0,
							disablePaging: true,
							listeners: {
								celldblclick: function (view, td, cellIndex, record) {
									editMap(record);
								}
							}
						}
					});
					return grid;
				};
				/**
				 * Creates grid for parents request response filtering map
				 * @returns {*|Object}
				 */
				var getIncludeExludeGrid = function (filter) {
					var mapData = [],
						grid,
						columns = edi.columns.get('complex_request_include'),
						typesStore = edi.stores.createSimpleInlineStore(
							['EQUAL', 'IN', 'NOTIN', 'GREATERTHAN', 'LESSTHAN'],
							function (id) {
								return edi.i18n.getMessage('debug.request.include.type.' + id);
							}
						);
					if ('include' !== filter && 'exclude' !== filter) {
						filter = 'include';
					}
					/**
					 * Opens modal for editing/adding of include or exlude entry
					 * @param    {Object}    rec    record for edit or undefined for new
					 */
					var editRow = function (rec) {
						var saveBtn,
							initVals = rec
								? rec.getData()
								: {
										type: 'EQUAL',
										value: []
								  },
							valueData = [],
							recCnt = 0,
							columns = edi.columns.get('complex_request_include_values'),
							i;
						columns.push(
							createActionsColumnConfig({
								items: [
									{
										glyph: edi.constants.ICONS.DELETE,
										handler: function (grid, rowIndex) {
											grid.getStore().removeAt(rowIndex);
										}
									}
								]
							})
						);
						if (initVals.value.length) {
							for (i = 0; i < initVals.value.length; i++) {
								valueData.push({
									id: recCnt,
									name: initVals.value[i]
								});
								recCnt++;
							}
						}
						var valStore = edi.stores.createInlineStore(valueData, 'SIMPLE');
						var form = createForm({
							cls: 'edi-form',
							fieldDefaults: {
								labelWidth: 150
							},
							bodyPadding: 10,
							defaults: {
								anchor: '100%'
							},
							autoScroll: true,
							border: 0,
							items: [
								createTextField({
									name: 'field',
									fieldLabel: edi.i18n.getMessage('debug.request.map.field'),
									allowBlank: false,
									value: initVals.field
								}),
								createCombo({
									name: 'type',
									fieldLabel: edi.i18n.getMessage('debug.request.include.type'),
									store: typesStore,
									displayField: 'name',
									valueField: 'id',
									allowBlank: false,
									forceSelection: true,
									autoValue: initVals.type
								}),
								createGrid({
									gridConfig: {
										title: edi.i18n.getMessage('debug.request.include.values'),
										store: valStore,
										columns: columns,
										hideHeaders: true,
										cls: 'is-edit',
										dockedItems: createToolBar({
											items: [
												createAddButton(() => {
													var values = edi.utils.collectFormValues(form),
														recs = valStore.getRange();
													if (
														values.type === 'IN' ||
														values.type === 'NOTIN' ||
														!recs.length
													) {
														valStore.add(
															edi.models.createInstance('SIMPLE', {
																id: recCnt
															})
														);
														recCnt++;
													}
												})
											]
										}),
										border: 0,
										padding: 0,
										plugins: [
											Ext.create('Ext.grid.plugin.CellEditing', {
												clicksToEdit: 1
											})
										],
										disablePaging: true,
										disableSelection: true
									}
								})
							]
						});
						var modal = createModalPanel({
							title: edi.i18n.getMessage('debug.request.' + filter + '.row.details.title'),
							cls: 'edi-complex-request-map-details',
							width: MODAL_SIZE.widthMedium,
							resizable: true,
							layout: 'fit',
							items: [form],
							buttonsBefore: [
								(saveBtn = createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('form.btn.save'),
									disabled: !rec,
									handler: function () {
										var values = edi.utils.collectFormValues(form.getForm());
										var records = valStore.getRange(),
											i;
										if (records.length) {
											values.value = [];
											for (i = 0; i < records.length; i++) {
												values.value.push(records[i].get('name'));
											}
											if (rec) {
												rec.set('field', values.field);
												rec.set('type', values.type);
												rec.set('value', values.value);
											} else {
												stores[filter].add(
													edi.models.createInstance('COMPLEX_REQUEST_INCLUDE', values)
												);
											}
											stores[filter].commitChanges();
											grid.getView().refresh(); //Needed cos when we restore initial values on second editing, view do not refresh cell value
											modal.close();
										} else {
											edi.core.showError('debug.request.include.row.value.missing.error');
										}
									}
								}))
							]
						});
						form.on('validitychange', function (frm, valid) {
							saveBtn.setDisabled(!valid);
						});
						form.isValid();
						modal.show();
					};
					columns.push(
						createActionsColumnConfig({
							items: [
								{
									glyph: edi.constants.ICONS.EDIT,
									handler: function (grid, rowIndex) {
										var record = grid.getStore().getAt(rowIndex);
										editRow(record);
									}
								},
								{
									glyph: edi.constants.ICONS.DELETE,
									handler: function (grid, rowIndex) {
										edi.core.confirm(
											undefined,
											'debug.request.map.delete.row.question',
											function () {
												grid.getStore().removeAt(rowIndex);
											}
										);
									}
								}
							]
						})
					);
					if (data[filter]) {
						var fType, value;
						for (i in data[filter]) {
							if (data[filter].hasOwnProperty(i)) {
								if (Ext.isObject(data[filter][i])) {
									for (fType in data[filter][i]) {
										if (data[filter][i].hasOwnProperty(fType)) {
											break;
										}
									}
									if (fType === 'IN' || fType === 'NOTIN') {
										value = data[filter][i][fType];
									} else {
										value = [data[filter][i][fType]];
									}
								} else {
									fType = 'EQUAL';
									value = [data[filter][i]];
								}
								mapData.push({
									field: i,
									type: fType,
									value: value
								});
							}
						}
					}
					stores[filter] = edi.stores.createInlineStore(mapData, 'COMPLEX_REQUEST_INCLUDE', undefined, {
						listeners: {
							datachanged: dataChanged,
							update: dataChanged
						}
					});
					grid = createGrid({
						gridConfig: {
							store: stores[filter],
							columns: columns,
							cls: 'edi-collapsible-panel edi-create-field-line-separated',
							title: edi.i18n.getMessage('debug.request.' + filter + '.grid.title'),
							dockedItems: createToolBar({
								items: [createAddButton(() => editRow())]
							}),
							header: {
								cls: 'document-filter-header panel-header-small',
								tools: [
									{
										xtype: 'button',
										glyph: edi.constants.ICONS.FILTER_LIST,
										cls: 'edi-button-collapse',
										handler: function () {
											grid.toggleCollapse();
										}
									}
								]
							},
							border: 0,
							padding: 0,
							disablePaging: true,
							listeners: {
								celldblclick: function (view, td, cellIndex, record) {
									editMap(record);
								}
							}
						}
					});
					return grid;
				};
				form = createForm({
					cls: 'edi-form',
					fieldDefaults: {
						labelWidth: 150
					},
					bodyPadding: 10,
					defaults: {
						anchor: '100%'
					},
					autoScroll: true,
					border: 0,
					items: [
						createTextField({
							name: 'url',
							fieldLabel: edi.i18n.getMessage('debug.request.url'),
							allowBlank: false,
							listeners: {
								change: dataChanged
							},
							valueSrc: data
						}),
						createTwoColumnsLayout(
							[
								createCombo({
									name: 'method',
									fieldLabel: edi.i18n.getMessage('debug.request.type'),
									store: methodsStore,
									displayField: 'name',
									valueField: 'id',
									allowBlank: false,
									forceSelection: true,
									autoValue: data.method,
									listeners: {
										select: function (cmb, value) {
											var method = 'GET';
											if (value && value.get) {
												method = value.get('id');
											}
											if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
												postData.show();
											} else {
												postData.hide();
											}
											dataChanged();
										}
									}
								})
							],
							[
								createDisplayField({
									name: 'depth',
									fieldLabel: edi.i18n.getMessage('debug.request.level'),
									valueSrc: data
								})
							],
							0.5,
							{
								items2Conf: {
									margin: '0 0 0 10'
								}
							}
						),
						(postData = createTextField({
							name: 'postData',
							hidden: !(data.method === 'POST' || data.method === 'PUT'),
							fieldLabel: edi.i18n.getMessage('debug.request.post.or.put.data'),
							value: data.postData,
							listeners: {
								change: dataChanged
							},
							isTextarea: true
						})),
						data.depth > 1 || (data.depth == 1 && processData.response) ? getMapGrid() : null,
						data.depth > 1 || (data.depth == 1 && processData.response) ? getIncludeExludeGrid() : null,
						data.depth > 1 || (data.depth == 1 && processData.response)
							? getIncludeExludeGrid('exclude')
							: null
					]
				});
				var modal = createModalPanel({
					title: edi.i18n.getMessage('debug.complex.request.row.edit.title'),
					cls: 'edi-complex-request-form',
					height: '70%',
					width: MODAL_SIZE.widthLarge,
					resizable: true,
					layout: 'fit',
					items: [form],
					buttonsBefore: [
						(saveBtn = createButton({
							cls: BUTTON_CLS.primary,
							text: edi.i18n.getMessage('form.btn.save'),
							disabled: true,
							handler: function () {
								var values = collectFormData(),
									i;
								for (i in values) {
									if (values.hasOwnProperty(i)) {
										record.set(i, values[i]);
									}
								}
								record.commit();
								if (isNew && record.parentNode) {
									record.parentNode.expand();
									processButton.setDisabled(false);
								}
								modal.close();
							}
						}))
					]
				});
				form.isValid();
				modal.show();
			};
			treeStore = new Ext.data.TreeStore({
				proxy: {
					type: 'memory',
					data: prepareDataForTree(processData)
				},
				defaultRootProperty: 'requests',
				model: edi.models.getModel('COMPLEX_REQUEST'),
				listeners: {
					datachanged: updateProcessingData,
					update: updateProcessingData
				}
			});
			var columns = edi.columns.get('complex_request');
			columns.push(
				createActionsColumnConfig({
					flex: 0.5,
					align: 'center',
					items: [
						{
							glyph: edi.constants.ICONS.ADD,
							handler: function (grid, rowIndex, colindex, actionItem, event, record) {
								var defaults = record.get('defaults') || {};
								var node = record.appendChild(Object.assign({}, defaults));
								modalRequestDataForm(node, true);
							}
						},
						{
							glyph: edi.constants.ICONS.EDIT,
							handler: function (grid, rowIndex, colindex, actionItem, event, record) {
								modalRequestDataForm(record);
							}
						},
						{
							glyph: edi.constants.ICONS.DELETE,
							handler: function (grid, rowIndex, colindex, actionItem, event, record) {
								edi.core.confirm(
									undefined,
									record.childNodes.length
										? 'debug.complex.request.remove.row.with.children.question'
										: 'debug.complex.request.remove.row.question',
									function () {
										record.remove();
										processButton.setDisabled(!grid.getStore().getCount());
									}
								);
							}
						}
					]
				})
			);
			var tree = createTreePanel({
				store: treeStore,
				rootVisible: false,
				columns: columns,
				title: edi.i18n.getMessage('debug.complex.request.tree.title'),
				dockedItems: createToolBar({
					items: [
						createAddButton(() => {
							var root = treeStore.getRootNode(),
								node;
							if (root) {
								node = root.appendChild({});
								modalRequestDataForm(node, true);
							}
						})
					]
				}),
				useArrows: true,
				region: 'center',
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						if (cellIndex) {
							modalRequestDataForm(record);
						}
					}
				}
			});
			var form = createForm({
				cls: 'edi-form document-filter',
				region: 'north',
				fieldDefaults: {
					labelWidth: 150
				},
				bodyPadding: 10,
				collapsible: true,
				collapsed: false,
				collapseMode: 'header',
				title: edi.i18n.getMessage('debug.complex.request.form.title'),
				defaults: {
					anchor: '100%'
				},
				width: '100%',
				border: 0,
				header: {
					cls: 'document-filter-header',
					tools: [
						{
							xtype: 'button',
							glyph: edi.constants.ICONS.FILTER_LIST,
							cls: 'edi-button-filter',
							handler: function () {
								form.toggleCollapse();
							}
						}
					]
				},
				items: [
					createTwoColumnsLayout(
						[
							createCheckbox({
								name: 'requestLoop',
								boxLabel: edi.i18n.getMessage('debug.complex.request.loop')
							})
						],
						[
							createCheckbox({
								name: 'breakOnError',
								boxLabel: edi.i18n.getMessage('debug.complex.request.break.on.error')
							})
						],
						0.5,
						{
							items2Conf: {
								margin: '0 0 0 10'
							}
						}
					)
				]
			});
			form.isValid();
			resultsButton = createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('debug.complex.request.results'),
				glyph: edi.constants.ICONS.LIST,
				handler: showResultsModalGrid,
				hidden: true
			});
			processButton = createButton({
				text: edi.i18n.getMessage('debug.complex.request.process'),
				cls: BUTTON_CLS.primary,
				glyph: edi.constants.ICONS.DONE_ALL,
				handler: processForm,
				disabled: true
			});
			return createTab({
				title: edi.i18n.getMessage('debug.complex.request'),
				closable: false,
				layout: 'border',
				buttons: [
					resultsButton,
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('debug.complex.request.import.export.json'),
						glyph: edi.constants.ICONS.CODE,
						handler: showJsonDetailsModalForm
					}),
					processButton
				],
				items: [form, tree]
			});
		};
		/**
		 * Form for sending simple requests
		 * @returns {*|Object}
		 */
		var testRequest = function () {
			var loopCounter = 0,
				loopProgress,
				progressClosedFlag = false,
				copyButton,
				fireRequest,
				requestData,
				selectFileFieldForm,
				selectFileField,
				requestEncode,
				xmlResponse,
				panel = createPanel({
					cls: 'edi-test-result-label',
					autoScroll: true
				});

			var formConfig = {
				requests: [
					{
						method: 'GET',
						name: '../logic_engine/api/client/users/self',
						url: '../logic_engine/api/client/users/self',
						base64: false,
						data: ''
					}
				]
			};

			var savedConfig = edi.utils.getCookie('dev_test_send_config');
			if (savedConfig) {
				formConfig = Ext.decode(savedConfig);
			}

			var saveFormData = function (form, withData) {
				var values = form.getValues();
				var request = {
					method: values.method,
					url: values.url,
					base64: !!values.base64,
					data: withData ? values.data : ''
				};
				var i = 0;
				while (i < formConfig.requests.length) {
					if (formConfig.requests[i].url === request.url || !formConfig.requests[i].url) {
						formConfig.requests.splice(i, 1);
					} else {
						i++;
					}
				}
				formConfig.requests.splice(0, 0, request);
				if (formConfig.requests.length > 10) {
					formConfig.requests.splice(10, formConfig.requests.length - 1);
				}
				requestsStore.loadData(formConfig.requests);
				restoreFormData(form, formConfig.requests[0], true);

				edi.utils.setCookie('dev_test_send_config', '', 0);
				edi.utils.setCookie('dev_test_send_config', Ext.encode(formConfig));
			};

			var restoreFormData = function (form, conf, withURL) {
				var fields = edi.utils.getFormFields(form);
				if (!conf.method) {
					conf.method = 'GET';
				}
				fields.data.setValue(conf.data);
				fields.base64.setValue(!!conf.base64);
				fields.method.setValue(conf.method);
				showHideFieldsByMethod(conf.method);
				if (withURL) {
					fields.url.setValue(conf.url);
				}
			};

			var setPanelData = function (data) {
				var doNotEncode = 'string' == typeof data;
				var processed = edi.utils.stringifyObject(data, doNotEncode, {
					useTabs: true
				});
				copyButton.setDisabled(!data);
				panel.body.setHtml(processed);
			};

			var requestsStore = createStore({
				proxy: createProxyConfig({
					type: 'memory',
					data: formConfig.requests
				}),
				fields: ['url', 'base64', 'data', 'method']
			});

			var methodsStore = edi.stores.createInlineStore(
				[
					{
						id: 'GET',
						name: 'GET'
					},
					{
						id: 'POST',
						name: 'POST'
					},
					{
						id: 'PUT',
						name: 'PUT'
					},
					{
						id: 'DELETE',
						name: 'DELETE'
					},
					{
						id: 'DOWNLOAD',
						name: 'DOWNLOAD'
					},
					{
						id: 'UPLOAD',
						name: 'UPLOAD'
					}
				],
				'SIMPLE'
			);

			var form = createForm({
				cls: 'edi-form document-filter',
				fieldDefaults: {
					labelWidth: 150
				},
				region: 'north',
				autoScroll: true,
				bodyPadding: 10,
				collapsible: true,
				collapsed: false,
				collapseMode: 'header',
				title: edi.i18n.getMessage('debug.request.form.title'),
				defaults: {
					anchor: '100%'
				},
				width: '100%',
				border: 0,
				header: {
					cls: 'document-filter-header',
					tools: [
						{
							xtype: 'button',
							glyph: edi.constants.ICONS.FILTER_LIST,
							cls: 'edi-button-filter',
							handler: function () {
								form.toggleCollapse();
							}
						}
					]
				},
				items: [
					createCombo({
						name: 'method',
						fieldLabel: edi.i18n.getMessage('debug.request.type'),
						store: methodsStore,
						displayField: 'name',
						valueField: 'id',
						allowBlank: false,
						forceSelection: true,
						autoValue: 'GET',
						listeners: {
							select: function (cmb, value) {
								var method = 'GET';
								if (value && value.get) {
									method = value.get('id');
								}
								showHideFieldsByMethod(method);
							}
						}
					}),
					createCombo({
						name: 'timeout',
						fieldLabel: edi.i18n.getMessage('Timeout'),
						store: createStore({
							proxy: createProxyConfig({
								type: 'memory',
								data: [
									{
										time: 3,
										name: '3 min'
									},
									{
										time: 5,
										name: '5 min'
									},
									{
										time: 10,
										name: '10 min'
									},
									{
										time: 15,
										name: '15 min'
									}
								]
							}),
							fields: ['time', 'name']
						}),
						displayField: 'name',
						valueField: 'time',
						allowBlank: false,
						forceSelection: true,
						autoValue: edi.constants.AJAX.TIMEOUT / 1000 / 60
					}),
					createCombo({
						name: 'url',
						fieldLabel: edi.i18n.getMessage('debug.request.url'),
						store: requestsStore,
						displayField: 'url',
						valueField: 'url',
						allowBlank: false,
						forceSelection: false,
						allowManualInput: true,
						anyMatch: false,
						listeners: {
							change: function (comp) {
								var value = comp.getValue();
								if (value && comp.valueModels && comp.valueModels[0]) {
									restoreFormData(form, comp.valueModels[0].data);
								}
							},
							afterrender() {
								restoreFormData(form, formConfig.requests[0], true);
							}
						}
					}),
					(requestData = createTextField({
						name: 'data',
						hidden: true,
						fieldLabel: edi.i18n.getMessage('debug.request.post.or.put.data'),
						value: '',
						isTextarea: true
					})),
					(selectFileFieldForm = createForm({
						items: [
							(selectFileField = createFile(undefined, {
								allowBlank: false,
								name: 'attachment'
							}))
						]
					})),
					createPanel({
						flex: 1,
						layout: 'hbox',
						items: [
							(requestEncode = createCheckbox({
								hidden: true,
								name: 'base64',
								boxLabel: edi.i18n.getMessage('debug.base64.encode.post.data'),
								flex: 1
							})),
							(xmlResponse = createCheckbox({
								hidden: true,
								name: 'xmlResponse',
								boxLabel: edi.i18n.getMessage('debug.xml.response'),
								flex: 1
							})),
							createCheckbox({
								name: 'requestLoop',
								boxLabel: edi.i18n.getMessage('debug.request.loop'),
								flex: 1
							})
						]
					})
				]
			});

			var showHideFieldsByMethod = function (method) {
				if (method === 'GET' || method === 'POST' || method === 'PUT') {
					xmlResponse.show();
				} else {
					xmlResponse.hide();
				}
				if (method === 'POST' || method === 'PUT') {
					requestEncode.show();
				} else {
					requestEncode.hide();
				}
				if (method === 'POST' || method === 'PUT' || method === 'UPLOAD' || method === 'DELETE') {
					requestData.show();
				} else {
					requestData.hide();
					requestEncode.hide();
				}
				if (method === 'UPLOAD') {
					selectFileFieldForm.show();
					selectFileField.setDisabled(false);
					form.isValid();
				} else {
					selectFileField.setDisabled(true);
					selectFileFieldForm.hide();
					form.isValid();
				}
			};

			var handleLoopRequest = function (values, params) {
				var timeout = values.timeout * 60 * 1000;
				loopCounter++;
				var text = edi.utils.formatString(edi.i18n.getMessage('debug.request.loop.progress.count'), {
						count: loopCounter
					}),
					closeProgress = function () {
						progressClosedFlag = true;
						loopCounter = 0;
						loopProgress.hide();
						loopProgress = null;
					};
				if (!loopProgress) {
					loopProgress = Ext.MessageBox.show({
						closable: false,
						title: edi.i18n.getMessage('debug.request.loop.progress'),
						msg: text,
						fn: closeProgress,
						buttons: Ext.MessageBox.CANCEL,
						wait: true,
						waitConfig: {
							interval: 500,
							text: loopCounter
						}
					});
				} else {
					loopProgress.updateProgress(loopProgress.progressBar.value, loopCounter, text);
				}
				var handle = function (data) {
					setPanelData(data);
					if (progressClosedFlag) {
						modal.setLoading(false);
						progressClosedFlag = false;
					} else {
						handleLoopRequest(values, params);
					}
				};
				edi.rest.sendRequest(
					values.url,
					values.method,
					params,
					handle,
					function (data) {
						closeProgress();
						handle(data);
					},
					null,
					{
						timeout: timeout
					}
				);
			};
			copyButton = createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('debug.complex.request.json.copy'),
				glyph: edi.constants.ICONS.COPY,
				disabled: true,
				handler: function () {
					edi.utils.copyToClipboard(panel.body.dom);
				}
			});
			fireRequest = createButton({
				text: edi.i18n.getMessage('debug.complex.request.process'),
				cls: BUTTON_CLS.primary,
				glyph: edi.constants.ICONS.DONE,
				disabled: true,
				handler: function () {
					if (form.isValid()) {
						modal.setLoading();
						saveFormData(form, true);
						var values = form.getValues(),
							timeout = values.timeout * 60 * 1000;
						if (values.url) {
							values.url = values.url.replace('../logic_engine/api/', edi.constants.DEFAULT.REST_PREFIX);
						}
						var params = null;
						if ('PUT' === values.method || 'POST' === values.method || 'DELETE' === values.method) {
							params = values.data;
							if (values.base64) {
								params = edi.utils.base64.encode(params);
							}
						}
						panel.body.setHtml('');
						if (values.requestLoop) {
							handleLoopRequest(values, params);
						} else {
							var handle = function (data) {
								setPanelData(data);
								modal.setLoading(false);
							};
							if ('DOWNLOAD' === values.method) {
								edi.rest.downloadFile(values.url);
								modal.setLoading(false);
							} else if ('UPLOAD' === values.method) {
								var onAttachmentUploadFailure = function (data) {
									modal.setLoading(false);
									edi.core.logMessage(
										'Error uploading document attachment file' +
											(data && data.status ? ' status - ' + data.status : ''),
										'error'
									);
									edi.core.showError(
										edi.utils.formatComplexServerError(
											data,
											'document.file.upload.error.load.attachment'
										),
										function () {
											modal.setLoading(false);
										}
									);
								};
								var options = {};
								var jsonString = requestData && requestData.getValue();
								if (jsonString) {
									try {
										options.params = Ext.decode(jsonString);
									} catch (err) {}
								}
								edi.core.submitUploadForm(
									selectFileFieldForm,
									values.url,
									'document.file.upload.attachment.in.progress',
									function (responseData) {
										if (responseData && responseData.data && responseData.data.id) {
											modal.setLoading(false);
											edi.core.showInfo('web.upload.document.success', function () {
												modal.close();
											});
										} else {
											onAttachmentUploadFailure(responseData);
										}
									},
									onAttachmentUploadFailure,
									options
								);
							} else if (
								values.xmlResponse &&
								('GET' === values.method || 'POST' === values.method || 'PUT' === values.method)
							) {
								params = {
									authType: 'EDI'
								};
								edi.rest.sendRequest(values.url, values.method, params, handle, handle, null, {
									headers: {
										Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
									},
									textResponse: true,
									timeout: timeout
								});
							} else {
								edi.rest.sendRequest(values.url, values.method, params, handle, handle, null, {
									timeout: timeout
								});
							}
						}
					}
				}
			});
			form.on('validitychange', function (frm, valid) {
				fireRequest.setDisabled(!valid);
			});
			form.isValid();
			return createTab({
				title: edi.i18n.getMessage('debug.request'),
				closable: false,
				layout: 'border',
				buttons: [copyButton, fireRequest],
				items: [
					form,
					createPanel({
						region: 'center',
						bodyPadding: 10,
						layout: 'fit',
						items: [panel]
					})
				]
			});
		};

		var translationsLoading = function (translationInfo) {
			translationInfo = translationInfo || {};

			//translationInfo.currentInstalledObNOject.hash = 'a99f5d5a2eca8418572aec9076df8b824bbab4d334cbed5579551e62ba8bdd4a' // test

			if (
				!edi.utils.getObjectProperty(edi.rest.services, 'ADMIN.I18N.TRANSLATION') ||
				!edi.utils.getObjectProperty(edi.rest.services, 'ADMIN.TRANSLATIONS')
			) {
				return null;
			}

			let checkProductVersion = function (obj) {
				return translationInfo.uiBuildVersion.majorVer === obj.version;
			};

			var panel = createPanel({
				cls: 'edi-test-result-label',
				autoScroll: true
			});

			var form = createForm({
				region: 'north',
				autoScroll: true,
				collapsible: true,
				collapsed: false,
				collapseMode: 'header',
				title: 'POST: /api/admin/translation/inner/loading',
				defaults: {
					anchor: '100%'
				},
				width: '100%',
				border: 0,
				header: {
					cls: 'document-filter-header',
					tools: [
						{
							xtype: 'button',
							glyph: edi.constants.ICONS.FILTER_LIST,
							cls: 'edi-button-filter',
							handler: function () {
								form.toggleCollapse();
							}
						}
					]
				},
				cls: 'edi-form document-filter',
				bodyPadding: 10,
				layout: 'card',
				items: [
					createTwoColumnsLayout(
						[
							createTextField({
								name: 'id',
								fieldLabel: 'id',
								value: ''
							}),
							createCombo({
								name: 'objectType',
								fieldLabel: 'objectType',
								store: edi.stores.createInlineStore(
									[
										{
											id: 'VIEW',
											name: 'Package'
										},
										{
											id: 'TRANSLATION_OBJECT',
											name: 'Object'
										}
									],
									'SIMPLE'
								),
								allowBlank: true,
								forceSelection: true
							})
						],
						[
							createTextField({
								name: 'pkgName',
								fieldLabel: 'pkgName',
								value: ''
							}),
							createTextField({
								name: 'version',
								fieldLabel: 'version',
								value: ''
							})
						],
						0.5,
						{
							items1Conf: {
								cls: 'two-column-no-padding',
								margin: '0 5 0 0'
							},
							items2Conf: {
								cls: 'two-column-no-padding',
								margin: '0 0 0 5'
							}
						}
					)
				]
			});

			var setPanelData = function (data) {
				var processed = edi.utils.stringifyObject(data, false, {
					useTabs: true
				});
				panel.body.setHtml(processed);
			};

			var lablObj, lablObj2, lablObj3;
			var createTranslationObjectFormItems = function (translationsForm) {
				let op1visibility = true,
					op2visibility = true,
					op3visibility = true,
					op1EqualOp2 = false,
					installOp2Ability = false,
					installOp3Ability = false,
					checkOp1Ability = false,
					nonInstalledObjectMessage = false,
					wrongVersionMessage = false,
					latestVersionMessage = false,
					oldVersionErrorMessage = false,
					optimalStateMessage = false,
					op1CreationDateAbilityMessage = false;

				if (!translationInfo?.currentInstalledObject) {
					nonInstalledObjectMessage = true;
					installOp3Ability = true;
				} else {
					if (!checkProductVersion(translationInfo?.currentInstalledObject)) {
						wrongVersionMessage = true;
					}
					if (
						translationInfo?.currentInstalledObject?.hash ===
						translationInfo?.latestLinkedTranslationObject?.hash
					) {
						op1visibility = false;
						op1EqualOp2 = true;
						if (
							translationInfo?.latestLinkedTranslationObject?.hash ===
							translationInfo?.latestCreatedTranslationObject?.hash
						) {
							optimalStateMessage = true;
							op3visibility = false;
						} else {
							installOp3Ability = true;
						}
					} else {
						if (
							translationInfo?.currentInstalledObject?.hash ===
							translationInfo?.latestCreatedTranslationObject?.hash
						) {
							checkOp1Ability = !translationInfo?.latestCreatedTranslationObject?.verified;
							op3visibility = false;
						} else {
							latestVersionMessage = true;
							installOp3Ability = true;
							if (checkProductVersion(translationInfo?.currentInstalledObject)) {
								checkOp1Ability = true;
							}
						}
						if (
							translationInfo?.currentInstalledObject?.objectCreationDate <
							translationInfo?.latestLinkedTranslationObject?.creationDate
						) {
							oldVersionErrorMessage = true;
							checkOp1Ability = false;
						}
						if (
							translationInfo?.latestLinkedTranslationObject?.hash ===
							translationInfo?.latestCreatedTranslationObject?.hash
						) {
							op3visibility = false;
							installOp2Ability = true;
						}
					}
				}

				if (!translationInfo?.currentInstalledObject?.objectCreationDate) {
					checkOp1Ability = false;
					op1CreationDateAbilityMessage = true;
				}
				if (!translationInfo.currentInstalledObject) {
					op1visibility = false;
				}
				if (!translationInfo.latestLinkedTranslationObject) {
					op2visibility = false;
				}
				if (!translationInfo.latestCreatedTranslationObject) {
					op3visibility = false;
				}

				if (wrongVersionMessage) {
					nonInstalledObjectMessage = false;
					latestVersionMessage = false;
					optimalStateMessage = false;
					op1CreationDateAbilityMessage = false;
					oldVersionErrorMessage = false;
				}

				let markVerified = (translationObject) => {
					let title = edi.i18n.getMessage('debug.translations.object.verify.confirm.title'),
						hash = translationObject.hash,
						version = translationObject.version,
						msg = edi.i18n.getMessage('debug.translations.object.verify.confirm.msg', [hash, version]);

					edi.core.confirm(title, msg, function () {
						translationTab.setLoading(true);
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.ADMIN.TRANSLATIONS.VERIFY.PUT,
								{
									objectId: hash
								},
								true
							),
							'PUT',
							null,
							function () {
								let check = function () {
									loadTranslationObjects(
										(translationObjects) => {
											if (
												translationObjects?.currentInstalledObject?.hash ===
												translationObjects?.latestLinkedTranslationObject?.hash
											) {
												refreshTranslationObjects();
											} else {
												setTimeout(() => check(), 3000);
											}
										},
										(message, error) => edi.core.logMessage(message, error)
									);
								};
								setTimeout(() => check(), 3000);
							},
							function (data) {
								edi.core.handleException(
									edi.utils.formatComplexServerError(data, 'User data did not saved properly')
								);
							}
						);
					});
				};
				let installObject = (translationObject) => {
					translationTab.setLoading(true);
					var values;
					if (translationObject) {
						values = {
							id: translationObject.id,
							objectType: 'TRANSLATION_OBJECT'
						};
					} else {
						values = form.getValues();
						edi.utils.clearEmptyValues(values, false);
					}
					var handle = function (data) {
						setPanelData(data);
						refreshTranslationObjects();
					};
					edi.rest.sendRequest(
						edi.rest.services.ADMIN.I18N.TRANSLATION.INNER_LOADING.POST,
						'POST',
						Ext.encode(values),
						handle,
						handle,
						null
					);
				};
				let items = [
					createContainer({
						layout: {
							type: 'hbox',
							align: 'middle'
						},
						items: [
							createLabel({
								text:
									edi.i18n.getMessage('debug.translations.ui.version') +
										translationInfo.uiBuildVersion.version || '',
								style: {
									display: 'block',
									color: '#000f17',
									marginBottom: '15px',
									fontSize: '15px'
								},
								flex: 1
							}),
							createButton({
								cls: BUTTON_CLS.light,
								glyph: edi.constants.ICONS.REFRESH,
								tooltip: edi.i18n.getMessage('debug.translations.objects.reload.btn'),
								handler: () => refreshTranslationObjects()
							})
						]
					}),
					optimalStateMessage
						? createLabel({
								html: edi.i18n.getMessage('debug.translations.op1.equal.op2.msg'),
								style: {
									display: 'block',
									color: '#0b8000',
									marginBottom: '10px'
								}
						  })
						: null,

					latestVersionMessage && !oldVersionErrorMessage
						? createLabel({
								html: edi.i18n.getMessage('debug.translations.newer.object.exists.msg'),
								style: {
									display: 'block',
									color: '#000000',
									marginBottom: '10px'
								}
						  })
						: null
				];
				if (op1visibility) {
					!(function (items, data) {
						if (wrongVersionMessage) {
							items.push(
								createLabel({
									html: edi.i18n.getMessage(
										'debug.translations.installed.object.version.not.match.product.msg'
									),
									style: {
										display: 'block',
										color: '#8b040e',
										marginBottom: '10px'
									}
								})
							);
						}
						if (op1CreationDateAbilityMessage) {
							items.push(
								createLabel({
									html: edi.i18n.getMessage(
										'debug.translations.necessary.update.installed.object.msg'
									),
									style: {
										display: 'block',
										color: '#8b040e',
										marginBottom: '10px'
									}
								})
							);
						}
						if (oldVersionErrorMessage) {
							items.push(
								createLabel({
									html: edi.i18n.getMessage(
										'debug.translations.incorrect.object.not.equal.code.version.msg'
									),
									style: {
										display: 'block',
										color: '#8b040e',
										marginBottom: '10px'
									}
								})
							);
						}
						items.push(
							createLabel({
								text: edi.i18n.getMessage('debug.translations.installed.object.used.now.msg'),
								style: {
									display: 'block',
									color: '#08628c',
									marginBottom: '15px',
									fontSize: '17px'
								}
							}),
							createField({
								title: 'ID',
								input: createLabel({
									valueSrc: data.currentInstalledObject,
									name: 'objectId',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Hash',
								input: (lablObj = createTriggerField({
									valid: false,
									allowBlank: false,
									editable: false,
									columnWidth: 0.7,
									name: 'hash',
									valueSrc: data.currentInstalledObject,
									listeners: {
										render: function (label) {
											label.validate();
										}
									},
									validator: function (value) {
										return (
											checkProductVersion(data.currentInstalledObject) ||
											edi.i18n.getMessage(
												'debug.translations.object.not.match.product.version.validator.msg'
											)
										);
									},
									cls: 'edi-ellipsis-text-field',
									triggers: {
										copy: {
											extraCls: 'edi-icon edi-icon-COPY',
											tooltip: 'Copy',
											handler() {
												lablObj.focus(true);
												document.execCommand('copy');
											}
										}
									}
								}))
							}),
							createField({
								title: 'Version',
								input: createLabel({
									valueSrc: data.currentInstalledObject,
									name: 'version',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Platform',
								input: createLabel({
									valueSrc: data.currentInstalledObject,
									name: 'platformPackage',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Creation Date',
								input: createDateLabel({
									valueSrc: data.currentInstalledObject,
									name: 'objectCreationDate',
									dateFormat: edi.constants.DATE_FORMAT.DATE_TIME,
									valueLabel: true,
									columnWidth: 0.7
								})
							})
						);

						if (edi.permissions.hasPermission('ADMIN_VERIFY_TRANSLATIONS')) {
							if (checkOp1Ability) {
								if (isLocalBuild) {
									items.push(
										createFormActionField({
											label: null,
											actionsColumnsWidth: 0.7,
											actions: [
												createLabel({
													html: edi.i18n.getMessage(
														'debug.translations.local.build.object.cannot.marked.verified.msg'
													),
													style: {
														display: 'block',
														color: '#8b040e',
														marginBottom: '10px'
													}
												})
											]
										})
									);
								} else {
									items.push(
										createFormActionField({
											label: null,
											actionsColumnsWidth: 0.7,
											actions: [
												createLink({
													text: edi.i18n.getMessage(
														'debug.translations.object.mark.checked.btn'
													),
													handler: () => markVerified(data.currentInstalledObject)
												})
											]
										})
									);
								}
							}
						}
					})(items, translationInfo);
				}
				if (nonInstalledObjectMessage) {
					items.push(
						createLabel({
							html: edi.i18n.getMessage('debug.translations.not.installed.object.for.product.msg', [
								edi.constants.LOCALIZATION_PACKAGE_NAME
							]),
							style: {
								display: 'block',
								color: '#8b040e',
								marginBottom: '10px'
							}
						})
					);
				}
				if (op2visibility) {
					!(function (items, data) {
						items.push(
							createLabel({
								text:
									edi.i18n.getMessage('debug.translations.linked.object.with.code.version.msg') +
									(op1EqualOp2 && !optimalStateMessage
										? edi.i18n.getMessage('debug.translations.linked.object.equal.installed')
										: ''),
								style: {
									display: 'block',
									color: '#08628c',
									marginBottom: '15px',
									marginTop: '25px',
									fontSize: '17px'
								}
							}),
							installOp2Ability && !wrongVersionMessage
								? createLabel({
										html: edi.i18n.getMessage(
											'debug.translations.linked.object.with.code.is.newest.msg'
										),
										style: {
											display: 'block',
											color: '#000000',
											marginBottom: '10px'
										}
								  })
								: null,
							createField({
								title: 'ID',
								input: createLabel({
									valueSrc: data.latestLinkedTranslationObject,
									name: 'id',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Hash',
								input: (lablObj2 = createTriggerField({
									valid: false,
									allowBlank: false,
									editable: false,
									columnWidth: 0.7,
									name: 'hash',
									valueSrc: data.latestLinkedTranslationObject,
									cls: 'edi-ellipsis-text-field',
									triggers: {
										copy: {
											extraCls: 'edi-icon edi-icon-COPY',
											tooltip: 'Copy',
											handler() {
												lablObj2.focus(true);
												document.execCommand('copy');
											}
										}
									}
								}))
							}),
							createField({
								title: 'Version',
								input: createLabel({
									valueSrc: data.latestLinkedTranslationObject,
									name: 'version',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Platform',
								input: createLabel({
									valueSrc: data.latestLinkedTranslationObject,
									name: 'platformPackage',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Creation Date',
								input: createDateLabel({
									valueSrc: data.latestLinkedTranslationObject,
									name: 'creationDate',
									dateFormat: edi.constants.DATE_FORMAT.DATE_TIME,
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							installOp2Ability
								? createButton({
										width: 250,
										cls: BUTTON_CLS.primary,
										text: edi.i18n.getMessage('debug.translations.install.object'),
										handler: () => installObject(data.latestLinkedTranslationObject)
								  })
								: null
						);
						return items;
					})(items, translationInfo);
				}
				if (op3visibility) {
					!(function (items, data) {
						items.push(
							createLabel({
								text: edi.i18n.getMessage(
									'debug.translations.unchecked.latest.object.for.version.product'
								),
								style: {
									display: 'block',
									color: '#08628c',
									marginBottom: '15px',
									marginTop: '25px',
									fontSize: '17px'
								}
							}),
							createField({
								title: 'ID',
								input: createLabel({
									valueSrc: data.latestCreatedTranslationObject,
									name: 'id',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Hash',
								input: (lablObj3 = createTriggerField({
									valid: false,
									allowBlank: false,
									editable: false,
									columnWidth: 0.7,
									name: 'hash',
									valueSrc: data.latestCreatedTranslationObject,
									cls: 'edi-ellipsis-text-field',
									triggers: {
										copy: {
											extraCls: 'edi-icon edi-icon-COPY',
											tooltip: 'Copy',
											handler() {
												lablObj3.focus(true);
												document.execCommand('copy');
											}
										}
									}
								}))
							}),
							createField({
								title: 'Version',
								input: createLabel({
									valueSrc: data.latestCreatedTranslationObject,
									name: 'version',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Platform',
								input: createLabel({
									valueSrc: data.latestCreatedTranslationObject,
									name: 'platformPackage',
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							createField({
								title: 'Creation Date',
								input: createDateLabel({
									valueSrc: data.latestCreatedTranslationObject,
									name: 'creationDate',
									dateFormat: edi.constants.DATE_FORMAT.DATE_TIME,
									valueLabel: true,
									columnWidth: 0.7
								})
							}),
							installOp3Ability
								? createButton({
										width: 250,
										cls: BUTTON_CLS.primary,
										text: edi.i18n.getMessage('debug.translations.install.object'),
										handler: () => installObject(data.latestCreatedTranslationObject)
								  })
								: null
						);
					})(items, translationInfo);
				}
				translationsForm.removeAll();
				translationsForm.add(items);
			};

			let translationsForm = createForm({
				autoScroll: true
			});

			let translationTab = createTab({
				title: edi.i18n.getMessage('debug.translations.download.tab.title'),
				closable: false,
				layout: 'fit',
				items: [
					createTabPanel({
						items: [
							createTab({
								title: edi.i18n.getMessage('debug.translations.objects.tab.title'),
								closable: false,
								layout: 'border',
								items: [
									createPanel({
										cls: 'edi-details-panel',
										bodyPadding: '5 10 10 10',
										region: 'center',
										layout: 'fit',
										items: [translationsForm]
									})
								]
							}),
							createTab({
								title: edi.i18n.getMessage('debug.translations.download.tab.title'),
								closable: false,
								layout: 'border',
								buttons: [
									createButton({
										text: edi.i18n.getMessage('debug.translations.download.btn.text'),
										glyph: edi.constants.ICONS.DONE,
										cls: BUTTON_CLS.primary,
										handler: function () {
											translationTab.setLoading(true);
											var values = form.getValues();
											edi.utils.clearEmptyValues(values, false);
											var rest = edi.rest.services.ADMIN.I18N.TRANSLATION.INNER_LOADING.POST;
											var handle = function (data) {
												setPanelData(data);
												refreshTranslationObjects();
												translationTab.setLoading(false);
											};
											edi.rest.sendRequest(
												rest,
												'POST',
												Ext.encode(values),
												handle,
												handle,
												null
											);
										}
									})
								],
								items: [
									form,
									createPanel({
										region: 'center',
										bodyPadding: 10,
										layout: 'fit',
										items: [panel]
									})
								]
							})
						]
					})
				]
			});

			let refreshTranslationObjects = function (cb) {
				translationTab.setLoading(true);
				loadTranslationObjects(
					(translationObjects) => {
						translationInfo = translationObjects;
						createTranslationObjectFormItems(translationsForm);
						translationTab.setLoading(false);
					},
					(message, error) => edi.core.logMessage(message, error)
				);
			};

			refreshTranslationObjects();

			return translationTab;
		};

		var loadTranslationObjects = function (resolve, reject) {
			let uiBuildVersion = edi.core.getBuildVersion();
			/**
			 * https://regex101.com/r/JDlGOy/1
			 * @param version
			 * @returns {*}
			 */
			var getMajorVersion = function (version) {
				let result = version.match(/^(\d+\.\d+\.\d+)(\.\d+)?(-[a-zA-Z]+[0-9]*)?$/);
				return result && result.length && result[1] ? result[1] : null;
			};
			uiBuildVersion.majorVer = getMajorVersion(uiBuildVersion.version);

			// All installed on current Target translation object
			edi.rest.sendRequest(
				edi.rest.services.ADMIN.TRANSLATIONS.VERSION.GET,
				'GET',
				null,
				function (data) {
					if (data && data.items) {
						let currentInstalledObject = data.items.find(function (item) {
							return (
								edi.utils.getObjectProperty(item, 'platformPackage') ===
								edi.constants.LOCALIZATION_PACKAGE_NAME
							);
						});
						// Last Objects from Translation Server
						edi.rest.sendRequest(
							edi.rest.services.ADMIN.TRANSLATIONS.LATEST.GET,
							'GET',
							{
								platformPackage: edi.constants.LOCALIZATION_PACKAGE_NAME,
								version: uiBuildVersion.version
							},
							function (data) {
								let translationInfo = data.data;
								translationInfo.currentInstalledObject = currentInstalledObject;
								translationInfo.uiBuildVersion = uiBuildVersion;
								/*
						translationInfo.currentInstalledObject = {
							"objectVersion": 1630070162017,
							"platformPackage": "Coreplat",
							"id": "12441",
							"version": "1.5.9",
							"hash": "05d298636fdd78a17002748317f83a4b889ad6ed2a55d27050864c5b0842fa3"
						};
						translationInfo.latestLinkedTranslationObject = {
							"objectVersion": 1631106001204,
							"platformPackage": "Coreplat",
							"createdBy": "admin",
							"regression": true,
							"packageId": "60ec1f7abf8f2e79a90236fd",
							"verified": true,
							"description": "Automatically generated from changed packages",
							"id": "6138b3d182ea123312eab0d7",
							"creationDate": 1,
							"version": "1.5.9",
							"hash": "05d298636fdd78a17002748317f83a4b889ad6ed2a55d27050864c5b0842fad6"
						};
						translationInfo.latestCreatedTranslationObject = {
							"objectVersion": 1631257200839,
							"platformPackage": "Coreplat",
							"createdBy": "admin",
							"regression": false,
							"packageId": "60ec1f7abf8f2e79a90236fd",
							"verified": true,
							"description": "Automatically generated from changed packages",
							"id": "613b027182ea123312eab468",
							"creationDate": 1631257201002,
							"version": "1.5.9",
							"hash": "05d298636fdd78a17002748317f83a4b889ad6ed2a55d27050864c5b0842fad6"
						};
 						*/

								resolve(translationInfo);
							},
							function () {
								resolve({
									currentInstalledObject,
									uiBuildVersion
								});
							},
							null
						);
					}
				},
				function () {
					reject('Unable to read translation versions objects', 'warn');
				},
				null
			);
		};
	})();
}
