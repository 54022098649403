import { createPanel } from '@Components/panels';
import { createCombo, createLabel, createTextField } from '@Components/fields';
import { createCreateButton } from '@Components/buttons';
import { BUTTON_CLS, createActionsButton, createButton } from '@UIkit/components/buttons';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { realtimeEvents } from '../realtime_messages';
import { createTreePanel } from '@UIkit/components/treePanel';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';
import { showConfirmWithException } from '@UIkit/components/modal';
import { diff_match_patch } from '@App/js/diff_match_patch/diff_match_patch_uncompressed';
import { vkbeautify } from '@App/js/xmlformatter/xmlformatter';
import { getLogModelName } from '@Core/admin/modules/log/models';

Ext.namespace('edi.document.actions');
Object.assign(edi.document.actions, {
	availableFromGrid: {
		SIGN: [
			{
				documents: edi.constants.FREE_DOCUMENT_TYPES,
				allow: function (document) {
					var direction = edi.utils.getDocumentDirection(document.toOrg, document.fromOrg),
						//in this states & directions user have to sign an IZVPOL first. IZV_POL should be found in linked docs and then signed
						//So disable this action from grid (enable only in details) 'cause we can't get linked docs in documents grid
						forbidSign =
							(document.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER &&
								direction === edi.constants.DIRECTIONS.INCOMING) ||
							(document.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER &&
								direction === edi.constants.DIRECTIONS.OUTGOING);

					return !forbidSign;
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.X5_AGREEMENT_CONTAINER],
				allow: () => false
			}
		]
	},
	//Это список доступных действий с документами, для них есть готовые дефолтные конструкторы кнопок
	//Если кнопки нет в списке, значит она добавляется как кастомная из модуля
	//orderе задает порядок отображения кнопок в панеле, чем больше - тем левее (0 не используем)
	buttonsConfig: {
		[edi.constants.DOCUMENT_ACTIONS.REPROCESS_MANUALLY]: {
			order: 360
		},
		[edi.constants.DOCUMENT_ACTIONS.REPROCESS]: {
			order: 350
		},
		[edi.constants.DOCUMENT_ACTIONS.SIGN]: {
			order: 340
		},
		[edi.constants.DOCUMENT_ACTIONS.SEND]: {
			order: 330
		},
		[edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE]: {
			order: 320
		},
		[edi.constants.DOCUMENT_ACTIONS.APPROVE]: {
			order: 310
		},
		[edi.constants.DOCUMENT_ACTIONS.COMPLETE]: {
			order: 300
		},
		[edi.constants.DOCUMENT_ACTIONS.READ_AND_COMPLETE]: {
			order: 290
		},
		[edi.constants.DOCUMENT_ACTIONS.READ]: {
			order: 280
		},
		[edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_CONFIRM]: {
			order: 270
		},
		[edi.constants.DOCUMENT_ACTIONS.QUICK_ANSWER_REJECT]: {
			order: 260
		},
		[edi.constants.DOCUMENT_ACTIONS.ROUTE_CONFIRM]: {
			order: 250
		},
		[edi.constants.DOCUMENT_ACTIONS.ROUTE_REJECT]: {
			order: 240
		},
		[edi.constants.DOCUMENT_ACTIONS.CUSTOM_BUTTONS]: {
			//order: 230,
			//order задается у каждой кнопки свой, поэтому дефолтных значений нет
			buttons: []
		},
		[edi.constants.DOCUMENT_ACTIONS.CLARIFY]: {
			order: 220,
			isAvailable: function (docOptions) {
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.CLARIFY, docOptions);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.EDIT]: {
			order: 210,
			createButtonMethod: function (props, moduleData, docOptions, data) {
				return edi.document.actions.createEditActionButton(
					props.moduleName || edi.constants.CREATE_MODULE_NAME_BY_TYPE[data.type],
					data,
					//OVERRIDE Ediweb begin
					props.documentNumber || data.number,
					//OVERRIDE Ediweb end
					undefined,
					undefined,
					props.moduleAddConf
				);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.PRINT]: {
			order: 200,
			createButtonMethod: function (props, moduleData, docOptions, data) {
				var obj =
					'object' == typeof props.methodAddOptions
						? props.methodAddOptions
						: {
								usingReport: true,
								data: data,
								moduleData: moduleData
						  };
				return edi.document.actions.createPrintActionButton(
					data,
					moduleData.tab,
					props.beforeAction,
					props.success,
					props.failure,
					props.label,
					docOptions,
					obj
				);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.REJECT]: {
			order: 190
		},
		[edi.constants.DOCUMENT_ACTIONS.EXPORT]: {
			order: 180,
			createButtonMethod: function (props, moduleData) {
				return edi.document.actions.createExportActionButton(moduleData.initData, props);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.REFRESH]: {
			order: 170,
			isAvailable: true,
			createButtonMethod: function (props) {
				return edi.document.actions.createRefreshActionButton(props.handler);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.ANNUL]: {
			order: 160,
			isAvailable: function (docOptions) {
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.ANNUL, docOptions);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT]: {
			order: 150,
			isAvailable: function (docOptions) {
				return edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT, docOptions);
			}
		},
		[edi.constants.DOCUMENT_ACTIONS.PACKAGING]: {
			order: 140
		},
		[edi.constants.DOCUMENT_ACTIONS.EXPORT_DATASOURCE]: {
			order: 130
		},
		[edi.constants.DOCUMENT_ACTIONS.REVOKE]: {
			order: 120
		},
		[edi.constants.DOCUMENT_ACTIONS.CONVERT_TO_DRAFT]: {
			order: 110
		},
		[edi.constants.DOCUMENT_ACTIONS.COPY]: {
			order: 100
		},
		[edi.constants.DOCUMENT_ACTIONS.DELETE]: {
			order: 90
		}
	},

	/**
	 * Set's number and date to object
	 * @param    {Object}    obj
	 */
	setDocumentProperties: function (obj) {
		var data = {};
		if ('object' == typeof obj) {
			if (obj.data) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_DATA_FIELD] = obj.data;
			}
			if (obj.number) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_NUMBER_FIELD] = obj.number;
			}
			if (obj.autogen) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_AUTOGEN_FIELD] = obj.autogen;
			}
			if (obj.date) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_DATE_FIELD] = edi.utils.getUTCTimeZoneFree(
					obj.date,
					edi.constants.DATE_FORMAT.SERVER,
					undefined,
					obj.dateFormat ? obj.dateFormat : edi.constants.DATE_FORMAT.CLIENT
				);
			}
			if (obj.push) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_PUSH_FIELD] = obj.push;
			}
			if (obj.agreement) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_AGREEMENT_FIELD] = obj.agreement;
			}
			if (obj.supplyAgreement) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_SUPPLY_AGREEMENT_FIELD] = obj.supplyAgreement;
			}
			if (obj.factoringAgreement) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_FACTORING_AGREEMENT_FIELD] = obj.factoringAgreement;
			}
			if (obj.factoringContextId) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_FACTORING_CONTEXT_ID] = obj.factoringContextId;
			}
			if (obj.header) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOCUMENT_HEADER_FIELD] = obj.header;
			}
			if (obj.versionId) {
				data[edi.constants.DOCUMENT_PROPERTIES.VERSION_ID_FIELD] = obj.versionId;
			}
			if (obj.bp_id) {
				data[edi.constants.DOCUMENT_PROPERTIES.BP_ID] = obj.bp_id;
			}
			if (obj.bpName) {
				data[edi.constants.DOCUMENT_PROPERTIES.BP_NAME] = obj.bpName;
			}
			if (obj.maximumUseLimit) {
				data[edi.constants.DOCUMENT_PROPERTIES.MAXIMUM_USE_LIMIT] = obj.maximumUseLimit;
			}
			if (obj.customFields) {
				data[edi.constants.DOCUMENT_PROPERTIES.CUSTOM_FIELDS] = obj.customFields;
			}
			if (obj.docFlowType) {
				data[edi.constants.DOCUMENT_PROPERTIES.DOC_FLOW_TYPE] = obj.docFlowType;
			}
		}
		return data;
	},

	/**
	 * create and show Business Validations Warning window
	 * @param    {Object}    [docData]    document data
	 * @param    {Object}    [warningWindowConf]  additional properties
	 */
	showBusinessValidationsWarning: async function (docData, isBlocked, warningWindowConf = {}) {
		const url = edi.utils.formatString(
			isBlocked
				? edi.rest.services.DOCUMENTS.MESSAGE.BLOCKING.GET
				: edi.rest.services.DOCUMENTS.MESSAGE.WARNING.GET,
			{
				id: docData.id
			}
		);
		let {
			data: { data: message }
		} = await edi.rest.asyncSendRequest({ url });
		const docTypeWithBlock = edi.constants.DOCS_WITH_BLOCK.indexOf(docData.type) > -1;
		if (message === 'BlockedByActionWithDoc') {
			message = edi.i18n.getMessage('document.blockingMessage.BlockedByActionWithDoc');
		}

		const warningWindow = showConfirmWithException({
			title: isBlocked ? edi.i18n.getMessage('business.validations.blocking.title') : edi.i18n.getMessage('warn'),
			exceptionType: isBlocked ? 'error' : 'warning',
			exceptionText: message,
			msgText: isBlocked
				? edi.i18n.getMessage('business.validations.blocking.body')
				: edi.i18n.getMessage('business.validations.warning.body'),
			buttons: [
				//кнопка только для документов из списка
				docTypeWithBlock
					? createButton(
							Ext.merge(
								{
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('business.validations.action.return'),
									handler: function () {
										warningWindow.close();
									}
								},
								warningWindowConf.returnBtnConf
							)
					  )
					: null,
				//кнопка только для документов из списка
				!isBlocked &&
				docTypeWithBlock &&
				edi.action.isAvailable(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', docData))
				)
					? createButton(
							Ext.merge(
								{
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('document.send.document'),
									handler: function () {
										const success = function () {
											edi.events.documents.fireEvent('change', {
												id: docData.id
											});
										};
										const failure = edi.document.actions.defaultFailureHandler(
											undefined,
											'document.error.in.sending.process'
										);
										edi.rest.sendRequest(
											edi.utils.formatString(
												edi.rest.services.DOCUMENTS.SEND.PUT,
												{
													documentId: docData.id
												},
												true
											),
											'PUT',
											Ext.encode({}),
											success,
											failure,
											function () {
												warningWindow.close();
											}
										);
									}
								},
								warningWindowConf.nextBtnConf
							)
					  )
					: null
			].concat(warningWindowConf.additionalButtons || [])
		});
	},

	/**
	 * create or update document
	 * @param    {String}    toOrgId               to org id - not required for edit
	 * @param    {String}    orgIdType             type of id in toOrgId. default is "id" but can be "iln" or "inn"
	 * @param    {String}    [docType]               document type - not required for edit
	 * @param    {String}    [parentId]              parent document id - not required for edit
	 * @param    {String}    [documentId]            current document id - required for edit
	 * @param    {Function}  [success]               success callback
	 * @param    {Function}  [failure]               failure callback
	 * @param    {Object}    [documentProperties]    document properties to set
	 * @param    {Object}    [additionalProperties]  additional properties
	 */
	processDocument: function (
		toOrgId,
		orgIdType,
		docType,
		parentId,
		documentId,
		success,
		failure,
		documentProperties,
		additionalProperties
	) {
		var data = edi.document.actions.setDocumentProperties(documentProperties);
		var editUri =
			additionalProperties && additionalProperties.editUri
				? additionalProperties.editUri
				: edi.rest.services.DOCUMENTS.PUT;
		var forceDirectSave =
			additionalProperties && additionalProperties.forceDirectSave ? additionalProperties.forceDirectSave : false;
		var createUri =
			additionalProperties && additionalProperties.createUri
				? additionalProperties.createUri
				: edi.rest.services.DOCUMENTS.POST;

		var getErrorsHtml = function (errorsObject, level) {
			if (!errorsObject || !Array.isArray(errorsObject.errors) || !errorsObject.condition) {
				return '';
			}

			let lvl = level || 0;
			let margin = '';
			for (let i = 0; i < lvl; i++) {
				margin += '&nbsp;&nbsp;&nbsp;';
			}

			let rows = errorsObject.errors.map(function (err) {
				let res = 'unknown error';
				if ('string' === typeof err) {
					res = margin + err + '<br /><br />';
				} else if ('object' === typeof err && err !== null) {
					res = getErrorsHtml(err, ++lvl);
				}
				return res;
			});
			let cond = `${margin}<u>${edi.i18n.getMessage(
				`logic.operation.${errorsObject.condition}`
			)}</u><br/><br/>`.toLowerCase();
			let errorsHtml = rows.join(cond);

			//перед блоком ИЛИ добавляем описание, которое находится на предыдущем уровне,
			//что бы ошибки были внутри него (правее)
			if (errorsObject.condition === 'or') {
				let orMargin = '';
				let orLvl = lvl - 1 >= 0 ? lvl - 1 : 0;
				for (let i = 0; i < orLvl; i++) {
					orMargin += '&nbsp;&nbsp;&nbsp;';
				}
				let descriptionRow = `${orMargin}<u>${edi.i18n.getMessage('logic.operation.or.description')}</u><br/>`;
				errorsHtml = descriptionRow + errorsHtml;
			}

			return errorsHtml;
		};

		var successHandler = function (responseData) {
			//business validations warning
			const isWarning = edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
			const isBlocked = edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
			if (isWarning || isBlocked) {
				edi.document.actions.showBusinessValidationsWarning(
					responseData.data,
					isBlocked,
					additionalProperties?.warningWindowConf
				);
			}
			if ('function' === typeof success) {
				success.apply(this, arguments);
			}
		};

		var showBVErrors = function (data) {
			if (
				data.typeError === 'controller.process.document.business.validation.error' &&
				Array.isArray(data.additionalData) &&
				data.additionalData.length
			) {
				var errorsObject = Ext.decode(data.additionalData[0]);
				if (
					errorsObject &&
					'object' == typeof errorsObject &&
					Array.isArray(errorsObject.errors) &&
					(errorsObject.errors.length || errorsObject.transformError) &&
					errorsObject.condition
				) {
					var errorsTexts = getErrorsHtml(errorsObject);
					if (errorsTexts || errorsObject.transformError) {
						var modalError = createModalPanel({
							title: edi.i18n.getMessage(data.typeError),
							listeners: {
								close: function () {
									failure ? failure('showBVErrors') : null;
								}
							},
							items: [
								createModalForm({
									items: [
										createLabel({
											html: (function () {
												if (errorsObject.transformError) {
													errorsTexts +=
														(errorsTexts !== '' ? '<br /><br />' : '') +
														errorsObject.transformError;
												}
												return errorsTexts;
											})()
										})
									]
								})
							]
						});
						modalError.show();
					}
				}
			} else if ('function' == typeof failure) {
				failure(data);
			}
		};
		if (documentId) {
			if (additionalProperties && 'object' == typeof additionalProperties.putData) {
				Object.assign(data, additionalProperties.putData);
			}
			data.id = documentId;
			edi.rest.sendRequest(
				edi.utils.formatString(
					editUri,
					{
						documentId: documentId
					},
					true
				),
				'PUT',
				forceDirectSave
					? data.data
					: Ext.encode({
							UPDATE: true,
							UPDATE_PARAMS: data
					  }),
				successHandler,
				showBVErrors,
				null
			);
		} else {
			var continueWithDocument = function (toOrgId) {
				data.parentId = parentId;
				data.toOrgId = toOrgId;
				data.docType = docType;
				if (additionalProperties && 'object' == typeof additionalProperties.postData) {
					Object.assign(data, additionalProperties.postData);
				}
				edi.rest.sendRequest(createUri, 'POST', Ext.encode(data), successHandler, showBVErrors, null);
			};
			if (toOrgId && (!orgIdType || orgIdType === 'id')) {
				continueWithDocument(toOrgId);
			} else if (toOrgId && orgIdType === 'iln') {
				edi.utils.getOrgByILN(toOrgId, function (org) {
					if (org) {
						continueWithDocument(org.id);
					} else {
						failure ? failure() : null;
					}
				});
			} else if (toOrgId && orgIdType === 'inn') {
				edi.utils.getOrgByINN(toOrgId, function (org) {
					if (org) {
						continueWithDocument(org.id);
					} else {
						failure ? failure() : null;
					}
				});
			} else {
				failure ? failure() : null;
			}
		}
	},
	/**
	 * check linked documents on duplicated
	 * @param    {Number}     docId                  document id of based document
	 * @param    {String}     documentType        document type of created document
	 * @param    {Function}   openModule          function open module
	 * @param    {Object}     compSetLoading
	 */
	checkDuplicateCreatedLinkedDocument: function (docId, documentType, openModule, compSetLoading) {
		if (compSetLoading) {
			compSetLoading.setLoading();
		}

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: docId
			}),
			'GET',
			null,
			function (data) {
				if (
					data.success &&
					data.data &&
					data.data.hasOwnProperty('children') &&
					Array.isArray(data.data.children)
				) {
					for (var i = 0; i < data.data.children.length; i++) {
						var type = data.data.children[i].type;
						if (data.data.children[i].versionId) {
							type += '_' + data.data.children[i].versionId;
						}

						if (type === documentType && data.data.children[i].state === edi.constants.STATE.DRAFT) {
							break;
						}
					}

					if (i === data.data.children.length) {
						openModule();
					} else {
						var modalConfig = {
							closable: false,
							width: MODAL_SIZE.widthMedium,
							title: edi.i18n.getMessage('warn'),
							items: [
								createModalForm({
									items: [
										createLabel({
											margin: 20,
											html: edi.i18n.getMessage('document.linked.docs.message')
										})
									]
								})
							],
							buttonAlign: 'center',
							buttons: [
								createButton({
									cls: BUTTON_CLS.primary,
									text: edi.i18n.getMessage('button.yes'),
									glyph: edi.constants.ICONS.DONE,
									handler: function () {
										modal.close();
										openModule();
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('button.no'),
									glyph: edi.constants.ICONS.CANCEL,
									handler: function () {
										modal.close();
									}
								}),
								createButton({
									cls: BUTTON_CLS.secondary,
									text: edi.i18n.getMessage('document.linked.docs'),
									glyph: edi.constants.ICONS.DETAILS,
									handler: function () {
										edi.document.actions.linkedDocumentsDialog(docId);
										modal.close();
									}
								})
							]
						};
						var modal = createModalPanel(modalConfig);
						modal.show();
					}
				}
			},
			null,
			function () {
				if (compSetLoading) {
					compSetLoading.setLoading(false);
				}
			}
		);
	},
	checkContainerState: function (containerStateConf, containerState) {
		return function () {
			var containerStateIsCorrect = !containerStateConf;
			if ('string' == typeof containerStateConf) {
				containerStateIsCorrect = containerStateConf === containerState;
			}
			if (Array.isArray(containerStateConf)) {
				containerStateIsCorrect = containerStateConf.some((it) => it === containerState);
			}
			return containerStateIsCorrect;
		};
	},
	/**
	 * create documents list to add document button
	 * @param    {String}     available        available type
	 * @param    {Object}     data             data in moduleData
	 * @param    {Object}     moduleData       document module data
	 * @param    {Object}     document         document data
	 * @param    {String}     documentProperty document name
	 * @param    {Object}     createDocConfig  items config
	 */
	createListBasedDocuments: function (available, data, moduleData, document, documentProperty, createDocConfig) {
		if (!createDocConfig || !createDocConfig.length) {
			return [];
		}
		var actionCreateDoc = [];
		if (available && available.length) {
			var isAvailable = edi.action.isAvailable(
				available,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			);
			if (!isAvailable) {
				return [];
			}
		}
		for (var i = 0; i < createDocConfig.length; i++) {
			if (!createDocConfig[i].docType) {
				continue;
			}
			if ('function' == typeof createDocConfig[i].checkContainerStateMethod) {
				if (!createDocConfig[i].checkContainerStateMethod()) {
					continue;
				}
			}
			if ('string' == typeof createDocConfig[i].permission) {
				if (!edi.permissions.hasPermission(createDocConfig[i].permission)) {
					continue;
				}
			}
			if (Array.isArray(createDocConfig[i].permission)) {
				var deniedPermission = createDocConfig[i].permission.find(function (permission) {
					return !edi.permissions.hasPermission(permission);
				});
				if (deniedPermission) {
					continue;
				}
			}

			actionCreateDoc.push(
				'function' == typeof createDocConfig[i].createCustomMethod
					? createDocConfig[i].createCustomMethod(createDocConfig[i].title, createDocConfig[i].docType)
					: edi.document.actions.createConfigForDocumentCreateButton(
							createDocConfig[i].title,
							createDocConfig[i].docType,
							documentProperty,
							createDocConfig[i].documentConf ? createDocConfig[i].documentConf : document,
							data,
							moduleData,
							createDocConfig[i].config ? createDocConfig[i].config : {}
					  )
			);
		}

		return actionCreateDoc;
	},
	/**
	 * create action add document button
	 * @param    {Array}     items             items to list based documents
	 * @param	{Object}	[config]
	 */
	createBasedAddDocumentActionsButton: function (items, config) {
		return items.length
			? createActionsButton(
					Object.assign(
						{
							text: edi.i18n.getMessage('action.create'),
							glyph: edi.constants.ICONS.ADD,
							allowMultiClick: true,
							showInFirstHalf: true,
							order: 500,
							menu: {
								plain: true,
								hideMode: 'display',
								items: items
							}
						},
						config
					)
			  )
			: null;
	},
	/**
	 * create config for action button for creation of new document based on current one
	 * @param    {String}     title
	 * @param    {String}     documentType
	 * @param    {String}     documentProperty         name of property that will contain documentContent
	 * @param    {Object}     documentContent
	 * @param    {Object}     metaData                   document meta data
	 * @param    {Object}     moduleData               document module data
	 * @param    {String}     config                    additional config
	 * @param    {Boolean}    config.isFromTransformation     if create from Transformation
	 */
	createConfigForDocumentCreateButton: function (
		title,
		documentType,
		documentProperty,
		documentContent,
		metaData,
		moduleData,
		config
	) {
		config = config || {};
		var openModule = config.isFromTransformation
			? function () {
					edi.methods.transformations.createDocViaTransformation({
						docType: documentType,
						parentId: config.parentIdForGetTransform || metaData.id,
						uri: config.transformUri,
						parties: {
							fromOrg: moduleData.initData.data.fromOrg,
							toOrg: moduleData.initData.data.toOrg
						},
						buyerOrg: config.buyerOrg,
						moduleTab: moduleData.tab,
						additionalData: config.addData,
						postData: config.postData
					});
			  }
			: function () {
					if (config.versionId) {
						metaData.versionId = config.versionId;
					}
					var moduleConfig = {
							meta: metaData
						},
						moduleName;
					Ext.merge(moduleConfig, config.addData || {});
					moduleConfig[documentProperty] = documentContent;
					if (config.versionId) {
						moduleName =
							edi.constants.CREATE_MODULE_NAME_BY_TYPE[[documentType, config.versionId].join('_')];
					} else {
						moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[documentType];
					}
					edi.core.openModule(moduleName, undefined, undefined, false, undefined, moduleConfig);
			  };
		openModule = config.openModuleFunction || openModule;
		return {
			text: edi.i18n.getMessage(title),
			handler: function () {
				var callback = function () {
					edi.document.actions.checkDuplicateCreatedLinkedDocument(
						metaData.id,
						documentType,
						openModule,
						moduleData.tab
					);
				};
				if (config && config.beforeCustomHandler && 'function' == typeof config.beforeCustomHandler) {
					config.beforeCustomHandler(
						callback,
						title,
						documentType,
						documentProperty,
						documentContent,
						metaData,
						moduleData,
						config
					);
				} else {
					callback();
				}
			}
		};
	},
	/**
	 *  Create new documnet based on current UPD or UKD
	 *  @param	{string}	docFlowType		flowType for new document (bp name)
	 *  @param 	{string}	docType			docType for new document
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{Object}	config			additional options
	 */
	createDocumentFromUniversalDoc: function (
		docFlowType,
		docType,
		parentId,
		direction,
		productLines,
		grossPriceName,
		moduleData,
		config
	) {
		var versionUpd = config.version ? config.version : '5.01-N';
		edi.rest.sendRequest(
			edi.utils.formatString(
				config.transformUri ? config.transformUri : edi.rest.services.DOCUMENTS.TRANSFORMATION.POST,
				{
					parentId: parentId
				}
			),
			'POST',
			Ext.encode({
				docType: config.docType || edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
				docFlowType: docFlowType,
				version: versionUpd
			}),
			function (transformData) {
				if (transformData.success && 'object' == typeof transformData.data) {
					transformData.bpName = docFlowType;
					transformData.parentId = parentId;
					transformData.function = transformData.data.document.function;
					transformData.getPriceWithNDS = function (lineNumber) {
						var findedItem = productLines.find(function (item) {
							return item.LineNumber == lineNumber;
						});
						return findedItem && 'object' == typeof findedItem ? findedItem[grossPriceName] : '';
					};
					transformData.buyerOrg =
						edi.constants.DIRECTIONS.OUTGOING === direction
							? moduleData.initData.data.toOrg
							: moduleData.initData.data.fromOrg;
					transformData.toOrg =
						edi.constants.DIRECTIONS.OUTGOING === direction
							? moduleData.initData.data.fromOrg
							: moduleData.initData.data.toOrg;
					edi.core.openModule(
						edi.constants.CREATE_MODULE_NAME_BY_TYPE[docType + '_' + versionUpd],
						transformData,
						undefined,
						false,
						undefined,
						config.addData
					);
				}
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data'),
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	},

	/**
	 *  Create new documnet based on current (any doc)
	 *  @param	{string}	docFlowType		flowType for new document (bp name)
	 *  @param 	{string}	docType			docType for new document
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{string}	config			additional options
	 */
	createAnyDocumentFrom: function (
		docFlowType,
		docType,
		parentId,
		direction,
		productLines,
		grossPriceName,
		moduleData,
		config
	) {
		var version = config.version;
		edi.rest.sendRequest(
			edi.utils.formatString(
				config.transformUri ? config.transformUri : edi.rest.services.DOCUMENTS.TRANSFORMATION.POST,
				{
					parentId: parentId
				}
			),
			'POST',
			Ext.encode({
				docType: config.docType,
				docFlowType: docFlowType,
				version: version
			}),
			function (transformData) {
				if (transformData.success && 'object' == typeof transformData.data) {
					transformData.bpName = docFlowType;
					transformData.parentId = parentId;
					transformData.function = transformData.data.document.function;
					transformData.getPriceWithNDS = function (lineNumber) {
						var findedItem = productLines.find(function (item) {
							return item.LineNumber == lineNumber;
						});
						return findedItem && 'object' == typeof findedItem ? findedItem[grossPriceName] : '';
					};
					transformData.buyerOrg =
						edi.constants.DIRECTIONS.OUTGOING === direction
							? moduleData.initData.data.toOrg
							: moduleData.initData.data.fromOrg;
					transformData.toOrg =
						edi.constants.DIRECTIONS.OUTGOING === direction
							? moduleData.initData.data.fromOrg
							: moduleData.initData.data.toOrg;
					edi.core.openModule(
						edi.constants.CREATE_MODULE_NAME_BY_TYPE[version ? docType + '_' + version : docType],
						transformData,
						undefined,
						false,
						undefined,
						config.addData
					);
				}
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data'),
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	},
	/**
	 *  Create UPD menu documents with create from handler
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{Object}	[config]			additional options
	 *  @return	{Function}	function that creates menu item
	 */
	methodCreateUPDfromDocuments: function (parentId, direction, productLines, grossPriceName, moduleData, config) {
		config = config || {};
		return function (title, docType, documentProperty, docContent, parent) {
			var createDoc = function (docFlowType) {
				edi.document.actions.createDocumentFromUniversalDoc(
					docFlowType,
					docType,
					parentId,
					direction,
					productLines,
					grossPriceName,
					moduleData,
					config
				);
			};

			var menuItems = [];
			if (!config.hideSCHF) {
				menuItems.push({
					text: edi.i18n.getMessage('documents.fns_upd.type.SCHFDOP_SCHF_V2'),
					handler: function () {
						moduleData.tab.setLoading();
						createDoc(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF);
					}
				});
			}
			if (!config.hideSCHFDOP) {
				menuItems.push({
					text: edi.i18n.getMessage('documents.fns_upd.type.SCHFDOP_SCHFDOP_V2'),
					handler: function () {
						moduleData.tab.setLoading();
						createDoc(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP);
					}
				});
			}
			if (!config.hideDOP) {
				menuItems.push({
					text: edi.i18n.getMessage('documents.fns_upd.type.SCHFDOP_DOP_V2'),
					handler: function () {
						moduleData.tab.setLoading();
						createDoc(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP);
					}
				});
			}
			if (!config.hideSVRK) {
				menuItems.push({
					text: edi.i18n.getMessage('documents.fns_upd.type.SCHFDOP_SVRK_DECREE_14'),
					handler: function () {
						moduleData.tab.setLoading();
						createDoc(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14);
					}
				});
			}
			if (!config.hideSVZK) {
				menuItems.push({
					text: edi.i18n.getMessage('documents.fns_upd.type.SCHFDOP_SVZK_DECREE_14'),
					handler: function () {
						moduleData.tab.setLoading();
						createDoc(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14);
					}
				});
			}

			return {
				text: edi.i18n.getMessage(title),
				menu: {
					items: menuItems
				}
			};
		};
	},
	/**
	 *  Create UKD menu documents with create from handler
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{string}	config			additional options
	 *  @return	{Function}	function that creates menu item
	 */
	methodCreateUKDfromDocuments: function (parentId, direction, productLines, grossPriceName, moduleData, config) {
		config = config || {};
		config.docType = config.docType ? config.doctype : edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1;
		return function (title, docType, documentProperty, docContent, parent) {
			var createDoc = function (docFlowType) {
				edi.document.actions.createDocumentFromUniversalDoc(
					docFlowType,
					docType,
					parentId,
					direction,
					productLines,
					grossPriceName,
					moduleData,
					config
				);
			};

			var dockFlowType = 'UKD_' + (config.UKDFunction || 'DIS') + '_501_N';
			return {
				text: edi.i18n.getMessage(title),
				handler: function () {
					moduleData.tab.setLoading();
					createDoc(dockFlowType);
				}
			};
		};
	},

	/**
	 *  Create TORG2 menu documents with create from handler
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{string}	config			additional options
	 *  @return	{Function}	function that creates menu item
	 */
	methodCreateTORG2fromDocuments: function (parentId, direction, productLines, grossPriceName, moduleData, config) {
		config = config || {};
		config.docType = config.docType ? config.docType : edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1;
		// docType документа, который нужно открыть
		config.moduleDocType = config.moduleDocType ? config.moduleDocType : edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2;
		return function (title, docType, documentProperty, docContent, parent) {
			var createDoc = function (docFlowType) {
				edi.document.actions.createAnyDocumentFrom(
					docFlowType,
					config.moduleDocType,
					parentId,
					direction,
					productLines,
					grossPriceName,
					moduleData,
					config
				);
			};

			var dockFlowType = config.dockFlowType || edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1;
			return {
				text: edi.i18n.getMessage(title),
				handler: function () {
					moduleData.tab.setLoading();
					createDoc(dockFlowType);
				}
			};
		};
	},
	/**
	 *  Create UKD menu documents with create from handler
	 *  @param 	{number}	parentId		parent's document id
	 *  @param	{string}	direction		parent's document direction
	 *  @param	{Array}		productLines	parent's document product lines array
	 *  @param	{string}	grossPriceName	name for 'price with taxes'
	 *  @param	{Object}	moduleData		moduleData object
	 *  @param	{string}	config			additional options
	 *  @return	{Function}	function that creates menu item
	 */
	methodCreateMenuUKDfromDocuments: function (parentId, direction, productLines, grossPriceName, moduleData, config) {
		config = config || {};
		config.docType = config.docType ? config.doctype : edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1;
		return function (title, docType, documentProperty, docContent, parent) {
			var createDoc = function (docFlowType) {
				edi.document.actions.createDocumentFromUniversalDoc(
					docFlowType,
					docType,
					parentId,
					direction,
					productLines,
					grossPriceName,
					moduleData,
					config
				);
			};
			var version = '_501_N';
			return {
				text: edi.i18n.getMessage(title),
				menu: {
					items: [
						{
							text: edi.i18n.getMessage('documents.fns_ukd.type.SCHFDOP_DIS'),
							handler: function () {
								createDoc('UKD_DIS' + version);
							}
						},
						{
							text: edi.i18n.getMessage('documents.fns_ukd.type.SCHFDOP_KSCHF'),
							handler: function () {
								createDoc('UKD_KSCHF' + version);
							}
						},
						{
							text: edi.i18n.getMessage('documents.fns_ukd.type.SCHFDOP_KSCHFDIS'),
							handler: function () {
								createDoc('UKD_KSCHF_DIS' + version);
							}
						}
					]
				}
			};
		};
	},

	/**
	 * create read action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeRead      Function that should be called before mark read action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param	 {Object}	 docOptions		 document options object
	 * @param    {Object}    addConf     	 additionsl config object
	 */
	createReadActionButton: function (document, tab, beforeRead, success, failure, customLabel, docOptions, addConf) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.mark.read'),
			glyph: addConf && addConf.customGlyph ? addConf.customGlyph : edi.constants.ICONS.READ,
			handler: function () {
				edi.core.confirm(
					edi.i18n.getMessage('document.mark.read'),
					edi.i18n.getMessage('document.mark.read.question'),
					function () {
						tab.setLoading();
						success =
							'function' == typeof success
								? success
								: function () {
										tab.setLoading(false);
										edi.events.documents.fireEvent('change', {
											id: document.id
										});
								  };
						failure =
							'function' == typeof failure
								? failure
								: edi.document.actions.defaultFailureHandler(tab, 'document.error.mark.read');
						var markRead = function () {
							var postData = {};
							var stringified = Ext.encode(postData);
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: document.id
									},
									true
								),
								'PUT',
								stringified,
								success,
								failure
							);
						};
						if ('function' == typeof beforeRead) {
							beforeRead(markRead);
						} else {
							markRead();
						}
					}
				);
			}
		});
	},
	/**
	 * create read and complete action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeRead      Function that should be called before mark read action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createReadAndCompleteActionButton: function (document, tab, beforeRead, success, failure, customLabel) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.mark.read_and_complete'),
			glyph: edi.constants.ICONS.DONE,
			handler: function () {
				success =
					'function' == typeof success
						? success
						: function () {
								tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: document.id
								});
						  };
				failure =
					'function' == typeof failure
						? failure
						: edi.document.actions.defaultFailureHandler(tab, 'document.error.mark.read');
				var markRead = function () {
					tab.setLoading();
					var postData = {};
					var stringified = Ext.encode(postData);
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: document.id
							},
							true
						),
						'PUT',
						stringified,
						function () {
							if (
								edi.constants.DISABLE_DOUBLE_PUSH_TO_READ_AND_COMPLETE_ACTION &&
								edi.constants.DISABLE_DOUBLE_PUSH_TO_READ_AND_COMPLETE_ACTION.find &&
								edi.constants.DISABLE_DOUBLE_PUSH_TO_READ_AND_COMPLETE_ACTION.find(
									(docTypeToDisable) => {
										return docTypeToDisable === document.type;
									}
								)
							) {
								success();
								return;
							}
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: document.id
									},
									true
								),
								'PUT',
								stringified,
								success,
								failure
							);
						},
						failure
					);
				};
				if ('function' == typeof beforeRead) {
					beforeRead(markRead);
				} else {
					markRead();
				}
			}
		});
	},
	handlerRejectAnnualDocument: function (annulDoc, cfgSuccess, failure, tab) {
		var beforeAnnul = null;
		if (annulDoc && annulDoc.beforeAnnul) {
			beforeAnnul = annulDoc.beforeAnnul;
		}
		var annulUtochDocTypes = {};
		annulUtochDocTypes[edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL] =
			edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL_UTOCH;
		annulUtochDocTypes[edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL] =
			edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL_UTOCH;
		annulUtochDocTypes[edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL] =
			edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH;

		if (!annulUtochDocTypes.hasOwnProperty(annulDoc.type)) {
			return;
		}

		var modal,
			annulDocId = annulDoc.id;

		var success = function (data) {
			tab.setLoading(false);
			if (modal) {
				modal.close();
			}
			cfgSuccess(data);
		};

		var doReject = function (docId, values, onSuccess, onFailure) {
			var postData = {};
			postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT] = true;
			if (values) {
				postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON] = values;
			}
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: docId
					},
					true
				),
				'PUT',
				Ext.encode(postData),
				onSuccess,
				onFailure
			);
		};

		modal = edi.methods.documents.showReasonModal(
			'document.mark.annul.reject',
			function (reasonText) {
				var createRejectAnnul = function (certificate, poa, poaConfirmChecked) {
					doReject(
						annulDocId,
						reasonText,
						function () {
							//Sign annul utoch
							var utochType = annulUtochDocTypes[annulDoc.type];

							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
									documentId: annulDocId,
									depth: edi.constants.DEFAULT.TREE_DEPTH
								}),
								'GET',
								{},
								function (responseData) {
									var annulLinkedDocs =
										responseData && Array.isArray(responseData.items) && responseData.items.length
											? responseData.items
											: null;
									if (annulLinkedDocs) {
										annulLinkedDocs = annulLinkedDocs.filter(function (item) {
											return !edi.constants.DOCUMENT_NOT_BP.some((it) => it === item.type);
										});

										var AVZUtoch = annulLinkedDocs.filter(function (doc) {
											return doc.type === edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH;
										});

										var isHasAvzUtoch = AVZUtoch.length > 0;

										var isHasUtoch = !isHasAvzUtoch
											? annulLinkedDocs[0].type === utochType
											: isHasAvzUtoch;
										if (isHasUtoch) {
											var utoch = !isHasAvzUtoch ? annulLinkedDocs[0] : AVZUtoch[0];

											let certificateHandler = {
												_selectedCertificate: certificate,
												get: function () {
													return this._selectedCertificate;
												},
												set: function (cert) {
													this._selectedCertificate = cert;
												}
											};
											let poaHandler = {
												_selectedPoa: poa,
												get: function () {
													return this._selectedPoa;
												},
												set: function (poa) {
													this._selectedPoa = poa;
												},
												_poaConfirmChecked: poaConfirmChecked,
												setPoaConfirmCheck: function (poaConfirmChecked) {
													const handler = this;
													handler._poaConfirmChecked = poaConfirmChecked;
												},
												getPoaConfirmCheck: function () {
													const handler = this;
													return handler._poaConfirmChecked;
												}
											};

											edi.utils.sign(
												utoch,
												tab,
												function (failed, data) {
													if (failed) {
														edi.core.showError(
															edi.utils.formatComplexServerError(
																data,
																'error.annul.document'
															),
															() => {
																if (
																	utochType !==
																	edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL_UTOCH
																) {
																	doReject(utoch.id, null, success, failure);
																} else {
																	success(data);
																}
															}
														);
													} else {
														success(data);
													}
												},
												undefined,
												undefined,
												true,
												certificateHandler,
												{
													signRefuse: function () {
														doReject(utoch.id, null, success, failure);
													}
												},
												poaHandler
											);
										} else {
											doReject(annulLinkedDocs[0].id, null, success, failure);
										}
									} else {
										failure();
									}
								},
								failure
							);
						},
						failure
					);
				};

				if (beforeAnnul) {
					beforeAnnul(function (certificate, poa, poaConfirmChecked) {
						createRejectAnnul(certificate, poa, poaConfirmChecked);
					});
				} else {
					createRejectAnnul();
				}
			},
			null,
			annulDoc
		);
	},
	/**
	 * Wrapper for simple push button creation
	 * @param    {Object}    dataConf    push processing data and objects
	 * @param    {Object}    config      button config object
	 * @returns {*}
	 */
	createSimplePushBPButton: function (dataConf, config) {
		config = config ? config : {};
		dataConf = dataConf ? dataConf : {};
		var pushData = dataConf.pushData ? dataConf.pushData : {};
		if (dataConf.documentId) {
			var defaults = {
				text: edi.i18n.getMessage('document.send.document'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					dataConf.tab ? dataConf.tab.setLoading() : null;
					var success =
						'function' == typeof dataConf.success
							? dataConf.success
							: function () {
									dataConf.tab ? dataConf.tab.setLoading(false) : null;
									edi.events.documents.fireEvent('change', {
										id: dataConf.documentId
									});
							  };
					var failure =
						'function' == typeof dataConf.failure
							? dataConf.failure
							: edi.document.actions.defaultFailureHandler(
									dataConf.tab,
									'document.error.in.sending.process'
							  );
					var push = function () {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: dataConf.documentId
								},
								true
							),
							'PUT',
							Ext.encode(pushData),
							success,
							failure
						);
					};
					if ('function' == typeof dataConf.beforePush) {
						dataConf.beforePush(push, function () {
							dataConf.tab.setLoading(false);
						});
					} else {
						push();
					}
				}
			};
			Ext.applyIf(config, defaults);
			return createActionsButton(config);
		}
		return null;
	},
	/**
	 * create send to approve action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeSend      Function that should be called before send action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createSendToApproveActionButton: function (document, tab, beforeSend, success, failure, customLabel) {
		return edi.document.actions.createSimplePushBPButton(
			{
				documentId: document.id,
				tab: tab,
				success: success,
				failure:
					'function' == typeof failure
						? failure
						: edi.document.actions.defaultFailureHandler(tab, 'document.error.in.send.to.approve.process'),
				beforePush: beforeSend
			},
			{
				text: edi.i18n.getMessage(customLabel ? customLabel : 'document.send.to.approve.document'),
				glyph: edi.constants.ICONS.SEND_APPROVE
			}
		);
	},
	/**
	 * create approve action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeSend      Function that should be called before send action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createApproveActionButton: function (document, tab, beforeSend, success, failure, customLabel) {
		return edi.document.actions.createSimplePushBPButton(
			{
				documentId: document.id,
				tab: tab,
				success: success,
				failure:
					'function' == typeof failure
						? failure
						: edi.document.actions.defaultFailureHandler(tab, 'document.error.in.approve.process'),
				beforePush: beforeSend
			},
			{
				text: edi.i18n.getMessage(customLabel ? customLabel : 'document.approve.document'),
				glyph: edi.constants.ICONS.APPROVE
			}
		);
	},
	/**
	 * create send action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeSend      Function that should be called before send action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param    {Object}    options         document options object
	 * @param    {Object}    properties      Additional button properties
	 */
	createSendActionButton: function (document, tab, beforeSend, success, failure, customLabel, options, properties) {
		properties = properties || {};
		return edi.document.actions.createSimplePushBPButton(
			{
				documentId: document.id,
				tab: tab,
				success: success,
				failure: failure,
				beforePush: beforeSend,
				pushData: properties.pushData
			},
			{
				text: edi.i18n.getMessage(customLabel ? customLabel : 'document.send.document')
			}
		);
	},
	/**
	 * create reject action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeReject    Function that should be called before reject action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param    {Object}    data
	 * @param    {Object}    config     	 button config object
	 */
	createRejectActionButton: function (document, tab, beforeReject, success, failure, customLabel, data, config) {
		config = config || {};
		var process = Object.assign(
			{
				url: edi.rest.services.DOCUMENTS.SEND.PUT,
				method: 'PUT',
				params: {
					documentId: document.id
				},
				properties: function (reasonText) {
					var postData = {};
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT] = true;
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON] = reasonText;
					return postData;
				},
				callback: null
			},
			config.process || {}
		);

		var addReasonCfg = {},
			rejecetBtn;
		if (document.type === edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM) {
			addReasonCfg.maxLength = 255;
			addReasonCfg.listeners = {
				change: function (comp) {
					rejecetBtn.setDisabled(!comp.isValid());
				}
			};
		}
		Ext.merge(addReasonCfg, config?.addReasonCfg || {});

		failure =
			'function' == typeof failure
				? failure
				: function (failed, data, selectedCertificate, selectedPoa, silent) {
						if (!silent) {
							edi.document.actions.defaultFailureHandler(tab, 'document.error.in.reject.process')(data);
						} else {
							tab.setLoading(false);
						}
				  };
		success =
			'function' == typeof success
				? success
				: function (data) {
						tab.setLoading(false);
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
				  };

		var rejectAction = function (process, certificate, poa, poaConfirmChecked) {
			var successHandler =
				'function' == typeof process.callback
					? function (data) {
							process.callback(data, success, failure, certificate, poa, poaConfirmChecked);
					  }
					: success;
			edi.rest.sendRequest(
				edi.utils.formatString(process.url, process.params, true),
				process.method,
				Ext.encode(process.properties),
				successHandler,
				failure
			);
		};

		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.reject.document'),
			glyph: edi.constants.ICONS.REJECT,
			handler: function () {
				if (config.isDisableRejectModal) {
					tab.setLoading();

					var postData = {},
						userOrg = edi.core.getUserData().org;
					if (userOrg.id == edi.utils.getObjectProperty(document, 'fromOrg.id')) {
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.SENDER_REJECT] = true;
					} else {
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.RECEIVER_REJECT] = true;
					}
					process.properties = postData;

					if ('function' == typeof beforeReject) {
						beforeReject(function () {
							rejectAction(process);
						});
					} else {
						rejectAction(process);
					}
				} else if (config.isRejectNeedSign) {
					tab.setLoading(false);
					success();
				} else {
					var reason = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title:
							config?.modalCfg?.reasonText || edi.i18n.getMessage('document.reject.reason.field.title'),
						items: [
							createTextField(
								Object.assign(
									{
										isTextarea: true,
										columnWidth: 0.8,
										name: 'reason',
										allowBlank: false,
										maxLength: config?.maxInputLength,
										validator: function (value) {
											const errMsg = edi.i18n.getMessage(
												'document.reject.reason.fill.fields.error'
											);
											return value && !value.trim() ? errMsg : true;
										}
									},
									addReasonCfg
								)
							)
						]
					});

					var formPanel = createModalForm({
						submitEmptyText: false,
						items: [reason]
					});

					var modal = createModalPanel({
						title: config?.modalCfg?.modalTitle || edi.i18n.getMessage('document.reject.reason.title'),
						//width: MODAL_SIZE.widthLarge, //EW override
						items: [formPanel],
						buttonsBefore: [
							(rejecetBtn = createButton({
								cls: BUTTON_CLS.primary,
								text: config?.modalCfg?.buttonText || edi.i18n.getMessage('document.reject.document'),
								glyph: edi.constants.ICONS.REJECT,
								itemId: 'reject-button',
								disabled: document.type === edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
								handler: function () {
									var form = formPanel.getForm();
									if (!form.isValid()) {
										let error =
											typeof config?.getValidationError === 'function'
												? config.getValidationError(formPanel)
												: 'document.reject.reason.fill.fields.error';
										if (error) {
											edi.core.showError(error);
										}
									} else {
										var values = edi.utils.collectFormValues(form);
										var processData = Ext.clone(process);
										tab.setLoading();
										modal.close();

										if ('function' == typeof process.properties) {
											processData.properties = process.properties(values.reason || '');
										}

										if ('function' == typeof beforeReject) {
											beforeReject(function (certificate, poa, poaConfirmChecked) {
												rejectAction(processData, certificate, poa, poaConfirmChecked);
											});
										} else {
											rejectAction(processData);
										}
									}
								}
							}))
						]
					});
					formPanel.isValid();
					modal.show();
				}
			}
		});
	},
	/**
	 * create revoke action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeRevoke    Function that should be called before revoke action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param    {Object}    data
	 * @param    {Object}    config     	 button config object
	 */
	createRevokeActionButton: function (document, tab, beforeRevoke, success, failure, customLabel, data, config) {
		config = config || {};
		var process = Object.assign(
			{
				url: edi.rest.services.DOCUMENTS.SEND.PUT,
				method: 'PUT',
				params: {
					documentId: document.id
				},
				properties: function (reasonText) {
					var postData = {};
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REVOKE] = true;
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REVOKE_REASON] = reasonText;
					return postData;
				},
				callback: null
			},
			config.process || {}
		);

		var addReasonCfg = {},
			revokeBtn;
		Ext.merge(addReasonCfg, config?.addReasonCfg || {});

		failure =
			'function' == typeof failure
				? failure
				: function (failed, data, selectedCertificate, selectedPoa, silent) {
						if (!silent) {
							edi.document.actions.defaultFailureHandler(tab, 'document.error.in.reject.process')(data);
						} else {
							tab.setLoading(false);
						}
				  };
		success =
			'function' == typeof success
				? success
				: function (data) {
						tab.setLoading(false);
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
				  };

		var revokeAction = function (process, certificate, poa) {
			var successHandler =
				'function' == typeof process.callback
					? function (data) {
							process.callback(data, success, failure, certificate, poa);
					  }
					: success;
			edi.rest.sendRequest(
				edi.utils.formatString(process.url, process.params, true),
				process.method,
				Ext.encode(process.properties),
				successHandler,
				failure
			);
		};

		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'form.btn.revoke'),
			glyph: edi.constants.ICONS.REJECT,
			handler: function () {
				if (config.isDisableRevokeModal) {
					tab.setLoading();

					var postData = {};
					process.properties = postData;

					if ('function' == typeof beforeRevoke) {
						beforeRevoke(function () {
							revokeAction(process);
						});
					} else {
						revokeAction(process);
					}
				} else if (config.isRevokeNeedSign) {
					tab.setLoading(false);
					success();
				} else {
					var reason = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: config?.modalCfg?.reasonText || edi.i18n.getMessage('document.revoke.reason.title'),
						items: [
							createTextField(
								Object.assign(
									{
										isTextarea: true,
										columnWidth: 0.8,
										name: 'reason',
										allowBlank: false,
										maxLength: config?.maxInputLength,
										validator: function (value) {
											const errMsg = edi.i18n.getMessage(
												'document.revoke.reason.fill.fields.error'
											);
											return value && !value.trim() ? errMsg : true;
										}
									},
									addReasonCfg
								)
							)
						]
					});

					var formPanel = createModalForm({
						submitEmptyText: false,
						items: [reason]
					});

					var modal = createModalPanel({
						title: config?.modalCfg?.modalTitle || edi.i18n.getMessage('document.reject.reason.title'),
						//width: MODAL_SIZE.widthLarge, //EW override
						items: [formPanel],
						buttonsBefore: [
							(revokeBtn = createButton({
								cls: BUTTON_CLS.primary,
								text: config?.modalCfg?.buttonText || edi.i18n.getMessage('form.btn.revoke'),
								glyph: edi.constants.ICONS.REJECT,
								itemId: 'revoke-button',
								handler: function () {
									var form = formPanel.getForm();
									if (!form.isValid()) {
										let error =
											typeof config?.getValidationError === 'function'
												? config.getValidationError(formPanel)
												: 'document.revoke.reason.fill.fields.error';
										if (error) {
											edi.core.showError(error);
										}
									} else {
										var values = edi.utils.collectFormValues(form);
										var processData = Ext.clone(process);
										tab.setLoading();
										modal.close();

										if ('function' == typeof process.properties) {
											processData.properties = process.properties(values.reason || '');
										}

										if ('function' == typeof beforeRevoke) {
											beforeRevoke(function (certificate, poa) {
												revokeAction(processData, certificate, poa);
											});
										} else {
											revokeAction(processData);
										}
									}
								}
							}))
						]
					});
					formPanel.isValid();
					modal.show();
				}
			}
		});
	},
	/**
	 * Alias to method create action reject button
	 */
	createRouteRejectActionButton: edi.document.actions.createRejectActionButton,
	/**
	 * Creates modal dialog for entering reject reason in grids
	 * @param    {Array}       data        grid selected records
	 * @param    {Function}    callback    callback that will be called to continue processing grid data
	 * @param    {Object}      options     options for modal dialog, setReason function is mandatory - setter for reject reason text
	 */
	createGridRejectReasonDialog: function (data, callback, options) {
		options = options ? options : {};
		if (data && data.length && 'function' == typeof options.setReason) {
			var reason =
				options.component ||
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage(options.fieldTitle || 'document.reject.reason.field.title'),
					items: [
						createTextField({
							isTextarea: true,
							name: 'reason',
							allowBlank: false,
							validator: function (value) {
								const errMsg = options.fieldTitle
									? edi.i18n.getMessage(`${options.fieldTitle}.fill.fields.error`)
									: edi.i18n.getMessage('document.reject.reason.fill.fields.error');
								return value && !value.trim() ? errMsg : true;
							}
						})
					]
				});

			var formPanel = createModalForm({
				submitEmptyText: false,
				items: [reason]
			});

			var modal = createModalPanel({
				title: edi.i18n.getMessage(options.title || 'document.reject.reason.title'),
				//width: MODAL_SIZE.widthLarge,//EW override
				items: [formPanel],
				buttonsBefore: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage(options.buttonOkTitle || 'document.reject.document'),
						glyph: edi.constants.ICONS.REJECT,
						handler: function () {
							var form = formPanel.getForm();
							if (!form.isValid()) {
								edi.core.showError(options.errorText || 'document.reject.reason.fill.fields.error');
							} else {
								var values = edi.utils.collectFormValues(form);
								options.setReason(values.reason);
								modal.close();
								'function' == typeof callback ? callback() : null;
							}
						}
					})
				]
			});
			formPanel.isValid();
			modal.show();
		} else {
			'function' == typeof callback ? callback() : null;
		}
	},
	/**
	 * create delete action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeDelete    Function that should be called before delete action
	 * @param    {Function}  success         Function that will be called on success deletion of document
	 * @param    {Function}  failure         Function that will be called on failure deletion of document
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createDeleteActionButton: function (document, tab, beforeDelete, success, failure, customLabel) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.delete.document'),
			glyph: edi.constants.ICONS.DELETE,
			handler: function () {
				edi.core.confirm('confirmation.title', 'document.delete.question', function () {
					tab.setLoading();
					success =
						'function' == typeof success
							? success
							: function () {
									tab.setLoading(false);
									edi.events.documents.fireEvent('change', {
										id: document.id,
										deleted: true
									});
							  };
					failure =
						'function' == typeof failure
							? failure
							: edi.document.actions.defaultFailureHandler(tab, 'document.error.in.delete.process');
					var deleteAction = function () {
						var postData = {};
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
						var stringified = Ext.encode(postData);
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: document.id
								},
								true
							),
							'PUT',
							stringified,
							success,
							failure
						);
					};
					if ('function' == typeof beforeDelete) {
						beforeDelete(deleteAction);
					} else {
						deleteAction();
					}
				});
			}
		});
	},
	copyUsingUI: function ({ documentHeader }) {
		let moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[documentHeader.type];
		if (documentHeader.versionId) {
			moduleName =
				edi.constants.CREATE_MODULE_NAME_BY_TYPE[[documentHeader.type, documentHeader.versionId].join('_')];
		}
		edi.core.openModule(moduleName, documentHeader, undefined, false, undefined, {
			isCopy: true,
			objectId: documentHeader.id + '_copy'
		});
	},
	/**
	 * create copy action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeCopy      Function that should be called before copy action
	 * @param    {Function}  success         Function that will be called on success copy of document
	 * @param    {Function}  failure         Function that will be called on failure copy of document
	 * @param    {String}    customLabel     custom text constant to display on button,
	 * @param    {Function}  copyAction      Function that will be called instead of standard copy action
	 */
	createCopyActionButton: function (document, tab, beforeCopy, success, failure, customLabel, copyAction) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.copy.document'),
			//OVERRIDE Ediweb begin
			glyph: copyAction?.methodAddOptions?.glyph ? copyAction.methodAddOptions.glyph : edi.constants.ICONS.COPY,
			//OVERRIDE Ediweb end
			handler: function () {
				//OVERRIDE Ediweb begin
				if (
					(!edi.constants.IS_CAN_CREATE_DOCUMENT || edi.constants.IS_EXPIRED_DOCUMENT_TARIFF) &&
					document.type !== edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY
				) {
					edi.methods.tariffs.isCanCreateDocHandler();
					return;
				}
				if (
					(!edi.constants.IS_CAN_CREATE_POA || edi.constants.IS_EXPIRED_POA_TARIFF) &&
					document.type === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY
				) {
					edi.methods.tariffs.isCanCreatePoaHandler();
					return;
				}
				//OVERRIDE Ediweb end
				if (
					edi.constants.MODULES &&
					edi.constants.MODULES.DOCUMENTS &&
					Array.isArray(edi.constants.MODULES.DOCUMENTS.COPY_USING_UI) &&
					edi.constants.MODULES.DOCUMENTS.COPY_USING_UI.some((it) => it === document.type)
				) {
					edi.document.actions.copyUsingUI({ documentHeader: document });
				} else {
					tab.setLoading();
					success =
						'function' == typeof success
							? success
							: function (data) {
									tab.setLoading(false);
									edi.events.documents.fireEvent('create');
									if (data && data.data && data.data.type) {
										//business validations warning
										const isWarning =
											edi.utils.getAttributeByName(data.data?.attributes, 'isWarning') === 'true';
										const isBlocked =
											edi.utils.getAttributeByName(data.data?.attributes, 'isBlocked') === 'true';
										if (isWarning || isBlocked) {
											edi.document.actions.showBusinessValidationsWarning(data.data, isBlocked);
										}
										edi.document.actions.showDocumentCreatedMessage(
											edi.document.actions.openDetailsModule(data.data.type, data.data),
											'document.copy.success'
										);
									}
							  };
					failure =
						'function' == typeof failure
							? failure
							: edi.document.actions.defaultFailureHandler(tab, 'document.copy.document.error');
					copyAction =
						'function' == typeof copyAction
							? copyAction
							: function () {
									edi.rest.sendRequest(
										edi.utils.formatString(
											edi.rest.services.DOCUMENTS.COPY.POST,
											{
												documentId: document.id
											},
											true
										),
										'POST',
										'',
										success,
										failure
									);
							  };
					if ('function' == typeof beforeCopy) {
						beforeCopy(copyAction);
					} else {
						copyAction();
					}
				}
			}
		});
	},
	/**
	 * create complete action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeComplete  Function that should be called before complete action
	 * @param    {Function}  success         Function that will be called on success complete the document
	 * @param    {Function}  failure         Function that will be called on failure complete the document
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createCompleteActionButton: function (document, tab, beforeComplete, success, failure, customLabel) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.complete.document'),
			glyph: edi.constants.ICONS.DONE,
			handler: function () {
				tab.setLoading();
				success =
					'function' == typeof success
						? success
						: function () {
								tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: document.id
								});
						  };
				failure =
					'function' == typeof failure
						? failure
						: edi.document.actions.defaultFailureHandler(tab, 'document.error.in.complete.process');
				var complete = function () {
					var postData = {};
					var stringified = Ext.encode(postData);
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: document.id
							},
							true
						),
						'PUT',
						stringified,
						success,
						failure
					);
				};
				if ('function' == typeof beforeComplete) {
					beforeComplete(complete);
				} else {
					complete();
				}
			}
		});
	},
	createRouteConfirmActionButton: function (document, tab, beforeAction, success, failure, label) {
		return edi.document.actions.createCompleteActionButton(
			document,
			tab,
			beforeAction,
			success,
			failure,
			label || 'document.confirm.document'
		);
	},
	/**
	 * create packaging action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeComplete  Function that should be called before complete action
	 * @param    {Function}  success         Function that will be called on success complete the document
	 * @param    {Function}  failure         Function that will be called on failure complete the document
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param    {Object}    docOptions      document options object
	 */
	createPackagingActionButton: function (document, tab, beforeComplete, success, failure, customLabel, docOptions) {
		var successMethod = function () {
			edi.events.documents.fireEvent('change', {
				id: document.id
			});
			'function' == typeof success ? success() : null;
		};
		var toOrgId = docOptions.record.get('toOrg') ? docOptions.record.get('toOrg').id : null;
		var menu = new Ext.menu.Menu({
			plain: true,
			hideMode: 'display',
			items: [
				!document.packageId && edi.permissions.hasPermission('LINK_PACKAGE_OBJECT')
					? {
							text: edi.i18n.getMessage('document.mark.packaging.add'),
							glyph: edi.constants.ICONS.LIBRARY_ADD,
							handler: function () {
								const { formItemsMap, items } = edi.filters.config.document_packages.createFormItems();
								var modal = createModalRemoteSelect(
									edi.rest.services.DOCUMENT_PACKAGES.GET,
									function (record) {
										edi.rest.sendRequest(
											edi.utils.formatString(
												edi.rest.services.DOCUMENT_PACKAGES.ADD_DOCUMENT.PUT,
												{
													packageId: record.id,
													docId: document.id
												}
											),
											'PUT',
											null,
											successMethod,
											edi.rest.getErrorHandler()
										);
									},
									{
										title: 'document.packages',
										width: MODAL_SIZE.widthLarge,
										model: 'DOCUMENT_PACKAGES',
										columns: 'document_packages',
										storeConfig: {
											autoLoad: true
										},
										proxyConfig: {
											extraParams: {
												toOrg: toOrgId
											}
										},
										createFilterFormItems: items,
										createFormItemsMap: formItemsMap,
										createArgs: edi.filters.config.document_packages.createArgs
									}
								);
								modal.show();
							}
					  }
					: undefined,
				!docOptions.record.get('packageId') && edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
					? {
							text: edi.i18n.getMessage('document.mark.packaging.create'),
							glyph: edi.constants.ICONS.ADD,
							handler: function () {
								edi.methods.document_packages.addDocuments(toOrgId, null, successMethod, document.id);
							}
					  }
					: undefined,
				docOptions.record.get('packageId') && edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT')
					? {
							text: edi.i18n.getMessage('document.mark.packaging.remove'),
							glyph: edi.constants.ICONS.REMOVE,
							handler: function () {
								edi.methods.document_packages.removeDocument(document.id, successMethod);
							}
					  }
					: undefined
			]
		});

		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.action.PACKAGING'),
			glyph: edi.constants.ICONS.LIBRARY_BOOKS,
			allowMultiClick: true,
			menu: menu
		});
	},

	/**
	 * create sign action button
	 * @param    {Object}     document          document data where to put signed data
	 * @param    {Object}     tab               module tab
	 * @param    {Function}   beforeSign        Method to call before sign saving instead of default, used only if beforeSign = true
	 * @param    {Function}   onSuccess         Method to call after successful sign saving
	 * @param    {Function}   onFailure         Method to call in case of error during sign process
	 * @param    {String}     label             text or text constant to display on button
	 * @param    {Object}     docOptions        document options object
	 * @param    {Object}     properties        Additional button properties
	 */
	createSignActionButton: function (document, tab, beforeSign, onSuccess, onFailure, label, docOptions, properties) {
		properties = Ext.clone('object' == typeof properties ? properties : {});
		var documentId = 'object' == typeof document && document.id ? document.id : undefined;
		var isSignPart2 =
			edi.constants.CREATE_PART2_BY_ACTION.some((it) => it === document.type) &&
			edi.constants.DIRECTIONS.INCOMING === docOptions.direction;
		if (!label && docOptions && !isSignPart2) {
			label =
				1 < docOptions.needSignatures
					? 'document.sign.and.complete.document' //OVERRIDE Ediweb
					: edi.constants.DIRECTIONS.INCOMING === docOptions.direction ||
					  edi.constants.DIRECTIONS.LOOP === docOptions.direction
					? 'document.sign.and.complete.document'
					: 'document.sign.and.send.document';
		}
		var buttonProps = 'object' == typeof properties.buttonProps ? properties.buttonProps : {};
		var signProperties = 'object' == typeof properties.signProperties ? properties.signProperties : {};
		onSuccess =
			'function' == typeof onSuccess
				? onSuccess
				: function () {
						tab.setLoading(false);
						edi.events.documents.fireEvent('sign', {
							id: documentId
						});
						if ('function' == typeof signProperties.dataChanged) {
							signProperties.dataChanged();
						} else {
							edi.events.documents.fireEvent('change', {
								id: documentId
							});
						}
				  };
		var endSignMethod = function (data, certificate, poa) {
			if (
				'object' == typeof document &&
				document.type &&
				document.state &&
				'object' == typeof edi.constants.PUSH_AFTER_SIGN_RULES &&
				edi.constants.PUSH_AFTER_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === document.type) &&
				edi.constants.PUSH_AFTER_SIGN_RULES.STATES.some((it) => it === document.state)
			) {
				beforeSign(onSuccess, onFailure);
			} else {
				onSuccess(data, certificate, poa);
			}
		};
		onFailure =
			'function' == typeof onFailure
				? onFailure
				: function (data, silent = false) {
						if ('function' == typeof properties.afterFailure) {
							properties.afterFailure();
						}
						if (!silent) {
							//OVERRIDE Ediweb begin
							var errorTitle = null,
								showToast = false;
							if (
								data?.typeError ===
								'controller.document.cannot.sign.already.signed.this.signature.error'
							) {
								errorTitle = 'ediweb.document.error.in.sign.process';
								showToast = true;
							}
							edi.document.actions.defaultFailureHandler(
								tab,
								'document.error.in.sign.process',
								null,
								errorTitle,
								showToast
							)(data);
							//OVERRIDE Ediweb end
						} else {
							tab?.setLoading(false);
						}

						if (properties.useBeforeAction && !silent) {
							if ('function' === typeof signProperties.dataChanged) {
								signProperties.dataChanged();
							} else {
								edi.events.documents.fireEvent('change', {
									id: documentId
								});
							}
						}
				  };

		if (!signProperties.signRefuse) {
			signProperties = Object.assign({}, signProperties, {
				signRefuse: () => tab?.setLoading(false)
			});
		}

		beforeSign =
			'function' == typeof beforeSign
				? beforeSign
				: function (success, failure) {
						var postData = {};
						var stringified = Ext.encode(postData);
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							stringified,
							function () {
								'function' == typeof success ? success() : null;
							},
							function (data) {
								'function' == typeof failure ? failure(data) : null;
							}
						);
				  };
		var buttonHandler = function (certificate, poa, poaConfirmChecked) {
			tab.setLoading();

			let certificateHandler = {
				_selectedCertificate: certificate,
				get: function () {
					return this._selectedCertificate;
				},
				set: function (cert) {
					this._selectedCertificate = cert;
				}
			};
			let poaHandler = {
				_selectedPoa: poa,
				get: function () {
					return this._selectedPoa;
				},
				set: function (poa) {
					this._selectedPoa = poa;
				},
				_poaConfirmChecked: poaConfirmChecked,
				setPoaConfirmCheck: function (poaConfirmChecked) {
					const handler = this;
					handler._poaConfirmChecked = poaConfirmChecked;
				},
				getPoaConfirmCheck: function () {
					const handler = this;
					return handler._poaConfirmChecked;
				}
			};

			edi.utils.sign(
				document,
				tab,
				function (failed, data, certificate, poa, silent) {
					if (failed) {
						onFailure(data, silent);
					} else {
						endSignMethod(data, certificate, poa);
					}
				},
				properties.contentId,
				properties.useBeforeAction ? beforeSign : undefined,
				true,
				certificateHandler,
				signProperties,
				poaHandler
			);
		};
		Ext.applyIf(buttonProps, {
			text: edi.i18n.getMessage(label ? label : 'document.sign.document'),
			glyph: edi.constants.ICONS.SIGN,
			handler: function () {
				if ('function' == typeof properties.beforeInit) {
					properties.beforeInit(buttonHandler);
				} else if (isSignPart2) {
					if (document.state === edi.constants.STATE.RECEIVER_REVIEW) {
						properties.useBeforeAction = false;
						buttonHandler();
					} else {
						var docData = {};
						docData[document.type] = [document];
						edi.methods.createDocumentPart2.start(
							docData,
							function (createdPart2, successDocumentIds, cert, poa) {
								if (createdPart2.length && createdPart2[0]) {
									properties.contentId = createdPart2[0].id;
									properties.useBeforeAction = true;
									signProperties.signRefuse = function () {
										edi.events.documents.fireEvent('change', {
											id: documentId
										});
									};
									buttonHandler(cert, poa);
								}
							}
						);
					}
				} else {
					buttonHandler();
				}
			}
		});
		return createActionsButton(buttonProps);
	},
	/**
	 * create export action button
	 * @param	{Object}	initData    module init data
	 * @param	{Object}	[options]
	 */
	createExportActionButton: function (initData, options) {
		let props = Ext.merge({}, options);

		let menu = [];
		if (props.addXmlExport) {
			var addXmlExportId = props.addXmlExportId ? props.addXmlExportId : initData.id;
			menu.push({
				text: props.xmlExportBtnLabel || edi.i18n.getMessage('action.export'),
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				handler() {
					let url = props.xmlExportBtnUrl
						? props.xmlExportBtnUrl
						: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.XML_DOCUMENT, {
								documentId: initData.id
						  });
					edi.rest.downloadFile(url, addXmlExportId);
				}
			});
		}

		if (!props.hideDefaultExport) {
			menu.push({
				text: props.exportBtnLabel || edi.i18n.getMessage('action.export'),
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				handler() {
					let url = props.exportBtnUrl ? props.exportBtnUrl : edi.document.actions.formatExportUrl(initData);
					edi.rest.downloadFile(url, initData.id);
				}
			});
		}

		if (props.addExtendedExport) {
			menu.push({
				text: props.extendedExportBtnLabel || edi.i18n.getMessage('action.export.extended'),
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				handler() {
					let url = props.extendedExportBtnUrl
						? props.extendedExportBtnUrl
						: edi.document.actions.formatExpendedExportUrl(initData);
					edi.rest.downloadFile(url, initData.id);
				}
			});
		}

		const isAllowOriginalExport =
			props.isAllowOriginalExport ??
			function (doc) {
				return edi.utils.getAttributeByName(doc.attributes, 'SOURCE') === 'INTEGRATION';
			};
		if (props.addOriginalExport && isAllowOriginalExport(initData.data)) {
			menu.push({
				text: props.originalExportBtnLabel ?? edi.i18n.getMessage('action.export.original'),
				glyph: props.originalExportBtnGlyph ?? edi.constants.ICONS.FILE_DOWNLOAD,
				handler() {
					let url =
						props.originalExportBtnUrl ??
						edi.document.actions.formatExportUrl(
							initData,
							undefined,
							edi.rest.services.DOCUMENTS.EXPORT.ORIGINAL
						);
					edi.rest.downloadFile(url, initData.id);
				}
			});
		}

		if (props.addTransitExport) {
			menu.push({
				text: props.transitExportBtnLabel ?? edi.i18n.getMessage('action.export.transit'),
				glyph: props.transitExportBtnGlyph ?? edi.constants.ICONS.FILE_DOWNLOAD,
				handler() {
					edi.document.actions.exportDocumentTransit(initData.id, props.transitExportBtnUrl);
				}
			});
		}

		if (props.menu && props.menu.length) {
			props.menu.forEach(function (item) {
				menu.push(item);
			});
		}

		let actionBtn = null;
		if (menu.length > 1) {
			actionBtn = createActionsButton({
				text: props.label ? props.label : edi.i18n.getMessage('action.export'),
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				allowMultiClick: true,
				menu: menu
			});
		} else if (menu.length === 1) {
			actionBtn = createActionsButton(menu[0]);
		}

		return actionBtn;
	},
	/**
	 * Tries to export "transit" document if document has integration channels
	 * @param	{Number|String}	docId
	 * @param	{String}	[customUrl]
	 */
	exportDocumentTransit: function (docId, customUrl) {
		if (!docId) {
			return;
		}

		const fail = edi.rest.getErrorHandler('error.export.transit.no_channels');

		const success = function (resp) {
			if (Array.isArray(resp?.items) && resp.items.length) {
				let downloadUrl =
					customUrl ??
					edi.document.actions.formatExportUrl(
						{ id: docId },
						undefined,
						edi.rest.services.DOCUMENTS.EXPORT.TRANSIT
					);
				edi.rest.downloadFile(downloadUrl, docId);
			} else {
				fail();
			}
		};

		const url = edi.utils.formatString(edi.rest.services.INTEGRATION_CHANNELS.GET_BY_DOC_ID, { docId });
		edi.rest.sendRequest(url, 'GET', null, success, fail);
	},
	/**
	 * Export reports group
	 * @param    {Array}    documentIds
	 */
	exportReportsGroup: function (documentIds) {
		var id,
			timeout,
			timer,
			modalLabel,
			modalCloseLabel,
			createButtonCmp,
			formatSelector,
			format = edi.constants.REPORT_FORMATS.PDF,
			isClosed = false,
			tickTime = 1000,
			stateReloadTime = 3000;

		//show form with format selection
		var modal = createModalPanel({
			title: edi.i18n.getMessage('report.export.title'),
			listeners: {
				close: function () {
					timer ? window.clearInterval(timer) : null;
					timeout ? window.clearTimeout(timeout) : null;
					isClosed = true;
				}
			},
			items: [
				createModalForm({
					items: [
						(modalLabel = createLabel({
							text: edi.i18n.getMessage('report.export.text'),
							cls: 'block-label'
						})),
						(modalCloseLabel = createLabel({
							text: edi.i18n.getMessage('report.export.text.close.group'),
							cls: 'block-label',
							hidden: true
						})),
						(formatSelector = createCombo({
							fieldLabel: edi.i18n.getMessage('column.format'),
							margin: '16 0 0 0',
							store: edi.stores.createMemoryStore(
								[
									{
										id: edi.constants.REPORT_FORMATS.PDF,
										name: edi.constants.REPORT_FORMATS.PDF
									},
									{
										id: edi.constants.REPORT_FORMATS.XLS,
										name: edi.constants.REPORT_FORMATS.XLS
									}
								],
								'SIMPLE'
							),
							value: format,
							forceSelection: true,
							allowBlank: false,
							listeners: {
								change: function (comp, value) {
									format = value;
								}
							}
						}))
					]
				})
			],
			buttonsBefore: [
				(createButtonCmp = createCreateButton(
					function () {
						createButtonCmp.hide();
						formatSelector.hide();
						modalCloseLabel.show();
						var seconds = 0,
							tick = function () {
								modalLabel.setText(edi.i18n.getMessage('report.export.timer', [seconds]));
								seconds++;
							},
							errorHandler = edi.rest.getErrorHandler('report.export.error'),
							errorProcessor = function (data) {
								errorHandler(data);
								modal.close();
							},
							downloadReport = function () {
								modal.close();
								var url = edi.utils.formatString(edi.rest.services.REPORT_REQUEST_GROUP.EXPORT.GET, {
									id: id
								});
								edi.rest.downloadFile(url, id);
							},
							askForDownloadWithError = function () {
								edi.core.confirm(
									undefined,
									'report.export.question.download.report.with.errors',
									function () {
										downloadReport();
									},
									function () {
										modal.close();
									},
									undefined,
									function () {
										modal.close();
									}
								);
							},
							reportCheckTimeout = function () {
								if (timeout) {
									window.clearTimeout(timeout);
								}
								timeout = window.setTimeout(checkReport, stateReloadTime);
							};
						tick();
						timer = window.setInterval(tick, tickTime);

						//check report status
						var checkReport = function () {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.REPORT_REQUEST_GROUP.GET, {
									id: id
								}),
								'GET',
								undefined,
								function (responseData) {
									if (responseData && responseData.data) {
										var state = responseData.data.status;
										if (!isClosed) {
											if (edi.constants.REPORT_STATE.COMPLETED === state) {
												//when status is correct - download the report
												downloadReport();
											} else if (edi.constants.REPORT_STATE.ORDERED === state) {
												reportCheckTimeout();
											} else if (edi.constants.REPORT_STATE.WAIT_DECISION === state) {
												var push = function (repeat) {
													var dataToSend = repeat
														? {
																REPEAT: true
														  }
														: {};
													edi.rest.sendRequest(
														edi.utils.formatString(
															edi.rest.services.REPORT_REQUEST_GROUP.PUT,
															{
																reportRequestGroupId: id
															}
														),
														'PUT',
														Ext.encode(dataToSend),
														function () {
															if (!repeat) {
																askForDownloadWithError();
															} else {
																reportCheckTimeout();
															}
														},
														errorProcessor
													);
												};
												edi.core.confirm(
													undefined,
													'report.export.wait.question',
													function () {
														push(true);
													},
													function () {
														push();
													},
													undefined,
													function () {
														push();
													}
												);
											} else if (edi.constants.REPORT_STATE.GENERATING_ERROR === state) {
												askForDownloadWithError();
											}
										}
									} else {
										errorProcessor(responseData);
									}
								},
								errorProcessor
							);
						};
						//send POST request to generate report
						edi.rest.sendRequest(
							edi.rest.services.REPORT_REQUEST_GROUP.DOC_LIST.POST,
							'POST',
							Ext.encode({
								ediReportRequestGroup: {
									format: format
								},
								docIds: documentIds
							}),
							function (responseData) {
								id = responseData.data.id;
							},
							errorProcessor,
							function () {
								if (id && !isClosed) {
									checkReport();
								} else if (!isClosed) {
									modal.close();
								}
							}
						);
					},
					{
						scale: 'small'
					}
				))
			]
		});
		modal.show();
	},
	/**
	 * create export pallet sheet documents button
	 * @param    {Object}     documents
	 */
	createExportPalletSheetDocumentsButton: function (documents) {
		return {
			text: edi.i18n.getMessage('action.print'),
			glyph: edi.constants.ICONS.PRINT,
			xtype: 'button',
			handler: function () {
				var data = [];
				for (var i = 0; i < documents.length; i++) {
					data.push(documents[i].id);
				}
				edi.document.actions.exportReportsGroup(data);
			}
		};
	},
	/**
	 * create export attachment action button
	 * @param    {Object}     initData        module init data
	 * @param    {String}     attachmentId
	 * @param    {String}     docId
	 */
	createExportAttachmentActionButton: function (initData, attachmentId, docId) {
		return createActionsButton({
			text: edi.i18n.getMessage('action.export.attachment'),
			glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
			handler: function () {
				var url = edi.document.actions.formatExportAttachementUrl(initData, attachmentId, docId);
				edi.rest.downloadFile(url, attachmentId);
			}
		});
	},
	/**
	 * Prepares URL to get print templates for document/object
	 * @param	{Object}	initDocumentData
	 * @param	{Number}	 docId
	 * @param	{String}	format
	 * @param	{Object}	[config]
	 * @returns	{string}	compiled url
	 */
	compileGetPrintTemplateUrl: function (initDocumentData, docId, format, config) {
		let conf = Ext.merge({}, config);

		let url = edi.document.actions.formatPrintTemplatesUrl(
			initDocumentData,
			conf.showCRPTPrintBtn ? docId : undefined,
			format
		);

		let queryParams = {};

		//Под клиентом при формировании ПФ должны отображаться только шаблоны,
		//в которых указан язык выбранный в профиле текущего пользователя
		const userLanguage = edi.core.getUserData()?.language;
		if (userLanguage) {
			queryParams.templateLanguage = userLanguage;
		}

		if (conf.asObject === true) {
			queryParams.objectType = conf.objectType || initDocumentData.data?.type || 'UNKNOWN';
		}
		if (conf.additionalGetTemplateQueryParams) {
			Ext.merge(queryParams, conf.additionalGetTemplateQueryParams);
		}
		url = edi.utils.compileURL(url, queryParams);

		return url;
	},
	/**
	 * create print modal for print button
	 */
	createPrintModal: function (initDocumentData, config, printDocId) {
		var modal,
			messageInterval,
			counter = 0,
			dataStore;
		let reportCheckModal, requestID, documentID, templateID;
		let conf = Ext.merge({}, config);
		const showReportCheckMessage = function () {
			reportCheckModal = edi.core.showInfo(
				edi.utils.formatString(edi.i18n.getMessage('print.report.requested'), [counter++]),
				function () {
					clearTimers(true);
				}
			);
		};
		const updateReportCheckMessage = function () {
			reportCheckModal?.updateMsgText(
				edi.utils.formatString(edi.i18n.getMessage('print.report.requested'), [counter++])
			);
		};
		const clearTimers = function (skipReportCheckMessageClose) {
			messageInterval ? clearInterval(messageInterval) : null;
			if (!skipReportCheckMessageClose) {
				reportCheckModal.close();
			}
			modal.close();
		};

		const onPrintFormComplete = function (msgData) {
			const { meta, content } = msgData?.data || {};
			const reportId = meta?.mainObjId;
			if (requestID === reportId || content.Error) {
				clearTimers();
				realtimeEvents.un('printFormComplete', onPrintFormComplete);
			}
			if (content?.Success) {
				downloadReport(templateID, null, requestID, documentID);
			} else {
				edi.core.showError(edi.i18n.getMessage('print.report.failed'));
			}
		};
		var createReport = function (templateId, store, format, docId) {
			counter = 0;
			dataStore = store;
			modal.setLoading();
			var failure = edi.document.actions.defaultFailureHandler(modal, 'print.report.request.error');
			let payload;
			if (conf.customPayload) {
				payload = conf.customPayload;
			} else {
				payload = {
					async: true,
					reportSourceDoc: docId,
					jasperTemplate: templateId,
					format: format
				};
				if (conf.asObject === true) {
					payload.object = true;
				}
				if (conf.additionalPayload) {
					Ext.merge(payload, conf.additionalPayload);
				}
				payload = Ext.encode(payload);
			}

			let url = conf.customCreateReportUrl
				? edi.utils.formatString(conf.customCreateReportUrl, conf.customCreateReportUrlParams || {})
				: edi.document.actions.formatPrintReportUrl(initDocumentData);

			realtimeEvents.on('printFormComplete', onPrintFormComplete);

			edi.rest.sendRequest(
				url,
				'POST',
				payload,
				function (response) {
					const responseData = response.data;
					requestID = responseData.id;
					templateID = responseData.jasperTemplate.id;
					documentID = responseData.reportSourceDoc;
					showReportCheckMessage();
					messageInterval = setInterval(updateReportCheckMessage, edi.constants.PRINT_STATUS_CHECK_DELAY);
				},
				failure
			);
		};
		var downloadReport = function (templateId, format, reportId, docId) {
			if (reportId) {
				edi.rest.downloadFile(edi.document.actions.formatDownloadReportUrl(initDocumentData, reportId), docId);
			}
		};

		function createPrintFormatStore(initDocumentData, conf) {
			let formats = conf.reportFormats || edi.constants.DEFAULT_PRINT_FORMATS;
			let docType = initDocumentData.type || initDocumentData.data?.type || 'UNKNOWN';
			if (edi.constants.EXLUDE_XLS_PRINT_FORMATS_DOC_TYPES.some((it) => it === docType)) {
				formats = edi.constants.EXCLUDE_XLS_PRINT_FORMATS;
			}
			return edi.stores.createSimpleInlineStore(formats, function (id) {
				return id;
			});
		}

		if (conf.usingReport && printDocId) {
			var columns = edi.columns.get('templates_with_status');
			columns.push(
				createActionsColumnConfig({
					align: 'center',
					width: edi.utils.getActionColumnWidth(3),
					items: [
						{
							glyph: edi.constants.ICONS.FILE_DOWNLOAD,
							testCls: 'test-action-column-download',
							tooltip: edi.i18n.getMessage('action.download'),
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex);
								downloadReport(
									record.get('templateId'),
									record.get('format'),
									record.get('requestId'),
									printDocId
								);
								modal.close();
							},
							isHidden: function (view, rowIndex, colIndex, item, record) {
								return edi.constants.PRINT_STATUS.READY !== record.get('reportStatus');
							}
						},
						{
							glyph: edi.constants.ICONS.PRINT,
							testCls: 'test-action-column-print',
							tooltip: edi.i18n.getMessage('action.print'),
							handler: function (grid, rowIndex) {
								if (edi.realtime.messages.isConnected()) {
									const store = grid.getStore();
									const record = store.getAt(rowIndex);
									createReport(record.get('templateId'), store, record.get('format'), printDocId);
								} else {
									edi.realtime.messages.showConnectionError('template.formation.websocket.error');
								}
							},
							isHidden: function (view, rowIndex, colIndex, item, record) {
								return edi.constants.PRINT_STATUS.NONE !== record.get('reportStatus');
							}
						},
						{
							glyph: edi.constants.ICONS.REMOVE,
							testCls: 'test-action-column-remove',
							tooltip: edi.i18n.getMessage('form.btn.reset'),
							handler: function (grid, rowIndex) {
								modal.setLoading();
								var store = grid.getStore();
								var record = store.getAt(rowIndex);

								let url = edi.utils.formatString(edi.rest.services.REPORT.RESULT.DELETE_ONE, {
									id: record.get('requestId')
								});
								let queryParams = {};
								if (conf.asObject === true) {
									queryParams.objectType =
										conf.objectType || initDocumentData.data?.type || 'UNKNOWN';
								}
								url = edi.utils.compileURL(url, queryParams);

								edi.rest.sendRequest(
									url,
									'DELETE',
									null,
									function () {
										store.reload();
									},
									null,
									function () {
										modal.setLoading(false);
									}
								);
							},
							isHidden: function (view, rowIndex, colIndex, item, record) {
								return edi.constants.PRINT_STATUS.READY !== record.get('reportStatus');
							},
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return !edi.permissions.hasPermission('UPDATE_REPORTS_SERVICES');
							}
						}
					]
				})
			);
			var format = conf.defaultFormat || edi.constants.REPORT_FORMATS.PDF;
			var selectReportFormatTypeCombo = createCombo({
				fieldLabel: edi.i18n.getMessage('column.format'),
				width: 200,
				margin: '0 5 0 0',
				store: createPrintFormatStore(initDocumentData, conf),
				value: format,
				forceSelection: true,
				//OVERRIDE Ediweb   allowBlank: false,
				listeners: {
					change: function (comp, value) {
						var store = modal.down('grid').getStore();
						value ? (format = value) : null;
						let url = edi.document.actions.compileGetPrintTemplateUrl(
							initDocumentData,
							printDocId,
							format,
							conf
						);
						store.getProxy().url = url;
						store.load();
						//OVERRIDE Ediweb begin
						if (!value) {
							comp.setValue(format);
						}
						//OVERRIDE Ediweb end
					}
				}
			});

			let url = edi.document.actions.compileGetPrintTemplateUrl(initDocumentData, printDocId, format, conf);
			modal = createModalRemoteSelect(url, undefined, {
				model: 'TEMPLATE_WITH_STATUS',
				createFilterFormItems: [],
				title: 'template.selection.title',
				noFilter: true,
				disablePaging: true,
				storeConfig: {
					sorters: undefined,
					autoLoad: true
				},
				proxyConfig: {
					pageParam: undefined,
					limitParam: undefined,
					startParam: undefined
				},
				gridConfig: {
					columns: columns,
					disableSelection: true
				},
				hideSaveButton: true,
				additionalControls: [
					selectReportFormatTypeCombo,
					createButton({
						cls: BUTTON_CLS.light,
						text: edi.i18n.getMessage('form.btn.reload'),
						glyph: edi.constants.ICONS.REFRESH,
						handler: function () {
							modal.down('grid').getStore().load();
						}
					})
				]
			});
		} else {
			window.print();
		}
	},
	/**
	 * create print action button
	 */
	createPrintActionButton: function (document, tab, beforeSign, onSuccess, onFailure, label, docOptions, config) {
		config = config ? config : {};
		var moduleInitData = config.moduleData && config.moduleData.initData ? config.moduleData.initData : null;

		var button;
		var isAperak =
			document && 'object' == typeof document
				? edi.utils.getAttributeByName(document.attributes, 'APERAK_CODE')
				: null;
		if (isAperak) {
			var lastAperak, aperakPrintMenuItem;
			var mainFailure = function (data) {
				aperakPrintMenuItem.hide();
				edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
					documentId: document.id,
					depth: edi.constants.DEFAULT.TREE_DEPTH
				}),
				'GET',
				{},
				function (responseData) {
					var linkedDocs =
						responseData && Array.isArray(responseData.items) && responseData.items.length
							? responseData.items
							: null;
					if (linkedDocs) {
						for (var i = 0; i < linkedDocs.length; i++) {
							if (
								linkedDocs[i].type === edi.constants.DOCUMENT_TYPES.LEGACY_APERAK &&
								(!lastAperak || linkedDocs[i].modifyDate > lastAperak.modifyDate)
							) {
								lastAperak = linkedDocs[i];
							}
						}
					} else if (!edi.constants.DOC_APERAK_WITHOUT_CHILD_CHECK.some((it) => it === document.type)) {
						mainFailure();
					}
					if (!lastAperak || !lastAperak.id) {
						aperakPrintMenuItem.hide();
					}
				},
				mainFailure
			);
			button = createActionsButton({
				text: edi.i18n.getMessage('action.print'),
				glyph: edi.constants.ICONS.PRINT,
				allowMultiClick: true,
				menu: new Ext.menu.Menu({
					plain: true,
					hideMode: 'display',
					items: [
						{
							text: edi.i18n.getMessage('action.print.document'),
							handler: function () {
								edi.document.actions.createPrintModal(moduleInitData, config, config.data.id);
							}
						},
						(aperakPrintMenuItem = Ext.create('Ext.menu.Item', {
							text: edi.i18n.getMessage('action.print.aperak'),
							handler: function () {
								if (lastAperak) {
									edi.document.actions.createPrintModal(lastAperak, config, lastAperak.id);
								} else {
									mainFailure();
								}
							}
						}))
					]
				})
			});
		} else {
			button = createActionsButton({
				text: edi.i18n.getMessage('action.print'),
				glyph: edi.constants.ICONS.PRINT,
				handler: function () {
					edi.document.actions.createPrintModal(moduleInitData, config, config.data.id);
				}
			});
		}
		return button;
	},

	createQuickAnswerConfirmActionButton: function (props) {
		return createActionsButton({
			text: edi.i18n.getMessage('quick.answer.apply.action.confirm'),
			glyph: edi.constants.ICONS.CHECK_CIRCLE,
			handler: function (btn, event) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.QUICK_ANSWER.CONFIRM.PUT, {
						documentId: props.id
					}),
					'PUT',
					null,
					function (response) {
						if (response.data) {
							edi.document.actions.openDetailsModule(response.data.type, response.data);
						}
					},
					function (data) {
						if ('640' === data.status) {
							edi.document.actions.defaultFailureHandler(undefined, data.typeError)(data);
						}
					},
					null
				);
			}
		});
	},

	createQuickAnswerRejectActionButton: function (props) {
		return createActionsButton({
			text: edi.i18n.getMessage('quick.answer.apply.action.reject'),
			glyph: edi.constants.ICONS.CANCEL,
			handler: function (btn, event) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.QUICK_ANSWER.REJECT.PUT, {
						documentId: props.id
					}),
					'PUT',
					null,
					function (response) {
						if (response.data) {
							edi.document.actions.openDetailsModule(response.data.type, response.data);
						}
					},
					function (data) {
						if ('640' === data.status) {
							edi.document.actions.defaultFailureHandler(undefined, data.typeError)(data);
						}
					},
					null
				);
			}
		});
	},

	/**
	 * Возвращает объект с исключенными действиями с документом (мержатся конфиги и глобальные костыли)
	 * @param	{Object}	config
	 * @param	{Object}	docHeader
	 * @return	{Object}	object with excluded actions
	 */
	getExcludedActions: function (config, docHeader) {
		const excludeActions = Object.assign({}, config.excludeActions);
		if (
			docHeader.state === edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX ||
			docHeader.state === edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.ROUTE_REJECT] = true;
			excludeActions[edi.constants.DOCUMENT_ACTIONS.ROUTE_CONFIRM] = true;
		}
		if (config.moduleData.initData.isDocumentSharing) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		}
		return excludeActions;
	},

	/**
	 * Создает кнопку действия с документом по имени экшена
	 * конвертирует имя экшена из "СЛОВО1_СЛОВО2" в "Слово1Слово2"
	 * и вызывает (если существует) edi.document.actions[`create${actionName}ActionButton`]
	 * @param	{string}	name
	 * @param	{Object}	props
	 * @param	{Object}	moduleData
	 * @param	{Object}	docOptions
	 * @param	{Object}	docHeader
	 * @return	{Object|null}	instance of UI.components.Button
	 */
	createActionButtonByActionName: function (name, props, moduleData, docOptions, docHeader) {
		const actionName = name
			.split('_')
			.map((word) => {
				const w = word.toLowerCase();
				return w[0].toUpperCase() + w.slice(1);
			})
			.join('');
		const methodName = `create${actionName}ActionButton`;
		const methodParams = [
			docHeader,
			moduleData.tab,
			props.beforeAction,
			props.success,
			props.failure,
			props.label,
			docOptions
		];
		if (props.methodAddOptions && 'object' === typeof props.methodAddOptions) {
			methodParams.push(props.methodAddOptions);
		}
		return typeof edi.document.actions[methodName] === 'function'
			? edi.document.actions[methodName].apply(edi.document.actions, methodParams)
			: null;
	},

	/**
	 * Сортирует и группирует действия с документами на 2 массива - "основные" и "другие" действия
	 * при этом собирая действия во вложенные меню
	 * @param	{Object[]}	items
	 * @param	{Object}	config
	 * @return {{mainActions: Object[], otherActions: Object[]}}
	 */
	groupAndSortActions: function (items, config) {
		const orderedActions = [];
		const unorderedActions = [];
		items
			.filter((it) => !!it)
			.forEach((it) => {
				(!!it.order ? orderedActions : unorderedActions).push(it);
			});
		orderedActions.sort((a, b) => b.order - a.order);

		let groupedItems = orderedActions.concat(unorderedActions);
		if (groupedItems.some((item) => item.hasOwnProperty('menuId'))) {
			let subItemsArr = groupedItems.filter((item) => {
				return item.hasOwnProperty('menuId') && item.type !== 'menu';
			});

			groupedItems = groupedItems.filter((item) => {
				return !item.hasOwnProperty('menuId') || (subItemsArr.length ? item.type === 'menu' : null);
			});

			groupedItems = groupedItems.map((item) => {
				if (item.type === 'menu') {
					if (subItemsArr.length > 1) {
						subItemsArr = subItemsArr.map((item) => {
							if (!!item) {
								item.glyph = undefined;
								item.textAlign = 'left';
							}
							return item;
						});
						item.menu = new Ext.menu.Menu({
							items: subItemsArr
						});
					} else if (subItemsArr.length === 1) {
						item.handler = subItemsArr[0].handler;
						item.on('afterrender', function (comp) {
							comp.btnWrap.el.removeCls('x-btn-arrow');
							comp.el.removeCls('edi-menu-action');
						});
					} else {
						item = null;
					}
				}
				return item;
			});
		}

		const mainActions = [];
		const otherActions = [];
		const mainActionsMaxCount = config.mainActionsMaxCount ?? edi.constants.DEFAULTS_FIRST_HALF_ACTIONS_QUANTITY;
		groupedItems.forEach((item) => {
			if (item) {
				const showInMain =
					(item.showInFirstHalf || edi.constants.FIRST_HALF_BUTTONS_ACTIONS.includes(item.actionName)) &&
					mainActions.length < mainActionsMaxCount;
				(showInMain ? mainActions : otherActions).push(item);
			}
		});

		return { mainActions, otherActions };
	},

	/**
	 * Создает компонент с информацией о документе (название и номер) в панеле действий с документами
	 * @param	{Object}	docHeader
	 * @param	{Object}	config
	 * @return	{Object}	instance of Ext.Component
	 */
	createActionsPanelInfoComponent: function (docHeader, config) {
		return Ext.create('Ext.Component', {
			cls: 'edi-info-top-container',
			flex: 2,
			tpl:
				config.infoTpl ??
				Ext.create(
					'Ext.XTemplate',
					'<div class="edi-detail-info-doctype" data-qtip="{[this.getDocType(values)]}">{[this.getDocType(values)]}</div>',
					'<div class="edi-detail-info-linebreak"></div> ',
					'<tpl if="this.getDocNumber(values)">',
					'<div class="edi-detail-info-number" data-qtip="{[this.getDocNumber(values)]}">{[this.getDocNumber(values)]}</div>',
					'</tpl>',
					{
						getDocType: function (values) {
							if (!!config?.infoTitle) {
								return config.infoTitle;
							}
							const bpName = edi.utils.getAttributeByName(values.attributes, 'businessProcess');
							const versionId = values.versionFormat ?? '';
							let title = edi.i18n.getMessage('documents.doctype.' + values.type);
							if (versionId && bpName) {
								title += ` (${versionId} ${bpName})`;
							}
							return title;
						},
						getDocNumber: function (values) {
							if (config.hasOwnProperty('infoSubTitle')) {
								return config.infoSubTitle;
							}
							const num = String(config?.infoNumber ?? values.number ?? '');
							return !!num ? '№' + num : '';
						}
					}
				),
			data: docHeader
		});
	},

	/**
	 * Создает копку "Другие действия" с меню из этих действий
	 * @param	{Object[]}	items
	 * @param	{Object}	config
	 * @return	{Object}	instance of UI.components.Button
	 */
	createOtherActionsButton: function (items, config) {
		//Подстроим кнопки под стиль элементов меню
		//хоть это и другой компонент, но выглядеть и элемент меню и кнопка в качестве строки меню должны одинаково
		const setMenuItemsCfg = function (item) {
			if (!!item) {
				item.addCls(BUTTON_CLS.menuItem);
				item.glyph = null;
				item.hideTrigger = true;
				item.minWidth = undefined;
				if (item.menu) {
					item.menu.plain = true;
				}

				const subMenuItems = item.menu?.items?.items || item.menu?.items;
				if (Array.isArray(subMenuItems)) {
					subMenuItems.forEach(setMenuItemsCfg);
				}
			}
		};

		items.forEach(setMenuItemsCfg);
		return createActionsButton({
			text: edi.i18n.getMessage('document.action.OTHER_ACTIONS'),
			menu: items
		});
	},

	/**
	 * Creates details action buttons and adds them into panel
	 * @param    {Object}    panel    action panel component
	 * @param    {Object}    config   configuration objects for action buttons
	 */
	createDocumentActionButtons: function (panel, config) {
		const docHeader = config?.data;
		if (!docHeader?.id || !panel) {
			return;
		}

		const moduleData = config.moduleData;
		const optionConfig = {
			direction: config.direction,
			hasPart2: config.hasPart2,
			needSignatures: config.needSignatures !== undefined ? parseInt(config.needSignatures, 10) : undefined
		};
		if (!!config.docType) {
			optionConfig['docType'] = config.docType;
		}
		const docOptions = edi.action.getDocumentData(
			edi.models.createInstance('DOCUMENT', docHeader),
			moduleData.initData.data,
			optionConfig
		);

		const excludeActions = edi.document.actions.getExcludedActions(config, docHeader);
		const actionProps = Ext.merge({}, edi.document.actions.buttonsConfig, config.actionProps);
		const items = [];
		//Идём по списку действий с документами (т.к. для них существуют константы, конструкторы и проверки доступности)
		//проверяем доступность и создаем инстансы кнопок
		const positions = Object.keys(edi.document.actions.buttonsConfig);
		positions.forEach((actionName) => {
			const props = actionProps[actionName] || {};
			if ('object' === typeof props.methodAddOptions && props.methodAddOptions) {
				docOptions.methodAddOptions = props.methodAddOptions;
			}
			if (actionName === edi.constants.DOCUMENT_ACTIONS.CUSTOM_BUTTONS) {
				(props.buttons || []).forEach((customBtn) => {
					items.push(customBtn);
				});
			} else {
				const available = props.hasOwnProperty('isAvailable')
					? 'function' === typeof props.isAvailable
						? props.isAvailable(docOptions)
						: props.isAvailable
					: edi.action.isAvailable(actionName, docOptions);

				if (available && !excludeActions[actionName]) {
					props.success = props.success || config.defaultSuccess;

					const comp =
						props.component ||
						('function' === typeof props.createButtonMethod
							? props.createButtonMethod(props, moduleData, docOptions, docHeader)
							: edi.document.actions.createActionButtonByActionName(
									actionName,
									props,
									moduleData,
									docOptions,
									docHeader
							  ));
					if (comp) {
						comp.actionName = actionName;
						if (props.order !== false) {
							// Если order не указан установим 1000 для отображения левее
							comp.order = props.order || 1000;
						}
						if (props.hasOwnProperty('showInFirstHalf')) {
							comp.showInFirstHalf = props.showInFirstHalf;
						}
						if (props.hasOwnProperty('menuId')) {
							comp.menuId = props.menuId;
						}
					}
					items.push(comp);
				}
			}
		});

		const { mainActions, otherActions } =
			typeof config.groupAndSortActions === 'function'
				? config.groupAndSortActions(items, config)
				: edi.document.actions.groupAndSortActions(items, config);

		panel.layout = 'hbox';
		if (config.hideInfoLabel !== true) {
			panel.add(edi.document.actions.createActionsPanelInfoComponent(docHeader, config));
		}
		if (Array.isArray(mainActions) && mainActions.length > 0) {
			panel.add(mainActions);
		}
		if (Array.isArray(otherActions) && otherActions.length > 0) {
			const otherBtnMenu = edi.document.actions.createOtherActionsButton(otherActions, config);
			panel.add(otherBtnMenu);
		}
	},

	/**
	 * Loads contract for passed values
	 * @param    {String}    docType               type of document to use
	 * @param    {String}    partnerOrg            partners organization
	 * @param    {String}    id                    id of contract to select
	 * @param    {Function}  callback              callback that will be called after request finish
	 * @param    {Function}  contractSetCallback   callback that will be called to set contract
	 * @param    {Boolean}   getAll                true to get all contracts
	 */
	loadContractsData: function (docType, partnerOrg, id, callback, contractSetCallback, getAll) {
		if (docType && partnerOrg && partnerOrg.id) {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.CONTRACTS.AVAILABLE.GET, {
					documentType: docType,
					partnerOrgId: partnerOrg.id
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items && 'undefined' != typeof data.items.length) {
						if (!getAll) {
							for (var i = 0; i < data.items.length; i++) {
								if ('undefined' == typeof id || data.items[i].id == id) {
									'function' == typeof contractSetCallback
										? contractSetCallback(data.items[i])
										: null;
									break;
								}
							}
						} else {
							'function' == typeof contractSetCallback ? contractSetCallback(data.items, id) : null;
						}
					}
				},
				function (data) {
					if (data && data.status == edi.constants.STATUS.DATA_NOT_FOUND) {
						edi.document.actions.defaultFailureHandler(undefined, 'error.getting.contracts.data')(data);
					}
				},
				callback
			);
		} else {
			'function' == typeof callback ? callback() : null;
		}
	},

	/**
	 * Loads contract details by id
	 * @param   {String}    id                    id of contract
	 * @param   {Function}  callback              callback that will be called after request
	 * @param   {Function}  contractSetCallback   callback that will be called to set contract
	 * @param   {Boolean}   supressError          true to hide error
	 * @param   {String}   url                    to use instead of default url
	 */
	loadContract: function (id, callback, contractSetCallback, supressError, url) {
		if (id) {
			edi.rest.sendRequest(
				edi.utils.formatString(url || edi.rest.services.CONTRACTS.GET, {
					id: id
				}),
				'GET',
				null,
				function (data) {
					if (data && data.data) {
						'function' == typeof contractSetCallback ? contractSetCallback(data.data) : null;
					}
				},
				function (data) {
					if (!supressError && data && data.status == edi.constants.STATUS.DATA_NOT_FOUND) {
						edi.document.actions.defaultFailureHandler(undefined, 'error.getting.contracts.data')(data);
					}
				},
				callback
			);
		} else {
			'function' == typeof callback ? callback() : null;
		}
	},

	/**
	 * Formats uri for getting document details info
	 * @param    {Object}    initData    object with module init data
	 * @param    {String}    docId       docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @returns  {string}
	 */
	formatHeaderUri: function (initData, docId) {
		var uri;
		if (initData && initData.docHeaderUri) {
			uri = edi.utils.formatString(
				initData.docHeaderUri,
				{
					documentId: docId || initData.id,
					containerDocId: initData.containerDocId
				},
				true
			);
		} else {
			uri = edi.utils.formatString(
				edi.rest.services.DOCUMENTS.HEADER.GET,
				{
					documentId: docId || initData.id
				},
				true
			);
		}
		return uri;
	},

	/**
	 * Details module data change handler
	 * @param    {Object}      data               data object from change event
	 * @param    {Object}      moduleData         Current module data
	 * @param    {Function}    [dataSetFn]          Function that will set reloaded module data
	 * @param    {Function}    [success]            Function that will be called on success of data reloading
	 * @param    {Boolean}     [skipMaskRemoval]    true to not remove loading mask
	 * @param    {Object}      [options]            object with additional parameters
	 */
	changeHandler: function (data, moduleData, dataSetFn, success, skipMaskRemoval, options) {
		options = options ? options : {};
		if (
			(data && data.id && data.id == moduleData.initData.data.id) ||
			(options.id && options.id == moduleData.initData.data.id)
		) {
			var defaultOptions = {
				url: edi.document.actions.formatHeaderUri(moduleData.initData),
				idParam: 'documentId',
				parentParam: 'parentId',
				id: data.id,
				parentId: data.parentId
			};
			Ext.applyIf(options, defaultOptions);
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.setLoading();
				var failure = edi.document.actions.defaultFailureHandler(undefined, 'error.getting.data', function () {
					edi.modulesHandler.removeModule(moduleData);
				});
				var replaceObj = {};
				replaceObj[options.idParam] = options.id;
				replaceObj[options.parentParam] = options.parentId;
				edi.rest.sendRequest(
					edi.utils.formatString(options.url, replaceObj, true),
					'GET',
					undefined,
					function (headerData) {
						if (headerData && headerData.data) {
							'function' == typeof dataSetFn ? dataSetFn(headerData) : null;
							var docNumber = options.pathToDocNumber
								? edi.utils.getObjectProperty(headerData.data, options.pathToDocNumber)
								: headerData.data.number;
							edi.document.actions.changeTabTitle(moduleData.tab, docNumber);
							!skipMaskRemoval ? moduleData.tab.setLoading(false) : null;
							moduleData ? moduleData.tab.removeAll() : null;
							'function' == typeof success ? success() : null;
						} else {
							failure();
						}
					},
					failure
				);
			}
		}
	},
	/**
	 * Empty contract record data
	 * @returns {{number: *, id: Number}}
	 */
	getEmptyContract: function () {
		return {
			number: edi.i18n.getMessage('form.combo.not.selected'),
			id: 0
		};
	},
	/**
	 * Sets internal line identifier, that will be used for line comparison before save and data merge
	 * @param    {Object}    initialLine    initial line object from document(returned from server)
	 * @param    {Object}    [parsedLine]     parsed line, that will be used for work in document
	 */
	setInternalLineId: function (initialLine, parsedLine) {
		initialLine[edi.constants.INTERNAL_LINE_ID] = edi.core.getId();
		if (parsedLine) {
			parsedLine[edi.constants.INTERNAL_LINE_ID] = initialLine[edi.constants.INTERNAL_LINE_ID];
		}
	},
	/**
	 * Merges line data into initial object
	 * @param      {Object}    initialData    document object from server
	 * @param      {Array}     linesArr       collection of parsed lines from initial and result object
	 */
	mergeLinesData: function (initialData, linesArr) {
		var i, j, k;
		for (k = 0; k < linesArr.length; k++) {
			var initialLines = linesArr[k].initial,
				resultLines = linesArr[k].result,
				conf = linesArr[k].conf;
			if (initialLines && initialLines.length) {
				if (resultLines && resultLines.length) {
					for (i = 0; i < resultLines.length; i++) {
						if (resultLines[i][edi.constants.INTERNAL_LINE_ID]) {
							for (j = 0; j < initialLines.length; j++) {
								if (
									initialLines[j][edi.constants.INTERNAL_LINE_ID] &&
									initialLines[j][edi.constants.INTERNAL_LINE_ID] ==
										resultLines[i][edi.constants.INTERNAL_LINE_ID]
								) {
									Ext.Object.merge(initialLines[j], resultLines[i]);
									resultLines[i] = initialLines[j];
									delete resultLines[i][edi.constants.INTERNAL_LINE_ID];
									break;
								}
							}
						} else if (conf.type === edi.constants.MERGE_TYPES.MERGE_INTO) {
							delete resultLines[i][edi.constants.INTERNAL_LINE_ID];
							initialLines.push(resultLines[i]);
						}
					}
				}
				if (conf.type === edi.constants.MERGE_TYPES.MERGE_INTO) {
					resultLines = initialLines;
				}
				edi.utils.setObjectProperty(initialData, conf.path, resultLines);
			}
		}
	},
	/**
	 * Merges data before saving, merges separately all product lines and removes internal identifiers from product lines
	 * @param      {Object}    initialData    document object from server
	 * @param      {Object}    result         document object prepared on UI
	 * @param      {Array}     linesConf      array of config objects with info about paths to collections of lines in object and how to merge them
	 * @param      {Object}    [options]        additional options
	 * @returns    {Object}    merged object
	 */
	mergeDataBeforeSave: function (initialData, result, linesConf, options) {
		if (Ext.isObject(initialData)) {
			options = options || {};
			var i,
				linesArr = [],
				fieldsToRemove = options.fieldsToRemove || edi.constants.FIELDS_TO_REMOVE_AFTER_MERGE;
			initialData = Ext.clone(initialData);
			result = Ext.clone(result);
			if (linesConf && linesConf.length) {
				for (i = 0; i < linesConf.length; i++) {
					linesArr.push({
						initial: edi.utils.getObjectProperty(initialData, linesConf[i].path, true),
						result: edi.utils.getObjectProperty(result, linesConf[i].path, true),
						conf: linesConf[i]
					});
				}
			}
			Ext.Object.merge(initialData, result);
			if (linesArr.length) {
				edi.document.actions.mergeLinesData(initialData, linesArr);
			}
			if (fieldsToRemove) {
				for (i = 0; i < fieldsToRemove.length; i++) {
					if (edi.utils.getObjectProperty(initialData, fieldsToRemove[i], true).length) {
						edi.utils.setObjectProperty(initialData, fieldsToRemove[i], undefined);
					}
				}
			}
		} else {
			initialData = result;
		}
		return initialData;
	},

	logsInfoDialog: function (ids) {
		if (ids && ids.length) {
			var filterObject,
				i,
				url = edi.rest.services.LOGS.FILTER.GET;
			for (i = 0; i < ids.length; i++) {
				url += (0 === i ? '?' : '&') + 'docId=' + ids[i];
			}
			var fireSearch = function () {
				if (!filterObject) {
					filterObject = edi.filters.createGridFilter(url, filterForm, grid);
				}
				filterObject.filter();
			};

			let formItemsMap = {
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('document.date.create'),
					period: edi.utils.getUserDefaultSearchPeriod(edi.constants.DEFAULT.FILTER.PERIODS.LAST90DAYS),
					fieldsConfig: {
						common: {
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy'),
							invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
							submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
							enabledUTC: true
						},
						from: {
							chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
							fieldLabel: edi.i18n.getMessage('document.date.create.from')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};

			let items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]]
				})
			];

			var filterForm = createModuleFilterForm(
				{
					formItemsMap,
					items
				},
				fireSearch
			);

			const compareText = function ({ oldData, newData }) {
				const dmp = new diff_match_patch();
				const diffs = dmp.diff_main(oldData, newData);
				dmp.diff_cleanupSemantic(diffs);

				let oldComparedData = '';
				let newComparedData = '';

				// Проходим по всем различиям и выделяем их в разметке
				for (let i = 0; i < diffs.length; i++) {
					const diff = diffs[i];
					const type = diff[0];
					const content = diff[1];

					if (type === 0) {
						// Одинаковые части текста
						oldComparedData += content;
						newComparedData += content;
					} else if (type === -1) {
						// Удаленные части текста
						oldComparedData += `{delStart}${content}{delEnd}`;
					} else if (type === 1) {
						// Добавленные части текста
						newComparedData += `{insStart}${content}{insEnd}`;
					}
				}

				return { oldComparedData, newComparedData };
			};

			const markDiff = (xmlString) => {
				const xmlLines = xmlString.split('\n');
				const changeFlagNames = ['del', 'ins'];
				const markedLines = [];
				xmlLines.forEach((tag) => {
					let isLineChanged = false;
					changeFlagNames.forEach((flag) => {
						const regex = `\\{${flag}Start\\}(.*?)\\{${flag}End\\}|\\{${flag}Start\\}|\\{${flag}End\\}`;
						tag = tag.replace(new RegExp(regex, 'g'), function (match, p1) {
							if (p1 !== undefined) {
								isLineChanged = true;
								return `<span class="change">${p1}</span>`;
							} else if (match === `{${flag}Start}`) {
								markedLines.push(`<section class="${flag}">`);
								return '';
							} else if (match === `{${flag}End}`) {
								markedLines.push(`</section>`);
								return '';
							}
						});
					});
					markedLines.push(`<div class="xml-line ${isLineChanged ? 'changed-line' : ''}">${tag}</div>`);
				});
				return markedLines.join('');
			};

			const createDocumentCompareModal = ({ oldData, newData }) => {
				if (!oldData || !newData) return;
				const { oldComparedData, newComparedData } = compareText({
					oldData: vkbeautify.xml(oldData),
					newData: vkbeautify.xml(newData)
				});

				const oldDataField = createTextField({
					isTextarea: true,
					cls: 'compare-area',
					rowsHtmlAttributeValue: 35,
					minHeight: 590
				});
				const newDataField = createTextField({
					isTextarea: true,
					cls: 'compare-area',
					rowsHtmlAttributeValue: 35,
					minHeight: 590
				});
				return createModalPanel(
					{
						title: edi.i18n.getMessage('documents.compare'),
						autoShow: true,
						width: '100%',
						maxWidth: 1318,
						padding: '0 0 24 0',
						listeners: {
							afterrender: () => {
								const safeOldData = edi.utils.safeString(oldComparedData);
								const safeNewData = edi.utils.safeString(newComparedData);
								oldDataField.setHtml(`<pre class="x-form-text">${markDiff(safeOldData)}</pre>`);
								newDataField.setHtml(`<pre class="x-form-text">${markDiff(safeNewData)}</pre>`);
							}
						},
						items: [
							createModalForm({
								layout: {
									type: 'grid',
									area: [[6, 6]]
								},
								items: [oldDataField, newDataField]
							})
						]
					},
					true
				);
			};

			const columns = edi.columns.get('document_logs');
			if (edi.permissions.hasPermission('CLIENT_GET_DOCUMENT_DATAS')) {
				columns.push(
					createActionsColumnConfig({
						flex: 0.5,
						align: 'center',
						items: [
							{
								glyph: edi.constants.ICONS.DETAILS,
								handler: function (grid, rowIndex, colindex, actionItem, event, record) {
									modal.setLoading(true);
									const url = edi.utils.formatString(edi.rest.services.LOGS.GET, {
										id: record.get('id')
									});
									edi.rest.sendRequest(
										url,
										'GET',
										undefined,
										(resp) => createDocumentCompareModal(resp.data),
										edi.rest.getErrorHandler(),
										() => modal.setLoading(false)
									);
								},
								isActionDisabled: function (view, rowIndex, colIndex, item, record) {
									const actionType = record.get('action');
									const actionsWithDetailView = ['document.log.update_draft'];
									return !actionsWithDetailView.includes(actionType);
								}
							}
						]
					})
				);
			}

			var grid = createGrid({
				proxyConfig: {
					type: 'ajax',
					pageParam: undefined,
					limitParam: undefined,
					startParam: undefined,
					url: url
				},
				storeConfig: {
					model: getLogModelName(),
					sortOnLoad: true,
					sorters: {
						property: 'creationDate',
						direction: 'ASC'
					},
					autoLoad: false
				},
				gridConfig: {
					columns,
					region: 'center'
				}
			});
			var modal = createModalPanel({
				cls: 'edi-modal-documents-selector',
				width: MODAL_SIZE.widthLarge,
				height: 500,
				title: edi.i18n.getMessage('document.logs.info'),
				layout: 'fit',
				items: [
					createPanel({
						cls: 'edi-form',
						layout: 'border',
						items: [filterForm, grid]
					})
				],
				listeners: {
					beforedestroy: function () {
						if (filterObject && filterObject.searchTimeout) {
							clearTimeout(filterObject.searchTimeout);
						}
					}
				}
			});
			modal.show();
			fireSearch();
		}
	},

	documentNotifyDialog: function (docId, config = {}) {
		const fail = edi.rest.getErrorHandler();
		const success = function (resp) {
			if (!Array.isArray(resp?.items)) {
				fail(resp);
				return;
			}
			const modal = createModalPanel(
				{
					width: MODAL_SIZE.widthLarge,
					title: edi.i18n.getMessage(config.modalTitle || 'document.action.annul.all.modal.title'),
					items: [
						createModalForm({
							items: [
								createGrid({
									storeConfig: {
										model: edi.models.getModel('DOCUMENTS_NOTIFY'),
										proxy: createProxyConfig({
											type: 'memory',
											data: {
												items: resp.items
											}
										}),
										remoteSort: false
									},
									gridConfig: {
										columns: edi.columns.get(config.columns || 'documents_notify'),
										disablePaging: true,
										maxHeight: 400
									}
								})
							]
						})
					]
				},
				true
			);
			modal.show();
		};

		const url = edi.utils.formatString(config.rest || edi.rest.services.DOCUMENTS.ANNUL_LOG.GET, { docId });
		edi.rest.sendRequest(url, 'GET', undefined, success, fail);
	},

	/**
	 * Renders modal grid with signature records
	 * @param initData
	 * @param id
	 */
	signatureInfoDialog: function (initData, id, config) {
		var modal;
		const { gridCfg } = config || {};
		let getModal = function () {
			return modal;
		};
		if ((initData && initData.id) || id) {
			var grid = createGrid(
				Ext.merge(
					{
						proxyConfig: {
							type: 'ajax',
							pageParam: undefined,
							limitParam: undefined,
							startParam: undefined,
							url: edi.document.actions.formatSignersUri(initData, id)
						},
						storeConfig: {
							model: edi.models.getModel('SIGN_INFO'),
							pageSize: undefined,
							remoteSort: false
						},
						gridConfig: {
							columns: edi.columns.get('documents_sign_info'),
							disablePaging: true,
							maxHeight: 400,
							listeners: {
								cellclick: function (self, td, cellIndex, record, tr, rowIndex, e, eOpts) {
									let dataIndex = self.getHeaderCt().getHeaderAtIndex(cellIndex).dataIndex;
									if (dataIndex === 'doverRegNumber' && record.get('poaId')) {
										edi.methods.poa.openDetailsById(
											{
												docId: id,
												poaId: record.get('poaId')
											},
											getModal,
											record.get('doverRegNumber')
										);
									}
								}
							}
						}
					},
					gridCfg
				)
			);

			modal = createModalPanel({
				width: MODAL_SIZE.widthLarge,
				title: edi.i18n.getMessage('document.sign.info'),
				items: [
					createModalForm({
						items: [grid]
					})
				]
			});
			modal.show();
		}
	},

	/**
	 * Opens modal with description of poa errors emerged while signing
	 * @param	{number|string}	docId
	 * @param	{Object}	[config]
	 * @return	{Object}	modal window
	 */
	showPoaErrorDialog: function (docId, config) {
		const grid = createGrid({
			proxyConfig: config?.proxyConfig ?? {
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.DOCUMENTS.POA_ERRORS.GET, { documentId: docId })
			},
			storeConfig: {
				model: edi.models.getModel('DOCUMENTS_POA_ERRORS'),
				autoLoad: true,
				listeners: {
					load: function (store, records) {
						const filteredData = (records || []).filter((item) => !!item.getData().poaError);
						store.loadRawData(filteredData);
					}
				}
			},
			gridConfig: {
				columns: config?.columns ?? edi.columns.get('documents_poa_errors'),
				disablePaging: true
			}
		});

		const modal = createModalPanel({
			title: edi.i18n.getMessage('document.poa.errors.window.title'),
			width: MODAL_SIZE.widthLarge,
			items: [
				createModalForm({
					items: [grid]
				})
			]
		});

		modal.show();
		return modal;
	},

	/**
	 * Handles module behavior after saving changes
	 * @param    {Object}    moduleData    current module data
	 * @param    {Object}    data          saved document
	 * @param    {Boolean}   isEdit        true if we are in the edit mode
	 * @param    {Object}    customRule    custom rule
	 * @param    {Object}    actionsObj    object that holds actions for opening modules
	 */
	documentCreateProcessing: function (moduleData, data, isEdit, customRule, actionsObj) {
		var rule = customRule
			? customRule
			: data && data.data && data.data.type
			? edi.constants[isEdit ? 'EDIT_ACTIONS' : 'CREATE_ACTIONS'][data.data.type] ||
			  edi.constants[isEdit ? 'EDIT_ACTIONS' : 'CREATE_ACTIONS'].DEFAULT
			: edi.constants[isEdit ? 'EDIT_ACTIONS' : 'CREATE_ACTIONS'].DEFAULT;
		if (rule) {
			actionsObj = 'object' == typeof actionsObj ? actionsObj : edi.document.actions;
			var openDetails = rule.OPEN_DETAILS,
				closeCurrent = rule.CLOSE_CURRENT,
				openEdit = rule.OPEN_EDIT;
			var continueProcessing = function () {
				closeCurrent && !rule.OPEN_ROOT && moduleData ? edi.modulesHandler.removeModule(moduleData) : null;
				if (openDetails && data && data.data && data.data.type) {
					if (!isEdit && !data.data.state) {
						data.data.state = edi.constants.STATE.DRAFT;
						data.data.modifyDate = data.data.creationDate;
					}
					if (
						edi.document.actions.checkDocumentActionPermission(
							data.data.type,
							edi.constants.DOCUMENT_ACTIONS.DETAILS
						)
					) {
						actionsObj.openDetailsModule(data.data.type, data.data);
					} else {
						isEdit
							? edi.core.showInfo('document.edit.success')
							: edi.core.showInfo('document.create.success');
					}
				} else if (openEdit) {
					if (
						edi.document.actions.checkDocumentActionPermission(
							data.data.type,
							edi.constants.DOCUMENT_ACTIONS.EDIT
						)
					) {
						actionsObj.openCreateModule(data.data.type, data.data, true);
					} else {
						isEdit
							? edi.core.showInfo('document.edit.success')
							: edi.core.showInfo('document.create.success');
					}
				}
			};
			if (rule.OPEN_ROOT) {
				edi.modulesHandler.removeAllModules(continueProcessing);
			} else {
				continueProcessing();
			}
		} else {
			!isEdit
				? edi.modulesHandler.removeAllModules()
				: moduleData
				? edi.modulesHandler.removeModule(moduleData)
				: null;
		}
	},
	/**
	 * Check document action permission
	 * @param    {String}    documentType
	 * @param    {String}    action
	 * @returns {Boolean}
	 */
	checkDocumentActionPermission: function (documentType, action) {
		var mappedAction = edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP[action];
		if (documentType && mappedAction) {
			var permissionName = mappedAction + '_' + documentType;
			if (edi.permissions.hasPermission(permissionName)) {
				return true;
			}
		}
		return false;
	},

	/**
	 * Opens create module
	 * @param    {String}    typeId        document type
	 * @param    {Object}    data        module data
	 * @param    {Boolean}   isEdit        flag that shows that we open in edit mode for existing document
	 * @param    {String}    versionId  document version id
	 */
	openCreateModule: function (typeId, data, isEdit, versionId) {
		var moduleName,
			res = false;
		if (typeId) {
			if (versionId) {
				moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[[typeId, versionId].join('_')];
			} else {
				moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[typeId];
			}
		}
		if (moduleName) {
			var postfix = isEdit ? data.number : undefined;
			res = edi.core.openModule(moduleName, data, postfix, isEdit);
		} else {
			edi.core.showInfo(edi.i18n.getMessage('not.implemented'));
		}
		return res;
	},

	/**
	 * Opens details of selected document
	 * @param    {String}    typeId              document type
	 * @param    {Object}    data                module data
	 * @param    {Object}    additionalConfig    document opening additional config
	 * @param    {Function}  afterInit           method that should be called after module initialisation
	 */
	openDetailsModule: function (typeId, data, additionalConfig, afterInit) {
		var moduleName,
			res = false;
		if (typeId && data && edi.constants.STATE.DELETED !== data.state) {
			if (data.versionId) {
				moduleName = edi.constants.DETAILS_MODULE_NAME_BY_TYPE[[typeId, data.versionId].join('_')];
			} else {
				moduleName = edi.constants.DETAILS_MODULE_NAME_BY_TYPE[typeId];
			}
			if (moduleName) {
				res = edi.core.openModule(
					moduleName,
					data,
					data.number + (data.title ? ' - ' + data.title : ''),
					undefined,
					undefined,
					additionalConfig,
					afterInit
				);
			} else {
				edi.core.showInfo(edi.i18n.getMessage('not.implemented'));
			}
		}
		return res;
	},

	/**
	 * Opens details window with text info about CONTRL or APERAK
	 * @param	{String}	type	document type
	 * @param	{Object}	docData	document data
	 * @param	{String}	contentUrl
	 */
	openDetailsWindow: function (type, docData, contentUrl) {
		var doc = docData || {};
		var getMessageText = function (cb) {
			if (type === edi.constants.DOCUMENT_TYPES.EDI_CONTRL && doc.id) {
				var url = edi.utils.formatString(contentUrl, {
					documentId: doc.id
				});
				edi.rest.sendRequest(
					url,
					'GET',
					null,
					function (respData) {
						cb(edi.utils.getObjectProperty(respData, 'data.ServiceReport-Header.ServiceReportText'));
					},
					function () {
						cb(null);
					}
				);
			} else if (type === edi.constants.DOCUMENT_TYPES.LEGACY_APERAK) {
				cb(edi.utils.getAttributeByName(doc.attributes, 'referenceText'));
			} else {
				cb(null);
			}
		};

		var buttonsBefore = edi.rest.services.ADMIN
			? [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('action.export'),
						handler: function () {
							var url = docData.archived
								? edi.utils.formatString(edi.rest.services.ADMIN.ARCHIVE_OBJECTS.DIRECT_EXPORT.GET, {
										archivedObjectId: docData.id
								  })
								: edi.utils.formatString(edi.rest.services.ADMIN.DOCUMENTS.EXPORT.GET, {
										id: docData.id
								  });

							edi.rest.downloadFile(url);
						}
					})
			  ]
			: undefined;

		var messageField;
		var modal = createModalPanel({
			autoShow: true,
			width: MODAL_SIZE.widthLarge,
			title: (type === edi.constants.DOCUMENT_TYPES.EDI_CONTRL ? 'CONTRL №' : 'APERAK №') + doc.number,
			buttonsBefore: buttonsBefore,
			items: [
				createModalForm({
					items: [
						createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage('info'),
							items: [
								(messageField = createLabel({
									text: 'message text'
								}))
							]
						})
					]
				})
			]
		});
		messageField.setText('');

		modal.setLoading(true);
		getMessageText(function (messageText) {
			if (messageText) {
				messageField.setText(messageText);
			}
			modal.setLoading(false);
		});
	},

	/**
	 * Formats uri for getting document signers info
	 * @param    {Object}    initData    object with module init data
	 * @param    {String}    docId       docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @returns  {string}
	 */
	formatSignersUri: function (initData, docId) {
		var uri;
		if (initData && initData.docSignersUri) {
			uri = edi.utils.formatString(initData.docSignersUri, {
				documentId: docId || initData.id,
				containerDocId: initData.containerDocId
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.INFO.POA.GET, {
				documentId: docId || initData.id
			});
		}
		return uri;
	},

	/**
	 * Formats uri for downloading document xml
	 * @param    {Object}    initData    object with module init data
	 * @param    {String}    [docId]       docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @param    {String}    [defaultUrl]
	 * @returns  {string}
	 */
	formatExportUrl: function (initData, docId, defaultUrl) {
		var uri;
		if (initData && initData.docExportUri) {
			uri = edi.utils.formatString(initData.docExportUri, {
				documentId: docId || initData.id,
				containerDocId: initData.containerDocId,
				id: docId || initData.id
			});
		} else {
			uri = edi.utils.formatString(defaultUrl ?? edi.rest.services.DOCUMENTS.EXPORT.XML, {
				documentId: docId || initData.id
			});
			if ('AB' === edi.login.getAuthType()) {
				uri = edi.utils.compileURL(uri, {
					handleId: 'AB'
				});
			}
		}
		return uri;
	},
	formatExpendedExportUrl: function (initData, docId) {
		var uri;
		if (initData && initData.docExportUri) {
			uri = edi.utils.formatString(initData.docExportUri, {
				documentId: docId || initData.id,
				containerDocId: initData.containerDocId,
				id: docId || initData.id
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.EXPENDED, {
				documentId: docId || initData.id
			});
			if ('AB' === edi.login.getAuthType()) {
				uri = edi.utils.compileURL(uri, {
					handleId: 'AB'
				});
			}
		}
		return uri;
	},

	/**
	 * Formats uri for downloading dsf document attachment
	 * @param    {Object}    initData        object with module init data
	 * @param    {String}    [attachmentId]    id of attachment
	 * @param    {String}    [docId]           docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @returns  {string}
	 */
	formatExportAttachementUrl: function (initData, attachmentId, docId) {
		var uri;
		if (initData && initData.docExportAttachmentUri) {
			uri = edi.utils.formatString(initData.docExportAttachmentUri, {
				documentId: docId || initData.id,
				containerDocId: initData.containerDocId
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.ATTACHMENT, {
				documentId: docId || initData.id
			});
			if ('AB' === edi.login.getAuthType()) {
				uri = edi.utils.compileURL(uri, {
					handleId: 'AB'
				});
			}
		}
		return uri;
	},

	/**
	 * Formats uri for print templates
	 * @param    {Object}    initData    object with module init data
	 * @param    {String}    docId       docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @param    {String}    format      export template format
	 * @returns  {string}
	 */
	formatPrintTemplatesUrl: function (initData, docId, format) {
		var uri;
		if (initData && initData.docPrintTemplatesUri) {
			uri = edi.utils.formatString(initData.docPrintTemplatesUri, {
				docId: docId || initData.id,
				containerDocId: initData.containerDocId,
				format: format || edi.constants.REPORT_FORMATS.PDF
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.REPORT.TEMPLATES_AND_STATUSES.GET, {
				docId: docId || initData.id,
				format: format || edi.constants.REPORT_FORMATS.PDF
			});
		}
		return uri;
	},

	formatPrintReportUrl: function (initData) {
		if (initData && initData.docPrintReportUri) {
			return edi.utils.formatString(initData.docPrintReportUri, {
				containerDocId: initData.containerDocId
			});
		}
		return edi.rest.services.REPORT.POST;
	},

	formatDownloadReportUrl: function (initData, reportId) {
		var url;
		if (initData && initData.docDownloadReportUri) {
			url = edi.utils.formatString(initData.docDownloadReportUri, {
				reportId: reportId,
				containerDocId: initData.containerDocId
			});
		} else {
			url = edi.utils.formatString(edi.rest.services.REPORT.PRINT_FORM.GET, {
				reportId: reportId
			});
		}
		return url;
	},

	/**
	 * Formats uri for getting document details info
	 * @param    {Object}    initData        object with module init data
	 * @param    {String}    [docId]           docId, if needed to override initValue(for documents within parent container, like AKT)
	 * @param    {Boolean}   [sharContentCustomDoc]     is get content for custom sharing doc
	 * @returns  {string}
	 */
	formatDetailsUri: function (initData, docId, sharContentCustomDoc) {
		var uri;
		if (initData && initData.docDetailsUri) {
			if (sharContentCustomDoc && docId) {
				uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.SHARING.CONTENT_DOC.GET, {
					containerDocId: docId
				});
			} else {
				uri = edi.utils.formatString(initData.docDetailsUri, {
					documentId: docId || initData.id,
					containerDocId: initData.containerDocId
				});
			}
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId: docId || initData.id
			});
		}
		return uri;
	},

	/**
	 * Formats uri for getting linked documents info
	 * @param    {Object}    initData    object with module init data
	 * @returns  {string}
	 */
	formatLinkedUri: function (initData) {
		var uri;
		if (initData && initData.docLinkedUri) {
			uri = edi.utils.formatString(initData.docLinkedUri, {
				documentId: initData.id,
				containerDocId: initData.containerDocId
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.SORTED.GET, {
				documentId: initData.id
			});
		}
		return uri;
	},

	/**
	 * Formats uri for getting linked childrens documents info
	 * @param    {Object}    initData    object with module init data
	 * @returns  {string}
	 */
	formatLinkedChildrenUri: function (initData) {
		var uri;
		if (initData && initData.docLinkedChildrenUri) {
			uri = edi.utils.formatString(initData.docLinkedChildrenUri, {
				documentId: initData.id
			});
		} else {
			uri = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.CHILDREN.GET, {
				documentId: initData.id
			});
		}
		return uri;
	},
	/**
	 * Formats uri for getting reject reason text
	 * @param    {Object}    initData    object with module init data
	 * @returns  {string}
	 */
	formatRejectReasonUri: function (initData) {
		var id = initData.data && initData.data.id ? initData.data.id : null;
		var docType = initData.data && initData.data.type ? initData.data.type : null;
		if (!id || !docType) {
			return '';
		}
		if (docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD) {
			return edi.utils.formatString(edi.rest.services.REJECT_REASON.UPD, {
				id: id
			});
		}
		if (docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD) {
			return edi.utils.formatString(edi.rest.services.REJECT_REASON.UKD, {
				id: id
			});
		}
	},
	/**
	 * Formats uri for getting docData info
	 * @param	{Object}	initData	object with module init data
	 * @param	{Object}	params
	 * @returns	{string}
	 */
	formatDocDataUri: function (initData, params) {
		return initData && initData.docLinkedChildrenUri
			? edi.utils.formatString(
					initData.docDataUri,
					Object.assign(params, {
						documentId: initData.id
					})
			  )
			: edi.utils.formatString(edi.rest.services.DOCUMENTS.INFO_FIELDS.GET, params);
	},

	/**
	 * Formats uri for getting linked documents info
	 * @param    {Object}    initData    object with module init data
	 * @param    {Object}    params      url params
	 * @returns  {string}
	 */
	formatLinkedTreeUri: function (initData, params) {
		var uri,
			urlParams = params || {};
		if (initData && initData.docLinkedTreeUri) {
			uri = edi.utils.formatString(
				initData.docLinkedTreeUri,
				Object.assign(urlParams, {
					containerDocId: initData.containerDocId
				})
			);
		} else {
			uri = edi.utils.formatString(
				edi.rest.services.DOCUMENTS.LINKED.TREE.GET,
				Object.assign(urlParams, {
					documentId: initData.id
				})
			);
		}
		return uri;
	},

	/**
	 * Formats uri for getting multiple documents headers info
	 * @param    {Object}    initData    object with module init data
	 * @returns  {string}
	 */
	formatBatchHeaderUri: function (initData) {
		if (initData && initData.docHeaderUriBatch) {
			return edi.utils.formatString(initData.docHeaderUriBatch, {
				containerDocId: initData.containerDocId
			});
		}
		return edi.rest.services.DOCUMENTS.HEADER.MULTIPLE.POST;
	},

	/**
	 * Formats uri for getting multiple documents content info
	 * @param    {Object}    initData    object with module init data
	 * @returns  {string}
	 */
	formatBatchContentUri: function (initData) {
		if (initData && initData.docDetailsUriBatch) {
			return edi.utils.formatString(initData.docDetailsUriBatch, {
				containerDocId: initData.containerDocId
			});
		}
		return edi.rest.services.DOCUMENTS.CONTENT.MULTIPLE.POST;
	},

	linkedDocumentsDialog: function (id, config, urls, depth) {
		config = 'object' == typeof config ? config : {};
		urls = 'object' == typeof urls ? urls : {};
		var modalConfig = 'object' == typeof config.modalConfig ? config.modalConfig : {};
		var treeUrl = !!urls.tree ? urls.tree : edi.rest.services.DOCUMENTS.LINKED.TREE.GET;
		var pathUrl = !!urls.path ? urls.path : edi.rest.services.DOCUMENTS.LINKED.PATH.GET;
		var contentUrl = !!urls.content ? urls.content : edi.rest.services.DOCUMENTS.CONTENT.GET;
		if (id) {
			edi.rest.sendRequest(
				edi.utils.formatString(pathUrl, {
					documentId: id
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items && data.items.length) {
						var path = data.items.reverse();
						var openMap = {};
						for (var i = 0; i < path.length; i++) {
							openMap[path[i]] = 'init';
						}
						edi.rest.sendRequest(
							edi.utils.formatString(treeUrl, {
								documentId: path[0],
								root: true,
								depth: !!depth ? depth : edi.constants.DEFAULT.TREE_DEPTH
							}),
							'GET',
							null,
							function (data) {
								var model = edi.models.getModel('DOCUMENT_LINKED');
								var modelClass = Ext.ClassManager.get(model);
								modelClass.addStatics({
									openMap: openMap,
									currentId: id
								});
								var filterLinkedDocuments = function (data, key) {
									recursiveForEach(data);

									function recursiveForEach(data) {
										if (Array.isArray(data)) {
											data.forEach(function (document) {
												if (!edi.constants.RECEIPTS.some((it) => it === document.type)) {
													if (document.hasOwnProperty(key)) {
														if (document[key] != null) {
															document[key] = document[key].filter(function (
																attachedDocument
															) {
																return (
																	!edi.constants.RECEIPTS.some(
																		(it) => it === attachedDocument.type
																	) && !attachedDocument.deleted
																);
															});
															recursiveForEach(document[key]);
														} else {
															document.leaf = true;
														}
													}
												}
											});
										}
									}

									return data;
								};
								var store = new Ext.data.TreeStore({
									proxy: {
										type: 'memory',
										data: filterLinkedDocuments(data.items, 'items')
									},
									root: { id: 0 },
									defaultRootProperty: 'items',
									model: model
								});

								var isAvailableForDetailsWindow = function (type) {
									return (
										type === edi.constants.DOCUMENT_TYPES.EDI_CONTRL ||
										type === edi.constants.DOCUMENT_TYPES.LEGACY_APERAK
									);
								};
								var columns = config.columns ? config.columns : edi.columns.get('documents_all_linked');
								columns.push(
									createActionsColumnConfig({
										flex: 0.5,
										align: 'center',
										items: [
											{
												glyph: edi.constants.ICONS.DETAILS,
												handler: function (
													grid,
													rowIndex,
													colindex,
													actionItem,
													event,
													record
												) {
													rowClick(record);
												},
												isActionDisabled: function (view, rowIndex, colIndex, item, record) {
													var type = edi.constants.DOCUMENT_TYPES[record.get('type')];
													var isAvailableDoc =
														edi.constants.DETAILS_MODULE_NAME_BY_TYPE.hasOwnProperty(type);
													var isAvailableForDetails = isAvailableForDetailsWindow(
														record.get('type')
													);
													return (
														record.get('id') == id ||
														record.get('archived') ||
														(!isAvailableDoc && !isAvailableForDetails)
													);
												}
											}
										],
										renderer: function (value, metaData, record) {
											if (record.get('archived')) {
												metaData.tdAttr =
													'data-qtip="' +
													edi.utils.replaceQuotes(
														edi.i18n.getMessage('document.in.archive')
													) +
													'"';
											} else if (!record.get('isCurrent')) {
												metaData.tdAttr =
													'data-qtip="' +
													edi.utils.replaceQuotes(
														edi.i18n.getMessage('document.open.linked.docs.details')
													) +
													'"';
											} else {
												metaData.tdAttr = null;
											}
											record.get('isCurrent')
												? (metaData.tdCls += ' edi-linked-current-element')
												: null;
											return value;
										}
									})
								);
								var tree = createTreePanel({
									store: store,
									rootVisible: false,
									columns: columns,
									useArrows: true,
									maxHeight: 400,
									listeners: {
										celldblclick: function (view, td, cellIndex, record) {
											if (cellIndex && !record.get('archived')) {
												rowClick(record);
											}
										}
									}
								});
								var rowClick = function (record) {
									var data = record.getData();
									if (id != data.id) {
										var type = record.get('type');
										if (isAvailableForDetailsWindow(type)) {
											edi.document.actions.openDetailsWindow(type, data, contentUrl);
										} else {
											modal.close();
											config && typeof config.openDetailsModule === 'function'
												? config.openDetailsModule(type, data)
												: edi.document.actions.openDetailsModule(type, data);
										}
									}
								};
								var defaults = {
									width: MODAL_SIZE.widthLarge,
									title: edi.i18n.getMessage('document.linked.docs'),
									items: [
										createModalForm({
											items: [tree]
										})
									]
								};
								Ext.applyIf(modalConfig, defaults);
								var modal = createModalPanel(modalConfig);
								modal.show();
							}
						);
					}
				}
			);
		}
	},
	receiptsDocumentsDialog: function (id, data, config) {
		config = 'object' == typeof config ? config : {};
		var modalConfig = 'object' == typeof config.modalConfig ? config.modalConfig : {};
		if (id && data) {
			var cols = config.columns ? config.columns : 'documents_all_linked_receipts';
			var proxyCnf = config.proxyConfig
				? config.proxyConfig
				: {
						type: 'ajax',
						url: edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
							documentId: id
						}),
						reader: {
							rootProperty: 'data.children'
						}
				  };
			var grid = createGrid({
				proxyConfig: proxyCnf,
				storeConfig: {
					model: edi.models.getModel('DOCUMENT_LINKED_RECEIPTS'),
					autoLoad: true,
					listeners: {
						load: function (store, records) {
							var filterData = records.filter(function (item) {
								return edi.constants.RECEIPTS.some((it) => it === item.data.type);
							});
							store.loadRawData(filterData);
						}
					}
				},
				gridConfig: {
					columns: edi.columns.get(cols),
					maxHeight: 400
				}
			});
			var defaults = {
				width: MODAL_SIZE.widthLarge,
				title: edi.i18n.getMessage('receipt.title'),
				items: [
					createModalForm({
						items: [grid]
					})
				]
			};
			Ext.applyIf(modalConfig, defaults);
			var modal = createModalPanel(modalConfig);
			modal.show();
		}
	},

	/**
	 * Get action permission for doctype
	 * @param    {String}    action     edi.constants.DOCUMENT_ACTIONS
	 * @param    {String}    doctype    edi.constants.DOCUMENT_TYPES
	 * @returns    {String}
	 */
	getActionPermission: function (action, doctype) {
		return edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP[action] + '_' + doctype;
	},

	/**
	 * Update document module header data
	 * @param moduleData
	 * @param callback
	 */
	updateDocumentHeaderData: function (moduleData, callback) {
		edi.document.actions.getDocumentHeaderData(
			null,
			function (data) {
				moduleData.initData.data = data;
				'function' == typeof callback ? callback(data) : null;
			},
			edi.document.actions.formatHeaderUri(moduleData.initData)
		);
	},

	/**
	 * Get document header data
	 * @param documentId
	 * @param callback
	 * @param url            optional url for request
	 */
	getDocumentHeaderData: function (documentId, callback, url) {
		edi.rest.sendRequest(
			url ||
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.HEADER.GET,
					{
						documentId: documentId
					},
					true
				),
			'GET',
			null,
			function (responseData) {
				'function' == typeof callback ? callback(responseData.data) : null;
			},
			function (errorData) {
				edi.core.showError(edi.utils.formatComplexServerError(errorData, 'error.server'), function () {
					'function' == typeof callback ? callback(null) : null;
				});
			}
		);
	},

	copyDocumentHandler: function (record, grid, callback, failure) {
		return function () {
			var id = record.get('id'),
				type = record.get('type'),
				versionId = record.get('versionId');

			var moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[type];
			if (versionId) {
				moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[[type, versionId].join('_')];
			}

			if (
				edi.constants.MODULES &&
				edi.constants.MODULES.DOCUMENTS &&
				Array.isArray(edi.constants.MODULES.DOCUMENTS.COPY_USING_UI) &&
				edi.constants.MODULES.DOCUMENTS.COPY_USING_UI.some((it) => it === type)
			) {
				edi.core.openModule(moduleName, record.getData(), undefined, false, undefined, {
					isCopy: true,
					objectId: id + '_copy'
				});
				grid.getSelectionModel().deselect(record);
				callback && 'function' == typeof callback ? callback() : null;
			} else {
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.COPY.POST,
						{
							documentId: id
						},
						true
					),
					'POST',
					'',
					function () {
						grid.getSelectionModel().deselect(record);
						callback && 'function' == typeof callback ? callback() : null;
					},
					function () {
						edi.core.logMessage(
							'Error occurred during copying of document ' +
								edi.i18n.getMessage('documents.doctype.' + type) +
								' ' +
								record.get('number'),
							'warn'
						);
						failure && 'function' == typeof failure ? failure(record) : null;
						callback && 'function' == typeof callback ? callback() : null;
					}
				);
			}
		};
	},

	readAndCompleteDocumentHandler: function (record, grid, callback, failure) {
		return function () {
			var id = record.get('id');
			var postData = {};
			var stringified = Ext.encode(postData);
			var success = function () {
				grid.getSelectionModel().deselect(record);
				callback && 'function' == typeof callback ? callback() : null;
				edi.events.documents.fireEvent('change', {
					id: id
				});
			};
			var failureAll = function () {
				failure(arguments);
				callback && 'function' == typeof callback ? callback() : null;
			};
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: id
					},
					true
				),
				'PUT',
				stringified,
				function () {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: id
							},
							true
						),
						'PUT',
						stringified,
						success,
						failureAll
					);
				},
				failureAll
			);
		};
	},

	defaultGridBeforeSignStart: function (data, callback) {
		if (!edi.sign.isAvailable()) {
			edi.sign.displayNotAvailableMessage();
		} else {
			'function' == typeof callback ? callback() : null;
		}
	},
	createSaveSuccessHandler: function (moduleData, id, afterSave, isEdit, additionalData, options) {
		const setModuleLoading = function (isLoading) {
			if (moduleData.tab && !moduleData.tab.isDestroyed) {
				moduleData.tab.setLoading(isLoading);
			}
		};
		return function (responseData) {
			const docHeader = responseData.data;
			const documentId = id || docHeader.id;
			const direction = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);
			const attrs = docHeader.attributes;
			const isWarning = edi.utils.getAttributeByName(attrs, 'isWarning') === 'true';
			const isBlocked = edi.utils.getAttributeByName(attrs, 'isBlocked') === 'true';
			const isIncoming = direction === edi.constants.DIRECTIONS.INCOMING;
			if (isIncoming && !isWarning && !isBlocked) {
				//это кейс для Мултона, когда мы исправляем ошибки в Заказе со стороны получателя документа
				//после исправления мы потеряем доступ к документу и поэтому будем просто закрывать модули
				edi.events.documents.fireEvent('incomingBlockRemoved', { docId: documentId });
				edi.events.documents.fireEvent('change');
			} else {
				var finishSaving = function () {
					moduleData.isChanged = false;
					setModuleLoading(false);
					edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
					if (id) {
						if (!options?.skipChange) {
							edi.events.documents.fireEvent('change', {
								id: id
							});
						}
					} else if (!options?.skipCreate) {
						edi.events.documents.fireEvent('create');
					}
				};

				if ('function' == typeof afterSave && documentId) {
					afterSave(
						documentId,
						function () {
							edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
								if (headerData) {
									responseData.data = headerData;
									Ext.mergeIf(responseData.data, additionalData || {});
								}
								finishSaving();
							});
						},
						responseData
					);
				} else {
					finishSaving();
				}
			}
		};
	},
	/**
	 * Returns failure callback
	 * @param    {Boolean}    isEdit
	 * @param    {Object}     moduleData
	 * @param    {String}     type
	 * @param    {Function}   [callback]
	 * @returns {Function}
	 */
	createSaveErrorHandler: function (isEdit, moduleData, type, callback) {
		return function (data) {
			if (data !== 'showBVErrors') {
				edi.core.logMessage('Error ' + (isEdit ? 'saving ' : 'creating ') + type, 'warn');
				edi.core.showError(
					edi.utils.formatComplexServerError(
						data,
						edi.utils.formatString(
							edi.i18n.getMessage(isEdit ? 'documents.edit.failure' : 'documents.create.failure'),
							{
								type: edi.i18n.getMessage('documents.doctype.' + type)
							}
						)
					),
					undefined,
					undefined,
					{
						title: edi.i18n.getMessage('document.saving.error'),
						okBtnConfig: {
							text: edi.i18n.getMessage('document.back.to.edit.btn')
						}
					}
				);
			}
			moduleData && moduleData.tab && 'function' == typeof moduleData.tab.setLoading
				? moduleData.tab.setLoading(false)
				: null;
			'function' == typeof callback ? callback(data) : null;
		};
	},
	/**
	 * Loads available templates for passed doctype
	 * @param    {String}      docType     type of the document
	 * @param    {Function}    success     callback that will be called on success
	 * @param    {Function}    failure     callback that will be called on failure
	 * @param    {Function}    callback    callback that will be called after end of request in any case
	 */
	loadDocumentTemplatesData: function (docType, success, failure, callback) {
		if (docType) {
			edi.rest.sendRequest(
				edi.utils.compileURL(edi.rest.services.REPORT.TEMPLATE.GET, {
					docType: docType
				}),
				'GET',
				null,
				success,
				failure,
				callback
			);
		} else {
			'function' == typeof failure ? failure() : null;
		}
	},

	signUtochDocument: function (
		utochDoc,
		document,
		success,
		failure,
		tab,
		certificate,
		properties = {},
		poa,
		poaConfirmChecked
	) {
		var isSignDocument = document.type === edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
			signContentUrl = isSignDocument
				? edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
						documentId: utochDoc.id
				  })
				: null;

		let certificateHandler = {
			_selectedCertificate: certificate,
			get: function () {
				return this._selectedCertificate;
			},
			set: function (cert) {
				this._selectedCertificate = cert;
			}
		};
		let poaHandler = {
			_selectedPoa: poa,
			get: function () {
				return this._selectedPoa;
			},
			set: function (poa) {
				this._selectedPoa = poa;
			},
			_poaConfirmChecked: poaConfirmChecked,
			setPoaConfirmCheck: function (poaConfirmChecked) {
				const handler = this;
				handler._poaConfirmChecked = poaConfirmChecked;
			},
			getPoaConfirmCheck: function () {
				const handler = this;
				return handler._poaConfirmChecked;
			}
		};

		const signProperties = signContentUrl
			? Ext.merge(
					{
						signContentUrl: signContentUrl,
						signRefuse: function () {
							tab?.setLoading(false);
							if (typeof success === 'function') {
								success();
							}
						}
					},
					properties
			  )
			: {
					signRefuse: function () {
						tab?.setLoading(false);
						if (typeof success === 'function') {
							success();
						}
					}
			  };

		//for UPD utoch sign an Utoch doc, for DSF utoch sign dsf itself
		edi.utils.sign(
			isSignDocument ? document : utochDoc,
			tab,
			function (failed, data, selectedCertificate, selectedPoa, silent) {
				if (failed) {
					failure(failed, data, selectedCertificate, selectedPoa, silent);
				} else {
					data = data || {};
					data.utochCreated = true;
					success(data);
				}
			},
			undefined,
			undefined,
			true,
			certificateHandler,
			signProperties,
			poaHandler
		);
	},
	/**
	 * create clarify action button
	 * @param    {Object}    document              document data
	 * @param    {Object}    tab                   ext element that should show loading mask
	 * @param    {Function}  beforeClarify         Function that should be called before mark clarify action
	 * @param    {Function}  success               Function that will be called on success deletion of document
	 * @param    {Function}  failure               Function that will be called on failure deletion of document
	 * @param    {String}    customLabel           custom text constant to display on button
	 * @param    {Object}    docOptions            document options data
	 * @param    {Object}    annulDoc        annul and utoch docs
	 */
	createAnnulActionButton: function (
		document,
		tab,
		beforeClarify,
		success,
		failure,
		customLabel,
		docOptions,
		annulDoc
	) {
		//костыль вытаскиваем доп параметр из annulDoc
		let options;
		if (annulDoc?.options) {
			options = annulDoc?.options;
			delete annulDoc.options;
		}
		if (annulDoc && Object.keys(annulDoc).length === 0) {
			annulDoc = null;
		}

		var modal,
			buttons = [],
			annualDirection,
			annulStatus = docOptions && docOptions.annulStatus ? docOptions.annulStatus : null;

		var annualDraft = annulDoc && annulDoc.id && annulDoc.state === edi.constants.STATE.DRAFT,
			annualWait = annulDoc && annulDoc.id && annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION;

		if (annulDoc) {
			annualDirection = edi.utils.getDocumentDirection(annulDoc.toOrg, annulDoc.fromOrg);
		}
		var mainSuccess = function (data) {
			tab.setLoading(false);
			if (modal) {
				modal.close();
			}
			if ('function' == typeof success) {
				success(data, document.id);
			} else {
				edi.events.documents.fireEvent('change', {
					id: document.id
				});
			}
		};
		var mainFailure =
			'function' == typeof failure
				? failure
				: function (data) {
						if (modal) {
							modal.close();
						}
						if (data.typeError !== 'certificate.data.not.matched.with.organization') {
							edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
						}
				  };
		var DOCUMENTS_FOR_ANNUL_POST = [
			edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
			edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
		];

		if (!annualDraft && (!annulStatus || annulStatus === edi.constants.STATE.DELETED)) {
			buttons.push({
				text:
					customLabel === null
						? null
						: edi.i18n.getMessage(customLabel ? customLabel : 'document.mark.annul'),
				handler: function () {
					let isAnnulPostUrl = DOCUMENTS_FOR_ANNUL_POST.find(function (documentType) {
						return document.type === documentType;
					});
					let failWithEvent = function (data) {
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
						mainFailure(data);
					};
					const formConfig = options?.maxInputLength
						? {
								maxLength: options.maxInputLength
						  }
						: {
								isTextarea: true,
								rowsHtmlAttributeValue: 4
						  };
					modal = edi.methods.documents.showReasonModal(
						'document.mark.annul',
						function (reasonText) {
							let sendAnnulDoc = function (certificate, poa, poaConfirmChecked) {
								let rest = isAnnulPostUrl
									? edi.rest.services.ANNUL.POST
									: edi.rest.services.DOCUMENTS.ANNUL.POST;
								edi.rest.sendRequest(
									edi.utils.formatString(rest, {
										docId: document.id
									}),
									'POST',
									Ext.encode({ reason: reasonText }),
									function (annulData) {
										let certificateHandler = {
											_selectedCertificate: certificate,
											get: function () {
												return this._selectedCertificate;
											},
											set: function (cert) {
												this._selectedCertificate = cert;
											}
										};
										let poaHandler = {
											_selectedPoa: poa,
											get: function () {
												return this._selectedPoa;
											},
											set: function (poa) {
												this._selectedPoa = poa;
											},
											_poaConfirmChecked: poaConfirmChecked,
											setPoaConfirmCheck: function (poaConfirmChecked) {
												const handler = this;
												handler._poaConfirmChecked = poaConfirmChecked;
											},
											getPoaConfirmCheck: function () {
												const handler = this;
												return handler._poaConfirmChecked;
											}
										};

										edi.utils.sign(
											annulData.data,
											tab,
											function (failed, data) {
												failed ? failWithEvent(data) : mainSuccess(data);
											},
											undefined,
											undefined,
											true,
											certificateHandler,
											{
												signRefuse: () =>
													function () {
														tab?.setLoading(false);
														if (modal && !modal.isDestroyed) {
															modal.close();
														}
													}
											},
											poaHandler
										);
									},
									failWithEvent
								);
							};

							if (annulDoc && annulDoc.beforeAnnul) {
								annulDoc.beforeAnnul(function (certificate, poa, poaConfirmChecked) {
									sendAnnulDoc(certificate, poa, poaConfirmChecked);
								});
							} else {
								sendAnnulDoc();
							}
						},
						formConfig
					);
				}
			});
		}
		var createSignOrAcceptAnnul = function () {
			var sendAnnulDoc = function (certificate, poa) {
				let certificateHandler = {
					_selectedCertificate: certificate,
					get: function () {
						return this._selectedCertificate;
					},
					set: function (cert) {
						this._selectedCertificate = cert;
					}
				};
				let poaHandler = {
					_selectedPoa: poa,
					get: function () {
						return this._selectedPoa;
					},
					set: function (poa) {
						this._selectedPoa = poa;
					}
				};

				edi.utils.sign(
					annulDoc,
					tab,
					function (failed, data) {
						if (failed) {
							mainFailure(data);
						} else {
							mainSuccess(data);
						}
					},
					undefined,
					undefined,
					true,
					certificateHandler,
					{
						signRefuse: () =>
							function () {
								tab?.setLoading(false);
								if (modal && !modal.isDestroyed) {
									modal.close();
								}
							}
					},
					poaHandler
				);
			};

			if (annulDoc && annulDoc.beforeAnnul) {
				annulDoc.beforeAnnul(function (certificate, poa) {
					sendAnnulDoc(certificate, poa);
				});
			} else {
				sendAnnulDoc();
			}
		};
		if (annualWait && annualDirection === edi.constants.DIRECTIONS.INCOMING) {
			buttons.push({
				text: edi.i18n.getMessage('document.mark.annul.accept'),
				handler: createSignOrAcceptAnnul
			});
		}
		if (annualWait && annualDirection === edi.constants.DIRECTIONS.INCOMING) {
			buttons.push({
				text: edi.i18n.getMessage('document.mark.annul.reject'),
				handler: function () {
					edi.document.actions.handlerRejectAnnualDocument(annulDoc, mainSuccess, mainFailure, tab);
				}
			});
		}
		if (annualDraft && annualDirection === edi.constants.DIRECTIONS.OUTGOING) {
			buttons.push({
				text: edi.i18n.getMessage('document.mark.annul.sign'),
				handler: createSignOrAcceptAnnul
			});
		}

		return buttons.length === 1
			? createActionsButton(
					Ext.merge(buttons[0], {
						glyph: edi.constants.ICONS.ANNUL
					})
			  )
			: buttons.length > 1
			? createActionsButton({
					text:
						customLabel === null
							? null
							: edi.i18n.getMessage(customLabel ? customLabel : 'document.mark.annul'),
					glyph: edi.constants.ICONS.ANNUL,
					allowMultiClick: true,
					menu: new Ext.menu.Menu({
						plain: true,
						hideMode: 'display',
						items: buttons
					})
			  })
			: null;
	},
	createClarifyActionButton: function (document, tab, before, success, failure, customLabel, docOptions, properties) {
		properties = 'object' == typeof properties ? properties : {};
		var btnConf = properties.buttonConf || {},
			modalConf = properties.modalConf || {};

		var modal;
		var mainSuccess = function (data) {
			tab.setLoading(false);
			if (modal) {
				modal.close();
			}
			if ('function' == typeof success) {
				success(data, document.id);
			} else {
				edi.events.documents.fireEvent('change', {
					id: document.id
				});
			}
		};
		var mainFailure =
			'function' == typeof failure
				? failure
				: function (data) {
						edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
				  };
		var signUtochFailure =
			'function' == typeof failure
				? failure
				: function (failed, data, selectedCertificate, selectedPoa, silent) {
						if (!silent) {
							edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
						} else {
							tab.setLoading(false);
						}
				  };

		return createActionsButton({
			text: edi.i18n.getMessage(btnConf.title || 'document.mark.clarify'),
			glyph: btnConf.glyph || edi.constants.ICONS.ANNOUNCEMENT,
			handler: function () {
				var DOCUMENTS_FOR_CLARIFY_POST = [
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
				];
				var DOCUMENTS_FOR_SHORT_CLARIFY = [
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
				];
				DOCUMENTS_FOR_SHORT_CLARIFY = DOCUMENTS_FOR_SHORT_CLARIFY.concat(edi.constants.FREE_DOCUMENT_TYPES);
				var isClarifyPostUrl = DOCUMENTS_FOR_CLARIFY_POST.find(function (documentType) {
					return document.type === documentType;
				});
				var isClarifyShot = DOCUMENTS_FOR_SHORT_CLARIFY.find(function (documentType) {
					return document.type === documentType;
				});
				var isDSF = edi.constants.FREE_DOCUMENT_TYPES.find(function (documentType) {
					return document.type === documentType;
				});
				var shortConfig = {
					maxLength: 2000
				};
				var clarifyUrl = isClarifyPostUrl
					? edi.rest.services.UTOCH.POST
					: edi.rest.services.DOCUMENTS.CREATE_UTOCH.POST;
				if (isDSF) {
					clarifyUrl = edi.rest.services.DOCUMENTS.DSF_REJECT.POST;
				}
				modal = edi.methods.documents.showReasonModal(
					modalConf.title || 'document.mark.clarify',
					function (reasonText) {
						edi.rest.sendRequest(
							edi.utils.formatString(clarifyUrl, {
								parentId: document.id
							}),
							'POST',
							Ext.encode({ reason: reasonText }),
							function (responseData) {
								if (responseData && responseData.data) {
									edi.document.actions.signUtochDocument(
										responseData.data,
										document,
										mainSuccess,
										signUtochFailure,
										tab,
										null
									);
								} else {
									mainFailure(responseData);
								}
							},
							mainFailure
						);
					},
					isClarifyShot ? shortConfig : null
				);
			}
		});
	},
	createRenouncementActionButton: function (document, tab, before, success, failure, customLabel, docOptions) {
		var modal,
			mainSuccess = function (data) {
				tab.setLoading(false);
				if (modal) {
					modal.close();
				}
				if ('function' == typeof success) {
					success(data, document.id);
				} else {
					edi.events.documents.fireEvent('change', {
						id: document.id
					});
				}
			};
		var mainFailure =
			'function' == typeof failure
				? failure
				: function (data) {
						edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
				  };
		var signUtochFailure =
			'function' == typeof failure
				? failure
				: function (failed, data, selectedCertificate, selectedPoa, silent) {
						if (!silent) {
							edi.document.actions.defaultFailureHandler(tab, 'document.mark.sign.error.process')(data);
						} else {
							tab.setLoading(false);
						}
				  };
		if (edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.RENOUNCEMENT, docOptions)) {
			return createActionsButton({
				text: edi.i18n.getMessage('document.renouncement'),
				glyph: edi.constants.ICONS.REMOVE_OUTLINE,
				handler: function () {
					modal = edi.methods.documents.showReasonModal(
						'document.renouncement',
						function (reasonText) {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.CREATE_UTOCH.POST, {
									parentId: document.id
								}),
								'POST',
								Ext.encode({
									reason: reasonText,
									RENOUNCEMENT: true
								}),
								function (responseData) {
									if (responseData && responseData.data) {
										edi.document.actions.signUtochDocument(
											responseData.data,
											document,
											mainSuccess,
											signUtochFailure,
											tab,
											null
										);
									} else {
										mainFailure(responseData);
									}
								},
								mainFailure
							);
						},
						null,
						document
					);
				}
			});
		}
	},
	/**
	 * Loads document part fields for organization
	 * @param orgId
	 * @param docType
	 * @param callback
	 */
	getOrgDocumentPartFields: function (orgId, docType, callback) {
		if (docType) {
			if (!orgId) {
				'function' == typeof callback ? callback([]) : null;
			} else {
				var failure = function (responseData) {
					var errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
					edi.core.showWarn(errorMsg, function () {
						callback([]);
					});
				};
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.INFO_FIELDS.GET, {
						docType: docType,
						orgId: orgId
					}),
					'GET',
					null,
					function (data) {
						if (data && data.items) {
							'function' == typeof callback ? callback(data.items) : null;
						} else {
							failure();
						}
					},
					failure
				);
			}
		}
	},
	/**
	 * Если у организации нет почтового адреса, но есть юридический, поля юридического адреса
	 *  копируются в почтовый (нужно для договоров поставки, Непрофиль и Дрогери)
	 * @param org	организация
	 */
	addPostalAddressForOrg: function (org) {
		let addrArray = org.addresses;
		if (addrArray && Array.isArray(addrArray)) {
			let postalAddr = null;
			let legalAddr = null;

			for (let i = 0; i < addrArray.length; ++i) {
				if (addrArray[i].type === edi.constants.ADDRESS_TYPES.LEGAL) {
					legalAddr = addrArray[i];
				} else if (addrArray[i].type === edi.constants.ADDRESS_TYPES.POSTAL) {
					postalAddr = addrArray[i];
				}
			}

			if (!!legalAddr && !postalAddr) {
				postalAddr = Ext.clone(legalAddr);
				postalAddr.type = edi.constants.ADDRESS_TYPES.POSTAL;
				org.addresses.push(postalAddr);
			}
		}
	}
});
