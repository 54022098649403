import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createUploadModalDialog } from '@Core/specialComponents/modals';
import { createTextField } from '@Components/fields';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { showError } from '@UIkit/components/modal/MessageBox';
import { createLabel } from '@UIkit/components/fields';
import { POA_CONST } from './configuration';

const xmlUploadHandler = function (props) {
	let userData = edi.core.getUserData();

	createUploadModalDialog(
		'POWER_OF_ATTORNEY',
		function () {
			if (!edi.realtime.messages.isConnected()) {
				edi.realtime.messages.showConnectionError('document.upload.websocket.error');
			}
			if (typeof props?.onSuccess === 'function') {
				props.onSuccess();
			}
		},
		function (data) {
			if (!data.success) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'power.of.attorney.upload.xml.error'));
			}
		},
		undefined,
		{
			uploadTitle: edi.i18n.getMessage('power.of.attorney.xml.file'),
			modalSaveBtnText: edi.i18n.getMessage('add.power.of.attorney.btn'),
			downloadBtnText: edi.i18n.getMessage('fileDropper.download.files.poa'),
			modalTitle: edi.i18n.getMessage('power.of.attorney.xml.upload'),
			modalSubTitle: edi.i18n.getMessage('power.of.attorney.xml.upload.subtitle'),
			uploadWaitMsg: edi.i18n.getMessage('power.of.attorney.xml.uploading.process'),
			notValidMsg: 'power.of.attorney.invalid.xml',
			allowedFileTypesText: edi.i18n.getMessage('fileDropper.allow.file.types.poa'),
			saveUrl: edi.rest.services.POWER_OF_ATTORNEY.UPLOAD_XML.POST,
			modalCloseButtonOptions: { hidden: true },
			accept: 'text/xml',
			additionalHiddenFields: [
				{
					name: 'org_id_receiver',
					value: userData.org.id
				},
				{
					name: 'byOrgId',
					value: userData.org.id
				},
				{
					name: 'byUserId',
					value: userData.id
				},
				{
					name: 'docType',
					value: edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY
				},
				{
					name: 'fileName',
					value: ''
				}
			]
		}
	);
};

const guidImportHandler = function () {
	let formPanel, createBtn;
	let userData = edi.core.getUserData();

	const uploadButtonHandler = function () {
		let form = formPanel.getForm();
		if (form.isValid()) {
			let values = edi.utils.collectFormValues(form);
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.IMPORT_BY_GUID.POST, {
					poaNumber: values.poaNumber
				}),
				'POST',
				Ext.encode({
					allSystems: true
				}),
				function () {
					modal.close();
				},
				function (data) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'), null, null, {
						okBtnConfig: {
							text: edi.i18n.getMessage('btn.understand'),
							glyph: edi.constants.ICONS.DONE
						},
						title: 'poa.not.loaded'
					});
				},
				null
			);
		}
	};

	let modal = createModalPanel({
		title: edi.i18n.getMessage('power.of.attorney.import.by.guid'),
		width: MODAL_SIZE.widthSmall,
		items: [
			(formPanel = createModalForm({
				submitEmptyText: false,
				items: [
					createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						items: [
							createTextField({
								fieldLabel: edi.i18n.getMessage('power.of.attorney.import.by.guid.label'),
								regex: POA_CONST.NUMBER_VALIDATOR,
								name: 'poaNumber',
								allowBlank: false
							})
						]
					})
				]
			}))
		],
		buttonsBefore: [
			(createBtn = createButton({
				text: edi.i18n.getMessage('add.power.of.attorney.btn'),
				glyph: edi.constants.ICONS.SAVE,
				cls: BUTTON_CLS.primary,
				handler: uploadButtonHandler
			}))
		],
		closeButtonOptions: {
			hidden: true
		}
	});

	formPanel.on('validitychange', function () {
		createBtn.setDisabled(!formPanel.isValid());
	});

	formPanel.isValid();
	modal.show();
};

const getPoaErrorReasonByType = function (errorType, poaNumber) {
	switch (errorType) {
		case '/errors':
		case '/errors/resource_not_found':
			return edi.i18n.getMessage('power.of.attorney.import.error.resource_not_found', { poaNumber });
		case '/errors/unauthenticated':
			return edi.i18n.getMessage('power.of.attorney.import.error.unauthenticated');
		case '/errors/unauthorized':
			return edi.i18n.getMessage('power.of.attorney.import.error.unauthorized');
		case '/errors/poa.issuer_signer_mismatch_with_egrul':
			return edi.i18n.getMessage('power.of.attorney.import.error.poa.issuer_signer_mismatch_with_egrul');
		case '/errors/poa.signature_is_invalid':
			return edi.i18n.getMessage('power.of.attorney.import.error.poa.signature_is_invalid');
		default:
			return edi.i18n.getMessage(errorType) === errorType ? '' : edi.i18n.getMessage(errorType);
	}
};

const onPoAUploadWithError = function (props) {
	const { realtimeMsgData: msgData } = props || {};
	const poaNumber = msgData?.data?.content?.poaNumber;
	const errorType = msgData?.data?.content?.errorMessage?.type || msgData?.data?.content?.TypeError;
	const errorText = getPoaErrorReasonByType(errorType, poaNumber);
	const errorMessage = edi.utils.safeString(
		msgData?.data?.content?.errorMessage?.errorMessage || msgData?.data?.content?.Error,
		true
	);

	const errorWindow = showError({
		title: edi.i18n.getMessage('power.of.attorney.import.error.title'),
		items: [
			createModalForm({
				layout: {
					type: 'grid',
					gap: 8
				},
				items: [
					createLabel({
						text: edi.i18n.getMessage('power.of.attorney.import.error'),
						typography: 'body-short_03'
					}),
					createLabel({
						text: errorText,
						typography: 'body-short_03'
					}),
					createLabel({
						text: errorMessage,
						cls: 'poa-error-details-text'
					})
				]
			})
		],
		okBtnConfig: {
			text: edi.i18n.getMessage('power.of.attorney.reload.btn'),
			handler: function () {
				errorWindow.close();
				xmlUploadHandler();
			}
		}
	});
};

const onGetPoAFromFNSError = function (props) {
	const { realtimeMsgData: msgData } = props || {};
	const poaNumber = msgData.data.content.poaNumber;
	const errorType = msgData?.data?.content?.errorMessage?.type || msgData?.data?.content?.TypeError;
	const errorText = getPoaErrorReasonByType(errorType, poaNumber);
	const errorMessage = edi.utils.safeString(msgData?.data?.content?.errorMessage?.errorMessage, true);

	const errorWindow = showError({
		title: edi.i18n.getMessage('power.of.attorney.import.error.title'),
		items: [
			createModalForm({
				layout: {
					type: 'grid',
					gap: 8
				},
				items: [
					createLabel({
						text: edi.i18n.getMessage('power.of.attorney.import.error.detailed', {
							poaNumber
						}),
						typography: 'body-short_03'
					}),
					createLabel({
						text: errorText,
						typography: 'body-short_03'
					}),
					createLabel({
						text: errorMessage,
						cls: 'poa-error-details-text'
					})
				]
			})
		],
		okBtnConfig: {
			text: edi.i18n.getMessage('power.of.attorney.reload.btn'),
			handler: function () {
				errorWindow.close();
				guidImportHandler();
			}
		}
	});
};

const flattenLinkedPoA = function (items) {
	const result = [];
	const recursiveForEach = function (data) {
		if (Array.isArray(data)) {
			data.forEach((document) => {
				if (edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY === document.type) {
					const childItemKey = 'items';
					result.push(document);
					if (document.hasOwnProperty(childItemKey) && document[childItemKey]) {
						recursiveForEach(document[childItemKey]);
					}
				}
			});
		}
	};
	recursiveForEach(items);
	return result;
};

const getLinkedPoA = function ({ poaId }) {
	return new Promise((resolve, reject) => {
		const pathUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.PATH.GET, {
			documentId: poaId
		});
		const success = function (data) {
			if (data && data.items && data.items.length) {
				const path = data.items.reverse();
				const treeUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.TREE.GET, {
					documentId: path[0],
					root: true,
					depth: edi.constants.DEFAULT.TREE_DEPTH
				});
				const success = function (resp) {
					resolve(resp.items);
				};
				edi.rest.sendRequest(treeUrl, 'GET', null, success);
			}
		};
		edi.rest.sendRequest(pathUrl, 'GET', null, success, reject);
	});
};

export {
	xmlUploadHandler,
	guidImportHandler,
	onPoAUploadWithError,
	onGetPoAFromFNSError,
	getPoaErrorReasonByType,
	getLinkedPoA,
	flattenLinkedPoA
};
