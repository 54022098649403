import { createActionsButton } from '@Components/buttons';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createLoccatFormItems, createProdcatFormItems, showItemDetails } from './methods';
import { getProductCatalogColumns } from './columns';
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createLabelBlockForDetails } from '@UIkit/components/fields';
/**
 * Class for catalog details
 */
class CatalogDetails {
	moduleData;
	objectData;
	grid;
	filterForm;
	filterObject;
	catalogType;
	isPRODCAT;
	isLOCCAT;
	_changeHandler;
	_fireSearch;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me._changeHandler = me.changeHandler.bind(me);
		me._fireSearch = me.fireSearch.bind(me);
		me.moduleData = data;
		me.objectData = me.moduleData.initData.data;
		me.catalogType = me.objectData['type'];
		me.isPRODCAT = me.catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT;
		me.isLOCCAT = me.catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.catalog.on('change', me._changeHandler);
	}

	getFilterUrl() {
		const me = this;
		return edi.utils.formatString(edi.rest.services.CATALOGS.V2.RECORDS.FILTER.GET, {
			type: edi.constants.CATALOG_TYPES[me.catalogType],
			id: me.objectData.id
		});
	}

	fireSearch() {
		const me = this;
		if (!me.filterObject) {
			const url = me.getFilterUrl();

			me.filterObject = edi.filters.createGridFilter(url, me.filterForm, me.grid);
		}
		me.filterObject.filter();
	}

	createMainDataBlock() {
		const me = this;
		const contents = [
			{
				title: edi.i18n.getMessage('documents.column.fromOrg'),
				text: edi.renderers.organization(me.objectData.fromOrg || me.objectData.fromNetwork)
			},
			{
				title: edi.i18n.getMessage('documents.column.toOrg'),
				text: edi.renderers.organization(me.objectData.toOrg || me.objectData.toNetwork)
			},
			{
				title: edi.i18n.getMessage('column.creation.date'),
				text: edi.utils.formatDate(me.objectData.creationDate, edi.constants.DATE_FORMAT.FNS)
			}
		];

		return createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					gap: [8, 16],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents
				})
			]
		});
	}

	createFilterForm() {
		const me = this;
		const { formItemsMap, items } = me.isPRODCAT ? createProdcatFormItems() : createLoccatFormItems();
		return (me.filterForm = createModuleFilterForm(
			{
				formItemsMap,
				items
			},
			me._fireSearch
		));
	}

	/**
	 * Creates details panel
	 * @returns {Object}
	 */
	createLayout() {
		const me = this;

		return createFormForModule({
			cls: 'edi-details-panel',
			region: 'north',
			items: [me.createMainDataBlock()]
		});
	}

	/**
	 * Create catalog lines grid
	 * @return {*|Object}
	 */
	createCatalogGrid() {
		const me = this;
		let columns, model;

		if (me.isPRODCAT) {
			columns = getProductCatalogColumns();
			model = edi.models.getModel('PRODCAT_LINE');
		} else {
			columns = edi.columns.get(me.isLOCCAT ? 'addresscatalog_lines' : 'delcat_lines');
			model = edi.models.getModel(me.isLOCCAT ? 'LOCCAT_LINE' : 'DELCAT_LINE');
		}

		columns.push(
			createActionsColumnConfig({
				width: edi.utils.getActionColumnWidth(1),
				items: [
					{
						glyph: edi.constants.ICONS.DETAILS,
						handler: function (grid, rowIndex) {
							const recordData = grid.getStore().getAt(rowIndex).getData();
							showItemDetails(recordData, me.catalogType);
						}
					}
				]
			})
		);
		return (me.grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model,
				autoLoad: false,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'creationDate',
					direction: 'ASC'
				}
			},
			pagingBarConfig: {
				paggingBarLabel: edi.i18n.getMessage('pagingbar.lines.show.per.page')
			},
			gridConfig: {
				cls: 'tab-grid with-filter',
				columns: columns,
				region: 'center',
				layout: 'fit',
				scrollable: {
					x: 'auto',
					y: 'auto'
				},
				border: 0,
				padding: '5 0 0 0',
				autoScroll: true,
				disableSelection: true,
				listeners: {
					render: me._fireSearch,
					celldblclick: function (view, td, cellIndex, record, tr, rowIndex) {
						const recordData = record.getData();
						showItemDetails(recordData, me.catalogType);
					}
				}
			}
		}));
	}

	/**
	 * Creates action pane
	 */
	createModuleActionsPanel() {
		const me = this;
		const actionsPanel = createActionsPanel();
		const userData = edi.core.getUserData();

		if (
			!me.moduleData.initData.onlyRead &&
			(me.objectData.fromItem.id == userData.org.id ||
				(Ext.isObject(userData.org.retailNetwork) &&
					me.objectData.fromItem.id == userData.org.retailNetwork.id))
		) {
			if (edi.permissions.hasPermission('EDIT_CATALOG_V2')) {
				const moduleTitle =
					edi.i18n.getMessage('catalog.type.' + edi.constants.CATALOG_TYPES[me.catalogType]) +
					' ' +
					me.objectData.id;
				actionsPanel.add(
					edi.document.actions.createEditActionButton(
						'catalog.create',
						me.objectData,
						moduleTitle,
						null,
						me.catalogType + me.objectData.id
					)
				);
			}
			actionsPanel.add(
				createActionsButton({
					text: edi.i18n.getMessage('action.export'),
					glyph: edi.constants.ICONS.FILE_DOWNLOAD,
					handler: function () {
						const catalogs = [
							{
								type: edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT,
								url: edi.rest.services.DOCUMENTS.EXPORT.CATALOGS.PRODCAT
							},
							{
								type: edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT,
								url: edi.rest.services.DOCUMENTS.EXPORT.CATALOGS.LOCCAT
							},
							{
								type: edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT,
								url: edi.rest.services.DOCUMENTS.EXPORT.CATALOGS.DELCAT
							}
						];

						catalogs.forEach(function (catalog) {
							if (catalog.type === edi.utils.getObjectProperty(me.moduleData.initData, 'data.type')) {
								me.moduleData.initData.docExportUri = catalog.url;
							}
						});

						edi.rest.downloadFile(
							edi.utils.formatString(me.moduleData.initData.docExportUri, {
								documentId: edi.utils.getObjectProperty(me.moduleData.initData, 'data.id')
							})
						);
					}
				})
			);
			if (edi.permissions.hasPermission('DELETE_CATALOG')) {
				actionsPanel.add(
					createActionsButton({
						text: edi.i18n.getMessage('document.delete.document'),
						glyph: edi.constants.ICONS.DELETE,
						handler: function () {
							edi.methods.catalog.remove(me.objectData.id, me.catalogType);
						}
					})
				);
			}
		}

		return actionsPanel;
	}

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	changeHandler(eventData) {
		const me = this;
		if (
			eventData &&
			eventData.catalogType == me.catalogType &&
			(eventData.id == me.objectData.id || eventData.groupId == me.objectData.groupId)
		) {
			if (eventData.deleted || eventData.reloadCatalog) {
				edi.modulesHandler.removeModule(me.moduleData);
			} else {
				me.grid.getStore().reload();
			}
		}
	}

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	renderData(initCallBack) {
		const me = this;
		const modulePanel = createDetailsModulePanel({
			layout: 'border',
			items: [
				me.createModuleActionsPanel(),
				me.createLayout(),
				createContainer({
					layout: 'border',
					region: 'center',
					items: [me.createFilterForm(), me.createCatalogGrid()]
				})
			]
		});

		me.moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;
		if (me.filterObject && me.filterObject.searchTimeout) {
			clearTimeout(me.filterObject.searchTimeout);
		}
		edi.events.catalog.un('change', me._changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

class ClientCatalogDetails extends CatalogDetails {}

Ext.namespace('edi.modules');
edi.modules['catalog.details'] = ClientCatalogDetails;

export { CatalogDetails };
