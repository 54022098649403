import { createFile, createHiddenField } from '@Components/fields';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';

/**
 * Creates modal for selecting and upload file
 * @param	{Object}	[params]
 * @returns	{Object}	Ext.window.Window instance
 */
const showUploadReportModal = function (params) {
	params = params || {};

	var modalTitle = params.title || edi.i18n.getMessage('file.create.title');

	var success = function () {
		edi.core.confirm(modalTitle, 'fhp.reports.uploaded.question', function () {
			edi.core.openModule('user.file.storage');
		});
		modal.close();
	};
	var failure = function () {
		edi.core.logMessage('Error uploading ' + params.docType, 'warn');
		edi.core.showError('documents.status.ERROR');
		modal.close();
	};

	var formPanel = createModalForm({
		submitEmptyText: false,
		items: [
			createHiddenField({
				name: 'docType',
				value: params.docType
			}),
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('file.attachment'),
				items: [
					createFile(
						{},
						{
							name: 'attachment',
							allowBlank: false
						}
					)
				]
			})
		]
	});

	var modalConf = {
		title: modalTitle,
		width: MODAL_SIZE.widthSmall,
		items: [formPanel],
		buttonsBefore: [
			createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('fhp.reports.load'),
				handler: function () {
					var form = formPanel.getForm();
					if (form.isValid()) {
						edi.core.submitUploadForm(
							form,
							edi.rest.services.FILE_STORAGE.POST,
							'documents.uploading.report', //waitMsg
							success,
							failure
						);
					} else {
						edi.core.showError('fhp.excel.upload.error.select.file');
					}
				}
			})
		]
	};
	var modal = createModalPanel(modalConf);
	formPanel.isValid();
	modal.show();
};

export { showUploadReportModal };
