import { createTextField } from '@Components/fields';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createPanel } from '@Components/panels';

const PRODUCT_MODEL_CONFIG_NAME = 'PRODUCT';
edi.models.setModels({
	[PRODUCT_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'buyerItemCode',
				mapping: 'lineItem.buyerItemCode',
				type: 'string'
			},
			{
				name: 'discount',
				mapping: 'lineItem.discount',
				type: 'number'
			},
			{
				name: 'ean',
				mapping: 'lineItem.ean',
				type: 'string'
			},
			{
				name: 'itemDescription',
				mapping: 'lineItem.itemDescription',
				type: 'string'
			},
			{
				name: 'itemGroupDescription',
				mapping: 'lineItem.itemGroupDescription',
				type: 'string'
			},
			{
				name: 'itemGroupIdentificationCode',
				mapping: 'lineItem.itemGroupIdentificationCode',
				type: 'string'
			},
			{
				name: 'itemType',
				mapping: 'lineItem.itemType',
				type: 'string'
			},
			{
				name: 'lineNumber',
				mapping: 'lineItem.lineNumber',
				type: 'number'
			},
			{
				name: 'lineType',
				mapping: 'lineItem.lineType',
				type: 'number'
			},
			{
				name: 'packDetails',
				mapping: 'lineItem.packDetails'
				//type: 'object'
			},
			{
				name: 'packDetails.unitPacksize',
				mapping: 'lineItem.packDetails.unitPacksize',
				type: 'string'
			},
			{
				name: 'remarks',
				mapping: 'lineItem.remarks',
				type: 'string'
			},
			{
				name: 'supplierItemCode',
				mapping: 'lineItem.supplierItemCode',
				type: 'string'
			},
			{
				name: 'taxCategoryCode',
				mapping: 'lineItem.taxCategoryCode',
				type: 'string'
			},
			{
				name: 'taxRate',
				mapping: 'lineItem.taxRate',
				type: 'string'
			},
			{
				name: 'unitNetPrice',
				mapping: 'lineItem.unitNetPrice',
				type: 'string'
			},
			{
				name: 'unitNetWeight',
				mapping: 'lineItem.unitNetWeight',
				type: 'string'
			},
			{
				name: 'unitOfMeasure',
				mapping: 'lineItem.unitOfMeasure',
				type: 'string'
			},
			{
				name: 'unitPacksize',
				mapping: 'lineItem.unitPacksize',
				type: 'string',
				convert: function (value, record) {
					var data = record.getData();
					return data.packDetails && data.packDetails.unitPacksize ? data.packDetails.unitPacksize : '';
				}
			}
		],
		idProperty: 'lineNumber'
	}
});
const getProductModelName = () => edi.models.getModel(PRODUCT_MODEL_CONFIG_NAME);

const PRODUCT_CONVERTER_CONFIG_NAME = 'PRODUCT_TO_LEGACY_PRODUCT';
/**
 * Edi converters methods
 * @author Anatoly Deryshev
 */
edi.converters.setMap({
	[PRODUCT_CONVERTER_CONFIG_NAME]: {
		BuyerItemCode: 'buyerItemCode',
		Discount: 'discount',
		EAN: 'ean',
		ItemDescription: 'itemDescription',
		ItemGroupDescription: 'itemGroupDescription',
		ItemGroupIdentificationCode: 'itemGroupIdentificationCode',
		ItemType: 'itemType',
		LineNumber: 'lineNumber',
		LineType: 'lineType',
		PackDetails: 'packDetails',
		Remarks: 'remarks',
		SupplierItemCode: 'supplierItemCode',
		TaxCategoryCode: 'taxCategoryCode',
		TaxRate: 'taxRate',
		UnitNetPrice: 'unitNetPrice',
		UnitNetWeight: 'unitNetWeight',
		UnitOfMeasure: 'unitOfMeasure'
	}
});

/**
 * Creates modal product selection dialog
 * @param	{Object}	parent		parent component, that will show loading mask (not used =\)
 * @param	{Object}	orgsData	organization ids for catalog reading
 * @param	{Function}	[callback]	function that will be called on grid selection
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalProductSelect = function (parent, orgsData, callback) {
	var isFirstLoad = true;
	if (!orgsData.toOrg) {
		orgsData.toOrg = orgsData.fromOrg;
	}
	var url = edi.utils.formatString(
		edi.rest.services.CATALOGS.V2.RECORDS.GET,
		{
			type: edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT],
			fromOrgId: orgsData.fromOrg,
			toOrgId: orgsData.toOrg
		},
		true
	);
	const formItemsMap = {
		ean: createTextField({
			fieldLabel: edi.i18n.getMessage('line.item.ean'),
			name: 'ean'
		}),
		itemDescription: createTextField({
			fieldLabel: edi.i18n.getMessage('line.item.item.description'),
			name: 'itemDescription'
		}),
		buyerItemCode: createTextField({
			fieldLabel: edi.i18n.getMessage('line.item.buyer.item.code'),
			name: 'buyerItemCode'
		}),
		supplierItemCode: createTextField({
			fieldLabel: edi.i18n.getMessage('line.item.supplier.item.code'),
			name: 'supplierItemCode'
		})
	};
	var modal = createModalRemoteSelect(url, undefined, {
		storeConfig: {
			model: getProductModelName(),
			sortOnLoad: true,
			sorters: {
				property: 'lineNumber',
				direction: 'ASC'
			},
			listeners: {
				load: function (store) {
					if (isFirstLoad && !store.getCount()) {
						setTimeout(
							() =>
								edi.core.showInfo(edi.i18n.getMessage('info.no.products'), function () {
									modal.close();
								}),
							100
						);
					}
					isFirstLoad = false;
				}
			},
			autoLoad: false
		},
		gridConfig: {
			columns: edi.columns.get('product'),
			listeners: {
				select: function (comp, record) {
					'function' == typeof callback
						? callback(edi.converters.convert(record.getData(), 'PRODUCT_TO_LEGACY_PRODUCT'))
						: null;
					modal.close();
				}
			}
		},
		hideSaveButton: true,
		createFilterFormItems: [
			createPanel({
				layout: {
					type: 'grid',
					area: [
						[6, 6],
						[6, 6]
					]
				},
				items: [
					formItemsMap.ean,
					formItemsMap.itemDescription,
					formItemsMap.buyerItemCode,
					formItemsMap.supplierItemCode
				]
			})
		],
		createFormItemsMap: formItemsMap,
		createArgs: edi.filters.config.generic.createArgs,
		cls: 'edi-modal-catalog-data-selector edi-modal-product-catalog-data-selector',
		title: edi.i18n.getMessage('document.product.select.title'),
		width: 1600,
		height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER
	});
	return modal;
};

export { createModalProductSelect, getProductModelName, PRODUCT_MODEL_CONFIG_NAME, PRODUCT_CONVERTER_CONFIG_NAME };
