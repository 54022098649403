/**
 * @author Vsevolod Liapin
 * Methods for data renderers
 */

import { DOCS_WITH_BUSINESS_STATE } from '@Edi/configuration';

Object.assign(edi.renderers.utils, {
	/**
	 * The array with methods changes the key suffixes to the status translation
	 * Key format for the translation file:
	 * "documents.status.{retValue}[.direction|.doctype][.addition]"
	 * styleSfx - suffix for css class to set color status
	 */
	modifyStateTitleKeys: [
		function (getData, direction, doctype, attributes, modifyValues) {
			var apperacCode = edi.utils.getAttributeByName(attributes, 'APERAK_CODE');
			if (apperacCode && apperacCode != edi.constants.APERAK_CODES.CODE_10) {
				modifyValues.retValue = 'APERAK_CODE_' + apperacCode;
				if (apperacCode == edi.constants.APERAK_CODES.CODE_27) {
					modifyValues.retValue = edi.constants.STATE.REJECTED;
				}
			}
			var cancelReason = edi.utils.getAttributeByName(attributes, 'CANCEL_REASON');
			if (cancelReason) {
				modifyValues.retValue = 'CANCEL_REASON';
			}
		},
		function (getData, direction, doctype, attributes, modifyValues) {
			var documentNameCode = edi.utils.getAttributeByName(attributes, 'DocumentNameCode');
			var orderNameCode = edi.utils.getAttributeByName(attributes, 'OrderNameCode');
			var orderCorrection = edi.utils.getAttributeByName(attributes, 'OrderCorrection');
			var orderRespCode = edi.utils.getAttributeByName(attributes, 'OrderRespCode');
			var aperakCode = edi.utils.getAttributeByName(attributes, 'AperakCode');
			if (
				(documentNameCode != 222 && orderNameCode != 222) ||
				edi.constants.STATE.DRAFT == modifyValues.retValue ||
				edi.constants.STATE.DELETED == modifyValues.retValue
			) {
				return;
			}
			modifyValues.retValue = 'DOCUMENT_NAME_CODE_222';
			modifyValues.styleSfx = 'OTHER_';
			var isOrder = doctype === edi.constants.DOCUMENT_TYPES.LEGACY_ORDER;
			if (isOrder && orderCorrection) {
				modifyValues.addition = 'CORRECTED';
			} else {
				var respCodePrefix = isOrder && orderRespCode ? 'RESPCODE_' : '';
				modifyValues.addition = respCodePrefix + 'APERAK_CODE_' + (aperakCode || '');
				if (aperakCode != 12 || (aperakCode == 12 && orderNameCode == 222)) {
					modifyValues.styleSfx = 'APERAK_CODE_' + (aperakCode || '') + '_';
				}
			}
		},
		function (getData, direction, doctype, attributes, modifyValues) {
			var isWarning = edi.utils.getAttributeByName(attributes, 'isWarning');
			if (isWarning === true || isWarning === 'true') {
				if (
					edi.constants.STATE.SENT == modifyValues.retValue ||
					edi.constants.STATE.READ == modifyValues.retValue ||
					edi.constants.STATE.COMPLETED == modifyValues.retValue
				) {
					modifyValues.styleSfx = 'WARNING_';
				}
			}
		},
		function (getData, direction, doctype, attributes, modifyValues) {
			var orderNameCode = edi.utils.getAttributeByName(attributes, 'OrderNameCode');
			// для кода 222 условия особые, не перекрываем их
			if (orderNameCode != 222) {
				var aperakCode = edi.utils.getAttributeByName(attributes, 'APERAK_CODE')
					? parseInt(edi.utils.getAttributeByName(attributes, 'APERAK_CODE'), 10)
					: null;
				if (
					aperakCode &&
					edi.constants.APERAK_CODE_FOR_STATUS.some((it) => it === aperakCode) &&
					edi.constants.DOCUMENT_TYPES_FOR_APERAK_STATUS.some((it) => it === doctype)
				) {
					modifyValues.retValue = 'APERAK_CODE';
					modifyValues.styleSfx = 'APERAK_STATUS_' + aperakCode + '_';
					modifyValues.addition = 'APERAK_CODE_' + aperakCode;
				}
			}
		},
		//OVERRIDE Ediweb begin
		function (getData, direction, doctype, attributes, modifyValues) {
			if (edi.constants.DOCUMENT_TYPES.EDI_USER_CERTIFICATE === doctype) {
				modifyValues.addition = 'CERTIFICATE';
			}
		}
		//OVERRIDE Ediweb end
	]
});

Object.assign(edi.renderers, {
	documentFunctionCode: function (value) {
		if ('O' == value) {
			value = 9;
		}
		return value ? edi.i18n.getMessage('document.function.code.' + value) : '';
	},
	taxCategoryCode: function (value) {
		var res = edi.i18n.getMessage('tax.category.code.' + value);
		if (res == 'tax.category.code.' + value) {
			res = value;
		}
		return res;
	},
	itemType: function (value) {
		var store = edi.stores.initItemTypeStore();
		var res = store.findRecord('id', value, 0, false, false, true);
		if (res) {
			res = res.get('name');
		}
		return res || value;
	},
	UnitOfMeasure: function (value) {
		return edi.utils.getUnitOfMeasureName(value);
	},
	transactionType: function (value) {
		var res = edi.i18n.getMessage('transaction.type.' + value);
		if (res == 'transaction.type.' + value) {
			res = value;
		}
		return res;
	},
	getValueFromStore: function (storeMethod, value, targetField) {
		var res = null;
		targetField = targetField || 'name';
		if ('function' == typeof storeMethod) {
			var store = storeMethod();
			store.load();
			res = store.findRecord('id', value, 0, false, false, true);
			if (res) {
				res = res.get(targetField);
			}
		}
		return res || value;
	},
	modeOfTransport: function (value) {
		var modeOfTransport = edi.stores.initModeOfTransportStore();
		var res = modeOfTransport.findRecord('id', value, 0, false, false, true);
		if (res) {
			res = res.get('name');
		}
		return res || value;
	},
	currency: function (value) {
		var store = edi.stores.initCurrencyStore();
		var res = store.findRecord('id', value, 0, false, false, true);
		if (res) {
			res = res.get('name');
		}
		return res || value;
	},
	okei: function (value) {
		var okei = edi.stores.initOkeiStore();
		var res = okei.findRecord('id', value, 0, false, false, true);
		if (res) {
			res = res.get('name');
		}
		return res || value;
	},
	kc2total: function (value, column, record) {
		var amount = parseFloat(record.get('quantity')) || 0;
		var price = parseFloat(record.get('unitPrice')) || 0;
		return edi.utils.displayCurrencyDecimals(edi.utils.roundTo(amount * price, edi.core.getPrecisionNumber()));
	},
	kc2category: function (value) {
		value = edi.i18n.getMessage('category.' + String(value).toLowerCase().replace('_', '.'));
		return value;
	},
	contractNumberAndValidity: function (contract) {
		var value = '';
		if (contract && contract.number) {
			value = contract.number;
			value +=
				' (' +
				edi.renderers.dateFromMs(contract.validFrom) +
				'-' +
				(contract.perpetual ? '...' : edi.renderers.dateFromMs(contract.validTo)) +
				')';
			value = edi.utils.safeString(value, true, true);
		}
		return value;
	},
	invitationStatus: function (value) {
		var status = String(value);
		if (status == edi.constants.STATE.DRAFT) {
			status = String(edi.constants.STATE.AWAITING_ACCEPT);
		}
		return Ext.String.format('<span class="status-{0}">{1}</span>', status, edi.i18n.getTr(['STATE', status]));
	},
	status: function (value, meta, record) {
		var getData = function (name, isNull) {
			var returnValue = isNull ? null : {};
			if (record && record.get(name)) {
				returnValue = record.get(name);
			} else if (value && 'object' == typeof value && value[name]) {
				returnValue = value[name];
			}
			return returnValue;
		};
		var stateMap = edi.i18n.trMap.STATE;
		const doctype = getData('type');
		const isDocWithBusinessState =
			DOCS_WITH_BUSINESS_STATE?.some((businessDocType) => businessDocType === doctype) ||
			edi.renderers.utils.isFakePoa(record?.getData ? record.getData() : value);
		const businessStateMap = edi.i18n.trMap.BUSINESS_STATE;
		var fromOrg = getData('fromOrg');
		var toOrg = getData('toOrg');
		var factor = getData('factor', true);
		var direction = edi.utils.getDocumentDirection(toOrg, fromOrg, factor);
		var values = {
			retValue: value && 'object' == typeof value && value.state ? value.state : value || '',
			businessState: value && 'object' == typeof value ? value.businessState : value,
			addition: '',
			styleSfx: ''
		};
		if (!values.retValue === null) {
			values.retValue = 'NULL';
		}
		var attributes = record
			? record.get('attributes')
			: value?.hasOwnProperty('attributes')
			? value.attributes
			: {};

		//Модифицируем статусы только в документах НЕ переведенных на businessState
		if (!isDocWithBusinessState) {
			for (var i = 0; i < edi.renderers.utils.modifyStateTitleKeys.length; i++) {
				if ('function' == typeof edi.renderers.utils.modifyStateTitleKeys[i]) {
					edi.renderers.utils.modifyStateTitleKeys[i](getData, direction, doctype, attributes, values);
				}
			}
		}

		var msgSuffixes = [direction, doctype, direction + '.' + doctype],
			msgKey,
			msg;
		if (isDocWithBusinessState && values.businessState && businessStateMap) {
			const path = [values.businessState, direction, doctype];
			if (values.addition) {
				path.push(values.addition);
			}
			msgKey = edi.i18n.findKey(businessStateMap, path);
			if (msgKey) {
				msg = edi.i18n.getMessage(msgKey);
			}
		} else if (stateMap && stateMap[values.retValue]) {
			var path = [values.retValue, direction, doctype];
			if (values.addition) {
				path.push(values.addition);
			}
			msgKey = edi.i18n.findKey(stateMap, path);
			if (msgKey) {
				msg = edi.i18n.getMessage(msgKey);
			}
		}
		const resultState = isDocWithBusinessState ? values.businessState : values.retValue;
		if (!msg) {
			// If no map value found, use old logic for search
			do {
				var msgKeyWords = ['documents.status.' + resultState];
				if (msgSuffixes.length) {
					msgKeyWords.push(msgSuffixes[msgSuffixes.length - 1]);
				}
				if (values.addition) {
					msgKeyWords.push(values.addition);
				}
				msgKey = msgKeyWords.join('.');
				msg = edi.i18n.getMessage(msgKey);
			} while (msgSuffixes.pop() && msg == msgKey);
		}
		return resultState ? edi.renderers.baseStateRenderer(values.styleSfx + resultState, msg, meta) : '';
	},
	columnStatus: function (value, metaData, record) {
		const recAttr = record.getData().attributes || {};
		const { businessState, type } = record.getData() || {};
		const utochCreated = edi.utils.getAttributeByName(recAttr, 'utochCreated') === 'true',
			totalCode = edi.utils.getAttributeByName(recAttr, 'КодИтога'),
			docTimePart2 = edi.utils.getAttributeByName(recAttr, 'docTimePart2'),
			docTimeUtoch = edi.utils.getAttributeByName(recAttr, 'docTimeUtoch'),
			status = {};
		let stateHTML;

		const convertDate = function (date) {
			const splitDate = date.split(' ').join('.').split('.');
			return new Date(
				splitDate[2],
				splitDate[1] - 1,
				splitDate[0],
				splitDate[3],
				splitDate[4],
				splitDate[5]
			).getTime();
		};

		const isDocWithBusinessState =
			DOCS_WITH_BUSINESS_STATE?.some((businessDocType) => businessDocType === type) ||
			edi.renderers.utils.isFakePoa(record.getData());
		if (isDocWithBusinessState && businessState) {
			stateHTML = edi.renderers.status(record.getData(), metaData, record);
		} else if (!utochCreated && value === edi.constants.STATE.COMPLETED && (totalCode == 3 || totalCode == 2)) {
			stateHTML = edi.renderers.status(edi.constants.STATE.COMPLETED + '_' + totalCode, metaData, record);
		} else if (
			!utochCreated &&
			value === edi.constants.STATE.SIGNED_BY_CONSUMER &&
			(totalCode == 3 || totalCode == 2)
		) {
			status.state = edi.constants.STATE.SIGNED_BY_CONSUMER + '_short_' + totalCode;
			stateHTML = edi.renderers.status(status, metaData, record);
		} else if (
			utochCreated &&
			value === edi.constants.STATE.COMPLETED &&
			docTimePart2 &&
			convertDate(docTimeUtoch) < convertDate(docTimePart2) &&
			(totalCode == 3 || totalCode == 2)
		) {
			stateHTML = edi.renderers.status(edi.constants.STATE.COMPLETED + '_' + totalCode, metaData, record);
		} else if (
			utochCreated &&
			value === edi.constants.STATE.SIGNED_BY_CONSUMER &&
			docTimePart2 &&
			convertDate(docTimeUtoch) < convertDate(docTimePart2) &&
			(totalCode == 3 || totalCode == 2)
		) {
			status.state = edi.constants.STATE.SIGNED_BY_CONSUMER + '_short_' + totalCode;
			stateHTML = edi.renderers.status(status, metaData, record);
		} else {
			stateHTML = edi.renderers.status(value, metaData, record);
		}
		return stateHTML;
	},
	stateWithTooltip: function (value, meta) {
		var state = edi.i18n.getTr(['STATE', value]);
		meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(state) + '"';
		return edi.renderers.baseStateRenderer(value, state, meta);
	},
	infoStateRenderer: function (text) {
		return edi.utils.formatString('<span>{0}</span>', [text]);
	},
	baseStateRenderer: function (state, text, meta) {
		if (meta && meta.hasOwnProperty('tdCls')) {
			meta.tdCls += ' edi-state-cell';
		}
		return edi.utils.formatString(
			'<div class="edi-state-container edi-state-container-{0}"><span class="status-{0}">{1}</span></div>',
			[state, text]
		);
	},
	orgNameWithInnFromInn: function (value, meta) {
		var org,
			result = value || '';
		if (value) {
			org = edi.utils.getOrg({
				orgINN: value
			});
			if (org) {
				result = org.name + ' ' + org.inn;
				meta.tdAttr = 'data-qtip="' + edi.utils.replaceQuotes(String(result)) + '"';
			}
		}
		return result;
	},
	dateTimeNoS: function (value) {
		if (value) {
			value = edi.utils.formatDate(parseFloat(value), edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S);
		}
		return value || '';
	},
	invitationDirection: function (value, column, record) {
		var acceptor = record.get('acceptor');
		var initiator = record.get('initiator');
		var self = edi.core.getUserData().org;

		if (self.id === initiator.id) {
			value = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'outgoing',
				edi.i18n.getMessage('documents.direction.outgoing')
			);
		} else if (self.id === acceptor.id) {
			value = Ext.String.format(
				'<span class="dir-{0}" title="{1}">{1}</span>',
				'incoming',
				edi.i18n.getMessage('documents.direction.incoming')
			);
		}
		return value;
	},
	decimal: function (value) {
		return String(edi.utils.roundTo(value, edi.core.getPrecisionNumber()) || 0);
	},
	decimalWithNull: function (value) {
		return !value && value !== 0 ? value : edi.renderers.decimal(value);
	},
	cost: function (value) {
		return edi.renderers.currencyWithSeparators(edi.utils.displayCurrencyDecimals(value ? value.Cost || 0 : 0));
	},
	taxRate: function (value) {
		return value.Rate;
	},
	taxRateString: function (rate) {
		var res = rate;
		if (edi.constants.TAX_RATES && edi.constants.TAX_RATES.NAMES.hasOwnProperty(rate)) {
			res = edi.constants.TAX_RATES.NAMES[rate];
		}
		return res;
	},
	linePartyType: function (value) {
		return edi.i18n.getMessage('party.type.' + value);
	},
	displayCurrencyDecimalsZeroAsEmpty: function (value) {
		if (Ext.isNumeric(value)) {
			value = edi.utils.roundTo(value, edi.core.getPrecisionNumber());
		}
		return value ? edi.renderers.displayCurrencyDecimals(value) : '';
	},
	desadvLineEAN: function (value, meta, productRecord) {
		var res = value;
		if (productRecord) {
			var isModelInstance = 'function' == typeof productRecord.get,
				idBegin = isModelInstance ? productRecord.get('ID-Begin') : productRecord['ID-Begin'],
				packingName = isModelInstance
					? productRecord.get('PackagingUnitName')
					: productRecord.PackagingUnitName,
				recordType = isModelInstance ? productRecord.get('Type') : productRecord.Type,
				isPallet = recordType && recordType == edi.constants.PACKAGE_TYPES.PALLET,
				isOtherPacking = recordType && recordType == edi.constants.PACKAGE_TYPES.OTHER;

			if (isPallet) {
				res = edi.i18n.getMessage('desadv.packing.type.BJ') + idBegin;
			} else if (isOtherPacking) {
				res = (packingName || edi.i18n.getMessage('desadv.packing.type.NUL.short')) + ' ' + idBegin;
			}
		}
		return res;
	},
	desadvLineDescription: function (value, meta, productRecord) {
		var res = value;
		if (productRecord) {
			var isModelInstance = 'function' == typeof productRecord.get,
				packingName = isModelInstance
					? productRecord.get('PackagingUnitName')
					: productRecord.PackagingUnitName,
				recordType = isModelInstance ? productRecord.get('Type') : productRecord.Type,
				isPallet = recordType && recordType == edi.constants.PACKAGE_TYPES.PALLET,
				isOtherPacking = recordType && recordType == edi.constants.PACKAGE_TYPES.OTHER;

			if (isPallet) {
				res = edi.i18n.getMessage('desadv.packing.type.BJ');
			} else if (isOtherPacking) {
				res = packingName || edi.i18n.getMessage('desadv.packing.type.NUL');
			}
		}
		return res;
	},
	delfor: {
		calendarCode: function (value) {
			var calendarStore = edi.stores.initDelforCalendarCodeStore(),
				code = calendarStore.findRecord('Delshchedulecode', value, 0, false, true, true);
			return code ? code.get('Delshchedulename') : value;
		}
	},
	errorMessage: function (value) {
		const errorMessage = edi.utils.getAttributeByName(value, 'errorMessage', null);
		const blockingMessage = edi.utils.getAttributeByName(value, 'blockingMessage', null);

		if (blockingMessage === 'BlockedByActionWithDoc') {
			return edi.i18n.getMessage('document.blockingMessage.BlockedByActionWithDoc');
		}

		return errorMessage || blockingMessage;
	},
	poaPrincipals: function (value, meta) {
		let result = '';

		if (Array.isArray(value)) {
			let fioList = value.map(function (elem) {
				let lastName = elem.surname || '';

				let firstName = (elem.name || '').substring(0, 1);
				firstName = firstName ? firstName + '.' : '';

				let patronymic = (elem.middleName || '').substring(0, 1);
				patronymic = patronymic ? patronymic + '.' : '';

				return `${lastName} ${firstName} ${patronymic}`;
			});

			result = fioList.filter((str) => !!str.trim()).join(', ');
		}

		meta.tdAttr = 'data-qtip="' + result + '"';
		return result;
	},
	poaConfidants: function (value, meta) {
		let result = '';

		if (Array.isArray(value)) {
			let fioList = value.map(function (elem) {
				elem = elem || {};
				let lastName = elem.surname || '';

				let firstName = (elem.name || '').substring(0, 1);
				firstName = firstName ? firstName + '.' : '';

				let patronymic = (elem.middleName || '').substring(0, 1);
				patronymic = patronymic ? patronymic + '.' : '';

				return `${lastName} ${firstName} ${patronymic}`;
			});

			result = fioList.filter((str) => !!str.trim()).join(', ');
		}

		meta.tdAttr = 'data-qtip="' + result + '"';
		return result;
	}
});
