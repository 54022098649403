const LOG_MODEL_CONFIG_NAME = 'LOG';

edi.models.setModels({
	[LOG_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'eventType',
				type: 'string'
			},
			{
				name: 'objectType',
				type: 'string'
			},
			{
				name: 'objectId',
				type: 'number'
			},
			{
				name: 'user'
				//type: 'object'
			},
			{
				name: 'user.lastName',
				type: 'string',
				convert: function (value, record) {
					return edi.renderers.user(record.get('user'));
				}
			},
			{
				name: 'actionType'
				//type: 'object'
			},
			{
				name: 'action',
				type: 'string'
			},
			{
				name: 'docNumber',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'docType',
				type: 'string'
			},
			{
				name: 'userLogin',
				type: 'string'
			},
			{
				name: 'additionalInfo',
				type: 'string'
			},
			{
				name: 'actionType.action',
				type: 'string',
				convert: function (value, record) {
					var data = record.get('actionType') || {};
					return edi.utils.safeString(data.description || data.name);
				}
			},
			{
				name: 'org'
				//type: 'object'
			},
			{
				name: 'org.name',
				type: 'string',
				convert: function (value, record) {
					return edi.renderers.organization(record.get('org'), {});
				}
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				type: 'number'
			}
		]
	}
});

let getLogModelName = () => edi.models.getModel(LOG_MODEL_CONFIG_NAME);

export { getLogModelName, LOG_MODEL_CONFIG_NAME };
