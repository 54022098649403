export const partinRenderers = {
	address: (value, meta, record) => {
		const {
			country: countryCode,
			state,
			postalCode,
			district,
			cityName,
			locality,
			streetAndNumber,
			houseNumber,
			housing,
			roomNumber
		} = record?.getData() ?? {};
		const country = edi.utils.getCountryFULLByISO(countryCode);
		const address = [];
		if (streetAndNumber) address.push(`${edi.i18n.getMessage('address.street')} ${streetAndNumber}`);
		if (houseNumber) address.push(houseNumber);
		if (housing) address.push(`${edi.i18n.getMessage('housing.short')} ${housing}`);
		if (roomNumber) address.push(roomNumber);
		if (cityName) address.push(`${edi.i18n.getMessage('address.city')} ${cityName}`);
		if (district) address.push(district);
		if (state) address.push(state);
		if (country) address.push(country.name);
		return address.join(', ');
	}
};
