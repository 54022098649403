/**
 * Models configuration
 * @author Vsevolod Liapin
 */

Ext.namespace('edi.models');

edi.models.setModels({
	CARGO_INFO_BY_ACCOMPANYING_DOCUMENTS: {
		fields: [
			{
				name: 'nameCargo',
				type: 'string'
			},
			{
				name: 'spaceCount',
				type: 'string'
			},
			{
				name: 'productOkei',
				type: 'string'
			},
			{
				name: 'unitOfMeasure',
				type: 'string'
			},
			{
				name: 'packType',
				type: 'string'
			},
			{
				name: 'sealMark',
				type: 'string'
			},
			{
				name: 'weightCargoStart'
				//type: 'object',
			},
			{
				name: 'weightCargoActual'
				//type: 'object',
			},
			{
				name: 'textInfo'
				//type: 'object',
			}
		]
	},
	TORG2_LINE: {
		idProperty: 'fake_id',
		fields: [
			{
				name: 'fake_id',
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'discrepancyInfo',
				type: 'string'
			},
			{
				name: 'values'
				//type: 'object'
			},
			{
				name: 'unitOfMeasure',
				type: 'string'
			},
			{
				name: 'productOkei',
				type: 'string'
			},
			{
				name: 'actualInfo'
				//type: 'object'
			},
			{
				name: 'senderInfo'
				//type: 'object'
			},
			{
				name: 'breakage'
				//type: 'object'
			},
			{
				name: 'defect'
				//type: 'object'
			},
			{
				name: 'shortage'
				//type: 'object'
			},
			{
				name: 'surplus'
				//type: 'object'
			},
			{
				name: 'costDeviation'
				//type: 'object'
			},
			{
				name: 'info'
				//type: 'object'
			}
		]
	},
	FNS_UKD_LINE: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'lineNumInvoice',
				type: 'number'
			},
			{
				name: 'ProductName',
				type: 'string'
			},
			{
				name: 'Info',
				type: 'string'
			},
			{
				name: 'Count',
				type: 'string'
			},
			{
				name: 'Cost',
				type: 'string'
			},
			{
				name: 'ProductOkei',
				type: 'string'
			},
			{
				name: 'SvTD'
				//type: 'object'
			},
			{
				name: 'CostWithoutNDS',
				type: 'string'
			},
			{
				name: 'CostWithNDS',
				type: 'string'
			},
			{
				name: 'InfPolStr'
				//type: 'object'
			},
			{
				name: 'State',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'ExciseCost',
				type: 'string'
			},
			{
				name: 'NDSCost',
				type: 'number'
			},
			{
				name: 'corAccDebet',
				type: 'string'
			},
			{
				name: 'corAccCredit',
				type: 'string'
			},
			{
				name: 'productDesc',
				type: 'string'
			},
			{
				name: 'productSort',
				type: 'string'
			},
			{
				name: 'productReference',
				type: 'string'
			},
			{
				name: 'productCode',
				type: 'string'
			},
			{
				name: 'productTypeCode',
				type: 'string'
			},
			{
				name: 'codeCountryOfOrigin',
				type: 'string'
			},
			{
				name: 'countryOfOrigin',
				type: 'string'
			},
			{
				name: 'InfPolStr'
				//type: 'array'
			},
			{
				name: 'infoTracking'
				//type: 'array'
			},
			{
				name: 'identifyProductToolNumbersBefore'
				//type: 'array'
			},
			{
				name: 'identifyProductToolNumbersAfter'
				//type: 'array'
			},
			{
				name: 'defСostBeforeEdit',
				type: 'string'
			},
			{
				name: 'fieldInfo'
				//type: 'object'
			}
		]
	},
	FNS_UKD_PRODUCT: {
		extend: 'edi.models.FNS_UKD_LINE',
		fields: [
			{
				name: 'lineNumber',
				type: 'number'
			},
			{
				name: 'lineNum',
				type: 'number'
			},
			{
				name: 'lineNumInvoice',
				type: 'number'
			},
			{
				name: 'OriginalData'
				//type: 'object'
			},
			{
				name: 'NewData'
				//type: 'object'
			},
			{
				name: 'Increase'
				//type: 'object'
			},
			{
				name: 'Decrease'
				//type: 'object'
			}
		]
	},
	FNS_UPD_LINE: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'lineNumber',
				type: 'number'
			},
			{
				name: 'productName',
				type: 'string'
			},
			{
				name: 'count',
				type: 'string'
			},
			{
				name: 'cost',
				type: 'string'
			},
			{
				name: 'priceWithNDS',
				type: 'string'
			},
			{
				name: 'productOkei',
				type: 'string'
			},
			{
				name: 'tax',
				type: 'string'
			},
			{
				name: 'costNDS'
				//type: 'object'
			},
			{
				name: 'excise'
				//type: 'object'
			},
			{
				name: 'taxSum'
				//type: 'object'
			},
			{
				name: 'costWithoutNDS',
				type: 'string'
			},
			{
				name: 'info',
				type: 'string'
			},
			{
				name: 'additionalProductInfo'
				//type: 'object'
			},
			{
				name: 'textInfos'
				//type: 'array'
			},
			{
				name: 'customsDeclarationInfo'
				//type: 'array'
			},
			{
				name: 'fieldInfo'
				//type: 'object'
			}
		],
		idProperty: 'lineNumber'
	},
	FNS_UKD_SUMMARY: {
		fields: [
			{
				name: 'State',
				type: 'string'
			},
			{
				name: 'TotalWithoutNDS',
				type: 'float',
				defaultValue: 0
			},
			{
				name: 'MustPaid',
				type: 'float',
				defaultValue: 0
			},
			{
				name: 'TotalWithNDS',
				type: 'float',
				defaultValue: 0
			}
		]
	},
	FNS_UPD_SUMMARY: {
		fields: [
			{
				name: 'totalWithoutNDS',
				type: 'string'
			},
			{
				name: 'totalNDS'
				//type: 'object'
			},
			{
				name: 'totalWithNDS',
				type: 'float',
				defaultValue: 0
			}
		]
	},
	FNS_UPD_SERVICE_AKT_LINE: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'lineNumber',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'period',
				type: 'string'
			},
			{
				name: 'currency',
				type: 'string'
			},
			{
				name: 'planingSum',
				type: 'string'
			},
			{
				name: 'factSum',
				type: 'string'
			},
			{
				name: 'sumWithoutNDS',
				type: 'string'
			},
			{
				name: 'bonusAmountTotal',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	FNS_UPD_SERVICE_AKT_SUMMARY: {
		fields: [
			{
				name: 'totalPay',
				type: 'string'
			}
		]
	},
	FNS_UKD_IDENTIFY_PRODUCT_TOOL: {
		fields: [
			{
				name: 'type'
				//type: 'text'
			},
			{
				name: 'value'
				//type: 'text'
			}
		]
	},
	UPD_DECLARATION_INFORMATION: {
		fields: [
			{
				name: 'countryOrigin',
				type: 'string'
			},
			{
				name: 'number',
				type: 'string'
			}
		]
	},
	UPD_PRODUCT_TEXT_INFO: {
		fields: [
			{
				name: 'identifier',
				type: 'string'
			},
			{
				name: 'value',
				type: 'string'
			},
			{
				name: 'allowEmptyValue',
				type: 'boolean'
			},
			{
				name: 'infoFieldTooltip',
				type: 'string'
			},
			{
				name: 'valueSrc',
				type: 'string'
			}
		]
	},
	UPD_PRODUCT_INFO_TRACKING: {
		fields: [
			{
				name: 'trackingProdNum',
				type: 'string'
			},
			{
				name: 'trackingUnitMes',
				type: 'string'
			},
			{
				name: 'trackingUnitMesName',
				type: 'string'
			},
			{
				name: 'trackingProdQuan',
				type: 'string'
			},
			{
				name: 'trackingProdQuanDiff'
				//type: 'object'
			},
			{
				name: 'trackingAdd',
				type: 'string'
			}
		]
	},
	UKD_PRODUCT_INFO_TRACKING: {
		fields: [
			{
				name: 'trackingProdNum',
				type: 'string'
			},
			{
				name: 'trackingUnitMes',
				type: 'string'
			},
			{
				name: 'trackingUnitMesName',
				type: 'string'
			},
			{
				name: 'trackingProdQuanDiff'
				//type: 'object'
			},
			{
				name: 'trackingAdd',
				type: 'string'
			}
		]
	},
	UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS: {
		idProperty: 'fake_id',
		fields: [
			{
				name: 'fake_id',
				type: 'string'
			},
			{
				name: 'packId',
				type: 'string'
			},
			{
				name: 'kiz'
				//type: 'array'
			},
			{
				name: 'packNumber'
				//type: 'array'
			},
			{
				name: 'type',
				type: 'string',
				convert: function (value, record) {
					var retVal = value;
					if (!value || ('kiz' != value && 'packNumber' != value)) {
						var packNumber = record.get('packNumber'),
							kiz = record.get('kiz');
						if (packNumber && packNumber.length) {
							retVal = 'packNumber';
						} else if (kiz && kiz.length) {
							retVal = 'kiz';
						} else {
							retVal = value;
						}
					}
					return retVal;
				}
			},
			{
				name: 'numbers',
				//type: 'array',
				convert: function (value, record) {
					var type = record.get('type'),
						packNumber = record.get('packNumber');
					if (!type) {
						type = packNumber && packNumber.length ? 'packNumber' : 'kiz';
					}
					var currentVal = record.get(type) || '';
					if (!value) {
						value = currentVal;
					} else if (!Ext.Array.equals(currentVal, value)) {
						//We must check to disable infinite loop
						edi.utils.async(function () {
							record.set(type, value);
							record.set(type == 'packNumber' ? 'kiz' : 'packNumber', []);
						});
					}
					return value;
				}
			},
			{
				name: 'purchaseQuantity',
				type: 'string'
			},
			{
				name: 'GTIN',
				type: 'string'
			},
			{
				name: 'useSortAccounting',
				type: 'boolean'
			}
		]
	},
	USER_CERT: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'user'
				//type: 'object'
			},
			{
				name: 'subjectName',
				//type: 'object',
				convert: function (record) {
					return edi.utils.certificateObject(record);
				}
			}
		]
	},
	ORGANIZATION_ADD_DATA: {
		extend: 'edi.models.ORGANIZATION_HEADER',
		fields: [
			{
				name: 'inn',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.inn ? orgData.inn : value;
				}
			},
			{
				name: 'kpp',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.kpp ? orgData.kpp : value;
				}
			},
			{
				name: 'regNr',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.regNr ? orgData.regNr : value;
				}
			},
			{
				name: 'fnsId',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.fnsId ? orgData.fnsId : value;
				}
			},
			{
				name: 'fnsCode',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.fnsCode ? orgData.fnsCode : value;
				}
			},
			{
				name: 'okpo',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.okpo ? orgData.okpo : value;
				}
			},
			{
				name: 'okato',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.okato ? orgData.okato : value;
				}
			},
			{
				name: 'oktmo',
				type: 'string',
				convert: function (value, record) {
					var orgData = record ? record.get('orgData') : null;
					return orgData && orgData.oktmo ? orgData.oktmo : value;
				}
			},
			{
				name: 'individualOrgAdditionalData'
				//type: 'object'
			},
			{
				name: 'personalOrgAdditionalData'
				// type: 'object'
			},
			{
				name: 'deleted',
				type: 'boolean'
			}
		]
	},
	ORGANIZATIONS: {
		extend: 'edi.models.ORGANIZATION_ADD_DATA'
	},
	ORGANIZATION: {
		extend: 'edi.models.ORGANIZATION_ADD_DATA',
		fields: [
			{
				name: 'zip',
				type: 'string'
			},
			{
				name: 'billingType',
				type: 'string'
			},
			{
				name: 'visualName',
				type: 'string',
				convert: function (val, record) {
					val = '';
					val += record.get('name');
					return val;
				}
			},
			{
				name: 'accounts'
				//type: 'array'
			},
			{
				name: 'headOrgId',
				type: 'number',
				convert: function (value, record) {
					var headOrg = record ? record.get('headOrg') : null;
					return headOrg && headOrg.id ? headOrg.id : null;
				}
			},
			{
				name: 'visualName2',
				type: 'string',
				convert: function (val, record) {
					val = '';
					val += record.get('name');
					val += record.get('inn') ? ' ' + record.get('inn') : '';
					return val;
				}
			}
		]
	}
});
