import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createNumberField, createTextField } from '@Components/fields';
import { createPanel } from '@Components/panels';

/**
 * Creates modal for selection catalog line
 * @param	{String}	url
 * @param	{String}	catalogType
 * @param	{Function}	[callback]
 * @returns	{Object}	Ext.window.Window instance
 */
const createCatalogLineSelectModal = function (url, catalogType, callback) {
	const formItemsMap = {
		code: createNumberField({
			fieldLabel: edi.i18n.getMessage('fhp.catalogs.lines.code'),
			allowDecimals: false,
			name: 'code'
		}),
		name: createTextField({
			fieldLabel: edi.i18n.getMessage('fhp.catalogs.lines.name'),
			name: 'name'
		})
	};
	var modal = createModalRemoteSelect(url, undefined, {
		storeConfig: {
			model: edi.models.getModel(catalogType + '_LINE'),
			sortOnLoad: true,
			sorters: {
				property: 'code',
				direction: 'ASC'
			},
			listeners: {
				load: function (store) {}
			},
			autoLoad: false
		},
		gridConfig: {
			columns: edi.columns.get('fhp_catalogs_' + catalogType + '_lines'),
			listeners: {
				select: function (comp, record) {
					'function' === typeof callback ? callback(record.getData()) : null;
					modal.close();
				}
			}
		},
		hideSaveButton: true,
		createFormItemsMap: formItemsMap,
		createFilterFormItems: [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [formItemsMap.code, formItemsMap.name]
			})
		],
		createArgs: edi.filters.config.generic.createArgs,
		cls: 'edi-modal-catalog-selector',
		title: edi.i18n.getMessage('fhp.catalogs.type.' + catalogType)
	});
	return modal;
};

export { createCatalogLineSelectModal };
