import { createComboTree } from '@UIkit/components/fields/Combobox/ComboboxTree';

const createComboDocType = function (config) {
	config = 'object' == typeof config ? config : {};

	var initDocTypeStore = function () {
		const data = [],
			categories_arr = [],
			type_categories = edi.stores.data.docTypesTree;

		for (let prop in type_categories) {
			if (type_categories.hasOwnProperty(prop)) {
				const elm = type_categories[prop];
				elm['id'] = prop;
				categories_arr.push(elm);
			}
		}
		categories_arr.sort(function (o1, o2) {
			return o1.order > o2.order ? 1 : -1;
		});
		for (let j = 0; j < categories_arr.length; j++) {
			const cat = categories_arr[j];
			const category = {};
			category.id = cat.id;
			category.name = edi.i18n.getMessage('document.type.category.' + cat.id);
			category.items = [];
			data.push(category);

			for (let i = 0; i < cat.items.length; i++) {
				const elm = {};
				if ('object' === typeof cat.items[i]) {
					var objElm = cat.items[i];
					if (objElm.hasOwnProperty('id') && objElm.hasOwnProperty('name')) {
						elm.id = objElm.id;
						elm.name = objElm.name;
					}
				} else {
					elm.id = cat.items[i];
					elm.name = edi.i18n.getMessage('documents.doctype.' + cat.items[i]);
				}
				category.items[i] = elm;
			}
		}
		return edi.stores.createInlineStore(data, 'DOCTYPES_TREE', undefined, {
			addEmptyRecord: true
		});
	};

	var defaults = {
		name: 'includeDocTypes',
		store: initDocTypeStore(),
		multiSelect: true,
		anyMatch: true
	};
	Ext.applyIf(config, defaults);
	return createComboTree(config);
};

/**
 * Creates fieldContainer with docTypes tree combobox
 * @param	{Object}	[config]
 * @returns	{Object}	UI.components.ComboboxTreeField instance
 */
const createDocumentTypeFilterSelectForGrid = function (config) {
	config = 'object' == typeof config ? config : {};
	var defaults = {
		fieldLabel: edi.i18n.getMessage('documents.form.type'),
		emptyText: ''
	};
	Ext.applyIf(config, defaults);

	return createComboDocType(config);
};

export { createDocumentTypeFilterSelectForGrid };
