import { createModalPanel } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createTextField, createPasswordField, createLabel } from '@UIkit/components/fields';
import { createContainer, createFormContainer, createForm, createPanel } from '@UIkit/components/panels';

/**
 * OVERRIDES for edi.login
 */
Ext.namespace('edi.login');
let __self = edi.login;

/**
 * Displays restore password form
 */
__self.passwordRestore = function (loginModal) {
	loginModal.hide();
	var loginField, errorContainer, errorField, restoreButton;
	var restorePassword = function () {
			modal.setLoading(true);
			setError('');
			var values = edi.utils.collectFormValues(formPanel);
			var failure = function (data) {
				edi.core.logMessage('Error sending password recovery request', 'warn');
				setError(edi.i18n.getMessage(data?.typeError));
				restoreButton.setDisabled(true);
				modal.setLoading(false);
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.USER.RECOVERY.POST, values),
				'POST',
				Ext.encode({}),
				function () {
					modal.close();
					__self.successPasswordRestore(values);
				},
				failure,
				null,
				{
					notInterceptable: true,
					suppressDefaultError: true,
					headers: {
						Authorization: edi.constants.AUTH_TYPE_BASIC
					}
				}
			);
		},
		centerWin = function () {
			modal.center();
		};

	loginField = createTextField({
		fieldLabel: edi.i18n.getMessage('login.form.login'),
		cls: 'ui-field-big',
		name: 'login',
		listeners: {
			change: function () {
				if (restoreButton.isDisabled()) {
					restoreButton.setDisabled(false);
				}
			},
			specialkey: function (field, e) {
				if (e.getKey() == e.ENTER) {
					restorePassword();
				}
			},
			afterrender: function (field) {
				setTimeout(function () {
					field.focus();
				}, 2000);
			}
		}
	});

	/**
	 * Fills error container with error msg and force layout of form
	 * @param msg
	 */
	var setError = function (msg) {
		errorField.setVisible(!!msg);
		errorField.setText(msg, false);
		centerWin();
	};

	var logo = createContainer({
		cls: 'ui-login-logo'
	});
	var subtitleLabel = createLabel({
		typography: 'body-short_01',
		color: '--color-grey-50',
		margin: '16 0 24 0',
		text: edi.i18n.getMessage('password.reset.request')
	});
	var formPanel = createForm({
		bodyPadding: '40 24 24 24',
		submitEmptyText: false,
		items: [
			createContainer({
				cls: 'ui-login-label',
				layout: 'fit',
				items: [logo, subtitleLabel]
			}),
			loginField,
			createContainer({
				cls: 'edi-login-error-container edi-login-form__label',
				items: [
					(errorField = createLabel({
						typography: 'caption_01',
						color: '--color-error',
						text: '',
						margin: '8 0 0 0'
					}))
				]
			}),
			(restoreButton = createButton({
				text: edi.i18n.getMessage('password.recovery.send'),
				handler: restorePassword,
				formBind: true,
				cls: BUTTON_CLS.primary,
				width: '100%',
				margin: '24 0 0 0'
			})),
			createButton({
				text: edi.i18n.getMessage('ediweb.back.btn'),
				glyph: edi.constants.ICONS.ARROW_BACK,
				cls: BUTTON_CLS.secondary,
				margin: '16 0 0 0',
				handler: function () {
					window.location.href = 'index.html';
				}
			}),
			createContainer({
				layout: {
					type: 'hbox',
					pack: 'center'
				},
				items: [
					createLabel({
						typography: 'body-short_01',
						color: '--color-error',
						text: '',
						hidden: true,
						margin: '16 0 0 0'
					})
				]
			})
		]
	});

	var modalConf = {
		width: 328,
		minWidth: 328,
		items: [formPanel],
		ghost: false,
		closable: false,
		draggable: false,
		resizable: false,
		modal: false,
		listeners: {
			close: function () {
				edi.core.getViewPort().un('resize', centerWin);
			}
		}
	};
	var modal = createModalPanel(modalConf, true);
	edi.core.getViewPort().on('resize', centerWin);
	modal.show();
};

__self.successPasswordRestore = function (values) {
	var infoContainer, sendAgainBtn;
	var restorePassword = function () {
			setError('');
			var failure = function (data) {
				edi.core.logMessage('Error sending password recovery request', 'warn');
				setError(edi.utils.formatComplexServerError(data, 'password.recovery.request.failed'));
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.USER.RECOVERY.POST, values),
				'POST',
				Ext.encode({}),
				function () {
					errorContainer.setVisible(false);
					sendAgainBtn.setDisabled(true);
					setInfo(edi.i18n.getMessage('ediweb.resend.timer'));

					setTimeout(function () {
						infoContainer.setVisible(false);
						sendAgainBtn.setDisabled(false);
					}, 60000);
				},
				failure,
				null,
				{
					notInterceptable: true,
					//suppressDefaultError: true,
					headers: {
						Authorization: edi.constants.AUTH_TYPE_BASIC
					}
				}
			);
		},
		centerWin = function () {
			modal.center();
		};
	/**
	 * Fills error container with error msg and force layout of form
	 * @param msg
	 */
	var setError = function (msg) {
		errorLabel.setText(msg);
		modal.updateLayout();
		centerWin();
	};

	var setInfo = function (msg) {
		infoContainer.setVisible(true);
		infoContainer.setText(msg, false);
		modal.updateLayout();
		centerWin();
	};

	var successPasswordRestorePanel = createContainer({
		cls: 'ui-login-label',
		layout: 'fit',
		items: [
			createContainer({
				cls: 'ui-login-info ui-login-info-envelope',
				margin: '0 0 32 0'
			}),
			createLabel({
				style: {
					textAlign: 'center'
				},
				typography: 'body-short_01',
				html: edi.i18n.getMessage('ediweb.link.password.recovery')
			})
		]
	});

	var subtitleLabel = createLabel({
		typography: 'body-short_01',
		color: '--color-grey-50',
		margin: '16 0 24 0',
		text: edi.i18n.getMessage('password.reset.request')
	});
	let errorLabel = createLabel({
		typography: 'body-short_01',
		color: '--color-error',
		cls: 'error-label',
		text: ''
	});

	var logo = createContainer({
		cls: 'ui-login-logo'
	});

	var formPanel = createForm({
		minHeight: 406,
		bodyPadding: '40 24 24 24',
		submitEmptyText: false,
		items: [
			createContainer({
				cls: 'ui-login-label',
				layout: 'fit',
				items: [logo, subtitleLabel]
			}),
			successPasswordRestorePanel,
			errorLabel,
			(sendAgainBtn = createButton({
				text: edi.i18n.getMessage('ediweb.send.again.btn'),
				handler: restorePassword,
				formBind: true,
				margin: '22 0 0 0',
				cls: BUTTON_CLS.primary
			})),
			(infoContainer = createLabel({
				isBlock: true,
				style: {
					display: 'block',
					textAlign: 'center'
				},
				margin: '16 0 0 0',
				typography: 'caption_01',
				color: '--color-grey-50',
				text: ''
			}))
		]
	});

	var modalConf = {
		width: 328,
		minWidth: 328,
		items: [formPanel],
		ghost: false,
		closable: false,
		draggable: false,
		resizable: false,
		modal: false,
		listeners: {
			close: function () {
				edi.core.getViewPort().un('resize', centerWin);
			}
		}
	};
	var modal = createModalPanel(modalConf, true);
	edi.core.getViewPort().on('resize', centerWin);
	modal.show();
};

/**
 * Show login modal for local (same server) auth
 * @param	{Object}	user
 * @param	{Function}	success
 * @param	{Function}	userDataFn
 */
__self.showLocalLoginModal = function (user, success, userDataFn) {
	var attemptQnt = 0,
		loginTimeout = __self.getLoginTimeoutCookie(),
		currTime = new Date().getTime(),
		loginBtn,
		needBlockLogin = loginTimeout && currTime - loginTimeout < edi.constants.LOGIN_TIMEOUT;
	edi.core.getViewPort().addCls('edi-login-form-displayed');
	var loginField = createTextField({
		fieldLabel: edi.i18n.getMessage('login.form.login'),
		cls: 'ui-field-big',
		name: 'login',
		listeners: {
			change: function (cmp) {
				if (loginBtn.isDisabled()) {
					loginBtn.setDisabled(false);
				}
				setError('');
			},
			specialkey: function (field, e) {
				if (e.getKey() == e.ENTER) {
					submitLogin();
				}
			},
			afterrender: function (field) {
				setTimeout(function () {
					field.focus();
				}, 2000);
			}
		}
	});

	var passwordField = createPasswordField({
		cls: 'ui-field-big',
		fieldLabel: edi.i18n.getMessage('login.form.password'),
		emptyText: null,
		name: 'password',
		listeners: {
			change: function (cmp) {
				if (loginBtn.isDisabled()) {
					loginBtn.setDisabled(false);
				}
				setError('');
			},
			specialkey: function (field, e) {
				if (e.getKey() == e.ENTER) {
					submitLogin();
				}
			}
		}
	});

	let errorContainer;

	var forcePasswordSave = function (callback) {
		__self.passwordSaveCallback = callback;
		document.getElementById('edi_loginForm').submit();
	};

	var successFn = function (data) {
		__self.getOrganizations(function (orgs) {
			data.data.orgs = orgs;
			userDataFn(data.data);
			var userLogged = edi.core.getUserData();
			if (!user || userLogged.id == user.id) {
				if (!user) {
					if (__self.getAuthType() === 'EDI') {
						__self.checkPasswordExpired();
					}

					forcePasswordSave(function () {
						modal.close();
						__self.setLoginState((userLogged && !userLogged.orgs.length) || edi.core.getUiRendered());
					});
				} else {
					modal.close();
					__self.setLoginState(edi.core.getUiRendered());
				}
				success();
			} else {
				if (__self.getAuthType() === 'EDI') {
					__self.checkPasswordExpired();
				}
				forcePasswordSave(function () {
					modal.close();
					document.location.reload();
				});
			}
		});
	};

	var blockLogin = function (skipCookie) {
		skipCookie = 'boolean' == typeof skipCookie ? skipCookie : false;
		!skipCookie ? __self.setLoginTimeoutCookie() : null;
		var leftTime = parseInt(
			(skipCookie ? edi.constants.LOGIN_TIMEOUT - (currTime - loginTimeout) : edi.constants.LOGIN_TIMEOUT) / 1000,
			10
		);
		var interval = window.setInterval(function () {
			leftTime -= 1;
			if (leftTime <= 0) {
				attemptQnt = 0;
				modal.setLoading(false);
				window.clearInterval(interval);
				let input = document.querySelector(`#${loginField.getInputId()}`);
				input && input.focus();
			} else {
				modal.setLoading(
					edi.utils.formatString(edi.i18n.getMessage('login.form.disabled'), {
						seconds: leftTime
					})
				);
			}
		}, 1000);
		modal.setLoading(
			edi.utils.formatString(edi.i18n.getMessage('login.form.disabled'), {
				seconds: leftTime
			})
		);
	};
	/**
	 * Fills error container with error msg and force layout of form
	 * @param msg
	 */
	var setError = function (msg) {
		errorContainer.setVisible(!!msg);
		errorContainer.setText(msg, false);
		var parentContainer =
			typeof errorContainer.findParentByType === 'function' ? errorContainer.findParentByType('container') : null;
		if (typeof parentContainer.setMargin === 'function') {
			msg ? parentContainer.setMargin('16, 0, 0, 0') : parentContainer.setMargin('0, 0, 0, 0');
		}
		modal.updateLayout();
		centerWin();
	};
	/**
	 * Submits login form
	 */
	var submitLogin = function () {
		var form = formPanel.getForm();
		var values = edi.utils.collectFormValues(form);
		if (form.isValid()) {
			setError();
			modal.setLoading();
			edi.rest.sendRequest(
				edi.rest.services.USER.LOGIN.POST,
				'POST',
				Ext.encode({
					login: values.login,
					password: values.password
				}),
				successFn,
				function (responseData) {
					attemptQnt++;
					if (attemptQnt >= 3) {
						edi.core.showError(
							edi.utils.formatString(edi.i18n.getMessage('login.failed.qnt.times'), {
								qnt: edi.constants.LOGIN_ATTEMPTS,
								block: parseInt(edi.constants.LOGIN_TIMEOUT / 1000, 10)
							}),
							blockLogin
						);
					} else {
						setError(edi.i18n.getMessage(responseData?.typeError) || edi.i18n.getMessage('error.server'));
						loginField.markInvalid('');
						passwordField.markInvalid('');
						loginBtn.setDisabled(true);

						modal.setLoading(false);
					}
				},
				undefined,
				{
					notInterceptable: true,
					suppressDefaultError: true
				}
			);
		} else {
			setError(edi.i18n.getMessage('login.form.not.valid'));
		}
	};
	var userLogged = edi.core.getUserData(),
		hideMask = !(userLogged && userLogged.id) && edi.constants.DEFAULT.LOGIN_FORM_HIDE_MASK;
	var modalConf = {
			layout: 'fit',
			width: 328,
			minWidth: 328,
			items: [],
			ghost: false,
			closable: false,
			draggable: false,
			resizable: false,
			modal: !hideMask,
			listeners: {
				close: function () {
					edi.core.getViewPort().un('resize', centerWin);
				}
			}
		},
		centerWin = function () {
			modal.center();
		};
	var modal = createModalPanel(modalConf, true);
	modal.show();

	var form = Ext.create('Ext.Panel', {
		html: [
			"<iframe id='edi_loginFrame' name='edi_loginFrame' src='./login/login.html' class='edi-login-frame'></iframe>",
			"<form id='edi_loginForm' name='edi_loginForm' method='",
			edi.constants.LOGIN_FORM_CRD_SAVE_METHOD,
			"' action='" + __self.loginFrameLocation + "' target='edi_loginFrame'>",
			'GET' === edi.constants.LOGIN_FORM_CRD_SAVE_METHOD
				? "<input type='hidden' name='loginFrame' value='true'/>"
				: '',
			'</form>'
		].join('')
	});
	modal.add(form);

	var logo = createContainer({
		cls: 'ui-login-logo'
	});
	var subtitleLabel = createLabel({
		typography: 'body-short_01',
		color: '--color-grey-50',
		margin: '16 0 24 0',
		text: edi.i18n.getMessage('ediweb.enter.title')
	});
	var formPanel = createForm({
		minHeight: 406,
		bodyPadding: '40 24 24 24',
		submitEmptyText: false,
		hideMode: 'visibility',
		hidden: true,
		renderTo: 'edi_loginForm',
		items: [
			createContainer({
				cls: 'ui-login-label',
				layout: 'fit',
				items: [logo, subtitleLabel]
			}),
			createFormContainer({
				gap: [24, 16],
				items: [loginField, passwordField]
			}),
			createContainer({
				margin: '12 0 0 0',
				layout: {
					type: 'hbox',
					pack: 'end'
				},
				items: [
					createButton({
						cls: [BUTTON_CLS.link],
						text: edi.i18n.getMessage('password.recovery.link'),
						handler: function () {
							setError();
							edi.login.passwordRestore(modal);
						}
					})
				]
			}),
			(errorContainer = createLabel({
				typography: 'caption_01',
				color: '--color-error',
				text: '',
				margin: '16 0 0 0'
			})),
			(loginBtn = createButton({
				text: edi.i18n.getMessage('ediweb.form.btn.login'),
				handler: submitLogin,
				cls: BUTTON_CLS.primary,
				margin: '24 0 0 0'
			})),
			createButton({
				text: edi.i18n.getMessage('register.btn'),
				handler: function () {
					window.location.href = 'onboarding.html';
				},
				cls: BUTTON_CLS.secondary,
				margin: '16 0 0 0'
			})
		]
	});
	if (needBlockLogin) {
		blockLogin(true);
	} else {
		edi.utils.async(function () {
			var fields = formPanel.getForm().getFields();
			fields.each(function (fld) {
				fld.blur(); //hook to force browser fill component with change fire
			});
			loginBtn.focus();
		}, 400);
	}
	formPanel.updateLayout();
	formPanel.setVisible(true);
	modal.updateLayout();
	centerWin();
	edi.core.getViewPort().on('resize', centerWin);
	formPanel.on('validitychange', function (form, valid) {
		if (valid) {
			setError();
		}
	});
	__self.setLoginState('login');
};

/**
 * Get user extra data
 * @param {Function}    callback
 */
__self.getExtraData = function (callback) {
	var success = function (data) {
		edi.core.logMessage('User extra data loaded', 'info');
		edi.constants.isGetExtraInfo = true;
		if (
			!edi.utils.getObjectProperty(data, 'data.user').hasOwnProperty('lastNotifyTime') &&
			edi.login.getAuthType() === edi.constants.AUTH_TYPE
		) {
			var lastNotifyTime = new Date(2000, 0, 1, 23, 59, 59).getTime();

			edi.utils.setObjectProperty(data, 'data.user.lastNotifyTime', lastNotifyTime);

			var putData = {
				lastNotifyTime: lastNotifyTime
			};
			edi.rest.sendRequest(edi.rest.services.USER.SELF.EXTRA_INFO_ATTRIBUTES.PUT, 'PUT', Ext.encode(putData));
		}
		'function' == typeof callback ? callback(data.data) : null;
	};
	var failure = function () {
		edi.core.handleException('User extra data did not loaded properly');
		'function' == typeof callback ? callback(null) : null;
	};
	edi.rest.sendRequest(edi.rest.services.USER.SELF.EXTRA_INFO.GET, 'GET', null, success, failure);
};
