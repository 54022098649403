import { createPanel } from '@Components/panels';
import { createLabel, createLabelBlock } from '@UIkit/components/fields';
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createDetailLabel } from '@UIkit/components/fields';
import { createContainer } from '@Components/miscComponents';
import * as poaCodes from './power.of.attorney.code.json';
import { POA_CODES_MODEL } from '@Edi/modules/power_of_attorney/models';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { powerOfAttorneyAPI } from '@Edi/modules/power_of_attorney/rest';
import { getPoaErrorReasonByType } from '../methods';
import { PTYPE_HINT } from '@UIkit/components/hint/Hint';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
const self = this;

export const moduleMethods = {
	//заглушка для EW, если определить функцию будет выбор сотрудника в уполномеченый ФЛ
	getModalEmployees: function (selectCallback) {
		return createModalRemoteSelect(
			edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
				gridName: 'active'
			}),
			selectCallback,
			{
				columns: edi.columns.get('ew_employees_modal'),
				model: edi.models.getModel('EW_EMPLOYEES'),
				title: 'employee.select.title',
				noFilter: true,
				storeConfig: {
					autoLoad: true
				}
			}
		);
	},

	selectionModalFl: function (callback, context) {
		let selectCallback = function (orgValues) {
			let snils = orgValues?.user?.personalId;

			if (snils) {
				snils = edi.converters.SNILS.convertForShow(snils);
			}
			callback.call(context, {
				fio: {
					lastName: orgValues.lastName,
					firstName: orgValues.firstName,
					middleName: orgValues.middleName
				},
				snils: snils
			});
		};
		self.moduleMethods.getModalEmployees(selectCallback);
	},
	selectionModalSvFL: function (callback, context) {
		let selectCallback = function (orgValues) {
			let snils = orgValues?.user?.personalId;

			if (snils) {
				snils = edi.converters.SNILS.convertForShow(snils);
			}
			callback.call(context, {
				svFL: {
					fio: {
						lastName: orgValues.lastName,
						firstName: orgValues.firstName,
						middleName: orgValues.middleName
					},
					snils: snils
				}
			});
		};
		self.moduleMethods.getModalEmployees(selectCallback);
	},
	defPrincipalType: {
		INDIVIDUAL: 'ФЛ',
		COMPANY: 'ЮЛ',
		INDIV_ENTREPRENEUR: 'ИП',
		FOREIGN_COMPANY: 'ИО'
	},
	defConfidantType: {
		INDIVIDUAL: 'ФЛ',
		COMPANY: 'ЮЛ',
		INDIV_ENTREPRENEUR: 'ИП'
	},
	validitySign: {
		VALID: '1',
		INVALID: '2'
	},
	DEFAULT_FNS_NUMBER: '00000000-0000-0000-0000-000000000000',
	ISSUER_CODE_REGEXP: /^[0-9]{3}-[0-9]{3}$/,
	getSideTypes: () => [
		{
			id: 'ФЛ',
			name: edi.i18n.getMessage('company.type.individualParticipant')
		},
		{
			id: 'ЮЛ',
			name: edi.i18n.getMessage('company.type.company')
		},
		{
			id: 'ИП',
			name: edi.i18n.getMessage('company.type.individual')
		}
	],
	createSidesStore: () => {
		return edi.stores.createInlineStore(moduleMethods.getSideTypes(), 'SIMPLE');
	},
	//на КП нет организаций с типом ФЛ поэтому и уберем его из стора комбобокса
	createPrincipalTypesStore: function () {
		const items = moduleMethods.getSideTypes().filter((it) => it.id !== 'ФЛ');
		return edi.stores.createInlineStore(items, 'SIMPLE');
	},
	getPersonIdentityCardTypes: () => {
		return ['21', '03', '07', '10', '11', '12', '13', '15', '19', '24'].map((it) => ({
			id: it,
			name: edi.i18n.getMessage('person.identity.card.type.' + it)
		}));
	},
	createPersonIdentityCardTypeStore: () => {
		return edi.stores.createInlineStore(moduleMethods.getPersonIdentityCardTypes(), 'SIMPLE', undefined, {
			addEmptyRecord: true
		});
	},
	createFormItemsByValues: function (method, formData, valuePath) {
		let fields = [];
		let fieldsValues = edi.utils.getObjectProperty(formData, valuePath) || [];
		if (fieldsValues.length > 0) {
			for (let i = 0; i < fieldsValues.length; i++) {
				fields.push(method(!i, fieldsValues[i], i === fieldsValues.length - 1));
			}
		}
		return fields.length > 0 ? fields : [method(true)];
	},
	createRowsBlock: function (createContentFieldsFn, formData, fieldsPropertyName) {
		const createRow = function (isFirstRow, rowData) {
			return createContentFieldsFn(false, rowData);
		};

		return createPanel({
			items: moduleMethods.createFormItemsByValues(createRow, formData, fieldsPropertyName)
		});
	},
	fetchData: ({ poaId, fromSignature, docId }) =>
		new Promise((resolve, reject) => {
			const getHeaderSuccess = function (headerResponse) {
				const getContentSuccess = function (contentResponse) {
					resolve({
						header: headerResponse.data,
						content: contentResponse.data
					});
					if (contentResponse?.data?.errorMessage?.title) {
						const txt = edi.utils.safeString(contentResponse.data.errorMessage.title);
						edi.core.showError(`<span style="color:red">${txt}</span>`);
					}
				};

				const contentUrl =
					fromSignature && docId
						? edi.utils.formatString(powerOfAttorneyAPI.FROM_DOCUMENT.CONTENT.GET, {
								docId,
								poaId
						  })
						: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.GET, {
								documentId: poaId
						  });
				edi.rest.sendRequest(contentUrl, 'GET', null, getContentSuccess, reject);
			};

			const headerUrl =
				fromSignature && docId
					? edi.utils.formatString(powerOfAttorneyAPI.FROM_DOCUMENT.HEADER.GET, {
							docId,
							poaId
					  })
					: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.HEADER.GET, {
							documentId: poaId
					  });
			edi.rest.sendRequest(headerUrl, 'GET', null, getHeaderSuccess, reject);
		}),
	getLegalEntityTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimOrg')">`,
			'<div class="edi-company-name">{[this.getValue(values, "naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innyul')">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.org.inn')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'tlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "tlf")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getLitsoBezDovFlTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, \'svFL.innfl\')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'svFL.snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.dateOfBirth\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.svedFL.dateOfBirth")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.prGrazhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(\'power.of.attorney.citizenship.\' + this.getValue(values, "svedFL.prGrazhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.prGrazhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(\'power.of.attorney.citizenship.\' + this.getValue(values, "svFL.svedFL.prGrazhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.dolzhnost\')">',
			`<span class="code">${edi.i18n.getMessage('job.title')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.dolzhnost")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.naimDokPoln\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.principal.delegate.power.document')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.naimDokPoln")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svFL.svedFL.contactTelephone\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svFL.svedFL.contactTelephone")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;
					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}

					if (path.includes('dateOfBirth')) {
						return edi.utils.formatDate(res, edi.constants.DATE_FORMAT.FNS);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getLitsoBezDovUlTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimOrg')">`,
			'<div class="edi-company-name">{[this.getValue(values, "naimOrg")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}: </span>`,
			'<span class="row-data">{[this.getValue(values, "ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innyul')">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.org.inn')}: </span>`,
			'<span class="row-data">{[this.getValue(values, "innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}: </span>`,
			'<span class="row-data">{[this.getValue(values, "kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getIpDoverTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'naimIP')">`,
			'<div class="edi-company-name">{[this.getValue(values, "naimIP")]}</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrnip\')">',
			`<span class="code">${edi.i18n.getMessage('company.ogrnip.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrnip")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'contactTelephone\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "contactTelephone")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getIpDoverFlTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'dateOfBirth\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "dateOfBirth")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'prGrazhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(\'power.of.attorney.citizenship.\' + this.getValue(values, "prGrazhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'contactTelephone\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "contactTelephone")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					if (path.includes('dateOfBirth')) {
						return edi.utils.formatDate(res, edi.constants.DATE_FORMAT.FNS);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getSvedFizLTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'fio.lastName') || !this.isEmpty($v, 'fio.firstName') || !this.isEmpty($v, 'fio.middleName')">`,
			'<div class="edi-company-name">',
			'<span class="row-data edi-company-individual-lastname">{[this.getValue(values, "fio.lastName")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-firstname">{[this.getValue(values, "fio.firstName")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-patronymicname">{[this.getValue(values, "fio.middleName")]}</span>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dateOfBirth\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.dateOfBirth")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.prGrazhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(\'power.of.attorney.citizenship.\' + this.getValue(values, "svedFL.prGrazhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.contactTelephone\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.contactTelephone")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}

					if (path.includes('dateOfBirth')) {
						return edi.utils.formatDate(res, edi.constants.DATE_FORMAT.FNS);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getSvUpPredTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			//'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty($v, 'naimOrg')">`,
			'<div class="edi-company-name">{[this.getValue(values, "naimOrg")]}</div>',
			'</tpl>',
			//'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'ogrn\')">',
			`<span class="code">${edi.i18n.getMessage('column.register.number')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "ogrn")]}</span>',
			'</tpl>',
			`<tpl if="!this.isEmpty(values, 'innyul')">`,
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.org.inn')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innyul")]}</span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'kpp\')">',
			`<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kpp")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'adrReg.adrRF\')">',
			`<span class="code">${edi.i18n.getMessage('power.of.attorney.russ.address')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "adrReg.adrRF")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'kontaktTlf\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "kontaktTlf")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	getSvUpPredFlTpl: (namePath) =>
		new Ext.XTemplate(
			'<tpl>',
			`<tpl if="!this.isEmpty($v, 'fio.lastName') || !this.isEmpty($v, 'fio.firstName') || !this.isEmpty($v, 'fio.middleName')">`,
			'<div class="edi-company-name">',
			'<span class="row-data edi-company-individual-lastname">{[this.getValue(values, "fio.lastName")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-firstname">{[this.getValue(values, "fio.firstName")]}</span>&nbsp;',
			'<span class="row-data edi-company-individual-patronymicname">{[this.getValue(values, "fio.middleName")]}</span>',
			'</div>',
			'</tpl>',
			'<div class="edi-company-row">',
			`<tpl if="!this.isEmpty(values, 'innfl')">`,
			`<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "innfl")]} </span>',
			'</tpl>',
			'<tpl if="!this.isEmpty(values, \'snils\')">',
			`<span class="code">${edi.i18n.getMessage('person.snils')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "snils")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.dateOfBirth\')">',
			`<span class="code">${edi.i18n.getMessage('person.birthday')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.dateOfBirth")]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.prGrazhd\')">',
			`<span class="code">${edi.i18n.getMessage('person.citizenship')}:</span>`,
			'<span class="row-data">{[edi.i18n.getMessage(\'power.of.attorney.citizenship.\' + this.getValue(values, "svedFL.prGrazhd"))]}</span>',
			'</tpl>',
			'</div>',
			'<div class="edi-company-row">',
			'<tpl if="!this.isEmpty(values, \'svedFL.contactTelephone\')">',
			`<span class="code">${edi.i18n.getMessage('company.contact.phone')}:</span>`,
			'<span class="row-data">{[this.getValue(values, "svedFL.contactTelephone")]}</span>',
			'</tpl>',
			'</div>',
			'</tpl>',
			{
				getValue: function (values, name) {
					let path = values[name] ? name : namePath + '.' + name;

					let res = values[path];
					if (edi.utils.getObjectProperty(values, path)) {
						res = edi.utils.getObjectProperty(values, path);
					} else if (edi.utils.getObjectProperty(values, name)) {
						res = edi.utils.getObjectProperty(values, name);
					}

					if (path.includes('dateOfBirth')) {
						return edi.utils.formatDate(res, edi.constants.DATE_FORMAT.FNS);
					}
					return res;
				},
				isEmpty(values, name) {
					return Ext.isEmpty(this.getValue(values, name));
				}
			}
		),
	showPOAErrorMessageAlert: function (poaNumber, errorType, errorMessage) {
		edi.core.showError(
			edi.i18n.getMessage('power.of.attorney.import.error.detailed', {
				poaNumber: poaNumber,
				errorText: getPoaErrorReasonByType(errorType),
				errorMessage: edi.utils.safeString(errorMessage)
			})
		);
	},
	createPowerListSection: function ({ powerLists }, isDetailModal) {
		if (!Array.isArray(powerLists) || !powerLists?.length) {
			return null;
		}

		const headPowerListSection = createLabel({
			typography: 'heading_01',
			text: edi.i18n.getMessage('power.of.attorney.power.list')
		});

		//Creates panel
		const createPowerListsPanel = function (powerList, number) {
			let tekstPolnValue = '';
			if (powerList) {
				const poln = powerList.soderzhPoln
					? powerList.kodPoln + ' - ' + powerList.soderzhPoln
					: powerList.kodPoln;
				tekstPolnValue = powerList.tekstPoln ?? poln;
			}
			return createFieldBlock({
				cls: `${FIELD_BLOCK_CLS.default} ${isDetailModal ? '' : FIELD_BLOCK_CLS.detailsBlock}`,
				items: [
					createLabelBlock({
						contents: [
							{
								title: edi.i18n.getMessage('power.of.attorney.power.code.tekstPoln', [number]),
								text: tekstPolnValue,
								delimiter: '',
								isVertical: true,
								padding: isDetailModal ? '0 0 8 0' : 0, // EW override
								isBlock: true
							},
							{
								title: edi.i18n.getMessage('power.of.attorney.power.additional.info'),
								text: powerList?.dopTekstPoln,
								delimiter: '',
								isVertical: true,
								isBlock: true
							}
						]
					})
				]
			});
		};

		const panels = powerLists.map(function (powerList, index) {
			return createPowerListsPanel(powerList, index + 1);
		});

		return createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: [headPowerListSection].concat(panels)
		});
	},
	createPoaCodesStore: function (onload) {
		return edi.stores.createInlineStore(poaCodes.items, POA_CODES_MODEL, onload, {
			addEmptyRecord: false
		});
	},
	getStateField: function ({ docHeader }) {
		const errorMessage = docHeader?.errorMessage?.errorMessage;
		const errorType = docHeader?.errorMessage?.type;
		const isFakePoa = docHeader.businessState === edi.constants.STATE.NOT_DEFINED;

		const fakePoaInfoBtn = createButton({
			margin: '0 0 0 10',
			cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
			glyph: edi.constants.ICONS.INFO,
			plugins: [
				{
					ptype: PTYPE_HINT,
					items: [
						createLabel({
							text: edi.i18n.getMessage('poa.notChecked.info')
						})
					]
				}
			]
		});

		const errorInfoBtn = createButton({
			margin: '0 0 0 10',
			cls: [BUTTON_CLS.light, BUTTON_CLS.icon],
			tooltip: edi.i18n.getMessage('document.poa.errors.status.details'),
			glyph: edi.constants.ICONS.DETAILS,
			hidden: docHeader?.state !== edi.constants.STATE.ERROR,
			handler: function () {
				moduleMethods.showPOAErrorMessageAlert(docHeader.poaNumber, errorType, errorMessage);
			}
		});

		return createFieldBlock({
			cls: `${FIELD_BLOCK_CLS.small} edi-fields-block edi-document-action-status`,
			title: edi.i18n.getMessage('document.state'),
			grid: {
				col: 2
			},
			layout: {
				type: 'hbox',
				align: 'center'
			},
			order: 30,
			items: [
				createLabel({
					height: 22,
					html: edi.renderers.poaStateRenderer(isFakePoa ? docHeader?.businessState : docHeader?.state)
				}),
				isFakePoa ? fakePoaInfoBtn : errorInfoBtn
			]
		});
	},
	// EW method
	getPOAForSigningAndShowNotification: function () {
		const userData = edi.core.getUserData();
		const snils = userData?.personalId?.replace(/[- ]/g, '');
		if (snils) {
			edi.rest.sendRequest(
				edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
					principalSnils: snils,
					state: Ext.encode([edi.constants.STATE.FOR_SIGNING])
				}),
				'GET',
				null,
				function (data) {
					if (data.items.length) {
						const notification = {
							read: false,
							action: {
								type: 'POA_FOR_SIGNING'
							}
						};

						edi.toast.showToast({
							values: notification,
							content: edi.notifications.itemTpl.apply(notification),
							autoCloseTimeout: 0,
							afterAppend: function (newToast, toastValues) {
								edi.notifications.addBtnClickHandler(newToast, toastValues);
							}
						});
					}
				},
				undefined,
				null
			);
		}
	}
};
