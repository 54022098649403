import { columnsChangeHandler, createActionsColumnConfig, createGridSettingButton } from './grid';
import { createProxyConfig } from './storeComponents';
import '@UIkit/components/treePanel';

Ext.namespace('edi.components');
Ext.define('edi.components.EdiTreeGrid', {
	extend: 'UI.components.TreePanel',
	xtype: 'edi-tree-grid',

	title: undefined,
	useArrows: true,
	rootVisible: false,
	multiSelect: false,
	singleExpand: false,
	tools: undefined, //tree title action buttons can be overridden
	cls: 'edi-product-grid-container with-row-lines',
	readOnly: false,
	allowBlank: false,
	gridModelConfig: 'SIMPLE', //need to be overridden
	gridColumnsConfig: 'simple', //need to be overridden
	gridValues: [],
	rowActions: [],
	//you can add levels config to manage field names for children and depth of tree
	//levels: ['children_lvl_1', 'children_lvl_2'],
	toggleOnDblClick: false,

	/**
	 * Add items to tree root
	 * @param	{Object[]}	newItems	array of objects to add to root of tree
	 * @param 	{boolean}	expandAll
	 */
	loadData: function (newItems, expandAll) {
		var __self = this;
		var items = Array.isArray(newItems) ? newItems : [];
		__self.suspendLayouts();
		__self.__addChildrenItems(items, __self.getStore().getRootNode());
		if (expandAll) {
			__self.expandAll(function () {
				__self.resumeLayouts();
			});
		} else {
			__self.resumeLayouts();
		}
	},

	/**
	 * Create new node and append it to parent or root
	 * @param	{Object}	newData	new node data
	 * @param	{Object}	parent	parent node to append new node
	 * @param	{boolean}	preventExpand	prevent expand parent node
	 * @return	{Object}	new node
	 */
	createAndAppend: function (newData, parent, preventExpand) {
		var __self = this;
		var p = parent && parent.isNode ? parent : __self.getRootNode();
		var newNode = p.createNode({});
		newNode.set({ data: newData || {} });
		newNode.set('leaf', true);
		p.set('leaf', false);
		p.appendChild(newNode, false, true);
		if (!preventExpand && p.isExpandable()) {
			p.expand();
		}
		return newNode;
	},

	/**
	 * Returns values of tree's items in the same structure as they were loaded
	 * @return	{Object[]}
	 */
	getTreeValues: function (rootNode) {
		var __self = this;
		var currentRoot = rootNode || __self.getRootNode();
		var result = [];
		if (currentRoot && Array.isArray(currentRoot.childNodes) && currentRoot.childNodes.length) {
			currentRoot.childNodes.forEach(function (node) {
				var nodeData = Ext.clone(node.data.data || {});
				if (!node.isLeaf()) {
					var nextLevelFieldName = 'children';
					if (Array.isArray(__self.levels)) {
						nextLevelFieldName = __self.levels[node.getDepth() - 1];
					}
					nodeData[nextLevelFieldName] = __self.getTreeValues(node);
				}
				result.push(nodeData);
			});
		}
		return result;
	},

	/**
	 * Private method recursively add children nodes to parent node
	 * @param	{Object[]}	itemsData	array of objects to add to parentNode
	 * @param	{Object}	parentNode	parent for adding new nodes
	 */
	__addChildrenItems: function (itemsData, parentNode) {
		var __self = this;
		if (Array.isArray(itemsData) && itemsData.length && parentNode && parentNode.appendChild) {
			itemsData.forEach(function (item) {
				var newNode = parentNode.createNode({});
				newNode.set({ data: (item.isNode && item.data.data) || item });
				parentNode.set('leaf', false);
				parentNode.appendChild(newNode, false, true);

				var nextLevelFieldName = 'children';
				if (Array.isArray(__self.levels)) {
					nextLevelFieldName = __self.levels[newNode.getDepth() - 1];
				}
				if (nextLevelFieldName && Array.isArray(item[nextLevelFieldName]) && item[nextLevelFieldName].length) {
					__self.__addChildrenItems(item[nextLevelFieldName], newNode);
				} else {
					newNode.set('leaf', true);
				}
			});
		}
	},

	__convertChildrenItems: function (rootNode) {
		var __self = this;
		var nextLevelFieldName = 'children';

		var childNodes =
			rootNode.hasOwnProperty('data') && rootNode.childNodes.length === 0
				? rootNode.get(nextLevelFieldName)
				: rootNode.childNodes;
		if (rootNode && childNodes.length) {
			rootNode.set(nextLevelFieldName, childNodes);

			Ext.Array.forEach(childNodes, function (childNode) {
				if (childNode.hasOwnProperty('data')) {
					childNode.set('leaf', false);
				}

				if (
					childNode.hasOwnProperty('data') &&
					Ext.isArray(childNode.get(nextLevelFieldName)) &&
					childNode.get(nextLevelFieldName).length > 0
				) {
					__self.__convertChildrenItems(childNode);
				} else {
					if (childNode.hasOwnProperty('data')) {
						childNode.set('leaf', true);
					} else {
						childNode.leaf = true;
					}
				}
			});
		}
	},

	initComponent: function () {
		var __self = this;
		var columns = edi.columns.get(__self.gridColumnsConfig) || [];
		columns.push(
			createActionsColumnConfig({
				align: 'right',
				items: Array.isArray(__self.rowActions) ? __self.rowActions : []
			})
		);

		if (edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.SHOW_SETTING_BUTTON) {
			for (var i = 0; i < columns.length; i++) {
				columns[i].menuDisabled = true;
				columns[i].tooltip = columns[i].tooltip || columns[i].text;
			}
		}

		__self.viewConfig.loadingText = null;

		Ext.applyIf(__self, {
			columns: columns,
			componentCls: 'edi-grid-with-row-lines'
		});

		if (!__self.store) {
			__self.store = new Ext.data.TreeStore({
				model: edi.models.getModel(__self.gridModelConfig),
				root: { id: 0 },
				proxy: createProxyConfig({
					type: 'pagingmemory',
					data: {
						items: []
					}
				}),
				folderSort: true
			});
		}

		__self.on('afterrender', createGridSettingButton);

		this.callParent();

		if (edi.constants.COLUMN_CONFIG_SAVE_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE')) {
			__self.on('render', columnsChangeHandler);
		}

		__self.loadData(Array.isArray(__self.gridValues) ? __self.gridValues : []);
	}
});

/**
 * Creates products tree grid
 * @param	{Object}	cfg		config for new product.EdiTreeGrid
 * @return	{Object}	edi.components.product.EdiTreeGrid instance
 */
const createTreeGrid = function (cfg) {
	return Ext.create('edi.components.EdiTreeGrid', cfg);
};

export { createTreeGrid };
