import { createWebSocket } from './ws.js';

/**
 * @author Marina Soloschenko
 * Singleton class for realtime messages (without storing on backend)
 */
Ext.define('edi.realtime.messages', {
	singleton: true,
	WS_REAL_TIME_MESSAGE_GET_URL: 'client/realtime/message',
	//to initiate connection run this method after app is started
	//or put it into edi.constants.BEFORE_LOAD_MODULES
	getRealTimeMessage(callback) {
		let me = this;

		if ('function' == typeof callback) {
			callback();
		}

		if (!me._ws) {
			me._ws = createWebSocket({
				url: me.WS_REAL_TIME_MESSAGE_GET_URL,
				onmessage(msg) {
					let msgData = Ext.decode(msg?.data, true);
					if (!msgData || +msgData.status !== 200) {
						edi.core.logMessage(msgData, 'error');
					} else if (msgData.data?.meta?.messageType) {
						let type = msgData.data.meta.messageType;
						setTimeout(() => {
							if ('function' == typeof edi.realtime.messages.eventByMsgType[type]) {
								edi.realtime.messages.eventByMsgType[type](msgData);
							}
						}, 0);
					}
				},
				onMaxReconnect(socket) {
					edi.core.confirm('error', 'confirm.ws.notifications.reconnect', function () {
						socket.reconnect({ force: true });
					});
				}
			});
		}

		return me;
	},
	isConnected() {
		const ws = this._ws;
		const state = ws.state;
		return state === ws._states.CONNECTED;
	},
	showConnectionError(errorText) {
		const ws = this._ws;
		edi.core.showError(errorText);
		ws.reconnect({ force: true });
	},
	/**
	 * Handlers for messages by it's type
	 * example:
	 * 'UVPERADR_EXPIRED': function(msgData) {console.log(msgData);}
	 */
	eventByMsgType: {}
});

const realtimeEvents = new Ext.util.Observable();

export { realtimeEvents };
