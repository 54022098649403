import { createModalTabForm } from '@Core/specialComponents/modals';
import * as okeiCodes from '@App/json/okei.codes.json';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { createContainer, createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createCombo, createNumberField, createTextField } from '@UIkit/components/fields';

/**
 *
 * @param	{Object}	[data]
 * @param	{Function}	[callback]
 * @param	{Object}	[options]
 * @returns	{Object}	Ext.window.Window instance
 */
const createProductCatalogItemModal = function (data, callback, options) {
	const isEdit = !!data;
	data = data ? data : {};
	const fieldConf = {
		valueSrc: data,
		readOnly: false,
		disabled: options?.readOnly,
		...options
	};
	const fieldBlockConf = {
		cls: FIELD_BLOCK_CLS.small
	};
	const containerConf = {
		padding: '24 10 0 24'
	};
	const tabConf = {
		padding: 0,
		layout: 'auto'
	};

	const itemTypes = [
		{
			id: 'CU',
			name: edi.i18n.getMessage('item.type.commercial.unit')
		},
		{
			id: 'RC',
			name: edi.i18n.getMessage('item.type.return.package')
		}
	];

	const validOkei = (okeiCodes?.items || []).filter((okeiItem) => okeiItem.name);

	const basics = createContainer({
		padding: 24,
		layout: {
			type: 'grid',
			gap: 16,
			area: [6, [6, 6], [4, 4, 4], [4, 4, 4], [6, 6], [6, 6], [4, 4, 4], [4, 4, 4], 8]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.type'),
				items: [
					createCombo({
						name: 'itemType',
						store: edi.stores.createInlineStore(itemTypes, 'SIMPLE', undefined, {
							addEmptyRecord: true
						}),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.description'),
				items: [
					createTextField({
						name: 'itemDescription',
						maxLength: 100,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.description.eng'),
				items: [
					createTextField({
						name: 'itemDescriptionENG',
						maxLength: 100,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.ean'),
				items: [
					createTextField({
						name: 'ean',
						regex: edi.constants.VALIDATORS.EAN13,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 0,
						maxLength: 13,
						invalidText: edi.i18n.getMessage('invalid.field.ean.value.default'),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.buyer.item.code'),
				items: [
					createTextField({
						name: 'buyerItemCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.supplier.item.code'),
				items: [
					createTextField({
						name: 'supplierItemCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.previous.ean'),
				items: [
					createTextField({
						name: 'previousEAN',
						regex: edi.constants.VALIDATORS.EAN13,
						maskRe: /\d/i,
						enforceMaxLength: true,
						minLength: 0,
						maxLength: 13,
						invalidText: edi.i18n.getMessage('invalid.field.ean.value.default'),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.previous.buyer.item.code'),
				items: [
					createTextField({
						name: 'previousBuyerItemCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.previous.supplier.item.code'),
				items: [
					createTextField({
						name: 'previousSupplierItemCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.product.type'),
				items: [
					createTextField({
						name: 'productType',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.unit.of.measure'),
				items: [
					createCombo({
						name: 'unitOfMeasure',
						maxLength: 17,
						store: edi.stores.createInlineStore(validOkei, 'OKEI_CODES'),
						valueField: 'name_international',
						displayField: 'name',
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.colour.code'),
				items: [
					createTextField({
						name: 'colourCode',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.colour.name'),
				items: [
					createTextField({
						name: 'colourName',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.size'),
				items: [
					createTextField({
						name: 'size',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.family'),
				items: [
					createTextField({
						name: 'family',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.sub.family'),
				items: [
					createTextField({
						name: 'subFamily',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.department'),
				items: [
					createTextField({
						name: 'department',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.campaign'),
				items: [
					createTextField({
						name: 'campaign',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.campaign.year'),
				items: [
					createTextField({
						name: 'campaignYear',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.description.long'),
				items: [
					createTextField({
						name: 'itemDescriptionLong',
						isTextarea: true,
						maxLength: 255,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const prices = createContainer({
		padding: 24,
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[4, 4],
				[4, 4, 4],
				[4, 4, 4],
				[4, 4]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.unit.net.price'),
				items: [
					createNumberField({
						name: 'unitNetPrice',
						allowDecimals: true,
						decimalPrecision: 4,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.rate'),
				items: [
					createNumberField({
						name: 'taxRate',
						allowBlank: false,
						allowDecimals: true,
						decimalPrecision: 2,
						maxValue: 100,
						minValue: 0,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.category.code'),
				items: [
					createCombo({
						name: 'taxCategoryCode',
						allowBlank: false,
						store: edi.stores.createInlineStore(
							[
								{
									id: 'S',
									name: edi.i18n.getMessage('tax.category.code.S')
								},
								{
									id: 'E',
									name: edi.i18n.getMessage('tax.category.code.E')
								}
							],
							'SIMPLE',
							null,
							{
								addEmptyRecord: true
							}
						),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.reference.reference.type'),
				items: [
					createCombo({
						name: 'taxReference.referenceType',
						store: edi.stores.createMemoryStore(
							[
								{
									id: 'PKWIU',
									name: 'PKWIU'
								}
							],
							'SIMPLE',
							true
						),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.tax.reference.reference.number'),
				items: [
					createNumberField({
						name: 'taxReference.referenceNumber',
						allowDecimals: false,
						maxLength: 20,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.promotional.quantity'),
				items: [
					createNumberField({
						name: 'promotionalQuantity',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.promotional.price'),
				items: [
					createNumberField({
						name: 'promotionalPrice',
						allowDecimals: true,
						decimalPrecision: 4,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.promotion.code'),
				items: [
					createTextField({
						name: 'promotionCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.suggested.price'),
				items: [
					createNumberField({
						name: 'suggestedPrice',
						allowDecimals: true,
						decimalPrecision: 4,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.discount'),
				items: [
					createNumberField({
						name: 'discount',
						allowDecimals: true,
						decimalPrecision: 4,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const packaging = createContainer({
		padding: 24,
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[4, 4],
				[6, 6],
				[6, 6],
				[6, 6],
				[4, 4],
				[4, 4, 4],
				[6, 6]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.min.ordered.quantity'),
				items: [
					createNumberField({
						name: 'packDetails.minOrderedQuantity',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.type.packing'),
				items: [
					createTextField({
						name: 'packDetails.typePacking',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.pallet.size'),
				items: [
					createNumberField({
						name: 'packDetails.palletSize',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.layer.size'),
				items: [
					createNumberField({
						name: 'packDetails.layerSize',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.stacking.code'),
				items: [
					createNumberField({
						name: 'packDetails.stackingCode',
						type: 'number',
						allowDecimals: false,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.stacking.factor'),
				items: [
					createNumberField({
						name: 'packDetails.stackingFactor',
						allowDecimals: false,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.supplier.pack.code'),
				items: [
					createTextField({
						name: 'packDetails.supplierPackCode',
						maxLength: 26,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.unit.packsize'),
				items: [
					createNumberField({
						name: 'packDetails.unitPacksize',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.weight'),
				items: [
					createNumberField({
						name: 'packDetails.weight',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.volume'),
				items: [
					createNumberField({
						name: 'packDetails.volume',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.length'),
				items: [
					createNumberField({
						name: 'packDetails.length',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.width'),
				items: [
					createNumberField({
						name: 'packDetails.width',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.height'),
				items: [
					createNumberField({
						name: 'packDetails.height',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.item.numerator'),
				items: [
					createNumberField({
						name: 'packDetails.itemNumerator',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.pack.details.item.denumerator'),
				items: [
					createNumberField({
						name: 'packDetails.itemDenumerator',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const additional = createContainer({
		padding: 24,
		layout: {
			type: 'grid',
			gap: 16,
			area: [6, [6, 6], [4, 4, 4], [6, 3, 3], [6, 6], [6, 6], 8]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.activity.status'),
				items: [
					createCombo({
						name: 'activityStatus',
						store: edi.stores.createSimpleInlineStore(
							['ACTIVE', 'INACTIVE'],
							(id) => edi.i18n.getMessage('activity.status.' + id),
							true
						),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.unit.net.weight'),
				items: [
					createNumberField({
						name: 'unitNetWeight',
						allowDecimals: true,
						decimalPrecision: 3,
						maxLength: 10,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.customs.declaration.number'),
				items: [
					createTextField({
						name: 'customsDeclarationNumber',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.group.identification.code'),
				items: [
					createTextField({
						name: 'itemGroupIdentificationCode',
						maxLength: 20,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.item.group.description'),
				items: [
					createTextField({
						name: 'itemGroupDescription',
						maxLength: 100,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.assortment.group'),
				items: [
					createTextField({
						name: 'assortmentGroup',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('line.item.territory.code'),
				items: [
					createTextField({
						name: 'salesRegion',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('line.item.brand'),
				items: [
					createTextField({
						name: 'brandName',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.brand.code'),
				items: [
					createTextField({
						name: 'brandCode',
						maxLength: 17,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.delivery.terms'),
				items: [
					createTextField({
						name: 'deliveryTerms',
						type: 'number',
						allowDecimals: false,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.seller.location.code'),
				items: [
					createTextField({
						name: 'sellerLocationCode',
						maxLength: 35,
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.product.lifespan.period.format'),
				items: [
					createCombo({
						name: 'productLifespan.periodFormat',
						requiredFields: ['productLifespan.period'],
						store: edi.stores.createMemoryStore(
							[
								{
									id: '802',
									name: '802'
								},
								{
									id: '803',
									name: '803'
								},
								{
									id: '804',
									name: '804'
								}
							],
							'SIMPLE',
							true
						),
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.product.lifespan.period'),
				items: [
					createTextField({
						name: 'productLifespan.period',
						requiredFields: ['productLifespan.periodFormat'],
						...fieldConf
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('documents.column.remark'),
				items: [
					createTextField({
						name: 'remarks',
						maxLength: 255,
						isTextarea: true,
						...fieldConf
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const other = createContainer({
		padding: 24,
		layout: {
			type: 'grid',
			gap: 16,
			area: [
				[3, 3, 3, 3],
				[4, 4]
			]
		},
		items: [
			createFieldBlock({
				title: edi.i18n.getMessage('column.id'),
				items: [
					createTextField({
						name: 'id',
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.line.type'),
				items: [
					createTextField({
						name: 'lineType',
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.line.number'),
				items: [
					createTextField({
						name: 'lineNumber',
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.system.id'),
				items: [
					createTextField({
						name: 'systemID',
						...fieldConf,
						disabled: true
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.creation.date'),
				items: [
					createTextField({
						name: 'creationDate',
						...fieldConf,
						disabled: true,
						valueSrc: undefined,
						value: edi.utils.formatDate(data?.creationDate, edi.constants.DATE_FORMAT.FNS)
					})
				],
				...fieldBlockConf
			}),
			createFieldBlock({
				title: edi.i18n.getMessage('column.modify.date'),
				items: [
					createTextField({
						name: 'modifyDate',
						...fieldConf,
						disabled: true,
						valueSrc: undefined,
						value: edi.utils.formatDate(data?.modifyDate, edi.constants.DATE_FORMAT.FNS)
					})
				],
				...fieldBlockConf
			})
		],
		...containerConf
	});

	const tabs = [
		{
			title: edi.i18n.getMessage('tab.title.basic'),
			items: [basics],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.prices'),
			items: [prices],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.packaging'),
			items: [packaging],
			...tabConf
		},
		{
			title: edi.i18n.getMessage('tab.title.additional'),
			items: [additional],
			...tabConf
		}
	];
	if (isEdit || fieldConf.readOnly) {
		tabs.push({
			title: edi.i18n.getMessage('tab.title.other'),
			items: [other],
			...tabConf
		});
	}
	const modal = createModalTabForm(
		edi.i18n.getMessage('catalog.prodcat.item.title'),
		tabs,
		function (data) {
			'function' == typeof callback ? callback({ lineItem: data }) : null;
		},
		null,
		isEdit,
		{
			width: MODAL_SIZE.widthLarge,
			buttons: fieldConf.readOnly ? [] : undefined
		}
	);
	modal.show();
	return modal;
};

export { createProductCatalogItemModal };
