import { createNumberField, createTextField } from '@Components/fields';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createPanel } from '@Components/panels';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Creates modal window for catalog selection
 * @param	{String}	remoteURL url for get catalog fields
 * @param	{Function}	callback  callback for select
 * @param	{Object}	options   options modal window
 */
const createModalPartInCatalogSelect = function (remoteURL, callback, options) {
	var orgSource = options.getSourceOrgId();
	var orgTarget = options.getTargetOrgId();

	var url = edi.utils.formatString(edi.rest.services.CATALOGS.PARTIN.GET, {
		type: 'localization',
		org_source: orgSource,
		org_target: orgTarget
	});
	const formItemsMap = {
		iln: createNumberField({
			fieldLabel: edi.i18n.getMessage('company.gln'),
			allowDecimals: false,
			name: 'iln'
		}),
		name: createTextField({
			fieldLabel: edi.i18n.getMessage('company.name'),
			name: 'name'
		})
	};
	var modal = createModalRemoteSelect(url, undefined, {
		storeConfig: {
			model: edi.models.getModel('PARTIN_ORGANIZATION')
		},
		gridConfig: {
			columns: edi.columns.get('organization_catalog'),
			listeners: {
				select: function (comp, record) {
					'function' == typeof callback
						? callback(edi.converters.convertPartinCatToOrg(record.getData()))
						: null;
					modal.close();
				}
			}
		},
		hideSaveButton: true,
		createFilterFormItems: [
			createPanel({
				layout: {
					type: 'grid',
					area: [[6, 6]]
				},
				items: [formItemsMap.iln, formItemsMap.name]
			})
		],
		createFormItemsMap: formItemsMap,
		createArgs: edi.filters.config.generic.createArgs,
		cls: 'edi-modal-catalog-selector',
		title: edi.i18n.getMessage('document.partin.select.title')
	});
	return modal;
};

/**
 * Creates modal window for catalog selection with multiselect for grid
 * @param	{Number}	orgSource
 * @param	{Number}	orgTarget
 * @param	{Function}	callback
 * @param	{Object}	[options]
 */
const createModalPartInCatalogSelectForGrid = function (orgSource, orgTarget, callback, options) {
	var url = edi.utils.formatString(edi.rest.services.CATALOGS.PARTIN.GET, {
		type: 'localization',
		org_source: orgSource,
		org_target: orgTarget
	});
	const formItemsMap = {
		iln: createNumberField({
			fieldLabel: edi.i18n.getMessage('company.gln'),
			allowDecimals: false,
			name: 'iln'
		}),
		name: createTextField({
			fieldLabel: edi.i18n.getMessage('company.name'),
			name: 'name'
		}),
		state: createTextField({
			fieldLabel: edi.i18n.getMessage('company.region'),
			allowDecimals: false,
			name: 'state'
		}),
		cityName: createTextField({
			fieldLabel: edi.i18n.getMessage('company.city'),
			allowDecimals: false,
			name: 'cityName'
		}),
		locality: createTextField({
			fieldLabel: edi.i18n.getMessage('company.community'),
			allowDecimals: false,
			name: 'locality'
		}),
		streetAndNumber: createTextField({
			fieldLabel: edi.i18n.getMessage('company.street'),
			allowDecimals: false,
			name: 'streetAndNumber'
		})
	};
	return createModalRemoteSelect(
		url,
		function (data) {
			callback(data);
		},
		{
			checkboxes: true,
			model: 'PARTIN_SELECTION_MODAL',
			columns: 'partin_selection_modal',
			width: MODAL_SIZE.widthLarge,
			createFilterFormItems: [
				createPanel({
					layout: {
						type: 'grid',
						area: [
							[6, 6],
							[6, 6],
							[6, 6]
						]
					},
					items: [
						formItemsMap.iln,
						formItemsMap.name,
						formItemsMap.state,
						formItemsMap.cityName,
						formItemsMap.locality,
						formItemsMap.streetAndNumber
					]
				})
			],
			createFormItemsMap: formItemsMap,
			createArgs: edi.filters.config.generic.createArgs,
			title: options?.modalTitle || edi.i18n.getMessage('document.delcat.select.title')
		}
	);
};

export { createModalPartInCatalogSelect, createModalPartInCatalogSelectForGrid };
