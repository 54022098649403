import { createContainer } from '@Components/miscComponents';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Creates modal panel with iframe inside
 * @param	{String}	src
 * @param	{Object}	[config]
 * @returns	{Object}	Ext.window.Window instance
 */
const createModalFrame = function (src, config) {
	config = 'object' == typeof config ? config : {};
	var defaults = {
		width: MODAL_SIZE.widthLarge,
		title: edi.i18n.getMessage('column.result'),
		layout: 'fit',
		height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER
	};
	Ext.applyIf(config, defaults);
	config.items = [
		createContainer({
			items: [
				{
					xtype: 'component',
					autoEl: {
						tag: 'iframe',
						src: src,
						frameborder: 0,
						style: {
							height: '100%',
							width: '100%'
						}
					}
				}
			]
		})
	];
	return createModalPanel(config);
};

export { createModalFrame };
