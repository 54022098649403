import { createContainer } from '@Components/miscComponents';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import './rows-block.scss';

Ext.define('edi.components.RowsBlockV2', {
	extend: 'Ext.panel.Panel',
	alias: 'widget.rowsblock',

	cls: 'edi-rows-block',
	title: '',
	rowGap: 8,
	addRowBtnGap: 24,
	items: [],
	readOnly: false,
	createFirstRow: false,
	hideRemoveOnFirstRow: false,

	createContentFieldsFn() {},
	initialData: [],
	border: 0,
	contentCols: 11,
	buttonsCols: 1,
	onCreate() {},
	onAddRow() {},
	onRemoveRow() {},

	initComponent: function () {
		this.setOwnConfig();
		this.callParent();
		this.afterInit();
	},

	setOwnConfig() {
		let me = this;
		if (!me.initialData) {
			me.initialData = [];
		}
		me.items = me.createInitialRows();
		me.dockedItems = me.createDockedItems();
	},

	afterInit() {
		let me = this;
		me.updateRowsBlock();
		if (typeof me.onCreate === 'function') {
			me.onCreate();
		}

		me.on('afterrender', function () {
			me.updateRowsBlock();
			if (typeof me.rowChange === 'function') {
				me.rowChange(me);
			}
		});
	},
	/**
	 * Creates array of rows from initialData
	 * @returns	{Object[]}	Array of rows (containers ot panels)
	 */
	createInitialRows() {
		let me = this;

		let initialRows = me.initialData.map((values, i) => {
			return me.createRow(values, i === 0);
		});
		//если начальных данных нет, то создадим пустую строку
		return initialRows.length > 0 ? initialRows : me.createFirstRow ? [me.createRow(null, true)] : [];
	},
	createDockedItems: function () {
		const me = this;
		return [
			{
				xtype: 'toolbar',
				cls: 'edi-row-block-toolbar',
				dock: 'bottom',
				items: [me.createAddBtn()]
			}
		];
	},
	createAddBtn: function () {
		const me = this;
		me.addRowBtn = createButton(
			Object.assign(
				{
					cls: [BUTTON_CLS.secondary, 'edi-row-block-add_button'],
					itemId: 'add_button',
					glyph: edi.constants.ICONS.PLUS,
					tooltip: edi.i18n.getMessage('form.btn.add'),
					text: edi.i18n.getMessage('form.btn.add'),
					handler(btn) {
						me.addRowClickHandler(btn);
					}
				},
				me.addRowBtnConf
			)
		);
		return me.addRowBtn;
	},
	/**
	 * Initial row create method
	 * @param	{any}		rowData
	 * @param	{Boolean}	isFirstRow
	 * @returns	{Object}	row (containers ot panels)
	 */
	createRow(rowData, isFirstRow) {
		let me = this;

		if (me.isReadOnly) {
			var contentContainer = me.createContentFieldsFn(isFirstRow, rowData, {});
			return me.createRowPanel(rowData ? contentContainer : [], []);
		} else {
			return me.createRowWithAddRemoveButtons(isFirstRow, rowData);
		}
	},
	/**
	 * Creates row panel with fields and buttons
	 * @param	{Object}	contentFieldsPanel
	 * @param	{Object}	buttonsContainer
	 * @returns	{Object}	row (Ext.container.Container instance)
	 */
	createRowPanel(contentFieldsPanel, buttonsContainer) {
		let me = this;
		return createContainer({
			layout: {
				type: 'grid',
				area: [[me.contentCols, me.buttonsCols]]
			},
			contentFieldsPanel: contentFieldsPanel,
			buttonsContainer: buttonsContainer,
			items: [contentFieldsPanel, buttonsContainer]
		});
	},
	/**
	 * Add button click handler
	 * @param	{Object}	clickedButton
	 * @param	{any}	[props]	(костыль для упд)
	 */
	addRowClickHandler() {
		let me = this,
			newRow = me.createRow(null, me.getRows().length === 0);
		me.add(newRow);
		if (typeof me.rowChange === 'function') {
			me.rowChange(me);
		}
		me.updateRowsBlock();

		if ('function' == typeof me.onAddRow) {
			me.onAddRow(me, newRow);
		}
	},
	/**
	 * Remove button click handler
	 * @param	{Object}	btn
	 * @param	{Object}	rowWhereClickWas
	 */
	removeRowClickHandler(btn, rowWhereClickWas) {
		let me = this;
		me.remove(rowWhereClickWas);

		if (typeof me.rowChange === 'function') {
			me.rowChange(me);
		}
		me.updateRowsBlock();

		if ('function' == typeof me.onRemoveRow) {
			me.onRemoveRow();
		}
		if ('function' == typeof rowWhereClickWas?.contentFieldsPanel?.onRemoveRow) {
			rowWhereClickWas.contentFieldsPanel.onRemoveRow();
		}
	},
	/**
	 * Creates row with add/remove buttons
	 * @param   {Boolean}    isFirstRow
	 * @param	{any}	rowData
	 * @param	{any}	[props]	(костыль для упд)
	 * @returns	{Object}	row (container ot panel)
	 */
	createRowWithAddRemoveButtons(isFirstRow, rowData, props) {
		let me = this,
			contentContainer = me.createContentFieldsFn(isFirstRow, rowData || {}, props);

		let removeRowBtn = createButton({
			cls: [BUTTON_CLS.link, BUTTON_CLS.icon, BUTTON_CLS.small, 'edi-row-block-remove_button'],
			itemId: 'remove_button',
			hidden: me.hideRemoveOnFirstRow === true ? !!isFirstRow : false,
			permanentlyDisabled: props?.disableRemoveButton,
			glyph: edi.constants.ICONS.CLOSE,
			tooltip: edi.i18n.getMessage('form.btn.delete'),
			handler(btn) {
				me.removeRowClickHandler(btn, rowPanel);
			}
		});

		let rowPanel = me.createRowPanel(
			contentContainer,
			createContainer({
				layout: 'column',
				cls: 'row-block-buttons-container',
				items: [removeRowBtn]
			})
		);

		rowPanel.removeBtn = removeRowBtn;
		me.rowPanel = rowPanel;
		me.setExtraDataToRow(rowPanel);

		return rowPanel;
	},
	removeAllRows() {
		const me = this;
		const rows = [...me.getRows()];
		rows.forEach((row, rowIndex) => {
			const isLastRow = rowIndex === rows.length - 1;
			// Вызов removeRowClickHandler на последней строке нужен для вызова эвентов и апдейта панели
			isLastRow ? me.removeRowClickHandler(undefined, row) : me.remove(row);
		});
	},
	/**
	 * Modifies row after creation
	 * @param	{Object}	row
	 */
	setExtraDataToRow(row) {},

	updateRowsBlock() {
		let me = this;
		let rows = me.getRows() || [];
		rows.forEach((row, index) => {
			let el = row.el;
			if (el) {
				const isLastRow = index === rows.length - 1;
				el.setStyle({
					marginBottom: `${isLastRow ? me.addRowBtnGap : me.rowGap}px`
				});
			}
		});
		me.updateLayout();
	},
	/**
	 * Returns array of rows
	 * @returns	{Object[]}	Array of rows (containers or panels)
	 */
	getRows() {
		let me = this;
		return Ext.isArray(me.items?.items) ? me.items.items : [];
	},
	/**
	 * Returns index of row
	 * @param	{Object}	row		row instance (container or panel)
	 * @returns	{number}	index of row
	 */
	getRowIndex(row) {
		let me = this;
		return Ext.Array.indexOf(me.getRows(), row);
	}
});

const createRowsBlock = function (cfg) {
	return Ext.create('edi.components.RowsBlockV2', cfg);
};

export { createRowsBlock };
