import { SERVER_DATE_FORMAT } from './methods';

const PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME = 'partin_localizations';
const PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME = 'partin_headquarters';
const PARTIN_BRANCHES_MODEL_CONFIG_NAME = 'partin_branches';
const PARTIN_LICENSES_MODEL_CONFIG_NAME = 'partin_licenses';

edi.models.setModels({
	[PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME]: {
		//idProperty: 'iln',
		fields: [
			{
				name: 'name',
				type: 'string',
				useNull: true
			},
			{
				name: 'iln',
				type: 'string',
				useNull: true
			},
			{
				name: 'taxId',
				type: 'string'
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'enterpriseClassificationCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeBySender',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeByReceiver',
				type: 'string',
				useNull: true
			},
			{
				name: 'roomNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'housing',
				type: 'string',
				useNull: true
			},
			{
				name: 'houseNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'streetAndNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'cityName',
				type: 'string',
				useNull: true
			},
			{
				name: 'locality',
				type: 'string',
				useNull: true
			},
			{
				name: 'district',
				type: 'string',
				useNull: true
			},
			{
				name: 'state',
				type: 'string',
				useNull: true
			},
			{
				name: 'stateCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'postalCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'country',
				type: 'string',
				useNull: true
			},
			{
				name: 'additionalInformation',
				type: 'string',
				useNull: true
			},
			{
				name: 'tradeFormat',
				type: 'string',
				useNull: true
			},
			{
				name: 'format',
				type: 'string',
				useNull: true
			},
			{
				name: 'formatArea',
				type: 'string',
				useNull: true
			},
			{
				name: 'numberOfCashDesks',
				type: 'string',
				useNull: true
			},
			{
				name: 'status',
				type: 'string',
				useNull: true
			},
			{
				name: 'storeOpeningDate',
				type: 'string'
			},
			{
				name: 'dateNewOrders',
				type: 'string'
			},
			{
				name: 'contactPerson',
				type: 'string',
				useNull: true
			},
			{
				name: 'phoneNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'eMail',
				type: 'string',
				useNull: true
			},
			{
				name: 'latitude',
				type: 'string',
				useNull: true
			},
			{
				name: 'longitude',
				type: 'string',
				useNull: true
			},
			{
				name: 'headquarter'
				//type: 'object'
			},
			{
				name: 'branch'
				//type: 'object'
			},
			{
				name: 'franchisee'
				//type: 'object'
			}
		]
	},
	[PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME]: {
		//idProperty: 'iln',
		fields: [
			{
				name: 'iln',
				type: 'string',
				useNull: true
			},
			{
				name: 'taxId',
				type: 'string',
				useNull: true
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'utilizationRegisterNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'enterpriseClassificationCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeBySender',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeByReceiver',
				type: 'string',
				useNull: true
			},
			{
				name: 'name',
				type: 'string',
				useNull: true
			},
			{
				name: 'roomNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'housing',
				type: 'string',
				useNull: true
			},
			{
				name: 'houseNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'streetAndNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'cityName',
				type: 'string',
				useNull: true
			},
			{
				name: 'locality',
				type: 'string',
				useNull: true
			},
			{
				name: 'district',
				type: 'string',
				useNull: true
			},
			{
				name: 'state',
				type: 'string',
				useNull: true
			},
			{
				name: 'stateCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'postalCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'country',
				type: 'string',
				useNull: true
			},
			{
				name: 'additionalInformation',
				type: 'string',
				useNull: true
			},
			{
				name: 'checkingAccount',
				type: 'string',
				useNull: true
			},
			{
				name: 'correspondentAccount',
				type: 'string',
				useNull: true
			},
			{
				name: 'bankId',
				type: 'string',
				useNull: true
			},
			{
				name: 'bankName',
				type: 'string',
				useNull: true
			},
			{
				name: 'licenses',
				//type: 'object',
				useNull: true
			}
		]
	},
	[PARTIN_BRANCHES_MODEL_CONFIG_NAME]: {
		//idProperty: 'iln',
		fields: [
			{
				name: 'iln',
				type: 'string',
				useNull: true
			},
			{
				name: 'taxId',
				type: 'string',
				useNull: true
			},
			{
				name: 'taxRegistrationReasonCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'enterpriseClassificationCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeBySender',
				type: 'string',
				useNull: true
			},
			{
				name: 'codeByReceiver',
				type: 'string',
				useNull: true
			},
			{
				name: 'name',
				type: 'string',
				useNull: true
			},
			{
				name: 'roomNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'housing',
				type: 'string',
				useNull: true
			},
			{
				name: 'houseNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'streetAndNumber',
				type: 'string',
				useNull: true
			},
			{
				name: 'cityName',
				type: 'string',
				useNull: true
			},
			{
				name: 'locality',
				type: 'string',
				useNull: true
			},
			{
				name: 'district',
				type: 'string',
				useNull: true
			},
			{
				name: 'state',
				type: 'string',
				useNull: true
			},
			{
				name: 'stateCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'postalCode',
				type: 'string',
				useNull: true
			},
			{
				name: 'country',
				type: 'string',
				useNull: true
			},
			{
				name: 'additionalInformation',
				type: 'string',
				useNull: true
			},
			{
				name: 'headquarter',
				//type: 'object',
				useNull: true
			}
		]
	},
	[PARTIN_LICENSES_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'type',
				type: 'string'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'number',
				type: 'string'
			},
			{
				name: 'issuingAuthority',
				type: 'string'
			},
			{
				name: 'dateOfIssue',
				type: 'string'
			},
			{
				name: 'expirationDate',
				type: 'string'
			}
		]
	}
});

let getPartinLocalizationsModel = () => edi.models.getModel(PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME);
let getPartinHeadquartersModel = () => edi.models.getModel(PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME);
let getPartinBranchesModel = () => edi.models.getModel(PARTIN_BRANCHES_MODEL_CONFIG_NAME);
let getPartinLicensesModel = () => edi.models.getModel(PARTIN_LICENSES_MODEL_CONFIG_NAME);

export {
	getPartinLocalizationsModel,
	PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME,
	getPartinHeadquartersModel,
	PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME,
	getPartinBranchesModel,
	PARTIN_BRANCHES_MODEL_CONFIG_NAME,
	getPartinLicensesModel,
	PARTIN_LICENSES_MODEL_CONFIG_NAME
};
