import { createFields, createFieldsForProductGrid, createLabel } from '@Components/fields';
import { createActionsColumnConfig, createGrid, createToolBar } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createAddButton, createCancelButton, createSaveButton } from '@Components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createModalForm } from '@UIkit/components/panels';

/**
 * Creates panel with grid and fields
 * @param	{Object}	[data]
 * @param	{Object}	[config]
 * @returns	{Object}	Ext.Panel instance
 */
const createDeliveryCoordination = function (data, config) {
	data = data ? data : {};
	config = config ? config : {};
	Ext.applyIf(config, {
		deliveryDate: edi.utils.formatDate(new Date().getTime(), edi.constants.DATE_FORMAT.CLIENT),
		deliveryTime: '06:00'
	});

	var fields = [];
	if (config.creator) {
		fields = createFields(
			[
				{
					name: 'Name',
					type: 'label',
					title: edi.i18n.getMessage('full.name.abbr')
				},
				{
					name: 'TelephoneNumber',
					type: 'label',
					title: edi.i18n.getMessage('general.phone')
				},
				{
					name: 'E-mail',
					type: 'label',
					title: edi.i18n.getMessage('general.email.abbr')
				}
			],
			config.creator,
			null,
			{
				columnWidth: 0.5
			}
		);
		fields.unshift(
			createLabel({
				text: edi.i18n.getMessage('delivery.coordination'),
				cls: 'bold-label'
			})
		);
	}

	var createModal = function (record) {
		var isEdit = !!record;

		var recordData = isEdit
			? record.getData()
			: {
					TruckQuantity: 1
			  };
		if (isEdit) {
			recordData.DeliveryDate = edi.utils.formatDate(
				recordData.DeliveryDateTime,
				edi.constants.DATE_FORMAT.CLIENT,
				edi.constants.DATE_FORMAT.XS_DATE_TIME
			);
			recordData.DeliveryTime = edi.utils.formatDate(
				recordData.DeliveryDateTime,
				edi.constants.DATE_FORMAT.TIME,
				edi.constants.DATE_FORMAT.XS_DATE_TIME
			);
		} else {
			recordData.DeliveryDate = config.deliveryDate;
			recordData.DeliveryTime = config.deliveryTime;
		}

		var tmp = recordData.DeliveryTime.split(':');
		if (tmp.length == 3) {
			tmp.splice(2, 1);
			recordData.DeliveryTime = tmp.join(':');
		}

		var form = createModalForm({
			items: createFieldsForProductGrid(
				[
					{
						name: 'DeliveryDate',
						type: 'date',
						allowBlank: false
					},
					{
						name: 'DeliveryTime',
						type: 'time',
						allowBlank: false
					},
					{
						name: 'EndOfLoadingTime',
						type: 'time',
						allowBlank: true
					}
				],
				recordData
			)
		});
		var theRecord = record;
		var saveButton,
			modal = createModalPanel({
				title: edi.i18n.getMessage(isEdit ? 'title.delivery.edit' : 'title.delivery.create'),
				width: MODAL_SIZE.widthSmall,
				items: [form],
				buttons: [
					(saveButton = createSaveButton(
						function () {
							var values = edi.utils.collectFormValues(form);
							if (values.DeliveryTime.split(':').length === 2) {
								values.DeliveryTime += ':00';
							}
							var store = grid.getStore();
							if (isEdit) {
								!theRecord.get('TruckQuantity') ? theRecord.set('TruckQuantity', 1) : null;
								theRecord.set('DeliveryDateTime', values.DeliveryDate + 'T' + values.DeliveryTime);
								theRecord.set('EndOfLoadingTime', values.EndOfLoadingTime);
							} else {
								values.DeliveryDateTime = values.DeliveryDate + 'T' + values.DeliveryTime;
								delete values.DeliveryDate;
								delete values.DeliveryTime;
								var record = edi.models.createInstance('DELIVERY_COORDINATION', values);
								store.add(record);
							}
							store.commitChanges();
							modal.close();
						},
						{
							bindToForm: form,
							disabled: true
						}
					)),
					createCancelButton(function () {
						modal.close();
					})
				]
			});
		form.on('validitychange', function () {
			var hasInvalid = form.hasInvalidField();
			saveButton.setDisabled(hasInvalid);
		});
		modal.show();
		form.isValid();
		return modal;
	};

	var tools = config.readOnly ? [] : [createAddButton(() => createModal())];

	var columns = edi.columns.get('delivery_coordination');

	if (!config.readOnly) {
		columns.push(
			createActionsColumnConfig({
				items: [
					{
						glyph: edi.constants.ICONS.EDIT,
						testCls: 'test-action-column-edit',
						handler: function (grid, rowIndex) {
							createModal(grid.getStore().getAt(rowIndex));
						}
					},
					{
						glyph: edi.constants.ICONS.REMOVE,
						testCls: 'test-action-column-remove',
						handler: function (grid, rowIndex) {
							var store = grid.getStore();
							store.removeAt(rowIndex);
							store.commitChanges();
						}
					}
				]
			})
		);
	}

	var grid = createGrid({
		storeConfig: {
			model: edi.models.getModel('DELIVERY_COORDINATION'),
			proxy: createProxyConfig({
				type: 'memory',
				data: {
					items: data
				}
			}),
			remoteSort: false
		},
		gridConfig: {
			columns: columns,
			disablePaging: true,
			height: 250,
			bbar: {
				items: tools
			},
			title: edi.i18n.getMessage('delivery.coordination.grid'),
			cls: 'edi-delivery-coordination'
		},
		enableTextSelection: false
	});

	return grid;
};

export { createDeliveryCoordination };
